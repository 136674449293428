import api from ".";
import {isEmptyObj} from "../utils/utils";

/*  Youtube 데이터  */
export const getYoutube = async () => await api.get("/main/youtube");

/*  최근 등록매물, 최근 본 매물 데이터  */
export const getRecently = async userNo => await api.get(`/main/recently?userNo=${userNo}`);

/*  뉴스  */
export const getNews = async () => await api.get(`/main/news`);

/*  보노뉴스  */
export const getBonoNews = async () => await api.get(`/main/bonoNews`);

/*  review  */
export const getReviews = async () => await api.get(`/main/review`);

/*  recommend  */
export const getRecommend = async () => await api.get(`/main/recommend`);

/*  QNA  */
export const getQnas = async () => await api.get(`/main/qna`);

/* 인재채용 */
export const requestCreateResume = async (data) => {

    const formData = new FormData()
    const resumePersonalStatement = data.resumePersonalStatement || null;
    const resumeCareers = data.resumeCareers.filter(career => isEmptyObj(career));
    const resumeEducations = data.resumeEducations.filter(education => isEmptyObj(education));
    const resumeCertifications = data.resumeCertifications.filter(certificate => isEmptyObj(certificate));

    formData.append("resumeRequest", new Blob([JSON.stringify({...data, resumePersonalStatement,resumeCareers ,resumeEducations, resumeCertifications})], { type: "application/json" }));
    if (data.files.length > 0) formData.append("file", data.files[0]);
    return await api.post(`/main/resume`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
}
