import { getNumber, getCalcNumber } from '../../utils/number';

export const BASIC_FORM = {
  type: '단독요양원',
  capacity: '',
  totalCapacity: 0,
  duration: '',
  payDates: '',
  basePrice: 0,
};

// 급여정보 (등급별 인원)
export const GRADES_AND_PRICE = [
  { label: '1등급 인원', count: '', pay: 0 },
  { label: '2등급 인원', count: '', pay: 0 },
  { label: '3등급 인원', count: '', pay: 0 },
  { label: '5등급 인원', count: '', pay: 0 },
  { label: '5등급 인원', count: '', pay: 0 },
  { label: '인지지원등급 인원', count: 0, pay: 0 },
];

// 급여 시간
export const PAY_AND_TIMES = [
  { label: '1등급 인원', counts: ['', '', '', '', ''], total: 0 },
  { label: '2등급 인원', counts: ['', '', '', '', ''], total: 0 },
  { label: '3등급 인원', counts: ['', '', '', '', ''], total: 0 },
  { label: '4등급 인원', counts: ['', '', '', '', ''], total: 0 },
  { label: '5등급 인원', counts: ['', '', '', '', ''], total: 0 },
  { label: '인지지원등급 인원', counts: ['', '', '', '', ''], total: 0 },
];

// 급여 수가
export const PAYMENT = {
  common: [78200, 72550, 68510, 68510, 68510, 0],
  dayCare: [
    [36950, 49530, 61600, 67870, 72780],
    [34210, 45880, 57070, 62870, 67420],
    [31580, 42350, 52690, 58080, 62280],
    [30140, 40910, 51250, 56620, 60840],
    [28700, 39450, 49790, 55180, 59400],
    [28700, 39450, 49790, 49790, 49790],
  ],
};

// 단독상가 급여정보 계산
export const getCommonPayment = (payDates, originDatas) => {
  const datas = originDatas.map((data, idx) => {
    data.pay = data.count * payDates * PAYMENT['common'][idx];
    return data;
  });
  return datas;
};

// 주야간 급여정보 계산
export const getDayCarePayment = (payDates, payInfoData, dayCareData) => {
  const datas = payInfoData.map((data, idx) => {
    data.count = dayCareData[idx].total;
    data.pay =
      dayCareData[idx].counts.reduce(
        (acc, count, cIdx) => getCalcNumber(count) * PAYMENT['dayCare'][idx][cIdx] + acc,
        0
      ) * payDates;
    return data;
  });
  return datas;
};

// 추가 인력 배치
export const ADDITIONAL_LABOUR = [
  { label: '요양보호사', required: 0, working: '', additional: 0, perOne: 0, score: 0 },
  { label: '간호사', required: 0, working: '', additional: 0, perOne: 0, score: 0 },
  { label: '사회복지사', required: 0, working: '', additional: 0, perOne: 0, score: 0 },
  { label: '물리치료사', required: 0, working: '', additional: 0, perOne: 0, score: 0 },
  { label: '조리원', required: 0, working: '', additional: 0, perOne: 0, score: 0 },
  { label: '합계', required: 0, working: 0, additional: 0, perOne: 0, score: 0 },
];

// 추가 인력 배치 계산
export const getAdditionalLabour = (type, capacity, originDatas) => {
  const datas = [...originDatas];
  const isDayCare = type === '주야간보호센터';
  capacity = getCalcNumber(capacity);

  // 의무인원
  datas[0].required = Math.round(capacity / (isDayCare ? 7 : 2.5));
  datas[1].required = isDayCare ? (capacity > 40 ? 2 : 1) : Math.round(capacity / 25);
  datas[2].required = capacity === 0 ? 0 : isDayCare ? (capacity > 9 ? 1 : 0) : capacity > 100 ? 2 : 1;
  datas[3].required = isDayCare ? (capacity > 30 ? 1 : 0) : capacity > 100 ? 2 : capacity >= 30 ? 1 : 0;
  datas[4].required = isDayCare ? (capacity > 40 ? 2 : 1) : Math.round(capacity / 25);

  // 추가인원
  for (let data of datas) {
    data.additional = getCalcNumber(data.working) - data.required;
  }

  // 1인당 입소자
  datas[0].perOne = datas[0].working > 0 ? parseFloat(capacity / datas[0].working).toFixed(1) : 0;
  datas[1].perOne = datas[1].working > 0 ? parseFloat(capacity / datas[1].working).toFixed(1) : 0;

  // 가산점수
  datas[0].score =
    datas[0].additional > 0
      ? isDayCare
        ? datas[0].perOne < 6.4
          ? parseFloat(datas[0].additional * 1.2).toFixed(1)
          : 0
        : datas[0].perOne < 2.4
        ? parseFloat(datas[0].additional * 1.3).toFixed(1)
        : 0
      : 0;
  datas[1].score =
    datas[1].additional > 0
      ? isDayCare
        ? parseFloat(datas[1].additional * 1.2).toFixed(1)
        : parseFloat(datas[1].additional * 1.3).toFixed(1)
      : 0;
  datas[2].score = datas[2].additional > 0 ? (isDayCare ? 0 : parseFloat(datas[2].additional * 1.4).toFixed(1)) : 0;
  datas[3].score = datas[3].additional > 0 ? (isDayCare ? 0 : parseFloat(datas[3].additional * 1.4).toFixed(1)) : 0;
  datas[4].score = datas[4].additional > 0 ? datas[4].additional : 0;

  // 합계
  const totalIdx = datas.length - 1;
  for (let key in datas[totalIdx]) {
    if (key !== 'label') datas[totalIdx][key] = 0;
  }
  for (let i = 0; i < totalIdx; i++) {
    for (let key in datas[i]) {
      if (key !== 'label') datas[totalIdx][key] += Number(datas[i][key]);
    }
  }
  datas[totalIdx].score = parseFloat(datas[totalIdx].score).toFixed(1);
  return datas;
};

export const SERVICE_COUNT = [
  { label: '제공 안함', value: 0 },
  { label: '주 2~3회', value: 2 },
  { label: '주 4회', value: 4 },
];

export const ADD_STATE = {
  requiredLabour: 'O',
  addLabourScore: 0,
  nightLabourDay: '',
  nightLabourNight: '',
  nightLabourRate: 0,
  nightLabourScore: 0,
  nursingLabourScore: 0,
  serviceCount: 0,
  serviceScroe: 0,
  totalScore: 0,
};

// 가산 현황
export const getAddState = (capacity, labour, originDatas) => {
  const datas = { ...originDatas };
  const nightLabourDay = getCalcNumber(originDatas.nightLabourDay);
  const nightLabourNight = getCalcNumber(originDatas.nightLabourNight);

  const nightLabourRate =
    nightLabourDay > 0 && nightLabourNight > 0 ? parseFloat(nightLabourDay / nightLabourNight).toFixed(1) : 0;
  const nightLabourScore =
    nightLabourRate < 2 || capacity / nightLabourNight >= 21 || capacity <= 0
      ? 0
      : parseFloat(nightLabourNight * 0.9).toFixed(1);
  const serviceScore = datas['serviceCount'] > 0 ? datas['serviceCount'] / 10 : 0;

  datas['requiredLabour'] = getCalcNumber(labour[5]['working']) >= labour[5]['required'] ? 'O' : 'X';
  datas['addLabourScore'] = labour[5]['score'];
  datas['nightLabourRate'] = nightLabourRate;
  datas['nightLabourScore'] = nightLabourScore;
  datas['nursingLabourScore'] = labour[1].score;
  datas['serviceScore'] = serviceScore;
  datas['totalScore'] = (Number(nightLabourScore) + Number(labour[5]['score']) + Number(serviceScore)).toFixed(1);

  return datas;
};

const getRangeBase = (capacity) => {
  if (capacity >= 1 && capacity < 5) return 1.4;
  else if (capacity >= 5 && capacity < 10) return 2.6;
  else if (capacity >= 10 && capacity < 30) return 4.0;
  else if (capacity >= 30 && capacity < 50) return 6.0;
  else if (capacity >= 50 && capacity < 70) return 8.8;
  else if (capacity >= 70 && capacity < 80) return 10.0;
  else if (capacity >= 80 && capacity < 90) return 11.2;
  else if (capacity >= 90 && capacity < 120) return 13.6;
  else if (capacity >= 120) return 14.8;
  else return 0;
};

const getRangeAdds = (type, capacity, addedLabour) => {
  if (type === '주야간보호센터') return 0;

  if (capacity >= 120) {
    if (addedLabour >= 9) return 2.4;
    else if (addedLabour === 8) return 1.2;
    else return 0;
  } else if (capacity >= 90) {
    if (addedLabour >= 8) return 2.4;
    else if (addedLabour === 7) return 1.2;
    else return 0;
  } else if (capacity >= 70) {
    if (addedLabour >= 6) return 1.2;
    else return 0;
  } else if (capacity >= 50) {
    if (addedLabour >= 5) return 1.2;
    else return 0;
  } else if (capacity >= 30) {
    if (addedLabour >= 4) return 1.2;
    else return 0;
  } else {
    if (addedLabour >= 3) return 1.2;
    else return 0;
  }
};

// 예상 가산 금액
export const getAddResult = (baseInfo, addLabour, addState) => {
  const totalScore = getNumber(addState.totalScore);
  const serviceScore = baseInfo.type === '주야간보호센터' ? 1.5 : 1;
  const rangeBase = addState.totalScore <= 0 ? 0 : getRangeBase(baseInfo.totalCapacity);
  const rangeAdds = getRangeAdds(baseInfo.type, baseInfo.totalCapacity, addLabour[0].additional);
  const rangeSum = rangeBase + rangeAdds;

  const result = {
    basePrice: baseInfo.basePrice,
    addScore: addState.totalScore,
    scoreRangeBase: rangeBase,
    scoreRangeAdds: rangeAdds,
    monthlyCapacity: baseInfo.totalCapacity,
    serviceScore: serviceScore,
    monthlyPrice:
      baseInfo.basePrice > 0
        ? parseFloat(
            ((totalScore <= rangeSum ? baseInfo.basePrice * totalScore : baseInfo.basePrice * rangeSum) /
              baseInfo.totalCapacity) *
              serviceScore
          ).toFixed(0)
        : 0,
  };

  return result;
};
