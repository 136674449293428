import CenterList from '../../components/Center/CenterList/CenterList';
import Section from '../../components/ui/Section/Section';
import { isDesktop } from '../../utils/detect';
import ListTab from '../../components/List/ListTab/ListTab';
import React, { useState, useEffect } from 'react';
import { getUserScrapCenters, getUserScrapSales } from '../../api/user';
import { useSelector, useDispatch } from 'react-redux';
import { deactivateMyScrap } from '../../store/actions/page';

const SCRAP_CTG = {
  mamul: {
    title: '매물',
    index: 0,
  },
  sisul: {
    title: '시설',
    index: 1,
  },
};

const UserScrapContainer = () => {
  const dispatch = useDispatch();

  const USER_NO = useSelector((state) => state.User.userInfo).no;

  const [category, setCategory] = useState('mamul');
  const [total, setTotal] = useState(0);
  const [sisuls, setSisuls] = useState([]);
  const [mamuls, setMamuls] = useState([]);

  const [sisulNextIndex, setSisulNextIndex] = useState(0);
  const [sisulHasNext, setSisulHasNext] = useState(false);
  const [sisulIsNextLoading, setSisulIsNextLoading] = useState(false);

  const [mamulNextIndex, setMamulNextIndex] = useState(0);
  const [mamulHasNext, setMamulHasNext] = useState(false);
  const [mamulIsNextLoading, setMamulIsNextLoading] = useState(false);

  const sisulLoadNext = async () => {
    setSisulIsNextLoading(true);
    const RESPONSE = await getUserScrapCenters({ userNo: USER_NO, page: sisulNextIndex });
    if (RESPONSE && RESPONSE.data.code === 1) {
      setSisuls([...sisuls, ...RESPONSE.data.arrayResult]);
      setSisulHasNext(RESPONSE.data.pageCode === 1);
      setTotal(RESPONSE.data.totalCount);
    }
    setSisulIsNextLoading(false);
    setSisulNextIndex((sisulNextIndex) => sisulNextIndex + 1);
  };

  const mamulLoadNext = async () => {
    setMamulIsNextLoading(true);
    const RESPONSE = await getUserScrapSales({ userNo: USER_NO, page: mamulNextIndex });
    if (RESPONSE && RESPONSE.data.code === 1) {
      setMamuls([...mamuls, ...RESPONSE.data.arrayResult]);
      setMamulHasNext(RESPONSE.data.pageCode === 1);
      setTotal(RESPONSE.data.totalCount);
    }
    setMamulIsNextLoading(false);
    setMamulNextIndex((mamulNextIndex) => mamulNextIndex + 1);
  };

  const onCloseClick = () => {
    dispatch(deactivateMyScrap());
  };

  const changeCategory = (nav) => {
    setCategory(Object.keys(SCRAP_CTG).filter((key) => SCRAP_CTG[key].title === nav)[0]);
  };

  useEffect(() => {
    if (category === 'sisul') {
      setSisuls([]);
      setSisulNextIndex(1);
      setTotal(0);
    }
    if (category === 'mamul') {
      setMamuls([]);
      setMamulNextIndex(1);
      setTotal(0);
    }
  }, [category]);

  useEffect(() => {
    if (sisulNextIndex === 1) sisulLoadNext();
  }, [sisulNextIndex]);

  useEffect(() => {
    if (mamulNextIndex === 1) mamulLoadNext();
  }, [mamulNextIndex]);

  return (
    <Section
      title={`찜 매물(${total})`}
      themeColor={isDesktop ? 'primary' : 'secondary'}
      close={isDesktop && true}
      back={!isDesktop && true}
      onCloseClick={onCloseClick}
      onBackClick={onCloseClick}
      action={false}
    >
      <ListTab
        type={'full'}
        navs={Object.keys(SCRAP_CTG).map((key) => SCRAP_CTG[key].title)}
        onNavClick={changeCategory}
        active={category === 'mamul' ? 0 : 1}
        contents={[
          <CenterList
            type={'sub'}
            centers={mamuls}
            hasNext={mamulHasNext}
            isNextLoading={mamulIsNextLoading}
            loadNext={mamulLoadNext}
          />,
          <CenterList
            type={'sub'}
            centers={sisuls}
            hasNext={sisulHasNext}
            isNextLoading={sisulIsNextLoading}
            loadNext={sisulLoadNext}
          />,
        ]}
      />
    </Section>
  );
};

export default UserScrapContainer;
