import React from 'react';
import styled from 'styled-components';

const Container = styled.p({
  alignSelf: 'center',
  marginTop: '1rem',
  cursor: 'pointer',
  fontSize: 16,
  fontWeight: 400,
  color: '#616161',
});

const More = ({ children, onClick }) => {
  return <Container onClick={onClick}>{children}</Container>;
};

export default More;
