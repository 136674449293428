import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDesktop } from "../../utils/detect";
import Modal from '../../components/Modal/Modal';
import Section from '../../components/ui/Section/Section';
import CenterCalculator from '../../components/Calculators/CenterCalculator/CenterCalculator';
import { deactivateCalculator, resetCalculator } from '../../store/actions/service';
import { INCOME_DATASET, GET_INCOME_RESULT } from '../../scheme/calculator';

const CenterCalculatorContainer = () => {

    const dispatch = useDispatch();

    const calcData = useSelector(state => state.Service.calculatorData);

    const initialForm = Object.assign({}, INCOME_DATASET);
    const [ formData, setFormData ] = useState(initialForm);
    const [ result, setResult ] = useState(null);
    const [ resetAble, setResetAble ] = useState(false);
    const [ inputAble, setInputAble ] = useState(true);

    // 수익계산기 submit
    const submitForm = data => {
        // 현원수 기준 => 정원수 기준 계산식으로 수정(2022.09.02)
        // if(data.commons.length > 0) {
        if(data.capacity.length > 0) {
            dispatch(resetCalculator({
                input: data,
                output: GET_INCOME_RESULT(data)
            }))
        }
    };

    // 수익계산기 초기화
    const resetForm = event => {
        event.preventDefault();
        dispatch(resetCalculator());
        setFormData(Object.assign({}, INCOME_DATASET));
        setInputAble(true);
    };

    useEffect(() => {
        if(calcData) {
            setResult(calcData.output);
            setFormData(calcData.input);
            setResetAble(true);
            if(calcData.fixedData) setInputAble(false);
        } else {
            setFormData(initialForm);
            setResult(null);
            setResetAble(false);
            setInputAble(true);
        }
    }, [calcData]);
    

    const CALCULATOR_TEMPLATE = () => (
        <CenterCalculator
            initialData={ formData }
            onFormSubmit={ submitForm }
            onFormReset={ resetForm }
            resetAble={ resetAble }
            inputAble={ inputAble }
            result={ result }
        />
    );

    return (
        <> 
        {
            isDesktop &&
            <Modal
                open={ true }
                close={ true }
                onCloseClick={() => {dispatch(deactivateCalculator());}}
                title="수익 계산"
                width="970"
            >
            { CALCULATOR_TEMPLATE() }
            </Modal>
        }
        {
            !isDesktop &&
            <Section 
                zIndex="999"
                title="수익 계산"
                themeColor="primary"
                back={ true }
                onBackClick={() => {dispatch(deactivateCalculator());}}
            >
            { CALCULATOR_TEMPLATE() }
            </Section>
        }
        </>
    )
}

export default CenterCalculatorContainer;