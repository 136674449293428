import { GUIDE } from '../actions/guide';
import { USER_GUIDE } from '../../utils/user';

const initialState = {
  main: USER_GUIDE.isOff('main') ? false : true,
  details: USER_GUIDE.isOff('details') ? false : true,
  maemul: USER_GUIDE.isOff('maemul') ? false : true,
};

const GuideReducer = (state = initialState, action) => {
  switch (action.type) {
    case GUIDE.ACTIVATE:
      return { on: true };
    case GUIDE.DEACTIVATE:
      return { ...state, [action.payload.page]: false };
    default:
      return state;
  }
};

export default GuideReducer;
