import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deactivateLanding } from "../../store/actions/landing";
import ReactModal from "react-modal";
import LandingContainer from "../Landing/LandingContainer";
import Responsive from "../Landing/Responsive";
import GlobalHeader from "../../components/global/GlobalHeader/GlobalHeader";
import NewsContainer from "../News/NewsContainer";
import { isDesktop } from "../../utils/detect";
import MobileHeader from "../../components/global/GlobalHeader/MobileHeader";

const modalStyle = {
  overlay: {
    zIndex: "90",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  content: {
    zIndex: "100",
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#ffffff",
    width: "100%",
  },
};

const LandingModal = () => {
  const dispatch = useDispatch();
  const NEWS_MODE = useSelector(state => state.Page.news);

  const customStyle = Object.assign({}, modalStyle);

  return (
    <ReactModal
      isOpen={true}
      style={customStyle}
      appElement={document.getElementById("root") || undefined}
      onRequestClose={() => {
        dispatch(deactivateLanding());
      }}>
      {isDesktop ? <GlobalHeader /> : <MobileHeader />}
      {isDesktop ? <LandingContainer /> : <Responsive />}
      {NEWS_MODE && <NewsContainer />}
    </ReactModal>
  );
};

export default LandingModal;
