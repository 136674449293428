import React from 'react';
import { useDispatch } from 'react-redux';
import BuildCalculator from '../../components/Calculators/BuildCalculator/BuildCalculator';
import { isDesktop } from '../../utils/detect';
import Modal from '../../components/Modal/Modal';
import Section from '../../components/ui/Section/Section';
import { deactivateBuildCalculator } from '../../store/actions/service';

const BuildCalculatorContainer = () => {
  const dispatch = useDispatch();
  return (
    <>
      {isDesktop && (
        <Modal
          open={true}
          close={true}
          title="신규 시설 인원 산출"
          width="970"
          onCloseClick={() => dispatch(deactivateBuildCalculator())}
        >
          <BuildCalculator />
        </Modal>
      )}
      {!isDesktop && (
        <Section
          zIndex="90"
          title="신규 시설 인원 산출"
          themeColor="primary"
          back={true}
          onBackClick={() => dispatch(deactivateBuildCalculator())}
        >
          <BuildCalculator />
        </Section>
      )}
    </>
  );
};

export default BuildCalculatorContainer;
