import { useState, useEffect } from 'react';

export const useGet = (initialValue) => {
  const [api, setApi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [result, setResult] = useState(initialValue);

  const getResponse = async (api) => {
    const response = await api;
    if (response) {
      setResult(response.data);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const initProps = () => {
    setLoading(true);
    setError(false);
  };

  useEffect(() => {
    if (api !== null) {
      initProps();
      getResponse(api);
    }
  }, [api]);

  return [loading, error, result, setApi];
};

export const usePageGet = (api = () => {}, apiOption = {}) => {
  const [pageApi, setPageApi] = useState({ api: api, option: apiOption });
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);

  const loadData = async (loadApi, page) => {
    setIsPageLoading(true);
    const RESPONSE = await loadApi;

    if (RESPONSE && RESPONSE.data) {
      setData([...data, ...RESPONSE.data.arrayResult]);
      setHasNext(RESPONSE.data.pageCode === 1);
      setMessage('');
    } else {
      setHasNext(false);
      setMessage('결과를 조회할 수 없습니다');
    }
    setIsPageLoading(false);
    setPageIndex(page);
  };

  const setApi = (api = () => {}, apiOption = {}) => {
    setPageApi({ api: api, option: apiOption });
    setHasNext(false);
    setIsPageLoading(false);
    setMessage('');
    setData([]);
    setPageIndex(1);
  };

  useEffect(() => {
    if (pageIndex > 0) loadData(api({ ...apiOption, page: pageIndex }));
  }, [pageIndex]);

  return [isPageLoading, hasNext, message, pageIndex, data, setPageIndex, setApi];
};
