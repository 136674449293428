import React from 'react';
import { ErrorWrap } from './ErrorPageStyled';
import Main from '../Main/Main';
import errorIcon from '../../../assets/images/icon/img-error.png';

const ErrorPage = () => {
  return (
    <Main>
      <ErrorWrap>
        <div className="wrap">
          <img alt="없는 페이지" src={errorIcon} />
          <div className="inform">
            <strong>
              죄송합니다.
              <br />
              요청하신 페이지를 찾을 수 없습니다.
            </strong>
            <p>
              잘못된 url 주소를 입력하셨거나 찾을 수 없는 경로입니다.
              <br />
              올바른 주소로 입력하였는지 다시한번 확인해주세요.
            </p>
            <button onClick={() => window.location.replace('/')}>홈페이지 돌아가기</button>
          </div>
        </div>
      </ErrorWrap>
    </Main>
  );
};

export default ErrorPage;
