import React from 'react';
import { InformBox, LoadingCircle, LoadingDots } from './InformStyled';

export const Loading = (text = null) => (
  <InformBox>
    {text && <span> {text} </span>}
    {!text && <LoadingCircle />}
  </InformBox>
);

export const MapLoading = () => (
  <InformBox>
    <LoadingCircle type="map" />
  </InformBox>
);

export const MoreLoading = () => (
  <InformBox>
    <LoadingDots>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingDots>
  </InformBox>
);

export const NoData = (text = null) => (
  <InformBox>
    {text && <span> {text} </span>}
    {!text && <span>결과 없음</span>}
  </InformBox>
);

export const Error = (text = null) => (
  <InformBox>
    {text && <pre> {text} </pre>}
    {!text && <span>조회 실패</span>}
  </InformBox>
);
