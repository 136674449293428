import { CHART } from '../actions/chart';

const initialState = {
  active: true,
  data: null,
};

const ChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHART.ACTIVATE:
      return { ...state, active: true };
    case CHART.DEACTIVATE:
      return { ...state, active: false };
    case CHART.UPDATE:
      const data = action.payload;
      if (data) return { ...state, data: data };
      else return initialState;
    default:
      return state;
  }
};

export default ChartReducer;
