import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { isDesktop } from "../utils/detect";
import { useSelector } from "react-redux";
import GlobalHeader from "../components/global/GlobalHeader/GlobalHeader";
import MobileHeader from "../components/global/GlobalHeader/MobileHeader";
import MobileTabBar from "../components/global/MobileTabBar/MobileTabBar";
import Main from "../components/ui/Main/Main";
import MapContainer from "../containers/Map/MapContainer";
import QuickMenu from "../components/global/QuickMenu/QuickMenu";
import CenterCalculatorContainer from "../containers/Calculators/CenterCalculatorContainer";
import BuildCalculatorContainer from "../containers/Calculators/BuildCalculatorContainer";
import AddsCalculatorContainer from "../containers/Calculators/AddsCalculatorContainer";
import SignupContaienr from "../containers/Account/SignupContainer";
import FindPwdContainer from "../containers/Account/FindPwdContainer";
import FindIdContainer from "../containers/Account/FindIdContainer";
import LoginContainer from "../containers/Account/LoginContainer";
import MapChartContainer from "../containers/MapChart/MapChartContainer";
import LoginRequired from "../components/global/LoginRequired/LoginRequired";
import Alert from "../containers/Alert/Alert";
import ContactContaier from "../containers/Contact/ContactContainer";
import RegisterContainer from "../containers/Register/RegisterContainer";
/* page */
import NewsContainer from "../containers/News/NewsContainer";
import UserMenuContainer from "../containers/User/UserMenuContainer";
import UserInfoContainer from "../containers/User/UserInfoContainer";
import UserScrapContainer from "../containers/User/UserScrapContainer";
import UserRecentContainer from "../containers/User/UserRecentContainer";
import UserAlarmContainer from "../containers/User/UserAlarmContainer";
import UserAlarmFormContainer from "../containers/User/UserAlarmFormContainer";
import SiteGuide from "../components/Guide/SiteGuide/SiteGuide";
import MaemulGuide from "../components/Guide/MaemulGuide/MaemulGuide";
import ContactFloatingButton from "../components/ui/FloatingButton/ContactFloatingButton";
import RemodelingContactContainer from "../containers/Remodelings/RemodelingContactContainer";
import RemodelingJoinContainer from "../containers/Remodelings/RemodelingJoinContainer";
import QnaModal from "../components/Landing/QnaModal";
import LandingModal from "../containers/Landing/LandingModal";
import ResumeContainer from "../containers/Resume/ResumeContainer";

const MainView = () => {
    const IS_LOGGEDIN = useSelector(state => state.User.loggedIn);

    const location = useLocation();
    const GUIDE_MODE = useSelector(state => state.Guide);

    const [pageGuide, setPageGuide] = useState(null);
    const [maemulGuide, setMaemulGuide] = useState(null);

    const SIGNUP_MODE = useSelector(state => state.Service.signup);
    const LOGIN_MODE = useSelector(state => state.Service.login);
    const FIND_ID_MODE = useSelector(state => state.Service.findId);
    const FIND_PWD_MODE = useSelector(state => state.Service.findPwd);
    const LOGIN_REQUIRED_MODE = useSelector(state => state.Service.loginRequired);
    const CALCULATOR_MODE = useSelector(state => state.Service.calculator);
    const ADDS_CALCULATOR_MODE = useSelector(state => state.Service.addsCalculator);
    const BUILD_CALCULATOR_MODE = useSelector(state => state.Service.buildCalculator);
    const ALERT_MODE = useSelector(state => state.Alert.active);
    const CONTACT_MODE = useSelector(state => state.Service.contact);
    const CONTACT_DATA = useSelector(state => state.Service.contactData);
    const REGISTER_MODE = useSelector(state => state.Service.register);
    const REMODELING_CONTACT_MODE = useSelector(state => state.Service.remodelingContact);
    const REMODELING_JOIN_MODE = useSelector(state => state.Service.remodelingJoin);

    const NEWS_MODE = useSelector(state => state.Page.news);
    // const BONO_NEWS_MODE = useSelector(state => state.Page.bonoNews);

    const MY_MENU_MODE = useSelector(state => state.Page.myMenu);
    const MY_INFO_MODE = useSelector(state => state.Page.myInfo);
    const MY_ALARM_MODE = useSelector(state => state.Page.myAlarm);
    const MY_ALARM_FORM_MODE = useSelector(state => state.Page.myAlarmForm);
    const MY_RECENT_MODE = useSelector(state => state.Page.myRecent);
    const MY_SCRAP_MODE = useSelector(state => state.Page.myScrap);

    const IS_LANDING = useSelector(state => state.Landing.landing);

    const QNA_MODE = useSelector(state => state.Service.qna);

    const RESUME_MODE = useSelector(state => state.Service.resume);

    // 페이지, 매물 가이드 설정
    useEffect(() => {
        if (location.pathname === "/" && GUIDE_MODE.main) {
            if (GUIDE_MODE.main && !IS_LANDING) setPageGuide("main");
            else setPageGuide(null);
        } else if (location.pathname.includes("/center") && GUIDE_MODE.details) {
            setPageGuide("details");
        } else {
            setPageGuide(null);
        }

        if (location.pathname === "/" && !isDesktop && !GUIDE_MODE.main) {
            if (GUIDE_MODE.maemul) setMaemulGuide(true);
            else setMaemulGuide(false);
        }
    }, [location, GUIDE_MODE, IS_LANDING]);

    return (
        <>
            {isDesktop && <GlobalHeader />}
            {!isDesktop && <MobileTabBar />}
            {!isDesktop && IS_LANDING && <MobileHeader />}

            {!isDesktop && !IS_LANDING && (
                <ContactFloatingButton
                    customStyle={{ zIndex: "10", position: "fixed", bottom: "64px", right: "10px" }}
                    tel="16610169"
                >
                    <i
                        className="bono-guide"
                        data-guide="m11"
                        style={{
                            position: "absolute",
                            zIndex: "-1",
                            top: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                        }}
                    />
                </ContactFloatingButton>
            )}

            <Main>
                <QuickMenu />
                <Outlet />

                {NEWS_MODE && <NewsContainer />}
                {IS_LOGGEDIN && MY_MENU_MODE && <UserMenuContainer />}
                {IS_LOGGEDIN && MY_INFO_MODE && <UserInfoContainer />}
                {IS_LOGGEDIN && MY_RECENT_MODE && <UserRecentContainer />}
                {IS_LOGGEDIN && MY_SCRAP_MODE && <UserScrapContainer />}
                {IS_LOGGEDIN && MY_ALARM_MODE && <UserAlarmContainer />}
                {IS_LOGGEDIN && MY_ALARM_FORM_MODE && <UserAlarmFormContainer />}
                {!IS_LOGGEDIN && SIGNUP_MODE && <SignupContaienr />}
                {!IS_LOGGEDIN && LOGIN_MODE && <LoginContainer />}
                {!IS_LOGGEDIN && FIND_ID_MODE && <FindIdContainer />}
                {FIND_PWD_MODE && <FindPwdContainer />}
                {CALCULATOR_MODE && <CenterCalculatorContainer />}
                {ADDS_CALCULATOR_MODE && <AddsCalculatorContainer />}
                {BUILD_CALCULATOR_MODE && <BuildCalculatorContainer />}
                {CONTACT_MODE && <ContactContaier centerData={CONTACT_DATA} />}
                {(REGISTER_MODE) && <RegisterContainer />}

                {REMODELING_CONTACT_MODE && <RemodelingContactContainer />}
                {REMODELING_JOIN_MODE && <RemodelingJoinContainer />}
                {ALERT_MODE && <Alert />}

                {IS_LANDING && <LandingModal />}

                <MapChartContainer />
                <MapContainer />
                {QNA_MODE && <QnaModal />}
                {RESUME_MODE && <ResumeContainer />}
                {!IS_LOGGEDIN && LOGIN_REQUIRED_MODE && <LoginRequired />}
            </Main>

            {pageGuide && <SiteGuide page={pageGuide} />}
            {maemulGuide && <MaemulGuide />}
        </>
    );
};

export default MainView;
