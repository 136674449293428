import React from 'react';
import { Menus, Logout } from './UserMenuStyle';
import { Link } from 'react-router-dom';
import { isDesktop } from '../../../utils/detect';
import { logout } from '../../../store/actions/user';
import { useDispatch } from 'react-redux';
import {
  activateMyAlarm,
  activateMyInfo,
  activateMyRecent,
  activateMyScrap,
  deactivateMyMenu,
} from '../../../store/actions/page';
import { activateLanding } from '../../../store/actions/landing';

const UserMenu = () => {
  const dispatch = useDispatch();

  return (
    <Menus>
      <button onClick={() => dispatch(activateMyInfo())}>회원 정보 변경</button>
      <button onClick={() => dispatch(activateMyScrap())}>찜 매물</button>
      <button onClick={() => dispatch(activateMyRecent())}>최근 본 매물</button>
      <button onClick={() => dispatch(activateMyAlarm())}>알림 매물</button>
      <Link target="_blank" to="/terms">
        약관 정책
      </Link>
      <Logout
        className={!isDesktop && 'mobile'}
        onClick={() => {
          dispatch(deactivateMyMenu());
          dispatch(logout());
          dispatch(activateLanding());
          window.location.reload();
        }}
      >
        로그아웃
      </Logout>
    </Menus>
  );
};

export default UserMenu;
