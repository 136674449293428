import { useDispatch, useSelector } from 'react-redux';
import { deactivateAlert } from '../../store/actions/alert';
import Modal from '../../components/Modal/Modal';
import React from 'react';

const Alert = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.Alert.message);
  const closeAction = useSelector((state) => state.Alert.action);

  const closeModal = () => {
    dispatch(deactivateAlert());
  };

  const modalProps = {
    open: true,
    width: '300',
    close: true,
    closeAction: true,
    onCloseClick: closeModal,
    onActionClick: closeAction,
    title: message.title,
  };

  return <Modal {...modalProps}>{message.contents}</Modal>;
};

export default Alert;
