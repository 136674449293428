import styled from 'styled-components';

export const SwipeBox = styled.div`
  z-index: 31;
  position: fixed;
  top: 45%;
  left: 0;
  width: 100%;
  height: 55vh;
  padding: 24px 0 56px;
  background-color: #fff;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.12);
  transition: top 0.3s;
  border-radius: 20px 20px 0 0;

  &.hide {
    top: calc(100% - 96px);
  }
`;

export const SwipeArea = styled.div`
  z-index: 31;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: transparent;

  & + * {
    z-index: 30;
    position: relative;
  }
`;

export const SwipeIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 7px;
    width: 38px;
    height: 4px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.gray200};
  }
  &::before {
    left: calc(50% - 38px);
    transform-origin: bottom left;
    transform: rotate(10deg);
  }
  &::after {
    left: calc(50% - 4px);
    transform-origin: bottom right;
    transform: rotate(-10deg);
  }
  .hide & {
    &::before,
    &::after {
      top: 14px;
    }
    &::before {
      transform: rotate(-10deg);
    }
    &::after {
      transform: rotate(10deg);
    }
  }
`;
