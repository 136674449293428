import React, { useState } from "react";
import { Header, HomeLink, Menu, NavMenu, Nav, UtilMenu, Calculate, Hello, Button } from "./GlobalHeaderStyle";
import { useDispatch } from "react-redux";
import { activateSignup, activateLogin, activateContact, activateRegister } from "../../../store/actions/service";
import { useSelector } from "react-redux";
import CalculatorsMenu from "../CalculatorsMenu/CalculatorsMenu";
import RemodelingMenu from "../RemodelingMenu";
import { activateAlert } from "../../../store/actions/alert";
import { activateLanding, deactivateLanding } from "../../../store/actions/landing";
import { resetFilter } from "../../../store/actions/filter";
import {updateMapInfoWindow} from "../../../store/actions/map";

const GlobalHeader = () => {
    const dispatch = useDispatch();
    const LOGGEDIN = useSelector(state => state.User.loggedIn);
    const USER_NAME = useSelector(state => state.User.userInfo.name);
    const IS_LANDING = useSelector(state => state.Landing.landing);

    const [calcMenuOn, setCalcMenuOn] = useState(false);
    const [rmdMenuOn, setRmdMenuOn] = useState(false);

    const onCalcClick = e => {
        e.preventDefault();

        if (LOGGEDIN) {
            setCalcMenuOn(true);
        } else {
            dispatch(
                activateAlert({
                    title: "보노랜드 회원 서비스",
                    contents: "해당 기능은 회원가입 후 사용하실 수 있습니다.",
                })
            );
        }
    };

    const onLogoClick = () => {
        dispatch(IS_LANDING ? activateLanding() : deactivateLanding());
        window.location.href = '/';
    };

    const onHomeClick = () => {
        dispatch(IS_LANDING ? deactivateLanding() : activateLanding());
    }

    return (
        <Header>
            <HomeLink onClick={onLogoClick} />

            <Menu>
                <NavMenu>
                    <Nav>
                        <button className="bono-guide" data-guide="1" onClick={onHomeClick}>
                            {IS_LANDING ? "지도" : "홈"}
                        </button>
                    </Nav>

                    <Nav>
                        <button
                            className="bono-guide"
                            data-guide="1-2"
                            onClick={() => {
                                dispatch(activateRegister());
                            }}>
                            매물접수
                        </button>
                    </Nav>

                    <Nav>
                        <button
                            className="bono-guide"
                            data-guide="1-3"
                            onClick={() => {
                                dispatch(activateContact());
                            }}>
                            매수문의
                        </button>
                    </Nav>

                    <Nav>
                        <button
                            onClick={() => {
                                setRmdMenuOn(rmdMenuOn => !rmdMenuOn);
                            }}>
                            리모델링
                        </button>
                        <RemodelingMenu
                            on={rmdMenuOn}
                            setMenuOff={() => {
                                setRmdMenuOn(false);
                            }}
                        />
                    </Nav>

                    <Nav>
                        <button
                            onClick={() => {
                                window.open(
                                    "https://docs.google.com/forms/d/e/1FAIpQLScb_XqWZTy3T8EhjMezOeERpFzVS-Y_WLW4qD4frzLtYFpktQ/viewform?pli=1"
                                );
                            }}>
                            창업특강
                        </button>
                    </Nav>
                </NavMenu>

                <UtilMenu>
                    {LOGGEDIN && <Hello>안녕하세요, {USER_NAME} 님!</Hello>}

                    {!LOGGEDIN && (
                        <>
                            <Button
                                className="bono-guide"
                                data-guide="2"
                                onClick={() => {
                                    dispatch(activateLogin());
                                }}>
                                로그인
                            </Button>

                            <Button
                                background="#8B653E"
                                color="white"
                                onClick={() => {
                                    dispatch(activateSignup());
                                }}>
                                회원가입
                            </Button>
                        </>
                    )}

                    <Calculate className="bono-guide" data-guide="3" onClick={onCalcClick}>
                        수익 계산기
                    </Calculate>
                </UtilMenu>

                {calcMenuOn && (
                    <CalculatorsMenu
                        onCloseClick={() => {
                            setCalcMenuOn(false);
                        }}
                    />
                )}
            </Menu>
        </Header>
    );
};

export default GlobalHeader;
