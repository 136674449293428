import React, { useState, useEffect } from "react";
import { module } from "../../themes/module";
import { useForm } from "react-hook-form";
import { REGEXP } from "../../scheme/form";

const initValue = {
    resumeName: null,
    resumeBirth: null,
    resumePhoneNumber: null,
    resumeEmail: null,
    resumeAddress: null,
    resumePersonalStatement: null,
    files: null,
    resumeCareers: [
        {
            careerDuration: null,
            history: null,
            position: null,
            duty: null,
        }
    ],
    resumeEducations: [
        {
            school: null,
            graduateStatus: null,
            educationDuration: null,
        }
    ],
    resumeCertifications: [
        {
            acquisitionDate: null,
            certificationName: null,
            publisher: null,
            passStatus: null,
        }
    ]
}

const ResumeRegister = ({device,onFormSubmit}) => {

    const [_, setSubmitAble] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        getValues,
    } = useForm(initValue);

    const formWatching = watch();

    useEffect(() => {
        setSubmitAble(
            Object.keys(formWatching).filter(key =>
                typeof formWatching[key] === "boolean" ? formWatching[key] === false : formWatching[key].length <= 0
            ).length <= 0
        );
    }, [formWatching]);

    return (
        <module.scrollWrapper style={{ height: "500px", width: "100%" }}>
            <module.TableForm onSubmit={handleSubmit(onFormSubmit)}>
                <fieldset>
                    <legend>인적사항</legend>
                    <table>
                        <colgroup>
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th colSpan="1" rowSpan="1">
                                <label htmlFor="resumeName">성명</label>
                            </th>
                            <td colSpan="1" rowSpan="1">
                                <input
                                    type="text"
                                    name="resumeName"
                                    id="resumeName"
                                    {...register("resumeName", {
                                        required: { value: true, message: "지원자 정보를 모두 입력 시 지원이 가능합니다." },
                                    })}
                                />
                            </td>
                            <th colSpan="1" rowSpan="1">
                                <label htmlFor="resumeBirth">생년월일</label>
                            </th>
                            <td colSpan="1" rowSpan="1">
                                <input
                                    type="text"
                                    name="resumeBirth"
                                    id="resumeBirth"
                                    {...register("resumeBirth", {
                                        required: { value: true, message: "지원자 정보를 모두 입력 시 지원이 가능합니다." },
                                    })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th colSpan="1" rowSpan="1">
                                <label htmlFor="resumePhoneNumber">연락처</label>
                            </th>
                            <td colSpan="1" rowSpan="1">
                                <input
                                    type="text"
                                    name="resumePhoneNumber"
                                    id="resumePhoneNumber"
                                    {...register("resumePhoneNumber", {
                                        required: { value: true, message: "지원자 정보를 모두 입력 시 지원이 가능합니다." },
                                        pattern: { value: REGEXP.phone, message: "휴대폰 번호를 정확히 입력해 주세요." },
                                    })}
                                />
                            </td>
                            <th colSpan="1" rowSpan="1">
                                <label htmlFor="resumeEmail">이메일</label>
                            </th>
                            <td colSpan="1" rowSpan="1">
                                <input
                                    type="text"
                                    name="resumeEmail"
                                    id="resumeEmail"
                                    {...register("resumeEmail", {
                                        required: { value: true, message: "지원자 정보를 모두 입력 시 지원이 가능합니다." },
                                        pattern: { value: REGEXP.email, message: "이메일 주소를 정확히 입력해 주세요." },
                                    })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label htmlFor="resumeAddress">주소</label>
                            </th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    name="resumeAddress"
                                    id="resumeAddress"
                                    {...register("resumeAddress", {
                                        required: { value: true, message: "지원자 정보를 모두 입력 시 지원이 가능합니다." },
                                    })}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {Object.keys(errors).length > 0 && <span style={{marginTop: 8, fontSize: 12}} className="warn">{errors[Object.keys(errors)[0]].message}</span>}
                </fieldset>
                <fieldset>
                    <legend>학력</legend>
                    <table>
                        <colgroup>
                            <col width="33%" />
                            <col width="33%" />
                            <col width="33%" />
                        </colgroup>
                        <tbody className={"tableRow"}>
                        <tr>
                            <th>
                                <label htmlFor="resumeEducations.educationDuration">기간</label>
                            </th>
                            <th>
                                <label htmlFor="resumeEducations.school">학교</label>
                            </th>
                            <th>
                                <label htmlFor="resumeEducations.graduateStatus">졸업여부</label>
                            </th>
                        </tr>
                        {[0, 1, 2].map(i => (
                            <tr key={i}>
                                <td>
                                    <input
                                        type="text"
                                        name={`resumeEducations[${i}].educationDuration`}
                                        id={`resumeEducations[${i}].educationDuration`}
                                        {...register(`resumeEducations[${i}].educationDuration`)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name={`resumeEducations[${i}].school`}
                                        id={`resumeEducations[${i}].school`}
                                        {...register(`resumeEducations[${i}].school`)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name={`resumeEducations[${i}].graduateStatus`}
                                        id={`resumeEducations[${i}].graduateStatus`}
                                        {...register(`resumeEducations[${i}].graduateStatus`)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>경력</legend>
                    <table>
                        <colgroup>
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                        </colgroup>
                        <tbody className={"tableRow"}>
                        <tr>
                            <th>
                                <label htmlFor="resumeCareers.careerDuration">기간</label>
                            </th>
                            <th>
                                <label htmlFor="resumeCareers.history">경력사항</label>
                            </th>
                            <th>
                                <label htmlFor="resumeCareers.position">직급</label>
                            </th>
                            <th>
                                <label htmlFor="resumeCareers.duty">담당업무</label>
                            </th>
                        </tr>
                        {
                            [0,1,2].map(i => (
                                <tr key={i}>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCareers[${i}].careerDuration`}
                                            id={`resumeCareers[${i}].careerDuration`}
                                            {...register(`resumeCareers[${i}].careerDuration`)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCareers[${i}].history`}
                                            id={`resumeCareers[${i}].history`}
                                            {...register(`resumeCareers[${i}].history`)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCareers[${i}].position`}
                                            id={`resumeCareers[${i}].position`}
                                            {...register(`resumeCareers[${i}].position`)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCareers[${i}].duty`}
                                            id={`resumeCareers[${i}].duty`}
                                            {...register(`resumeCareers[${i}].duty`)}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>자격증</legend>
                    <table>
                        <colgroup>
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="25%" />
                        </colgroup>
                        <tbody className={"tableRow"}>
                        <tr>
                            <th>
                                <label htmlFor="resumeCertifications.acquisitionDate">취득일</label>
                            </th>
                            <th>
                                <label htmlFor="resumeCertifications.certificationName">자격명</label>
                            </th>
                            <th>
                                <label htmlFor="resumeCertifications.publisher">발행처</label>
                            </th>
                            <th>
                                <label htmlFor="resumeCertifications.passStatus">합격여부</label>
                            </th>
                        </tr>
                        {
                            [0,1,2].map(i => (
                                <tr key={i}>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCertifications[${i}].acquisitionDate`}
                                            id={`resumeCertifications[${i}].acquisitionDate`}
                                            {...register(`resumeCertifications[${i}].acquisitionDate`)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCertifications[${i}].certificationName`}
                                            id={`resumeCertifications[${i}].certificationName`}
                                            {...register(`resumeCertifications[${i}].certificationName`)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCertifications[${i}].publisher`}
                                            id={`resumeCertifications[${i}].publisher`}
                                            {...register(`resumeCertifications[${i}].publisher`)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name={`resumeCertifications[${i}].passStatus`}
                                            id={`resumeCertifications[${i}].passStatus`}
                                            {...register(`resumeCertifications[${i}].passStatus`)}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>자기소개서</legend>
                    <table>
                        <tbody className={"tableRow"}>
                        <tr>
                            <td colSpan="3">
                              <textarea
                                  name="resumePersonalStatement"
                                  id="resumePersonalStatement"
                                  {...register("resumePersonalStatement")}></textarea>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </fieldset>
                <fieldset>
                    <legend>
                        첨부파일
                        <module.FileAddIcon>
                            +
                            <input type="file" name={`files`} id={`files`} {...register(`files`)} multiple/>
                        </module.FileAddIcon>
                    </legend>
                   {watch("files")?.length > 0
                       ?    <module.FileList>
                               <button
                                   type={"button"}
                                   onClick={()=>setValue("files", "")}
                               >-</button>
                               <span>{watch("files")[0].name}</span>
                            </module.FileList>
                       :    <span>첨부파일을 등록해주세요.</span>}
                </fieldset>
                <module.Agreement>
                    <div className="inform">
                        <p style={{margin: 0}}>
                            위의 모든 내용은 사실과 다름 없음을 확인하며,
                            보노랜드 중개 영업사원 채용을 지원합니다.
                            <br />
                            <strong>지원자 : {watch('resumeName')} </strong>
                        </p>
                    </div>
                </module.Agreement>

                <button type="submit">
                    지원하기
                </button>
            </module.TableForm>
        </module.scrollWrapper>
    );
};

export default ResumeRegister;
