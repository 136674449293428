
/* === 한국 숫자 단위로 산출 === */
export const getLocalNumber = (value = 0) => {    
    return isNaN(value)? value : (Number(value)).toLocaleString();
};
const CURRENT_UNITS = [
    { 
        base: 100000000, 
        unit: '억',  
        calc(value){
            // let eok = parseInt(CALCULATOR.divide(value, this.base));
            // let cheon = parseInt(CALCULATOR.divide((value - eok * this.base), this.base/10));
            // return `${eok}${ cheon> 0? `.${cheon}` : '' }억`;
            
            let stringNum = value.toString();
            let numLen = stringNum.length;

            let eok = stringNum.substring(0, numLen - 8);
            let cheon = stringNum.substring(numLen - 8, numLen - 7);
            let baek = stringNum.substring(numLen - 7, numLen - 6);

            if(cheon === '0' && baek === '0') return `${eok}억`;
            else return `${eok}.${cheon}${baek ==='0'? '' : baek}억`

        }
    },
    { 
        base: 10000000, 
        unit: '천만',
        calc(value) {
            return parseInt(value/this.base) + this.unit;
        }
    },
    { 
        base: 1000000, 
        unit: '백만', 
        calc(value) {
            let baek = parseInt(CALCULATOR.divide(value, this.base));
            let sip = parseInt(CALCULATOR.divide((value - baek * this.base), this.base/10));

            return `${baek}백${ sip> 0? ` ${sip}십만` : '만' }`;
        }
    },
    { 
        base: 100000, 
        unit: '십만',
        calc(value) {
            return parseInt(value/this.base) + this.unit;
        }
    },
    { 
        base: 1, 
        unit: '',
        calc(value) {
            return value + '';
        }
    }
];
/* === 억/천 단위 통화 산출 === */
export const getLocalCurrent = value => {
    let num = value
    let result = '';
    
    if(typeof value === "string") num = getNumber(num);

    if(isNaN(num) || num <= 0){
        result =  '-';

    } else {
        for(let unit of CURRENT_UNITS) {
            if(value >= unit.base) {
                return unit.calc(value);
            }
        }
    }

    return result;
};

/* === NUMBER 타입 숫자로 산출 === */
export const getNumber = (strValue = "") => {
    let value = strValue.toString().replaceAll(',', '');
    return isNaN(value)? value : Number(value);
};

/* === 문자열에서 NUMBER 타입 숫자로 산출 (공백시 0으로 치환)== */
export const getCalcNumber = (value) => {
    if(value === '') return 0;
    if(typeof value === 'number') return value;
    let numValue = getNumber(value);
    return isNaN(numValue)? 0 : numValue;
};

/* === 문자열에서 number만 반환 === */
export const convertToNum = value => {
    const num = getNumber(value);
    if(isNaN(num)) return value.substring(0, value.length - 1);
    else return getLocalNumber(num);
};

/* === 면적 > 평수 산출 === */
export const getPyeong = size => {
    const pyeong = parseFloat(getNumber(size) / 3.3058).toFixed(2);
    const result  = isNaN(pyeong)? '- 평' : `${getLocalNumber(pyeong)} 평`;
    return result;
}

/* === 계산식 === */
export const CALCULATOR = {
    sum: (numbers = []) => {
        let result;
        for(let num of numbers) result += getNumber(num);
        return result;
    },
    divide: (x = 0, y = 0, fixNum = 0) => {
        let result;
        if(fixNum === 0) {
            result = parseInt(getNumber(x) / getNumber(y));
        } else { 
            result = parseFloat(getNumber(x) / getNumber(y)).toFixed(fixNum);
        }
        return result;
    },
    multiply: (x = 0, y = 0, fixNum = 0) => {
        let result;
        if(fixNum === 0) {
            result = parseInt(getNumber(x) * getNumber(y));
        } else { 
            result = parseFloat(getNumber(x) * getNumber(y)).toFixed(fixNum);
        }
        return result;
    }
};