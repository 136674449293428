import React from 'react';
import ReactModal from 'react-modal';
import { ModalWrap, Close, Title, Description, Hr, Contents, CloseAction } from './ModalStyle';
import { useNavigate } from 'react-router';
import { isDesktop } from '../../utils/detect';

const modalStyle = {
  overlay: {
    zIndex: '999',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  content: {
    zIndex: '91',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    boxSizing: 'content-box',
    width: `${window.innerWidth > 320 ? '100%' : 'calc(100% - 80px)'}`,
    maxWidth: `${isDesktop ? 'auto' : '260px'}`,
    height: 'auto',
    maxHeight: 'calc(100% - 160px)',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    borderWidth: '32px 30px',
    borderColor: '#ffffff',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
  },
};

const Modal = ({
  open,
  close,
  onCloseClick,
  closeAction,
  onActionClick,
  title,
  description,
  width = null,
  height = null,
  center = false,
  children,
}) => {
  const navigate = useNavigate();

  const customStyle = Object.assign({}, modalStyle);
  if (width && window.innerWidth > 390) customStyle.content.maxWidth = width + 'px';
  if (height) customStyle.content.maxHeight = height + 'px';

  const SIZE_LARGE = Number(width) > 390;

  return (
    <ReactModal
      isOpen={open ? open : true}
      style={customStyle}
      appElement={document.getElementById('root') || undefined}
      onRequestClose={() => {
        onCloseClick && onCloseClick();
      }}
    >
      <ModalWrap>
        {title && <Title className={(SIZE_LARGE || center === true) && 'a-c'}> {title}</Title>}
        {description && <Description>{description}</Description>}
        {SIZE_LARGE && <Hr />}
        {close && (
          <Close
            onClick={() => {
              onCloseClick ? onCloseClick() : navigate(-1);
            }}
          />
        )}
        <Contents>{children}</Contents>
        {closeAction && (
          <CloseAction
            onClick={() => {
              if (onActionClick) onActionClick();
              if (onCloseClick) onCloseClick();
            }}
          >
            확인
          </CloseAction>
        )}
      </ModalWrap>
    </ReactModal>
  );
};

export default Modal;
