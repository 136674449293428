import styled, { keyframes } from 'styled-components';

export const InformBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.gray500};

  img {
    width: 40px;
  }

  pre {
    width: 100%;
    text-align: center;
    line-height: 1.4;
    white-space: pre-wrap;
  }
`;

const loadingSpins = keyframes`
    0% { opacity: 1; transform: rotate(0deg) }
    100% { opacity: 1; transform: rotate(360deg) }
`;

export const LoadingCircle = styled.div`
  display: block;
  width: 36px;
  height: 36px;

  &::after {
    display: block;
    content: '';
    width: ${({ type }) => (type === 'map' ? '42px' : '34px')};
    height: ${({ type }) => (type === 'map' ? '42px' : '34px')};
    border-radius: 50%;
    border: 2px solid #3e468e;
    border-color: ${({ type }) =>
      type === 'map'
        ? `rgba(0, 31, 96, 4) transparent rgba(0, 31, 96, 2) transparent`
        : 'rgba(62, 70, 142, .8) transparent rgba(62, 70, 142, .4) transparent'};
    opacity: 0;
    animation: ${loadingSpins} 1s linear infinite;
    animation-delay: ${({ type }) => (type === 'map' ? '0' : '.5s')};
  }
`;

const scaleDot = keyframes`
    0% { transform: scale(0); }
    100% { transform: scale(1); }
`;

const movingDot = keyframes`
    0% { transform: translate(0, 0); }
    100% { transform: translate(20px, 0); }
`;

export const LoadingDots = styled.div`
  display: block;
  position: relative;
  width: 40px;
  height: 10px;
  margin: 0 auto;
  padding: 10px 0;

  > div {
    position: absolute;
    top: 10px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: rgba(62, 70, 142, 0.6);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 0;
      animation: ${scaleDot} 0.7s linear infinite;
    }
    &:nth-child(2) {
      left: 0;
      animation: ${movingDot} 0.7s linear infinite;
    }
    &:nth-child(3) {
      left: 16px;
      animation: ${movingDot} 0.7s linear infinite;
    }
    &:nth-child(4) {
      left: 40px;
      animation: ${scaleDot} 0.7s linear reverse infinite;
    }
  }
`;
