export const CENTER_TYPES = [
    {
        label: '단독요양원',
        value: '단독요양원'
    },
    {
        label: '상가요양원',
        value: '상가요양원'
    },
    {
        label: '주야간보호센터',
        value: '주간보호'
    }
];

export const CONTACT_TYPES = [
    {
        label: '일반매물',
        value: '일반매물'
    },
    {
        label: '신규사업자',
        value: '신규사업자'
    },
    {
        label: '신규리모델링',
        value: '신규리모델링'
    },
    {
        label: '기타',
        value: '기타'
    },
];

export const VISIT_ROUTES = [
    {
        label: '방문 경로 선택',
        value: ''
    },
    {
        label: '유튜브',
        value: '유튜브'
    },
    {
        label: '블로그',
        value: '블로그'
    },
    {
        label: '카페',
        value: '카페'
    },
    {
        label: '네이버 검색',
        value: '네이버 검색'
    },
    {
        label: '기타(직접입력)',
        value: '직접입력'
    },
];