import {
   Card,
   Head,
   Wrap,
   Thumbnail,
   Sales,
   Cate,
   Corp,
   Num,
   Badges,
   Badge,
   Name,
   Region,
   Price,
   Infos,
   Assets,
} from "./CenterCardStyle";
import CenterAction from "../CenterAction/CenterAction";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getLocalNumber, getLocalCurrent } from "../../../utils/number";
import defaultThumbnail from "../../../assets/images/form/img-default.png";
import { saveHistory } from "../../../store/actions/history";
import { getValidObjValue } from "../../../utils/center";

const FILTER_PATH = [
   { path: "/bono/biz", filter: "biz" },
   { path: "/bono/remodeling", filter: "remodeling" },
   { path: "/bono/nursing", filter: "nursing" },
   { path: "/bono/care", filter: "care" },
];

const CenterCard = ({ list, type, center }) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const navigate = useNavigate();

   const isMaemul = center["sisulState"] === "매물";
   const isDayCare = getValidObjValue(center, [["adminPttnCd"], ["sisul", "adminPttnCd"]]) === "주야간보호센터";
   const adminPttnCd = getValidObjValue(center, [["adminPttnCd"], ["sisul", "adminPttnCd"]]);
   const toPer = getValidObjValue(center, [["toPer"], ["sisul", "toPer"]]);
   const sido = getValidObjValue(center, [["siDoCd"], ["sisul", "siDoCd"]]);
   const gugun = getValidObjValue(center, [["siGunGuCd"], ["sisul", "siGunGuCd"]]);
   const local = getValidObjValue(center, [["local"], ["sisul", "local"]]);
   const maPer = getValidObjValue(center, [["maPer"], ["sisul", "maPer"]], 0);
   const fmPer = getValidObjValue(center, [["fmPer"], ["sisul", "fmPer"]], 0);
   const total = (maPer ? maPer : 0) + (fmPer ? fmPer : 0);

   const onLinkClick = e => {
      e.preventDefault();
      const currentPath = location.pathname;
      const filters = FILTER_PATH.filter(item => item.path === currentPath);
      const filter = filters.length > 0 ? filters[0].filter : "all";
      if (!currentPath.includes("/center/")) dispatch(saveHistory({ url: currentPath }));
      navigate(`/center/${center["sisulCustomNo"]}?filter=${filter}`);
   };

   const getImg = imgs => {
      if (imgs === null || imgs.length <= 0) return defaultThumbnail;

      if (imgs.length === 1) return imgs[0]["sisulFilePath"].replaceAll(" ", "");

      const thumbs = imgs.filter(item => item["sisulFileType"] === "썸네일");
      if (thumbs.length > 0) return thumbs[0]["sisulFilePath"].replaceAll(" ", "");
      else return imgs[0]["sisulFilePath"].replaceAll(" ", "");
   };

   return (
      <>
         {center && Object.keys(center).length > 0 && (
            <Card className={type}>
               {type === "sub" && (
                  <Head>{`${adminPttnCd} ${toPer}인 시설 ${
                     center["bonoForm"] ? center["bonoForm"].replaceAll(",", "/") : ""
                  }`}</Head>
               )}
               <Wrap
                  onClick={e => {
                     onLinkClick(e);
                  }}
                  className={list}>
                  {type !== "abstract" && (
                     <Thumbnail>
                        {center["bonoState"] === "거래완료" ? (
                           <div className="img soldout">거래완료</div>
                        ) : (
                           <div
                              className="img"
                              style={{
                                 backgroundImage: `url(${getImg(center["sisulImage"])})`,
                              }}
                           />
                        )}
                     </Thumbnail>
                  )}

                  <Sales>
                     <Num>
                        <span>
                           {isMaemul ? "매물번호 " : "시설번호 "} {center["sisulCustomNo"]}
                        </span>
                     </Num>

                     {type !== "abstract" && (
                        <>
                           {center["bonoFormArr"] && <Cate>{center["bonoFormArr"][0]}</Cate>}
                           {center["companyLogo"] && (
                              <Corp>
                                 <img src={center["companyLogo"]} alt="" />
                              </Corp>
                           )}
                        </>
                     )}

                     {center["bonoDivision"] && type !== "sub" && (
                        <Badges>
                           {center["bonoDivision"].split(",").map((division, idx) => (
                              <Badge division={division} key={idx}>
                                 {division}
                              </Badge>
                           ))}
                        </Badges>
                     )}

                     {type === "abstract" && (
                        <Name className="fw-b">{isMaemul ? `${adminPttnCd} ${toPer}인` : center["sisul"]["adminNm"]}</Name>
                     )}

                     {(!isMaemul && type !== "abstract") && <Name>{center["adminNm"] || center?.sisul?.adminNm || ""}</Name>}

                     <Region>
                        {isMaemul ? `${sido} ${gugun}` : local.length > 0 ? local : `${center["siDoCd"]} ${center["siGunGuCd"]}`}
                     </Region>

                     {isMaemul && (
                        <Price depositStyle={isDayCare}>
                           {isDayCare &&
                              `보증금 ${
                                 center["deposit"] ? getLocalCurrent(center["deposit"]).replaceAll("만", "") : "-"
                              } / 월세 ${
                                 center["monthlyRent"] ? getLocalCurrent(center["monthlyRent"]).replaceAll("만", "") : "-"
                              }`}

                           {!isDayCare &&
                              `매매 ${
                                 center["tradingPrice"] ? getLocalCurrent(center["tradingPrice"]).replaceAll("만", "") : "-"
                              }`}

                           {center["loans"] > 0 && center["bonoFileName"] && (
                              <Corp className="loan">
                                 <img src={center["bonoFileName"]} alt="" />
                              </Corp>
                           )}
                        </Price>
                     )}

                     <Infos className={isMaemul ? "" : "sisul"}>
                        <span>
                           {`${adminPttnCd}, 연면적 ${
                              getValidObjValue(center, [["totArea"], ["sisul", "totArea"]]).length > 0
                                 ? getLocalNumber(getValidObjValue(center, [["totArea"], ["sisul", "totArea"]]))
                                 : "-"
                           } m²`}
                        </span>

                        <span>{`[정원 ${toPer}인/현원 ${total}인]`}</span>
                     </Infos>

                     {isMaemul && type !== "abstract" && (
                        <Assets>
                           {center["investmentFee"] > 0 && (
                              <div>
                                 <em className="invest">투자</em>

                                 <span className="value">{getLocalCurrent(center["investmentFee"]).replaceAll("만", "")}</span>
                              </div>
                           )}
                           {center["loans"] > 0 && (
                              <div>
                                 <em className="loan">대출</em>

                                 <span className="value">{getLocalCurrent(center["loans"]).replaceAll("만", "")}</span>
                              </div>
                           )}
                        </Assets>
                     )}
                  </Sales>
               </Wrap>

               {type !== "abstract" && (
                  <CenterAction
                     actionType="details"
                     onDetailsClick={onLinkClick}
                     centerType={isMaemul ? "maemul" : "sisul"}
                     centerId={center["sisulCustomNo"]}
                  />
               )}
            </Card>
         )}
      </>
   );
};

export default CenterCard;
