/* === ACTION TYPE === */
export const SERVICE = {
  SIGNUP_ON: "/signup/on",
  SIGNUP_OFF: "/signup/off",
  LOGIN_ON: "/login/on",
  LOGIN_OFF: "/login/off",
  FIND_ID_ON: "/findId/on",
  FIND_ID_OFF: "/findId/off",
  FIND_PWD_ON: "/findPwd/on",
  FIND_PWD_OFF: "/findPwd/off",
  ADDS_CALCULATOR_ON: "/addsCalculator/on",
  ADDS_CALCULATOR_OFF: "/addsCalculator/off",
  BUILD_CALCULATOR_ON: "/buildCalculator/on",
  BUILD_CALCULATOR_OFF: "/buildCalculator/off",
  CENTER_CALCULATOR_ON: "/centerCalculator/on",
  CENTER_CALCULATOR_OFF: "/centerCalculator/off",
  CENTER_CALCULATOR_RESET: "/centerCalculator/reset",
  CONTACT_ON: "/contact/on",
  CONTACT_OFF: "/contact/off",
  REGISTER_ON: "/register/on",
  REGISTER_OFF: "/register/off",
  REMODELING_CONTACT_ON: "/remodeling/contact/on",
  REMODELING_CONTACT_OFF: "/remodeling/contact/off",
  REMODELING_JOIN_ON: "/remodeling/join/on",
  REMODELING_JOIN_OFF: "/remodeling/join/off",
  LOGIN_REQUIRED_ON: "/loginRequired/on",
  LOGIN_REQUIRED_OFF: "/loginRequired/off",
  BONO_NEWS_ON: "/bonoNews/on",
  BONO_NEWS_OFF: "/bonoNews/off",
  QNA_ON: "/qna/on",
  QNA_OFF: "/qna/off",
  RESUME_ON: "/resume/on",
  RESUME_OFF: "/resume/off",
};

export const activateSignup = () => ({ type: SERVICE.SIGNUP_ON });
export const activateLogin = () => ({ type: SERVICE.LOGIN_ON });
export const activateFindId = () => ({ type: SERVICE.FIND_ID_ON });
export const activateFindPwd = () => ({ type: SERVICE.FIND_PWD_ON });
export const activateRegister = () => ({ type: SERVICE.REGISTER_ON });
export const activateLoginRequired = () => ({ type: SERVICE.LOGIN_REQUIRED_ON });
export const activateAddsCalculator = () => ({ type: SERVICE.ADDS_CALCULATOR_ON });
export const activateBuildCalculator = () => ({ type: SERVICE.BUILD_CALCULATOR_ON });
export const activateCalculator = (data = null) => ({
  type: SERVICE.CENTER_CALCULATOR_ON,
  payload: data,
});
export const activateContact = (data = null) => ({
  type: SERVICE.CONTACT_ON,
  payload: data,
});
export const activateRemodelingContact = () => ({ type: SERVICE.REMODELING_CONTACT_ON });
export const activateRemodelingJoin = () => ({ type: SERVICE.REMODELING_JOIN_ON });

export const deactivateSignup = () => ({ type: SERVICE.SIGNUP_OFF });
export const deactivateLogin = () => ({ type: SERVICE.LOGIN_OFF });
export const deactivateFindId = () => ({ type: SERVICE.FIND_ID_OFF });
export const deactivateFindPwd = () => ({ type: SERVICE.FIND_PWD_OFF });
export const deactivateAddsCalculator = () => ({ type: SERVICE.ADDS_CALCULATOR_OFF });
export const deactivateBuildCalculator = () => ({ type: SERVICE.BUILD_CALCULATOR_OFF });
export const deactivateCalculator = () => ({ type: SERVICE.CENTER_CALCULATOR_OFF });
export const deactivateContact = () => ({ type: SERVICE.CONTACT_OFF });
export const deactivateRegister = () => ({ type: SERVICE.REGISTER_OFF });
export const deactivateLoginRequired = () => ({ type: SERVICE.LOGIN_REQUIRED_OFF });
export const deactivateRemodelingContact = () => ({ type: SERVICE.REMODELING_CONTACT_OFF });
export const deactivateRemodelingJoin = () => ({ type: SERVICE.REMODELING_JOIN_OFF });

export const activateBonoNews = () => ({ type: SERVICE.BONO_NEWS_ON });
export const deactivateBonoNews = () => ({ type: SERVICE.BONO_NEWS_OFF });

export const activateQna = () => ({ type: SERVICE.QNA_ON });
export const deactivateQna = () => ({ type: SERVICE.QNA_OFF });

export const activateResume = () => ({ type: SERVICE.RESUME_ON });
export const deactivateResume = () => ({ type: SERVICE.RESUME_OFF });

export const resetCalculator = (data = null) => ({
  type: SERVICE.CENTER_CALCULATOR_RESET,
  payload: data,
});
