import { isDesktop } from "./detect";
import { getPyeong, getLocalNumber } from "./number";
import { getFilteredLatlng } from "../scheme/latlng";

const { naver } = window;

export const ZOOMS = {
  dong: [15, 22], // 읍면동 진입(상세 마커)
  gugun: [13, 15], // 구군 진입(읍면동 마커)
  sido: [11, 13], // 시도 진입(구군 마커)
  korea: [8, 11], // 남한 진입(시도 마커)
  out: [0, 8], // 경계 이탈
};

export const getZoomLevel = zoom => {
  let level = "out";

  for (const key in ZOOMS) {
    if (zoom >= ZOOMS[key][0] && zoom < ZOOMS[key][1]) level = key;
  }

  return level;
};

export const getZoomByAddress = address => {
  let validAddress = address.filter(item => item.longName.length > 0);

  if (!validAddress) return ZOOMS.gugun[0]; // 디폴트값: 구군주소 레벨(읍면동 마커)

  if (validAddress.length >= 3) return ZOOMS.dong[0];
  // 읍면동 주소(상세 마커)
  else if (validAddress.length <= 1) return ZOOMS.sido[0];
  // 시도 주소(구군 마커)
  else return ZOOMS.gugun[0]; // 구군 주소(읍면동 마커)
};

export const getRegionByLatlng = latlng => {
  return new Promise((resolve, reject) => {
    naver.maps.Service.reverseGeocode(
      {
        coords: new naver.maps.LatLng(latlng[0], latlng[1]),
        orders: [
          naver.maps.Service.OrderType.ADDR, // 법정동 주소만 받음
        ].join(","),
      },
      (status, response) => {
        if (status === naver.maps.Service.Status.ERROR) reject("geocode오류");
        else if (response.v2.results.length <= 0) resolve("");
        else resolve(response.v2.results[0].region); // 법정동
      }
    );
  });
};

export const getRegionByZoom = (regions, zoom) => {
  const level = getZoomLevel(zoom);
  let result = { area1: "", area2: "", area3: "" };
  if (regions && Object.keys(regions).length > 0) {
    if (level === "sido") result = { area1: regions.area1.name, area2: "", area3: "" };
    if (level === "gugun")
      result = {
        area1: regions.area1.name,
        area2: regions.area1.name === "세종특별자치시" ? "세종특별자치시" : regions.area2.name,
        area3: "",
      };
    if (level === "dong")
      result = {
        area1: regions.area1.name,
        area2: regions.area1.name === "세종특별자치시" ? "세종특별자치시" : regions.area2.name,
        area3: regions.area3.name,
      };
  }
  return result;
};

export const getSearchByAddress = address => {
  return new Promise((resolve, reject) => {
    naver.maps.Service.geocode(
      {
        query: address,
      },
      async (status, response) => {
        if (status === naver.maps.Service.Status.ERROR) {
          reject(null);
        }

        if (response.v2.meta.totalCount === 0) {
          reject(null);
        }

        const data = response.v2.addresses[0];
        if (data) {
          const latlng = [parseFloat(data.y), parseFloat(data.x)];
          const zoom = getZoomByAddress(data.addressElements);
          const areas = await getRegionByLatlng(latlng);
          resolve({
            latlng: latlng,
            zoom: zoom,
            address: getRegionByZoom(areas, zoom),
          });
        }
      }
    );
  });
};

const SATURATION_PALETTE = [
  {
    circle: "#0000ff7f",
    textColor: "#ffffff",
    textBg: "transparent",
    text: "등급인원대비<br/>시설 수 적음",
  },
  {
    circle: "#bf90007e",
    textColor: "#ffffff",
    textBg: "transparent",
    text: "등급인원대비<br/>시설 수 비교적 많음",
  },
  {
    circle: "#c55a117e",
    textColor: "#ffffff",
    textBg: "transparent",
    text: "등급인원대비<br/>시설 수 많음",
  },
  {
    circle: "#c000007f",
    textColor: "#ffffff",
    textBg: "transparent",
    text: "등급인원대비<br/>시설 수 포화상태",
  },
];

const ADDR_CTG = {
  korea: {
    label: "siDoCd",
    full: ["siDoCd"],
  },
  sido: {
    label: "siGunGuCd",
    full: ["siDoCd", "siGunGuCd"],
  },
  gugun: {
    label: "bdongCd",
    full: ["siDoCd", "siGunGuCd", "bdongCd"],
  },
};

// 시/구/동 마커
export const renderedGroupMarker = (data, map, zoomLevel, onMarkerClick) => {
  const markers = data.map(item => {
    const miniMarker = !isDesktop && zoomLevel !== "gugun";
    const fullAddress = ADDR_CTG[zoomLevel].full.reduce((acc, addrCode) => acc + item[addrCode] + " ", "").trim();
    const address = item[ADDR_CTG[zoomLevel].label];
    const latlng = getFilteredLatlng([item.x, item.y], fullAddress);
    const total = item.totalCount;
    const saturation = item.saturationDegree < 1 || item.saturationDegree === null ? null : item.saturationDegree - 1;
    const saturationPercent = saturation === null ? saturation : item.resultPercentage;

    const template = (initial = false) => `
            <div class="gMarker ${miniMarker ? "gMarker--xs" : ""} ${initial ? "on" : ""}">
                <div class="gMarker-box">
                    <em class="gMarker-addr">${address}</em>
                    ${
                      miniMarker
                        ? ""
                        : `
                        <ul class="gMarker-infos">
                            <li>요양원 ${item["mallTotal"] + item["onlyTotal"]}</li>
                            <li>주야간보호 ${item["centerTotal"]}</li>
                        </ul>
                        `
                    }
                </div>
                <div class="gMarker-total"> ${total} </div>
            </div>
        `;

    const marker = new naver.maps.Marker({
      position: new naver.maps.LatLng([latlng[1], latlng[0]]),
      map: map,
      icon: {
        content: template(true),
        anchor: new naver.maps.Point(35, 50),
      },
      draggable: false,
    });

    const markerClick = () => {
      onMarkerClick({
        latlng: latlng,
        zoom: ZOOMS[getZoomLevel(map.zoom)][1],
        address: ADDR_CTG[zoomLevel].full.reduce((acc, key) => acc + " " + item[key], "").trim(),
      });
    };

    const zoomFn = event => {
      const isSaturationClicked =
        event.pointerEvent.target.className && event.pointerEvent.target.className.includes("gMarker-saturation");
      if (isSaturationClicked) clearSaturation();
      else markerClick();
    };

    const drawSaturation = () => {
      const saturaionTemplate = `
                <div class="gMarker-saturation" style="background-color:${SATURATION_PALETTE[saturation].circle}">
                    <span 
                        class="gMarker-saturation__conts" 
                        style="background-color:${SATURATION_PALETTE[saturation].textBg};color:${
        SATURATION_PALETTE[saturation].textColor
      }"
                        >${saturationPercent}% <br/> ${SATURATION_PALETTE[saturation].text}</span>
                    ${
                      item["totalQuantitySystem"].length > 0
                        ? `<span class="gMarker-saturation__conts" style="background-color:${SATURATION_PALETTE[saturation].textBg};color:${SATURATION_PALETTE[saturation].textColor}">${item["totalQuantitySystem"]}</span>`
                        : ""
                    }
                </div>
            `;
      marker.setIcon({
        content: template(false) + saturaionTemplate,
        anchor: new naver.maps.Point(35, 50),
      });
      marker.setZIndex(200);

      naver.maps.Event.addListener(marker, "click", zoomFn);
    };

    const clearSaturation = () => {
      marker.setIcon({
        content: template(false),
        anchor: new naver.maps.Point(35, 50),
      });
      naver.maps.Event.clearInstanceListeners(marker);
      naver.maps.Event.addListener(marker, "click", drawSaturation);
      marker.setZIndex(100);

      addMouseEvent(marker);
    };

    const addMouseEvent = marker => {
      if (!isDesktop) return;
      marker.addListener("mouseover", function () {
        marker.setZIndex(200);
      });
      marker.addListener("mouseout", function () {
        marker.setZIndex(100);
      });
    };

    if (saturation === null) {
      naver.maps.Event.addListener(marker, "click", markerClick);
    } else {
      naver.maps.Event.addListener(marker, "click", drawSaturation);
      naver.maps.Event.addListener(map, "click", clearSaturation);
    }

    addMouseEvent(marker);

    return marker;
  });
  return markers;
};

// 개별 시설/매물 마커
export const renderItemMarkers = (data, map, onMarkerClick) => {
  const pos = [];
  data = data.map(item => {
    item.group = item.group.filter(gp => gp["sisulState"] === "시설");
    return item;
  });
  data = data.reduce((datas, data) => {
    const xy = `${data.x}${data.y}`;
    if (pos.includes(xy)) {
      if (data.focus) {
        const idx = datas.findIndex(v => v.x === data.x && v.y === data.y);
        datas[idx] = data;
      }
      return datas;
    } else {
      pos.push(xy);
      return datas.concat(data);
    }
  }, []);
  const markers = data
    .filter(item => item.group.length > 0)
    .map(item => {
      const latlng = [item.y, item.x];
      // const group = item.group;
      const group = item.group.length > 0 ? item.group.filter(item => item["sisulState"] === "시설") : item.group; //시설만 노출 처리
      const hasFocus = item.focus;
      const markerOption = content => ({
        position: new naver.maps.LatLng(latlng),
        map: map,
        icon: {
          content: content,
          anchor: new naver.maps.Point(0, 65),
        },
        draggable: false,
      });
      let marker;
      let markerClickFn;

      if (group.length === 1) {
        const center = item.group[0];
        const content = `<div class="iMarker ${center["sisulState"] === "매물" ? "bono" : ""} on ${
          hasFocus ? "iMarker--focus" : ""
        }">
                                <div class="iMarker-box">
                                    <span 
                                        class="iMarker-name"
                                        data-color="${center["sisulState"] === "매물" ? "pr" : ""}"
                                        data-no="${center["sisulCustomNo"]}"
                                    >
                                        ${center["adminPttnCd"].substring(0, 2)}
                                    </span>
                                </div>
                                <div class="iMarker-tail"></div>
                            </div>`;
        marker = new naver.maps.Marker(markerOption(content));
        markerClickFn = e => {
          const clicked = e.pointerEvent.target;
          if (clicked.className === "iMarker-name") {
            onMarkerClick({ map: map, x: item.x, y: item.y, id: clicked.dataset.no });
          }
        };
      } else {
        const isFirstItemBono = group[0].sisulState === "매물";
        const isLastItemBono = group[group.length - 1].sisulState === "매물";
        const mainContent = (initial = true) => `<div class="iMarker ${initial ? "on" : ""} ${isLastItemBono ? "bono" : ""} ${
          hasFocus ? "iMarker--focus" : ""
        }">
                                    <div class="iMarker-count" data-color="${isLastItemBono ? "pr" : ""}">${group.length}</div>
                                    <div class="iMarker-box"><span class="iMarker-name" data-color="${
                                      isLastItemBono ? "pr" : ""
                                    }">${group[0]["adminPttnCd"].substring(0, 2)} 외</span></div>
                                    <div class="iMarker-tail"></div>
                                </div>`;
        const subContent = `<div class="iMarker iMarker--group on ${isLastItemBono ? "bono" : ""}  ${
          hasFocus ? "iMarker--focus" : ""
        }">
                                    <div class="iMarker-close" data-color="${isFirstItemBono ? "pr" : ""}"></div>
                                    <div class="iMarker-box">
                                        ${group.reduce((acc, item) => {
                                          return (acc += `<div class="iMarker-name" 
                                                    data-no="${item["sisulCustomNo"]}"
                                                    data-color="${item["sisulState"] === "매물" ? "pr" : ""}"
                                                    >${
                                                      item["sisulState"] === "매물"
                                                        ? `${item["adminPttnCd"]} (${item["toPer"]}인)`
                                                        : item["adminNm"]
                                                    }</div>`);
                                        }, "")}
                                    </div>
                                    <div class="iMarker-tail"></div>
                                </div>`;

        let groupSpread = false;
        marker = new naver.maps.Marker(markerOption(mainContent(true)));

        markerClickFn = e => {
          if (groupSpread) {
            const clicked = e.pointerEvent.target;
            if (clicked.className === "iMarker-name") {
              onMarkerClick({ map: map, x: item.x, y: item.y, id: clicked.dataset.no });
            }
            if (clicked.className === "iMarker-close") {
              marker.setIcon({ content: mainContent(false), anchor: new naver.maps.Point(0, 65) });
              marker.setZIndex(100);
              groupSpread = !groupSpread;
            }
          } else {
            marker.setIcon({
              content: subContent,
              anchor: new naver.maps.Point(0, 65 + 36 * (group.length - 1)),
            });
            marker.setZIndex(999);
            groupSpread = !groupSpread;
          }
        };
      }

      marker.addListener("click", markerClickFn);
      marker.addListener("mouseover", function () {
        marker.setZIndex(999);
      });
      marker.addListener("mouseout", function () {
        marker.setZIndex(100);
      });

      return marker;
    });

  return markers;
};

// 인포윈도우 뱃지 스타일 클래스
const BADGES = {
  분양: "sales",
  리모델링: "sales",
  추천: "rcmd",
  프리미엄: "premium",
};

// 인포윈도우(PC)
export const renderInfoWindow = props => {
  const isBono = props.data["sisulState"] === "매물";
  const isDayCare = props.data["adminPttnCd"] === "주야간보호센터";
  const price = !isBono
    ? ""
    : isDayCare
    ? `보증금 ${getLocalNumber(props.data["deposit"])} / 월세 ${getLocalNumber(props.data["monthlyRent"])}`
    : `매매 ${getLocalNumber(props.data["tradingPrice"])}`;

  const getBadges = () => {
    let badges = "";
    for (let badge of props.data["bonoForm"].split(",")) {
      badges += `<div class="badge badge--${BADGES[badge]}">${badge}</div>`;
    }
    return badges;
  };
  const windowTemplate = `<div class="info-window">
                                <div class="info-window__body">
                                    <div class="pic"><img src=${"sd"}/></div>
                                    <div class="conts">
                                        ${props.data["bonoForm"] ? `<div class="conts__badges">${getBadges()}</div>` : ""}
                                        <span class="conts__name">${
                                          isBono
                                            ? `${props.data["adminPttnCd"]} ${props.data["toPer"]} 인`
                                            : props.data["adminNm"]
                                        }</span>
                                        <span class="conts__category">${
                                          isBono ? "" : `${props.data["adminPttnCd"]} ${props.data["toPer"]} 인`
                                        }</span>
                                        <span class="conts__addr">${props.data["siDoCd"]} ${props.data["siGunGuCd"]}</span>
                                    </div>
                                    <div class="infos">
                                        <div class="infos__price">${price}</div>
                                        <span class="infos__capacity">
                                        ${
                                          isBono
                                            ? `면적 ${getPyeong(props.data["lndpclAr"])} / 인가 ${props.data["toPer"]}인 시설`
                                            : "※ 참조<br />권리금은 1천만원/인당<br/>시설기준 편차 ±1~5 백만원"
                                        }
                                        </span>
                                    </div>
                                </div>
                                <div class="info-window__actions">
                                    <button class="btn btn--details">상세</>
                                    ${isBono ? `<button class="btn btn--contact">문의</button>` : ""}
                                </div>
                            </div>`;

  const infoWindow = new naver.maps.Marker({
    position: new naver.maps.LatLng([props.data.y, props.data.x]),
    map: props.data.map,
    icon: {
      content: windowTemplate,
      anchor: new naver.maps.Point(1, 240),
    },
    zIndex: 999,
    draggable: false,
  });

  const removeInfoWindow = () => {
    props.onCloseClick();
    infoWindow.setMap(null);
  };

  naver.maps.Event.addListener(infoWindow, "click", e => {
    const clicked = e.pointerEvent.target;

    if (clicked.tagName === "BUTTON") {
      const mode = clicked.className.split("btn--")[1];

      if (mode === "contact") {
        props.onContactClick(props.data);
      }

      if (mode === "details") {
        removeInfoWindow();

        props.onDetailsClick(props.data["id"]);
      }
    } else {
      removeInfoWindow();
    }
  });

  naver.maps.Event.addListener(props.data.map, "click", () => {
    removeInfoWindow();
  });
  naver.maps.Event.addListener(props.data.map, "dragend", () => {
    removeInfoWindow();
  });

  return infoWindow;
};

// 내위치 마커
export const renderGeoPin = (map, latlng) => {
  const marker = new naver.maps.Marker({
    position: new naver.maps.LatLng([latlng[1], latlng[0]]),
    map: map,
    icon: {
      content: `<div class="geoPin"></div>`,
    },
  });
  return marker;
};

// 마커 삭제
export const removeMarkers = marker => {
  if (!marker) return;

  if (Array.isArray(marker)) for (const mk of marker) mk.setMap(null);
  else marker.setMap(null);
};

// 마커 활성화(비활성화)
export const showMarkers = (marker, show) => {
  if (!marker) return;

  if (Array.isArray(marker)) for (const mk of marker) mk.setVisible(show);
  else marker.setVisible(show);
};

// 지역 폴리곤 반환
export const getPolygonAddr = areaObj => {
  const gugun = areaObj["area2"].name ? areaObj["area2"].name : areaObj["area1"].name;
  if (gugun.length > 0) return gugun.replaceAll(" ", "").trim();
  else return null;
};

// 동일 주소 판별
export const isSameAddr = (prevAddr, nowAddr) => {
  return Object.keys(prevAddr).filter(key => prevAddr[key] !== nowAddr[key]).length <= 0;
};

// 지역 레벨 판별
export const getRegionLevel = addrObj => {
  const addrLen = Object.keys(addrObj).filter(key => addrObj[key].length > 0).length;
  switch (addrLen) {
    case 3:
      return "dong";
    case 2:
      return "gugun";
    case 1:
      return "sido";
    case 0:
      return "korea";
    default:
      return "korea";
  }
};

// 문자열 주소 반환
export const getStringAddr = addrObj => {
  let stringAddr = "";
  for (let i = 1; i < 4; i++) stringAddr += addrObj[`area${i}`].length > 0 ? addrObj[`area${i}`] + " " : "";
  return stringAddr;
};
