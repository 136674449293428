import { HISTORY } from "../actions/history";

const initialState = {
    url: '/'
};

const HistoryReducer = (state=initialState, action) => {
    switch(action.type) {
        case HISTORY.SAVE: 
            return {...state, ...action.payload};
        case HISTORY.CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default HistoryReducer;