import styled, { keyframes } from 'styled-components';
import { module } from '../../themes/module';
import resetIcon from '../../assets/images/form/ico-reset_white.svg';
import accrBlackIcon from '../../assets/images/form/ico-accordion_black.svg';
import selectArrow from '../../assets/images/form/ico-selectArrow.svg';

export const Wrapper = styled(module.scrollWrapper)`
  z-index: 10;
  position: relative;
  height: auto;
  max-height: calc(100vh - 234px);
  background-color: #fff;

  .mobile & {
    height: 100%;
    max-height: calc(100vh - 52px);
    padding: 40px 8px 0 16px;
  }

  &.calced {
    height: 510px;

    .mobile & {
      height: 100%;
      max-height: calc(100% - 56px);
    }

    form,
    > div {
      padding-bottom: 0;
    }
  }

  > .wrap {
    z-index: 11;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 56px;
    padding-bottom: 10px;

    .mobile & {
      gap: 0;
      padding-bottom: 56px;
    }
  }

  .loading {
    z-index: 12;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 100%;

  fieldset {
    width: 100%;

    legend {
      margin-bottom: 16px;
      font-size: ${({ theme }) => theme.fontSizes.l};
      font-weight: ${({ theme }) => theme.fontWeights.bold};

      .mobile & {
        margin-bottom: 10px;
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
    }

    table {
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.gray300};

      caption {
        margin: 12px 0 8px;
        text-align: left;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }

      thead {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
      }

      tbody {
        tr + tr > td {
          border-top: 1px solid ${({ theme }) => theme.colors.gray300};
        }
      }

      th {
        height: 36px;
        border-right: 1px solid ${({ theme }) => theme.colors.gray300};
        background-color: ${({ theme }) => theme.colors.gray200};
        vertical-align: middle;

        label {
          height: 100%;
          // line-height: 36px;
          line-height: 1.2;
          background-color: transparent;
        }

        .mobile & {
          width: 120px;
          height: 30px;
          background-color: ${({ theme }) => theme.colors.gray100};
          border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};

          label {
            line-height: 30px;
            font-weight: 500;
          }
        }
      }

      td {
        position: relative;
        padding: 0;
        height: 36px;
        border-right: 1px solid ${({ theme }) => theme.colors.gray300};

        span,
        input,
        select {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          line-height: 34px;
          padding: 0 12px;
          border: 0;
          text-align: center;
          font-size: ${({ theme }) => theme.fontSizes.base};

          .mobile & {
            font-size: 13px;
            line-height: 30px;
            padding: 0 5px;
          }
        }

        input::placeholder {
          font-size: ${({ theme }) => theme.fontSizes.s};

          .mobile & {
            font-size: 13px;
          }
        }

        select {
          -webkit-appearance: none;
          appearance: none;
          background: url(${selectArrow}) calc(100% - 6px) center no-repeat;
        }

        .mobile & {
          height: 30px;
        }
      }
    }
    .fs-s {
      font-size: ${({ theme }) => theme.fontSizes.s};
      .mobile & {
        font-size: ${({ theme }) => theme.fontSizes.xs};
      }
    }
    .fs-xs {
      font-size: ${({ theme }) => theme.fontSizes.xs};
      .mobile & {
        font-size: ${({ theme }) => theme.fontSizes.xs};
        line-height: 1.3;
      }
    }
    .fw-b {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
    .clr-black {
      background-color: #fff;
      color: ${({ theme }) => theme.colors.gray900};
    }
    .clr-accent {
      background-color: ${({ theme }) => theme.colors.secondary} !important;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: #fff;
    }

    .warn {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  .actions {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;

    button {
      width: 160px;
      height: 52px;
      line-height: 52px;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      background-color: #fff;
      color: ${({ theme }) => theme.colors.primary};

      &[type='submit'] {
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
      }
    }
  }

  .mobile & {
    padding-bottom: 0;
  }
`;

export const AddsForm = styled(Form)`
  gap: 24px;

  th,
  th span {
    color: ${({ theme }) => theme.colors.gray800};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  input:read-only {
    background-color: rgba(0, 31, 96, 0.05);
  }

  .mobile & {
    gap: 60px;
    margin-bottom: 32px;
    font-size: ${({ theme }) => theme.fontSizes.s};

    fieldset {
      &.rows {
        display: flex;
        flex-direction: column;
        gap: 24px;

        > div {
          margin-bottom: 0;
        }

        caption {
          margin: 0 0 8px;
        }
      }

      table {
        td,
        th {
          height: 36px;
          span,
          label {
            line-height: 36px;
          }
        }
      }
    }

    .warn {
      margin-top: 8px;
      color: ${({ theme }) => theme.colors.red};
    }

    .actions {
      z-index: 90;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      heigth: 52px;

      button {
        width: 100%;

        &[type='reset'] {
          background-color: ${({ theme }) => theme.colors.primary};
          color: #fff;
        }
      }
    }
  }
`;

export const OptionForm = styled.div`
  legend {
    display: flex !important;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray700};

    &::after {
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      background: url(${selectArrow}) center no-repeat;
    }

    &.on::after {
      transform: rotate(180deg);
    }
  }
`;

export const ResetIcon = styled.i`
  display: inline-block;
  width: 16px;
  height: 16px;
  transform: translate(5px, 2px);
  background: url(${resetIcon}) center no-repeat;
`;

// 계산 결과
export const CalcWrap = styled(module.scrollWrapper)`
  height: 100%;
  max-height: 600px;

  .mobile & {
    height: calc(100% - 112px);
    max-height: calc(100% - 112px);
  }
`;

export const Caption = styled.div`
  display: block;
  padding: 12px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.l};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  .mobile & {
    font-size: ${({ theme }) => theme.fontSizes.base};
    padding: 0 0 6px;
    margin-bottom: 6px;
  }

  &.adds {
    margin-bottom: 24px;
  }
`;

export const CalcHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.gray900};
  min-height: 32px;

  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.l};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .mobile & {
    min-height: 30px;
    .title {
      font-size: ${({ theme }) => theme.fontSizes.base};
    }
  }
`;

export const CalcForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .mobile & {
    gap: 24px;
  }

  table {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    color: ${({ theme }) => theme.colors.gray900};

    th,
    td {
      position: relative;
      height: 36px;
      vertical-align: middle;
      text-align: center;
      border-right: 1px solid ${({ theme }) => theme.colors.gray300};
      padding: 4px;

      &.price {
        text-align: right;
        padding-right: 12px;
      }

      &.memo {
        padding-left: 6px;
        text-align: left;
        font-size: ${({ theme }) => theme.fontSizes.xs};
      }

      &.accent {
        background-color: #fde2e4;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: #dd2c00;
      }

      .mobile & {
        height: 30px;
        font-size: ${({ theme }) => theme.fontSizes.s};
        word-break: keep-all;
      }
    }

    th {
      .mobile & {
        width: 120px;
      }
    }

    thead {
      background-color: ${({ theme }) => theme.colors.gray300};
      .mobile & {
        background-color: #fff;
      }
    }

    tbody {
      tr {
        border-top: 1px solid ${({ theme }) => theme.colors.gray300};

        &.total {
          background-color: #fde2e4;
          font-weight: ${({ theme }) => theme.fontWeights.bold};
          .mobile & {
            font-weight: ${({ theme }) => theme.fontWeights.regular};
          }

          td:first-child {
            background-color: ${({ theme }) => theme.colors.gray200};
            .mobile & {
              background-color: #fde2e4;
            }
          }

          td {
            .mobile & {
              color: #dd2c00;
            }
          }
        }

        &.cost {
          background-color: #dfe7fd;
          font-weight: ${({ theme }) => theme.fontWeights.bold};

          .mobile & {
            font-weight: ${({ theme }) => theme.fontWeights.regular};
          }

          td:first-child {
            background-color: #dfe7fd;
            .mobile & {
              color: #0091ea;
            }
          }

          td {
            .mobile & {
              color: #dfe7fd;
            }
            .mobile & {
              color: #0091ea;
            }
          }
        }
      }
    }
    tfoot {
      background-color: ${({ theme }) => theme.colors.gray300};
      .mobile & {
        background-color: #f1f1fa;
        border-top: 1px solid ${({ theme }) => theme.colors.gray300};
      }

      td {
        height: 36px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: #dd2c00;

        .mobile & {
          height: 40px;
          border-right: 0;
          color: ${({ theme }) => theme.colors.gray900};
          font-weight: ${({ theme }) => theme.fontWeights.regular};

          &:last-child {
            text-align: right;
            font-weight: ${({ theme }) => theme.fontWeights.m};
          }
        }
      }
    }
  }

  button {
    positon: relative;
    width: 160px;
    height: 52px;
    margin: 0 auto;
    line-height: 52px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    text-align: center;
    border-radius: 2px;

    &::after {
      display: inline-block;
      width: 18px;
      height: 18px;
      content: '';
      margin-left: 6px;
      transform: translateY(4px);
      background: url(${resetIcon}) center no-repeat;
    }

    .mobile & {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 0;
    }
  }

  .warn {
    color: ${({ theme }) => theme.colors.gray600};
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 1.3;
  }
`;

export const AddsCalcForm = styled(CalcForm)`
  th {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
  .clr-accent {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`;

export const ResultAbstract = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;

  .result {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 16px 10px;
    text-align: center;
    background-color: rgba(188, 147, 106, 0.5);
    border-radius: 2px;
    color: ${({ theme }) => theme.colors.gray900};
    font-size: ${({ theme }) => theme.fontSizes.l};

    strong {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.red};
    }
  }

  .base {
    width: 100%;
    text-align: right;
    color: ${({ theme }) => theme.colors.gray600};
    font-size: ${({ theme }) => theme.fontSizes.s};
  }

  button {
    padding: 8px;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    background-color: #fff;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSizes.base};

    .mobile & {
      padding: 8px 4px;
    }

    &::after {
      z-index: 20;
      position: absolute;
      top: 0;
      right: 0;
      width: 36px;
      height: 36px;
      content: '';
      background: url(${accrBlackIcon}) center no-repeat;
    }

    &.on::after {
      transform: translateY(-2px) scaleY(-1);
    }
  }
`;

export const Unit = styled.i`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.gray800};

  & + * {
    z-index: 1;
    position: relative;
    padding-right: 36px;
    text-align: right;
  }

  .mobile & {
    width: 20px;
    height: 30px;
    line-height: 28px;
    font-size: ${({ theme }) => theme.fontSizes.xs};

    & + * {
      padding-right: 20px;
    }
  }
`;

const cursorMotion = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

export const InputView = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '|';
    animation: ${cursorMotion} 1s infinite;
  }
  + input {
    position: relative;
    color: transparent !important;
    background-color: transparent !important;
  }
`;

export const DataList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.s};

  p {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  dl {
    display: flex;
    flex-direction: row;
    gap: 12px;

    dt {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }
`;

export const LegendInform = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray800};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.base};

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
