import axios from "axios";

/** PROD */
const BASE_URL = 'https://bonoland.co.kr';

/** DEV */
// const BASE_URL = "http://dev.bonoland.co.kr";

/**LOCAL */
// const BASE_URL = "http://localhost";

const api = axios.create({
    baseURL: BASE_URL,
});

const executingRequests = {};

api.interceptors.request.use(
    function(config) {
        config.headers["Content-type"] = "application/json";
        config.headers["cache-control"] = "no-cache";
        return config;
    },
    function(request) {
        const currentRequest = request;

        if (executingRequests[currentRequest.url]) {
            const source = executingRequests[currentRequest.url];
            delete executingRequests[currentRequest.url];
            source.cancel();
        }

        const source = axios.CancelToken.source();
        currentRequest.CancelToken = source.token;
        executingRequests[currentRequest.url] = source;

        return currentRequest;
    }
);

api.interceptors.response.use(
    function(response) {
        if (executingRequests[response.request.responseURL]) {
            delete executingRequests[response.request.responseURL];
        }
        return response;
    },
    function(error) {
        const { config } = error;
        const originalRequest = config;
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }

        if (executingRequests[originalRequest.url]) {
            delete executingRequests[originalRequest.url];
        }

        return Promise.reject(error);
    }
);

export const consoleErr = err => {
    if (err.response) {
        console.error(
            `status: ${err.response.status} | headers: ${JSON.stringify(err.response.headers)} | data: ${JSON.stringify(
                err.response.data
            )}`
        );
    } else {
        console.error(`ERROR: ${err.message}`);
    }
};

export default api;
