import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import salesIcon from '../../../assets/images/menu/ico-bonoHouse.svg';
import rcmdIcon from '../../../assets/images/menu/ico-star.svg';
import registerIcon from '../../../assets/images/menu/ico-docs.svg';
import contactIcon from '../../../assets/images/menu/ico-chat.svg';
import userIcon from '../../../assets/images/menu/ico-user.svg';
import remodelingIcon from '../../../assets/images/menu/ico-remodeling.svg';
import homsvg from "../../../assets/images/landing/homsvg.svg"
import Housesvg from "../../../assets/images/landing/Housesvg.svg"

export const TabBar = styled.nav`
  z-index: 39;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  background-color: #fff;
  box-shadow: -3px 0 6px rgba(0, 0, 0, 0.06);

  ul {
    display: flex;
    width: 100%;

    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
`;

const TabBtnCss = css`
  width: auto;
  height: 100%;
  padding-top: 36px;
  background: url(${(props) =>
      (props.icon === 'sales' && salesIcon) ||
      (props.icon === 'rcmd' && rcmdIcon) ||
      (props.icon === 'register' && registerIcon) ||
      (props.icon === 'contact' && contactIcon) ||
      (props.icon === 'remodeling' && remodelingIcon) ||
      (props.icon === 'user' && userIcon) ||
      (props.icon === 'home' && homsvg) || 
      (props.icon === 'house' && Housesvg)
        }
      )
    center 9px / 24px 24px no-repeat;
  font-size: 10px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.gray700};

  &.on {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const TabLink = styled(Link)`
  ${TabBtnCss}
`;
export const TabBtn = styled.button`
  ${TabBtnCss}
  padding-top: 28px;
`;
