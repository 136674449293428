import React from 'react';
import { AutoSearchForm, LocalSvg } from './AutoSearchFilterStyle';
import { getLocalCurrent } from '../../../utils/number';
import { MoreLoading } from '../../ui/Inform/Inform';

const AutoSearchFilter = ({ type, data, address, onAutoSearchSubmit, loading, message }) => {
	const localFilters = data && data.localFilters ? data.localFilters : [];
	const keywordFilters = data && data.keywordFilters ? data.keywordFilters : [];

	const textFilter = (text) => {
		const filterText = text.includes(address) ? text.replace(address, `<strong>${address}</strong>`) : text;

		return <span dangerouslySetInnerHTML={{ __html: filterText }}></span>;
	};

	const getDivision = (value) => {
		const array = value.split(',');
		let newTest = '';

		array.forEach((item) => {
			newTest += '[' + item + ']';
		});

		return newTest;
	};

	return (
		<AutoSearchForm type={type} active={localFilters.length + keywordFilters.length > 0 || loading || message}>
			<React.Fragment>
				{loading || message ? (
					<div className="status-box">
						{loading && <MoreLoading />}
						{!loading && message && <span>{message}</span>}
					</div>
				) : (
					<React.Fragment>
						{localFilters.length > 0 && (
							<div className="list-box">
								{localFilters.map((item, index) => (
									<div className="itemBox" key={index}>
										<button
											type="button"
											className="localFilterBtn"
											onClick={() => onAutoSearchSubmit(item.local, null, false)}
										>
											<LocalSvg />
											{textFilter(item.local)}
										</button>
									</div>
								))}
							</div>
						)}
						{keywordFilters.length > 0 && (
							<div className="list-box">
								{type === 'main'
									? keywordFilters.map((item, idx) => (
											<div className="itemBox" key={idx}>
												<button
													type="button"
													className="searchFilterBtn"
													onClick={() =>
														onAutoSearchSubmit(
															item.sisulState === '매물' ? `${item.siDoCd} ${item.siGunGuCd}` : item.local,
															item.sisulCustomNo,
															item.sisulState === '매물'
														)
													}
												>
													<div className="itemContent">
														{item.sisulState !== '매물' && textFilter(item.adminNm)}
														{textFilter(item.sisulState === '매물' ? `${item.siDoCd} ${item.siGunGuCd}` : item.local)}
														<span>
															({item.adminPttnCd}, 정원{item.toPer}인 / 현원
															{Number(item.maPer) + Number(item.fmPer)}인)
														</span>
													</div>
												</button>
											</div>
									  ))
									: keywordFilters.map((item, idx) => (
											<div className="itemBox" key={idx}>
												<button
													type="button"
													className="searchFilterBtn"
													onClick={() =>
														onAutoSearchSubmit(`${item.siDoCd} ${item.siGunGuCd}`, item.sisulCustomNo, true)
													}
												>
													<div className="itemContent">
														<span style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
															<span>
																[{item.bonoForm}] {item.adminPttnCd}{' '}
																{item.tradingPrice > 0 && `${getLocalCurrent(item.tradingPrice)}`}
															</span>
															<span>{item.bonoDivision !== '0' && getDivision(item.bonoDivision)}</span>
														</span>
														{textFilter(`${item.siDoCd} ${item.siGunGuCd}`)}
														<span>
															[정원{item.toPer}인 / 현원 {Number(item.maPer) + Number(item.fmPer)}인]
														</span>
													</div>
												</button>
											</div>
									  ))}
							</div>
						)}
					</React.Fragment>
				)}
			</React.Fragment>
		</AutoSearchForm>
	);
};

export default AutoSearchFilter;
