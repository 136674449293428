import api from '.';

const SERVICE_URL = {
  register: '/api/registerSaleProc',
  contact: '/api/registerBuyProc',
  remodelingContact: '/api/remodeling',
  remodelingJoin: '/api/partners',
};

export const registerCenter = async (option) => await api.post(SERVICE_URL.register, option);
export const contactCenter = async (option) => await api.post(SERVICE_URL.contact, option);
export const requestRemodelingContact = async (option) => await api.post(SERVICE_URL.remodelingContact, option);
export const requestRemodelingJoin = async (option) => await api.post(SERVICE_URL.remodelingJoin, option);
