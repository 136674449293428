import styled, { keyframes } from 'styled-components';

const jump = keyframes`
  0% { transform: translateY(0); }
  2% { transform: translateY(0); }
  50% { transform: translateY(15px); }
  80% { transform: translateY(0); }
  100% { transform: translateY(0); }
`;

export const GUIDEBOX = styled.div`
    z-index: 39;
    position: fixed;
    bottom: 50px;
    width: 260px;
    height: auto;
    font-size: ${({ theme }) => theme.fontSizes.s};
    //left: calc(${(props) => (props.index + 1 + (props.index % 2)) * 10}% - 33px);
    left: calc(${(props) => (props.index + 1 + (props.index % 2)) * 25}% - 33px);
    transform: translate(0, 0);
    transform-origin: bottom left;
    // animation: ${jump} 2s infinite ease-in-out;

    svg {
        transform: translateX(22px);
    }

    .wrap {
        z-index: 11;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 4px 6px 14px;
        border-radius: 8px;
        background-color: rgba(189, 147, 105, .8);
    }
    .close {
        position: absolute;
        top: 4px; 
        right: 10px;
        width: 24px;
        height: 30px;
        background-color: transparent;
        borddr-radius: 2px;
        &::before, 
        &::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 11px;
            width: 1px;
            height: 20px;
            background-color: ${({ theme }) => theme.colors.gray500};
        }
        &::before { transform: rotate(45deg) }
        &::after { transform: rotate(-45deg) }
    }
    .title {
        width: 100%;
        height: 30px;
        border-radius: 6px;
        line-height: 30px;
        text-align: center;
        background-color:#fff;
        font-size: ${({ theme }) => theme.fontSizes.base};
        font-weight: ${({ theme }) => theme.fontSizes.bold};
        color: ${({ theme }) => theme.colors.primary}
    }
    .contents {
        font-size: ${({ theme }) => theme.fontSizes.base};
        line-height: 1.6;
        color: white;
        text-align: center;
        strong {
            color: ${({ theme }) => theme.colors.secondary};
            font-weight: ${({ theme }) => theme.fontWeights.bold};
        }
    }
    .off {
        position: relative;
        height: 20px;
        line-height: 18px;
        font-size: ${({ theme }) => theme.fontSizes.s};
        padding-left: 22px;
        background-color: transparent;
        color: #fff;

        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            width: 14px;
            height: 14px;
            border-radius: 8px;
            border: 1px solid #fff;
        }
        &::after {
            content: '';
            position: absolute;
            top: 8px;
            left: 6px;
            width: 3px;
            height: 6px;
            border-width: 0 1px 1px 0;
            border-color: #fff;
            border-style: solid;
            transform: rotate(45deg);
        }
    }
`;
