import React, {useRef} from "react";
import styled from "styled-components";

import Intersect from "../../assets/images/landing/Intersect.png";
import left_carot from "../../assets/images/landing/left_carot.png";
import right_carot from "../../assets/images/landing/right_carot.png";
import StyledText from "./StyledText";
import Star from "./Star";
import { isDesktop } from "react-device-detect";
import ReviewMob from "./ReviewMob";

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 1280px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
`;

const ScrollStyle = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  transition: 1s all;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const Container = styled.div({
  display: "flex",
  maxWidth: 1080 + 120,
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
});

const Box = styled.div(({ marginTop, marginLeft }) => ({
  minWidth: 320,
  height: 300,
  marginTop,
  marginLeft,
  padding: "0px 20px 0px 20px",
  border: "1px solid #EEEEEE",
  boxShadow: "0px 1px 6px 0px rgb(0, 0, 0, 0.2)",
  marginBottom: "2rem",
}));

const Header = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  padding: "20px 0px 20px 0px",
}));

const Divider = styled.div(() => ({
  width: "100%",
  height: 1,
  background: "#EEEEEE",
}));

const InfoBlock = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: 20,
}));

const ContentBlock = styled.div(() => ({
  wordBreak: "break-all",
  marginTop: 20,
}));

const ProfileBlock = styled.div(() => ({
  width: 28,
  height: 28,
  backgroundColor: "#D9D9D9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ReviewText = styled.p({
    fontSize: "14px",
    color: "#616161",
    letterSpacing: "0.025px",
    lineHeight: "18px",
})

const MAX_LENGTH = 140;

const type = str => {
  if (str === "REMODELING") return "리모델링후기";
  if (str === "PURCHASE") return "시설매매후기";
  if (str === "CONSULTING") return "컨설팅후기";
};

const Review = ({ data }) => {
  const ref = useRef();

  const scroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset;
  };

  return isDesktop ? (
            <ReviewContainer>
              <StyledText fontSize={28} fontWeight={700} color={"#212121"}>
                고객들이 직접 등록한 생생한 거래후기
              </StyledText>
              <Container>
                <img
                    style={{ width: 48, height: 96, cursor: "pointer", position: "relative",  bottom: 40 }}
                    src={left_carot}
                    alt="left_carot"
                    onClick={() => scroll(-320)}
                />
                <ScrollStyle ref={ref}>
                  {data?.map((review, index) => (
                      <Box key={review.reviewNo} marginLeft={index && 10}>
                        <Header>
                          <Star rate={review.star} />

                          <StyledText fontSize={15} color="black" marginLeft={5}>
                            {type(review.reviewType)}
                          </StyledText>
                        </Header>

                        <Divider />

                        <InfoBlock>
                          <ProfileBlock>
                            <img style={{ width: 18, height: 22 }} src={Intersect} alt="Intersect" />
                          </ProfileBlock>

                          <StyledText fontSize={16} color="#212121" marginLeft={8}>
                            {review.username}
                          </StyledText>

                          <StyledText fontSize={13} color="#616161" marginLeft={8}>
                            {review.createDate.slice(0, 10)}
                          </StyledText>
                        </InfoBlock>

                        <ContentBlock>
                          <ReviewText>
                            {review.review.length > MAX_LENGTH ? `${review.review.slice(0, MAX_LENGTH)}...` : review.review}
                          </ReviewText>
                        </ContentBlock>
                      </Box>
                  ))}
                </ScrollStyle>
                <img
                    style={{ width: 48, height: 96, cursor: "pointer", position: "relative", bottom: 40 }}
                    src={right_carot}
                    alt="left_carot"
                    onClick={() => scroll(320)}
                />
              </Container>
            </ReviewContainer>
        ) : (
            <ReviewMob data={data}/>
        )
};

export default Review;
