import React, { useState, useEffect, useRef } from 'react';
import {
  Wrapper,
  Form,
  OptionForm,
  ResetIcon,
  CalcForm,
  Caption,
  CalcHeader,
  ResultAbstract,
} from '../CalculatorStyle';
import { module } from '../../../themes/module';
import { isDesktop } from '../../../utils/detect';
import { TYPE_AND_CAPACITY } from '../../../scheme/calculator';
import { getNumber, getLocalCurrent } from '../../../utils/number';
import { useOnlyNum } from '../../../hooks/form';
import Modal from '../../Modal/Modal';

const CenterCalculator = ({ initialData, onFormSubmit, onFormReset, resetAble, inputAble = true, result }) => {
  /* === 수익 계산 결과 보기 == */
  const [resultShow, setResultShow] = useState(false);
  /* === 수익 계산 input 허용 === */
  const [inputOn, setInputOn] = useState(inputAble);
  /* === 수익계산 옵션값 보기 === */
  const [optionShow, setOptionShow] = useState(true);

  /* === 입력값 세팅 & VALIDATION === */
  const [type, setType] = useState(''); // 요양시설 타입

  const [capacity, setCapacity, clearCapacity] = useOnlyNum('');
  const [commons, setCommons, clearCommons] = useOnlyNum(''); // 일반병실 현원수
  const [premiums, setPremiums, clearPremiums] = useOnlyNum(''); // 상급병실 현원수
  const [premiumPrice, setPremiumPrice, clearPremiumPrice] = useOnlyNum(''); // 상급병실료
  const [additions, setAdditions, clearAdditions] = useOnlyNum('');

  const [price, setPrice, clearPrice] = useOnlyNum(''); // 매매가
  const [loan, setLoan, clearLoan] = useOnlyNum(''); // 대출금
  const [rent, setRent, clearRent] = useOnlyNum(''); // 월차임

  const [warning, setWarning] = useState(false); // 경고 문구
  const [warningText, setWarningText] = useState('');

  const resultRef = useRef();
  const boxRef = useRef();

  const initForm = (data) => {
    const DEFAULT_TYPE = data.type || TYPE_AND_CAPACITY[Object.keys(TYPE_AND_CAPACITY)[0]];

    setType(DEFAULT_TYPE);
    clearCapacity(data.capacity);
    setWarning(false);
    setWarningText('');
    clearCommons(data.commons);
    clearPremiums(data.premiums);
    clearPremiumPrice(data.premiumPrice);
    clearAdditions(data.additions);
    clearPrice(data.price);
    clearLoan(data.loan);
    clearRent(data.rent);
  };

  /* === 일반병실 현원수 설정 === */
  const handlePeople = (event, name) => {
    const nums = getNumber(event.currentTarget.value);
    const max = capacity - getNumber(name === 'premiums' ? commons : premiums);

    if (nums > max) {
      event.preventDefault();
      setWarning(true);
      setWarningText('일반병실 현원수와 상급병실 현원수의 합이 정원수를 초과합니다.');
    } else {
      if (name === 'premiums') setPremiums(event);
      if (name === 'commons') setCommons(event);
    }
  };

  const handleCapacity = (capacity) => {
    const CAPACITY_EXCESS = getNumber(capacity) - (getNumber(commons) + getNumber(premiums)) < 0;
    if (CAPACITY_EXCESS) {
      clearCommons();
      clearPremiums();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (capacity.length <= 0 || commons.length <= 0) {
      setWarningText('정원수와 현원수는 필수 입력값 입니다.');
      setWarning(true);
    } else if (type === '주야간보호센터' && (price.length <= 0 || rent.length <= 0)) {
      setWarningText(`주야간보호센터는 \n 매매가(보증금)와 월차임(주간보호)이 필수 입력값 입니다.`);
      setWarning(true);
    } else if (type !== '주야간보호센터' && (price.length <= 0 || loan.length <= 0)) {
      setWarningText(`${type}은 \n 매매가(보증금)와 대출금이 필수 입력값 입니다.`);
      setWarning(true);
    } else {
      onFormSubmit({
        type: type,
        capacity: capacity,
        commons: commons,
        premiums: premiums,
        premiumPrice: premiumPrice,
        price: price,
        loan: loan,
        rent: rent,
        additions: additions,
      });
    }
  };

  useEffect(() => {
    if (type.length > 0 && capacity.length > 0 && inputAble) {
      handleCapacity(capacity);
    }
  }, [type, capacity]);

  useEffect(() => {
    setInputOn(false);
    setResultShow(false);

    if (initialData) {
      initForm(initialData);
    }

    if (result) {
      const top = parseInt(resultRef.current.getBoundingClientRect().top - boxRef.current.getBoundingClientRect().top);
      boxRef.current.scrollTo({ top: top, left: 0, behavior: 'smooth' });
    } else {
      setInputOn(true);
      setOptionShow(true);
    }
  }, [initialData, result]);

  useEffect(() => {
    if (result && resultShow === true) {
      const resultRect = resultRef.current.getBoundingClientRect();
      const top = parseInt(resultRect.top + resultRect.height - boxRef.current.getBoundingClientRect().top);
      boxRef.current.scrollTo({ top: top, left: 0, behavior: 'smooth' });
    }
  }, [resultShow]);

  const resultTableHead = () => (
    <tr>
      <th colSpan={isDesktop ? '2' : '1'}>구분</th>
      <th>인원</th>
      <th>단가</th>
      <th>금액</th>
      {isDesktop && <th>내용</th>}
    </tr>
  );

  const resultMobileColGroup = () => (
    <colgroup>
      <col width="82px"></col>
      <col width="auto"></col>
      <col width="auto"></col>
      <col width="auto"></col>
    </colgroup>
  );

  return (
    <Wrapper ref={boxRef} className={result ? 'calced' : ''}>
      <div className="wrap">
        {isDesktop && (
          <Form
            onSubmit={(event) => handleSubmit(event)}
            onReset={(event) => onFormReset(event)}
            style={{ gap: '20px' }}
          >
            <fieldset>
              <legend>예상 수익 계산</legend>
              <table>
                <colgroup>
                  <col width="33.3%" />
                  <col width="33.3%" />
                  <col width="33.3%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <label htmlFor="cf01">요양시설 타입</label>
                    </th>
                    <th>
                      <label htmlFor="cf02">정원수</label>
                    </th>
                    <th>
                      <label htmlFor="cf03">현원수(일반병실)</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select
                        id="cf01"
                        name="cf01"
                        value={type}
                        onChange={(event) => setType(event.currentTarget.value)}
                        disabled={!inputOn}
                      >
                        <option value="단독요양원">단독요양원</option>
                        <option value="상가요양원">상가요양원</option>
                        <option value="주야간보호센터">주야간보호센터</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        id="cf02"
                        name="cf02"
                        value={capacity}
                        placeholder="숫자 입력"
                        autoComplete="off"
                        onChange={(event) => setCapacity(event)}
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="cf03"
                        name="cf03"
                        value={commons}
                        placeholder="숫자 입력"
                        autoComplete="off"
                        onChange={(event) => handlePeople(event, 'commons')}
                        readOnly={!inputOn}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <colgroup>
                  <col width="33.3%" />
                  <col width="33.3%" />
                  <col width="33.3%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <label htmlFor="cf04">매매가(보증금)</label>
                    </th>
                    <th>
                      <label htmlFor="cf05">대출금</label>
                    </th>
                    <th>
                      <label htmlFor="cf06">월차임(주간보호)</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        id="cf04"
                        name="cf04"
                        value={price}
                        placeholder="숫자 입력"
                        autoComplete="off"
                        onChange={(event) => setPrice(event)}
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="cf05"
                        name="cf05"
                        value={loan}
                        placeholder={type === '주야간보호센터' ? '-' : '숫자 입력'}
                        autoComplete="off"
                        onChange={(event) => setLoan(event)}
                        readOnly={!inputOn || type === '주야간보호센터'}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        id="cf06"
                        name="cf06"
                        value={rent}
                        placeholder={type !== '주야간보호센터' ? '-' : '숫자 입력'}
                        autoComplete="off"
                        onChange={(event) => setRent(event)}
                        readOnly={!inputOn || type !== '주야간보호센터'}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <OptionForm>
              <fieldset>
                <legend
                  className={optionShow ? 'on' : ''}
                  onClick={() => {
                    setOptionShow((optionShow) => !optionShow);
                  }}
                >
                  {result ? '추가 사항' : '추가 입력 사항'}
                </legend>
                {optionShow && (
                  <table>
                    <colgroup>
                      <col width="33.3%" />
                      <col width="33.3%" />
                      <col width="33.3%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>
                          <label htmlFor="cfo01">현원수(상급병실)</label>
                        </th>
                        <th>
                          <label htmlFor="cfo02">상급병실료</label>
                        </th>
                        <th>
                          <label htmlFor="cfo03">예상 가산금(원/월)</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            type="text"
                            id="cfo01"
                            name="cfo01"
                            value={premiums}
                            placeholder="숫자 입력"
                            autoComplete="off"
                            onChange={(event) => handlePeople(event, 'premiums')}
                            readOnly={!inputOn}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            id="cfo02"
                            name="cfo02"
                            value={premiumPrice}
                            placeholder="숫자 입력"
                            autoComplete="off"
                            onChange={(event) => setPremiumPrice(event)}
                            readOnly={!inputOn}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            id="cfo03"
                            name="cfo03"
                            value={additions}
                            placeholder="숫자 입력"
                            autoComplete="off"
                            onChange={(event) => setAdditions(event)}
                            readOnly={!inputOn}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </fieldset>
            </OptionForm>
            <div className="actions">
              <button type="submit">계산하기</button>
              <button type="reset">초기화</button>
            </div>
          </Form>
        )}
        {!isDesktop && (
          <module.MobileForm onSubmit={(event) => handleSubmit(event)} onReset={(event) => onFormReset(event)}>
            <fieldset className="cols">
              <legend>필수 입력</legend>
              <div className="wrap">
                <label htmlFor="cf01">요양시설 타입</label>
                <select
                  id="cf01"
                  name="cf01"
                  value={type}
                  onChange={(event) => setType(event.currentTarget.value)}
                  disabled={!inputOn}
                >
                  <option value="단독요양원">단독요양원</option>
                  <option value="상가요양원">상가요양원</option>
                  <option value="주야간보호센터">주야간보호센터</option>
                </select>
              </div>
              <div className="wrap">
                <label htmlFor="cf02">정원수</label>
                <input
                  type="text"
                  id="cf02"
                  name="cf02"
                  value={capacity}
                  placeholder="숫자 입력"
                  autoComplete="off"
                  onChange={(event) => setCapacity(event)}
                  readOnly={!inputOn}
                />
              </div>
              <div className="wrap">
                <label htmlFor="cf03">현원수(일반병실)</label>
                <input
                  type="text"
                  id="cf03"
                  name="cf03"
                  value={commons}
                  placeholder="숫자 입력"
                  autoComplete="off"
                  onChange={(event) => handlePeople(event, 'commons')}
                  readOnly={!inputOn}
                />
              </div>
              <div className="wrap">
                <label htmlFor="cf08">매매가(보증금)</label>
                <input
                  type="text"
                  id="cf08"
                  name="cf08"
                  value={price}
                  placeholder="숫자 입력"
                  autoComplete="off"
                  onChange={(event) => setPrice(event)}
                  readOnly={!inputOn}
                />
              </div>
              <div className="wrap">
                <label htmlFor="cf09">대출금</label>
                <input
                  type="text"
                  id="cf09"
                  name="cf09"
                  value={loan}
                  placeholder={type === '주야간보호센터' ? '-' : '숫자 입력'}
                  autoComplete="off"
                  onChange={(event) => setLoan(event)}
                  readOnly={!inputOn || type === '주야간보호센터'}
                />
              </div>
              <div className="wrap">
                <label htmlFor="cf10">월차임(주간보호)</label>
                <input
                  type="text"
                  id="cf10"
                  name="cf10"
                  value={rent}
                  placeholder={type !== '주야간보호센터' ? '-' : '숫자 입력'}
                  autoComplete="off"
                  onChange={(event) => setRent(event)}
                  readOnly={!inputOn || type !== '주야간보호센터'}
                />
              </div>
            </fieldset>
            <OptionForm>
              <fieldset className="cols">
                <legend
                  className={optionShow ? 'on' : ''}
                  onClick={() => {
                    setOptionShow((optionShow) => !optionShow);
                  }}
                >
                  추가 입력
                </legend>
                {optionShow && (
                  <>
                    <div className="wrap">
                      <label htmlFor="cf04">현원수(상급병실)</label>
                      <input
                        type="text"
                        id="cf04"
                        name="cf04"
                        value={premiums}
                        placeholder="숫자 입력"
                        autoComplete="off"
                        onChange={(event) => handlePeople(event, 'premiums')}
                        readOnly={!inputOn}
                      />
                    </div>
                    <div className="wrap">
                      <label htmlFor="cf05">상급병실료</label>
                      <input
                        type="text"
                        id="cf05"
                        name="cf05"
                        value={premiumPrice}
                        placeholder="숫자 입력"
                        autoComplete="off"
                        onChange={(event) => setPremiumPrice(event)}
                        readOnly={!inputOn}
                      />
                    </div>
                    <div className="wrap">
                      <label htmlFor="cf07">예상 가산금(원/월)</label>
                      <input
                        type="text"
                        id="cf07"
                        name="cf07"
                        value={additions}
                        placeholder="숫자 입력"
                        autoComplete="off"
                        onChange={(event) => setAdditions(event)}
                        readOnly={!inputOn}
                      />
                    </div>
                  </>
                )}
              </fieldset>
            </OptionForm>
            <div className="actions">
              {!resetAble && <button type="submit">계산하기</button>}
              {resetAble && (
                <button type="reset">
                  초기화
                  <ResetIcon />
                </button>
              )}
            </div>
          </module.MobileForm>
        )}
        {result && (
          <>
            <ResultAbstract ref={resultRef}>
              <div className="result">
                <span>{result['월수익'].totalPrice} 원/월</span>
                <span>
                  투자 {getLocalCurrent(result['투자금'])}원 기준 <strong>예상 연수익율 {result['연수익율']}</strong>
                </span>
                <span>
                  (
                  {initialData['type'] === '주야간보호센터'
                    ? `권리금 ${getLocalCurrent(result['권리금'])}`
                    : `대출금 ${getLocalCurrent(result['대출금'])}`}{' '}
                  원)
                </span>
              </div>
              <span className="base">{result['기준']}</span>
              <button
                className={resultShow ? 'on' : ''}
                onClick={() => {
                  setResultShow((resultShow) => !resultShow);
                }}
              >
                상세 계산 내역 보기
              </button>
            </ResultAbstract>
            {resultShow && (
              <CalcForm>
                {isDesktop && (
                  <CalcHeader>
                    <span>{result['기준']}</span>
                    <span className="title">{`${result['시설']}(${result['정원']}인)시설`}</span>
                    <span>
                      {initialData['type'] === '주야간보호센터'
                        ? `※ 보증금: ${
                            getNumber(initialData['price']) >= 10000
                              ? parseInt(getNumber(initialData['price']) / 10000) + '만'
                              : initialData['price']
                          }원, 월차임: ${
                            getNumber(initialData['rent']) >= 10000
                              ? parseInt(getNumber(initialData['rent']) / 10000) + '만'
                              : initialData['rent']
                          }만원 기준`
                        : `※ 매매가 ${
                            getNumber(initialData['price']) >= 100000000
                              ? parseFloat(getNumber(initialData['price']) / 100000000).toFixed(1) + '억'
                              : initialData['price']
                          }원 기준`}
                    </span>
                  </CalcHeader>
                )}
                {isDesktop && (
                  <table>
                    <colgroup>
                      <col width="160px"></col>
                      <col width="160px"></col>
                      <col width="100px"></col>
                      <col width="auto"></col>
                      <col width="auto"></col>
                      <col width="160px"></col>
                    </colgroup>
                    <thead>{resultTableHead()}</thead>
                    <tbody>
                      {Object.keys(result['수입']).map((key, idx) => {
                        const item = result['수입'][key];
                        return (
                          <tr
                            key={idx}
                            className={item.title === '합계' ? 'total' : item.title.includes('인건비') ? 'cost' : ''}
                          >
                            {idx === 0 && <td rowSpan={Object.keys(result['수입']).length}>1 수입</td>}
                            <td>{item.title}</td>
                            <td>{item.capacity}</td>
                            <td className="price">{item.unitPrice}</td>
                            <td className="price">{item.totalPrice}</td>
                            <td className="memo">{item.memo && item.memo}</td>
                          </tr>
                        );
                      })}
                      {Object.keys(result['지출']).map((key, idx) => {
                        const item = result['지출'][key];
                        return (
                          <tr
                            key={idx}
                            className={item.title === '합계' ? 'total' : item.title.includes('인건비') ? 'cost' : ''}
                          >
                            {idx === 0 && <td rowSpan={Object.keys(result['지출']).length}>2. 지출</td>}
                            <td>{item.title}</td>
                            <td>{item.capacity}</td>
                            <td className="price">{item.unitPrice}</td>
                            <td className="price">{item.totalPrice}</td>
                            <td className="memo">{item.memo && item.memo}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td rowSpan="1" className="total">
                          3. 월 수익
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="price">{result['월수익'].totalPrice}</td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                )}
                {!isDesktop && (
                  <>
                    <Caption>수입</Caption>
                    <table>
                      {resultMobileColGroup()}
                      <thead>{resultTableHead()}</thead>
                      <tbody>
                        {Object.keys(result['수입']).map((key, idx) => {
                          const item = result['수입'][key];
                          return (
                            <tr
                              key={idx}
                              className={item.title === '합계' ? 'total' : item.title.includes('인건비') ? 'cost' : ''}
                            >
                              <td>{item.title}</td>
                              <td>{item.capacity}</td>
                              <td className="price">{item.unitPrice}</td>
                              <td className="price">{item.totalPrice}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <Caption>지출</Caption>
                    <table>
                      {resultMobileColGroup()}
                      <thead>{resultTableHead()}</thead>
                      <tbody>
                        {Object.keys(result['지출']).map((key, idx) => {
                          const item = result['지출'][key];
                          return (
                            <tr
                              key={idx}
                              className={item.title === '합계' ? 'total' : item.title.includes('인건비') ? 'cost' : ''}
                            >
                              <td>{item.title}</td>
                              <td>{item.capacity}</td>
                              <td className="price">{item.unitPrice}</td>
                              <td className="price">{item.totalPrice}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>월 수익</td>
                          <td colSpan={3} className="price">
                            {result['월수익'].totalPrice} 원
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </>
                )}
                <span className="warn">※ 해당 수익계산기의 예상 값은 실제 수익과 상이할 수 있으니 주의 바랍니다. </span>
              </CalcForm>
            )}
          </>
        )}
      </div>
      {warning && (
        <Modal title="수익 계산" width="300" close={true} onCloseClick={() => setWarning(false)}>
          <div>{warningText}</div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default CenterCalculator;
