import { MAP } from '../actions/map';
import { removeMarkers, renderGeoPin, showMarkers } from '../../utils/map';

const initialState = {
  map: null,
  markers: [],
  infoWindow: null,
  cadastral: false,
  street: false,
  maemulAlert: false,
  alert: '',
  geolocation: {
    latlng: [],
    region: '',
    pin: null,
  },
};

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAP.INIT_MAP:
      return { ...state, map: action.payload };
    case MAP.UPDATE_MARKERS:
      const prevMarkers = state.infoWindow ? state.markers.concat(state.infoWindow) : state.markers;
      removeMarkers(prevMarkers);
      const markers = action.payload;
      return { ...state, markers: markers, infoWindow: null };
    case MAP.UPDATE_INFOWINDOW:
      removeMarkers(state.infoWindow);
      const infoWindow = action.payload;
      return { ...state, infoWindow: infoWindow };
    case MAP.SHOW_OVERLAY:
      const sTargets = state.infoWindow ? state.markers.concat(state.infoWindow) : state.markers;
      showMarkers(sTargets, action.payload);
      return state;
    case MAP.CLEAR_OVERLAY:
      const cTargets = state.infoWindow ? state.markers.concat(state.infoWindow) : state.markers;
      removeMarkers(cTargets);
      return { ...state, markers: [], infoWindow: null };
    case MAP.ACTIVATE_GEOLOCATION:
      const geo = action.payload;
      const geoPin = renderGeoPin(state.map, geo.latlng);
      return { ...state, geolocation: { ...state.geolocation, ...action.payload, pin: geoPin } };
    case MAP.DEACTIVATE_GEOLOCATION:
      if (state.geolocation.pin) removeMarkers(state.geolocation.pin);
      return { ...state, geolocation: initialState.geolocation };
    case MAP.ACTIVATE_CADASTRAL:
      return { ...state, cadastral: true };
    case MAP.DEACTIVATE_CADASTRAL:
      return { ...state, cadastral: false };
    case MAP.STREET_MODE:
      return { ...state, street: !state.street };
    case MAP.ACTIVATE_MAEMULALERT:
      return { ...state, maemulAlert: true };
    case MAP.DEACTIVATE_MAEMULALERT:
      return { ...state, maemulAlert: false };
    default:
      return state;
  }
};

export default MapReducer;
