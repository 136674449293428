import styled, { css } from 'styled-components';
import { theme } from './theme';
import selectArrow from '../assets/images/form/ico-selectArrow.svg';

const YScrollCss = css`
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    width: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    border: 2px solid white;
    border-radius: 6px;
    background-color: #bdbdbd;
  }

  > * {
    height: auto;
  }
`;

const scrollWrapper = styled.div`
  height: 100%;
  ${YScrollCss}
`;

const Button = styled.button`
  width: 100%;
  border-radius: 2px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  height: 44px;
  padding: 0 8px;
  border-bottom: 2px solid ${theme.colors.gray200};
  font-size: 16px;
  background-color: #fff;

  &:focus,
  &:active {
    border-bottom: 2px solid ${theme.colors.primary};
  }

  &:placeholder {
    color: ${props => (props.border ? '#BDBDBD' : '#757575')};
  }

  &:read-only,
  &:read-only:active,
  &:read-only:focus {
    border: 0;
  }

  &.bd {
    padding: 0 16px;
    border: 1px solid ${theme.colors.gray200};
    font-size: 14px;
    &:focus,
    &:active {
      border: 1px solid ${theme.colors.primary};
    }
  }

  &.invalid:active,
  &.invalid:focus {
    border-color: ${theme.colors.red};
  }

  & + .warn {
    display: block;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.red};
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 226px;
  padding: 16px;
  border: 1px solid ${theme.colors.gray200};
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  background-color: #fff;
  resize: none;

  &:focus,
  &:active {
    border: 1px solid ${theme.colors.primary};
  }

  &:placeholder {
    color: #bdbdbd;
  }
`;

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SubmitButton = styled(Button)`
  height: 48px;
  line-height: 48px;
  background-color: #bd9369;
  color: #fff;
  margin: 0 auto;

  &.disabled {
    background-color: #f4f4f4;
    color: #bdbdbd;
  }
`;

const TableForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  font-size: ${theme.fontSizes.base};

  fieldset {
    display: flex;
    flex-direction: column;

    legend {
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 500;
    }

    @media screen and (max-width: 1024px) {
      table, th, td, input{
        font-size: 12px;
      }
    }

    table {
      width: 100%;
      border: 1px solid #e0e0e0;
      
      
      
      tbody {
        th, td {
          border-right: 1px solid #e0e0e0;
          height: 40px !important;
        }
      }
      
      tr {
        border-bottom: 1px solid #e0e0e0;
        &:last-child {
          border-bottom: 0;
        }

        th {
          height: 37px;
          background-color: #8b653e;
          color: #fff;
          vertical-align: top;

          label {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
          }
        }

        td {
          position: relative;
          height: 100%;
          vertical-align: middle;

          input,
          select {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 12px;
            border: 0;
            font-size: ${theme.fontSizes.base};
          }

          select {
            -webkit-appearance: none;
            appearance: none;
            background: url(${selectArrow}) calc(100% - 6px) center no-repeat;
          }

          textarea {
            display: inline-block;
            width: 100%;
            height: 74px;
            padding: 12px;
            border: 0;
            resize: none;
          }

          .selfType {
            position: absolute;
            top: 0;
            width: calc(100% - 30px);
            height: 100%;
            &::placeholder {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  button {
    width: 160px;
    height: 52px;
    margin: 0 auto;
    background-color: ${theme.colors.primary};
    color: #fff;

    &.disabled {
      background-color: ${theme.colors.gray100};
      color: ${theme.colors.gray400};
    }
  }

  .warn {
    display: block;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.red};
  }
`;

const MobileForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
  // padding-bottom: 52px;

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &.cols {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 32px 20px;

      .wrap {
        flex: inherit;
        justify-content: space-between;
        width: calc(50% - 10px);

        label {
          font-size: ${theme.fontSizes.s};
        }
      }
    }

    legend {
      display: block;
      width: 100%;
      padding-bottom: 12px;
      margin-bottom: 32px;
      border-bottom: 2px solid ${theme.colors.primary};
      font-size: ${theme.fontSizes.l};
      font-weight: ${theme.fontWeights.medium};
    }

    .wrap {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow-x: hidden;
    }

    label {
      font-weight: ${theme.fontWeights.medium};
      white-space: pre-wrap;
      line-height: 1.2;
    }

    input,
    select {
      width: 100%;
      height: 44px;
      padding: 0 8px;
      border-radius: 2px;
      border: 1px solid ${theme.colors.gray200};
    }

    select {
      -webkit-appearance: none;
      appearance: none;
      background: url(${selectArrow}) calc(100% - 6px) center no-repeat;
    }

    textarea {
      width: 100%;
      height: 160px;
      padding: 12px;
      border-radius: 2px;
      border: 1px solid ${theme.colors.gray200};
      resize: none;
    }

    .selfType {
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% - 40px);
    }
  }

  button {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background-color: ${theme.colors.primary};
    color: #fff;

    &.disabled {
      background-color: ${theme.colors.gray100};
      color: ${theme.colors.gray400};
    }
  }

  .warn {
    display: block;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.red};
  }
`;

export const AccountForm = styled.form`
  position: relative;
  width: 100%;
  height: 100%;

  .mobile & {
    padding: 40px 24px 76px;
  }

  .desc {
    margin-bottom: 40px;
    line-height: 1.4;
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.gray500};

    .mobile & {
      text-align: center;
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  hr {
    display: block;
    width: 100%;
    height: 1px;
    margin: 24px 0;
    border: 0;
    background-color: ${theme.colors.gray200};
  }

  .wrap {
    position: relative;
    display: flex;
    flex-direction: column;

    label {
      display: block;
      margin-bottom: 12px;
      font-size: 15px;
      font-weight: ${theme.fontWeights.medium};
    }

    input {
      width: 100%;
      height: 44px;
      border-bottom: 1px solid ${theme.colors.gray200};
      padding: 0 16px;

      &:focus {
        border-color: ${theme.colors.primary};
      }

      &.bd {
        border: 1px solid ${theme.colors.gray200};
        border-radius: 2px;
        &.invalid:focus {
          border: 1px solid ${theme.colors.red};
        }
      }

      &:read-only,
      &:read-only:focus,
      &.bd:read-only &.bd:read-only:focus {
        border: 0;
      }
    }

    .warn {
      display: block;
      padding-top: 8px;
      margin-top: -1px;
      border-top: 2px solid ${theme.colors.red};
      font-size: ${theme.fontSizes.xs};
      color: ${theme.colors.red};
    }

    input.bd + .warn {
      border: 0;
    }
  }

  .action {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;

    .mobile & {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    button {
      width: 100%;
      height: 56px;
      border-radius: 2px;
      text-align: center;
      line-height: 56px;
      background-color: ${theme.colors.primary};
      color: #fff;
      font-weight: ${theme.fontWeights.medium};

      &.disabled {
        background-color: ${theme.colors.gray100};
        color: ${theme.colors.gray400};
      }
    }

    span,
    a {
      text-decoration: underline;
      font-size: ${theme.fontSizes.s};
    }
  }
`;

const ModalAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  ${'' /* margin-top: 56px; */}

  .btn {
    ${'' /* margin-top: 56px; */}
    width: 100%;
    height: 56px;
    border-radius: 2px;
    text-align: center;
    line-height: 56px;
    background-color: ${theme.colors.primary};
    color: #fff;
    font-weight: ${theme.fontWeights.medium};
  }

  .link {
    margin-top: 16px;
    text-decoration: underline;
    font-size: ${theme.fontSizes.s};
    background-color: transparent;
  }
`;

const SectionLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &.btm {
    position: fixed;
    padding-bottom: 40px;
    bottom: 56px;
    left: 0;
    width: 100%;
  }

  .link {
    margin-top: 16px;
    text-decoration: underline;
    font-size: ${theme.fontSizes.s};
    background-color: transparent;
    color: ${theme.colors.gray700};
  }
`;

const Agreement = styled.div`
  .inform {
    margin-top: 32px;
    padding: 24px 32px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;

    strong {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }

    p {
      margin-top: 16px;
      font-size: ${({ theme }) => theme.fontSizes.base};
      line-height: 1.5;
    }

    .mobile & {
      padding: 16px 24px;
    }
  }

  .agree {
    display: flex;
    gap: 8px;
    margin-top: 32px;
  }

  .policy {
    margin-top: 8px;
    padding: 24px 32px;
    border: 1px solid ${({ theme }) => theme.colors.gray200};
    border-radius: 2px;
    font-size: ${({ theme }) => theme.fontSizes.base};
    color: ${({ theme }) => theme.colors.gray800};
    line-height: 1.5;

    .mobile & {
      padding: 16px 24px;
    }
  }

  .term {
    width: 100%;
    height: 100px;
    margin: 16px 0 12px;
    padding: 12px;
    width: 100%;
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.colors.gray300};
    color: ${({ theme }) => theme.colors.gray600};
    line-height: 1.3;
    white-space: pre-wrap;
    overflow-x: hidden;
    overflow-y: scroll;

    .mobile & {
      margin: 16px 0 20px;
      height: 160px;
    }
  }
`;

export const QuestionMark = styled.div`
  position: absolute;
  width: 0;
  height: 0;

  &.on {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    text-align: center;
    line-height: 14px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    background-color: #fff;
    cursor: pointer;
    transition: 0.1s linear;

    .mobile & {
      width: 15px;
      height: 15px;
      font-size: ${({ theme }) => theme.fontSizes.xxs};
      line-height: 13px;
    }

    &::before {
      content: '?';
    }

    &:hover {
      color: #fff;
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      transform: scale(1.2);
    }
  }
`;

const FileAddIcon = styled.label`
  border-radius: 99%;
  background: #BD9369;
  color: #fff;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 4px;
  
  input {
    display: none;
  }
`;

const FileList = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  
  button {
    border-radius: 99%;
    background: #BD9369;
    color: #fff;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    font-size: 24px;
  }
`;


export const module = {
  YScrollCss,
  scrollWrapper,
  Fieldset,
  Textarea,
  Input,
  SubmitButton,
  TableForm,
  MobileForm,
  AccountForm,
  ModalAction,
  SectionLink,
  Agreement,
  QuestionMark,
  FileAddIcon,
  FileList
};
