import React, { useState, useEffect, Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenterList from '../../components/Center/CenterList/CenterList';
import ListHeader from '../../components/List/ListHead/ListHead';
import ListTab from '../../components/List/ListTab/ListTab';
import Panel from '../../components/ui/Panel/Panel';
import { getCategoryCenters } from '../../api/centers';
import AddressFilterContainer from '../filters/AddressFilterContainer';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateFilterForData } from '../../store/actions/filter';
import { isDesktop } from '../../utils/detect';
import ContactFloatingButton from '../../components/ui/FloatingButton/ContactFloatingButton';
import useDeepCompareEffect from 'use-deep-compare-effect';

const CENTERS_CTG = {
  nursing: {
    title: '요양원',
    index: 0,
    category2: '요양원',
    url: '/bono/nursing',
    navs: ['요양원', '주야간보호', '신규 사업지'],
    head: '보노랜드 매물',
  },
  care: {
    title: '주야간보호',
    index: 1,
    category2: '주야간',
    url: '/bono/care',
    navs: ['요양원', '주야간보호', '신규 사업지'],
    head: '보노랜드 매물',
  },
  biz: {
    title: '신규 사업지',
    index: 2,
    category2: '신규',
    url: '/bono/biz',
    navs: ['요양원', '주야간보호', '신규 사업지'],
    head: '보노랜드 매물',
  },
};

const BonoListContainer = () => {
  let PARAMS = useLocation().pathname.split('/bono/')[1];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const FILTER = useSelector(state => state.Filter.data);
  const USER_NO = useSelector(state => state.User.userInfo.no);
  const [region, setRegion] = useState('');

  const [options, setOptions] = useState(null);

  const [nextIndex, setNextIndex] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [centers, setCenters] = useState([]);
  const [currentCtg, setCurrentCtg] = useState(CENTERS_CTG[PARAMS]);

  const onNavClick = nav => {
    const ctgParams = Object.keys(CENTERS_CTG).filter(key => CENTERS_CTG[key].title === nav)[0];
    navigate(CENTERS_CTG[ctgParams].url);
  };

  const loadNext = async () => {
    setIsNextLoading(true);
    const RESPONSE = await getCategoryCenters({ ...options, page: nextIndex });

    if (RESPONSE && RESPONSE.data.code === 1) {
      setCenters([...centers, ...RESPONSE.data.arrayResult]);
      setHasNext(RESPONSE.data.pageCode === 1);
      setErrMsg('');
    } else {
      setErrMsg(
        `해당 지역은 매물 공개 처리 심사 중입니다. \n 공개 전 매물 상담은 유선으로 가능합니다.`,
      );
    }
    setIsNextLoading(false);
    setNextIndex(nextIndex => nextIndex + 1);
  };

  useEffect(() => {
    dispatch(
      updateFilterForData({
        category2: CENTERS_CTG[PARAMS].category2,
      }),
    );
    setCurrentCtg(CENTERS_CTG[PARAMS]);
  }, [PARAMS]);

  useDeepCompareEffect(() => {
    if (FILTER.category2) {
      setCenters([]);
      setNextIndex(1);
      setOptions({
        area1: FILTER.address.area1,
        area2: FILTER.address.area2,
        area3: FILTER.address.area3,
        userNo: USER_NO,
        category2: FILTER.category2,
        sisulCustomNo: FILTER.sisulCustomNo,
      });
      setErrMsg('');

      if (!isDesktop) setRegion(FILTER.region);
    }
  }, [FILTER.address, FILTER.category2, FILTER.sisulCustomNo]);

  useEffect(() => {
    if (options) loadNext();
  }, [options]);

  return (
    <Panel
      type={isDesktop ? 'side' : ''}
      position={isDesktop ? 'left' : ''}
      fold={isDesktop ? true : false}
    >
      <ListHeader title={currentCtg.head}>
        <>
          {!isDesktop && <div className="region">{region.length > 0 ? region : '전국'}</div>}
          <AddressFilterContainer type="sub" category2={currentCtg.category2} />
        </>
      </ListHeader>
      <ListTab
        navs={currentCtg.navs}
        onNavClick={onNavClick}
        active={currentCtg.index}
        contents={[
          <CenterList
            list={PARAMS}
            centers={centers}
            hasNext={hasNext}
            isNextLoading={isNextLoading}
            loadNext={loadNext}
            msg={errMsg}
          />,
        ]}
      />
      {errMsg.length > 0 && (
        <ContactFloatingButton
          customStyle={{
            position: 'absolute',
            top: isDesktop ? 'calc(50% + 150px)' : 'calc(50% + 200px)',
            left: 'calc(50% - 16px)',
          }}
          shadow={false}
          tel="16610169"
        />
      )}
    </Panel>
  );
};

export default BonoListContainer;
