import React, { useEffect, useState, useRef } from 'react';
import { Tab, Logo, TabNavs, TabConts } from './TermsStyle';
import { isDesktop } from '../../utils/detect';
import { Loading, NoData, Error } from '../ui/Inform/Inform';

const TERMS_TITLE = ['이용약관', '개인정보 처리방침', '위치기반서비스 이용약관'];

const Terms = ({ terms, category, loading, error, children }) => {
  const boxRef = useRef(null);
  const [tabIdx, setTabIdx] = useState(0);

  const toggleTab = (idx) => {
    setTabIdx(idx);
  };

  const responsiveWords = (word) => {
    if (word.length <= 10) return word;

    let words = word.split(' ');
    return words.map((word, idx) => (idx < 1 ? <span>{word}</span> : <span className="pc">{word}</span>));
  };

  const getCategoryIdx = (category) => {
    const ctg = category.replaceAll(' ', '');
    const idx = TERMS_TITLE.reduce((acc, title, idx) => {
      if (title.replaceAll(' ', '') === ctg) return acc + idx;
      else return acc;
    }, 0);
    return idx;
  };

  useEffect(() => {
    toggleTab(getCategoryIdx(category));
  }, [category]);

  useEffect(() => {
    if (!isDesktop) boxRef.current.scrollTo(0, 0);
    else window.scrollTo(0, 0);
  }, [tabIdx]);

  return (
    <Tab className={!isDesktop ? 'mobile' : ''} ref={boxRef}>
      <div className="wrapper">
        <header>
          <div>
            <Logo to="/" />
            <TabNavs>
              {TERMS_TITLE.map((item, idx) => (
                <div key={idx} className={tabIdx === idx && 'active'} onClick={() => toggleTab(idx)}>
                  {responsiveWords(item)}
                </div>
              ))}
            </TabNavs>
          </div>
        </header>
        <main>
          {loading && Loading()}
          {error && Error()}
          {terms && terms.length > 0 && (
            <TabConts>
              {terms.map((item, idx) => (
                <div className={tabIdx === idx ? 'active' : ''} key={idx}>
                  <strong>{TERMS_TITLE[idx]}</strong>
                  <pre>{item.siteContents}</pre>
                </div>
              ))}
            </TabConts>
          )}
        </main>
        {children}
      </div>
    </Tab>
  );
};

export default Terms;
