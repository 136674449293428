import React, { useState, useEffect } from 'react';
import { module } from '../../themes/module';
import { useForm } from 'react-hook-form';
import { REGEXP } from '../../scheme/form';
import { VISIT_ROUTES } from '../../scheme/contact';
import { isDesktop } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { activateAlert } from '../../store/actions/alert';

const requiredFields = (visit) =>
  ['cName', 'cAddr', 'cManager', 'cTel', 'cEmail', 'cWeb', 'cType', 'cDesc', 'cOwner', 'cAgree'].concat(
    visit === '직접입력' ? 'cVisitSelf' : 'cVisit'
  );

const RemodelingJoin = ({ term, onFormSubmit }) => {
  const dispatch = useDispatch();
  const [submitAble, setSubmitAble] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      cName: '',
      cVisit: '',
      cAddr: '',
      cManager: '',
      cTel: '',
      cEmail: '',
      cWeb: '',
      cType: '신축건설',
      cDesc: '',
      cAgree: false,
      cOwner: '',
    },
  });

  const formWatching = watch();

  const handleMobileAlert = (errors) => {
    if (errors.length > 0) {
      dispatch(activateAlert({ title: '리모델링 협력사 신청', contents: errors[Object.keys(errors)[0]].message }));
    } else {
      dispatch(activateAlert({ title: '리모델링 협력사 신청', contents: '업체 정보 입력 시 매물 접수가 가능합니다.' }));
    }
  };

  useEffect(() => {
    const requireds = requiredFields(formWatching['cVisit']);
    const requiredLen = requireds.reduce((acc, required) => {
      let count = acc;
      if (formWatching.hasOwnProperty(required)) {
        if (typeof formWatching[required] === 'boolean' && formWatching[required] === true) count--;
        if (typeof formWatching[required] === 'string' && formWatching[required].length > 0) count--;
      }
      return count;
    }, requireds.length);
    setSubmitAble(requiredLen <= 0);
  }, [formWatching]);

  return (
    <>
      {isDesktop && (
        <module.scrollWrapper style={{ height: '500px' }}>
          <module.TableForm onSubmit={handleSubmit(onFormSubmit)}>
            <fieldset>
              <legend>업체 정보</legend>
              <table>
                <colgroup>
                  <col width="25%"></col>
                  <col width="25%"></col>
                  <col width="25%"></col>
                  <col width="25%"></col>
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="cName">업체명</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="cName"
                        id="cName"
                        {...register('cName', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="cVisit">방문 경로</label>
                    </th>
                    <td colSpan="1">
                      <select
                        name="cVisit"
                        id="cVisit"
                        {...register('cVisit', {
                          required: true,
                          message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.',
                        })}
                      >
                        {VISIT_ROUTES.map((route, idx) => (
                          <option key={idx} value={route.value}>
                            {route.label}
                          </option>
                        ))}
                      </select>
                      {getValues('cVisit') === '직접입력' && (
                        <input
                          className="selfType"
                          placeholder="방문경로를 입력해 주세요"
                          type="text"
                          name="cVisit"
                          {...register('cVisitSelf')}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="cAddr">주소</label>
                    </th>
                    <td colSpan="3">
                      <input
                        type="text"
                        name="cAddr"
                        id="cAddr"
                        {...register('cAddr', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="cManager">담당자</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="cManager"
                        id="cManager"
                        {...register('cManager', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="cTel">연락처</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="cTel"
                        id="cTel"
                        {...register('cTel', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                          pattern: { value: REGEXP.phone, message: '휴대폰 번호를 정확히 입력해 주세요.' },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="cEmail">이메일</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="cEmail"
                        id="cEmail"
                        {...register('cEmail', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                          pattern: { value: REGEXP.email, message: '이메일 주소를 정확히 입력해 주세요.' },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="cWeb">홈페이지</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="cWeb"
                        id="cWeb"
                        {...register('cWeb', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cType">업체 유형</label>
                    </th>
                    <td colSpan="1">
                      <select
                        name="cType"
                        id="cType"
                        {...register('cType', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 문의가 가능합니다.' },
                        })}
                      >
                        <option value="신축건설">신축건설</option>
                        <option value="인테리어">인테리어</option>
                      </select>
                    </td>
                    <th colSpan="1">
                      <label htmlFor="cOwner">보유 (종건 or)</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="cOwner"
                        id="cOwner"
                        {...register('cOwner', {
                          required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="cDesc">회사 소개</label>
                    </th>
                    <td colSpan="3">
                      <textarea
                        name="cDesc"
                        id="cDesc"
                        {...register('cDesc', {
                          required: { value: true, message: '(요양시설 리모델링 경험 유무 및 회사에 대한 소개 작성)' },
                        })}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <module.Agreement>
              <div className="inform">
                <strong>#보노랜드에서 알려드립니다.</strong>
                <p>
                  보노랜드는 리모델링 협력사를 신청하신 업체에 최대한 신속히 답변을 드리기 위한 기본 정보를 위와 같이
                  요청 드립니다. 가능한 많은 내용을 알려주시면 협력사 제휴에 큰 도움이 됩니다.
                </p>
              </div>
              <div className="agree">
                <input
                  type="checkbox"
                  id="cAgree"
                  {...register('cAgree', {
                    required: { value: true, message: '개인정보 수집 및 이용에 동의 시 매물 접수가 가능합니다.' },
                  })}
                />
                <label htmlFor="cAgree">개인정보수집에 대한 내용에 동의합니다</label>
              </div>
              <pre className="term">{term}</pre>
            </module.Agreement>
            {Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
            <button type="submit" className={submitAble ? '' : 'disabled'}>
              접수하기
            </button>
          </module.TableForm>
        </module.scrollWrapper>
      )}
      {!isDesktop && (
        <module.MobileForm onSubmit={handleSubmit(onFormSubmit)}>
          <fieldset>
            <legend>업체 정보</legend>
            <div className="wrap">
              <label htmlFor="cName">업체명</label>
              <input
                type="text"
                name="cName"
                id="cName"
                {...register('cName', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cVisit">방문 경로</label>
              <select
                name="cVisit"
                id="cVisit"
                {...register('cVisit', {
                  required: true,
                  message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.',
                })}
              >
                {VISIT_ROUTES.map((route, idx) => (
                  <option key={idx} value={route.value}>
                    {route.label}
                  </option>
                ))}
              </select>
              {getValues('cVisit') === '직접입력' && (
                <input
                  className="selfType"
                  placeholder="방문경로를 입력해 주세요"
                  type="text"
                  name="cVisit"
                  {...register('cVisitSelf')}
                />
              )}
            </div>
            <div className="wrap">
              <label htmlFor="cAddr">주소</label>
              <input
                type="text"
                name="cAddr"
                id="cAddr"
                {...register('cAddr', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cManager">담당자</label>
              <input
                type="text"
                name="cManager"
                id="cManager"
                {...register('cManager', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cTel">연락처</label>
              <input
                type="text"
                name="cTel"
                id="cTel"
                {...register('cTel', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                  pattern: { value: REGEXP.phone, message: '휴대폰 번호를 정확히 입력해 주세요.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cEmail">이메일</label>
              <input
                type="text"
                name="cEmail"
                id="cEmail"
                {...register('cEmail', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                  pattern: { value: REGEXP.email, message: '이메일 주소를 정확히 입력해 주세요.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cWeb">홈페이지</label>
              <input
                type="text"
                name="cWeb"
                id="cWeb"
                {...register('cWeb', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cType">업체 유형</label>
              <select
                name="cType"
                id="cType"
                {...register('cType', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 문의가 가능합니다.' },
                })}
              >
                <option value="신축건설">신축건설</option>
                <option value="인테리어">인테리어</option>
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cOwner">보유 (종건 or)</label>
              <input
                type="text"
                name="cOwner"
                id="cOwner"
                {...register('cOwner', {
                  required: { value: true, message: '업체 정보를 모두 입력 시 매물 접수가 가능합니다.' },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cDesc">회사 소개</label>
              <textarea
                name="cDesc"
                id="cDesc"
                {...register('cDesc', {
                  required: { value: true, message: '(요양시설 리모델링 경험 유무 및 회사에 대한 소개 작성)' },
                })}
              ></textarea>
            </div>
          </fieldset>
          <module.Agreement>
            <div className="inform">
              <strong>#보노랜드에서 알려드립니다.</strong>
              <p>
                보노랜드는 리모델링 협력사를 신청하신 업체에 최대한 신속히 답변을 드리기 위한 기본 정보를 위와 같이 요청
                드립니다. 가능한 많은 내용을 알려주시면 협력사 제휴에 큰 도움이 됩니다.
              </p>
            </div>
            <div className="agree">
              <input
                type="checkbox"
                id="cAgree"
                {...register('cAgree', {
                  required: { value: true, message: '개인정보 수집 및 이용에 동의 시 매물 접수가 가능합니다.' },
                })}
              />
              <label htmlFor="cAgree">개인정보수집에 대한 내용에 동의합니다</label>
            </div>
            <pre className="term">{term}</pre>
          </module.Agreement>
          {Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
          <button
            type="submit"
            onClick={() => {
              if (!submitAble) handleMobileAlert(errors);
            }}
            className={submitAble ? '' : 'disabled'}
          >
            접수하기
          </button>
        </module.MobileForm>
      )}
    </>
  );
};

export default RemodelingJoin;
