export const USER_AUTH = {
    USER_KEY:'bonoUser',
    store(user) {
        window.localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    },
    get() {
        const auth = JSON.parse(window.localStorage.getItem(this.USER_KEY)) || null;
        return auth;
    },
    remove() {
        window.localStorage.removeItem(this.USER_KEY);
    }
}

export const USER_ID = {
    ID_KEY: "bonouserId",
    store: value => {
        window.localStorage.setItem(USER_ID.ID_KEY, JSON.stringify(value));
    },
    get() {
        const value = window.localStorage.getItem(this.ID_KEY);
        if(value) return value.replaceAll('"', '');
        else return "";
    },
    remove() {
        window.localStorage.removeItem(this.ID_KEY);
    }
};

// 현재 내 위치 조회
export const GEOLOCATION = {
    get: () => {
        if("geolocation" in navigator) {
            return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject));
        } else {
            return false;
        }
    }
};

export const USER_GUIDE = {
    GUIDE_KEY: 'bonoGuideOffs',
    isOff(page) {
        return (this.isSessionOff(page) || this.isLocalOff(page));
    },
    isSessionOff(page) {
        const sessionValue = window.sessionStorage.getItem(this.GUIDE_KEY);
        if(!sessionValue) return false;
        else return JSON.parse(sessionValue).filter(item => item === page).length > 0;
    },
    isLocalOff(page) {
        const localValue = window.localStorage.getItem(this.GUIDE_KEY);
        if(!localValue) return false;
        else return JSON.parse(localValue).filter(item => item === page).length > 0;
    },
    setSessionOff(page) {
        const sessionValue = window.sessionStorage.getItem(this.GUIDE_KEY);
        if(!sessionValue) window.sessionStorage.setItem(this.GUIDE_KEY, JSON.stringify([page]));
        else window.sessionStorage.setItem(this.GUIDE_KEY, JSON.stringify([...JSON.parse(sessionValue), ...[page]]));
    },
    setLocalOff(page) {
        const localValue = window.localStorage.getItem(this.GUIDE_KEY);
        if(!localValue) window.localStorage.setItem(this.GUIDE_KEY, JSON.stringify([page]));
        else window.localStorage.setItem(this.GUIDE_KEY, JSON.stringify([...JSON.parse(localValue), ...[page]]));
    }
};

export const USER_MAEMUL_GUIDE = {
    MAEMUL_GUIDE_KEY: 'bonoMemulGuide',
    MAEMUL_GUIDE_VALUE: 'off',
    isOff(){
        const value = window.sessionStorage.getItem(this.MAEMUL_GUIDE_KEY);
        return (value && value === this.MAEMUL_GUIDE_VALUE);
    },
    setSessionOff() {
        window.sessionStorage.setItem(this.MAEMUL_GUIDE_KEY, this.MAEMUL_GUIDE_VALUE);
    }
};
