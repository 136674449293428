import React from "react";
import CommonBlock from "./CommonBlock";
import { WIDTH } from "../../containers/Landing/LandingContainer";
import lan_text from "../../assets/images/landing/lan_text.png";
import StyledText from "./StyledText";
import Ellipse from "./Ellipse";
import { isDesktop } from "react-device-detect";
import styled from "styled-components";

const BoboWellLifeText = styled.p(() => ({
    fontSize: "42px",
    fontWeight: 700,
    color: "#fff",
    letterSpacing: "2px",
}));

const BonoWellLife = ({ isInset }) => {
    const onClick = () => {
        window.open("https://forms.gle/6LFwhcFvQxqVXwwH9");
    }

  return isDesktop ? (
    <CommonBlock height={200} width={WIDTH} top={isInset ? 70 : 100} flexDirection="column" isPadding={false}>
      <div
        onClick={onClick}
        style={{
          display: "flex",
          justifyContent: "center",
          flex: 1,
          flexDirection: "column",
          cursor: "pointer",
          gap: "24px",
            background: "#2962FF",
            padding: "30px",
        }}>

        <BoboWellLifeText>
          BONO WELL LIFE
        </BoboWellLifeText>
        <StyledText fontSize={24} fontWeight={500} color="#fff">
          보노웰라이프 복지용구 도매가 구매하기
        </StyledText>

        <Ellipse
          width={200}
          height={46}
          borderColor="#fff"
          background="#2962FF"
          onClick={onClick}>
          <StyledText fontSize={18} fontWeight={500}>
            도매가 구매하기
          </StyledText>
        </Ellipse>
      </div>
    </CommonBlock>
  ) : (
    <CommonBlock height={176} display="flex" top={100} flexDirection="column" isPadding={false} boxShadowColor="#0057FF2E">
      <div
        onClick={onClick}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          flexDirection: "column",
          paddingInline: "3rem",
        }}>
        <img style={{ width: 300, height: 24 }} src={lan_text} alt="lan_text" />

        <StyledText fontSize={14} fontWeight={500} color="#2962FF" marginTop="1rem">
          보노웰라이프 복지용구 도매가 구매하기
        </StyledText>

        <Ellipse
          width={180}
          height={42}
          borderColor="#2962FF"
          background="#2962FF"
          marginTop="2rem"
          onClick={onClick}>
          <StyledText fontSize={15} fontWeight={500}>
            도매가 구매하기
          </StyledText>
        </Ellipse>
      </div>
    </CommonBlock>
  );
};

export default BonoWellLife;
