import React, { useEffect, useState, useRef } from 'react';
import { CardList, CardDivider, ListWrap } from './CenterListStyle';
import CenterCard from '../CenterCard/CenterCard';
import { Loading, NoData, Error, MoreLoading } from '../../ui/Inform/Inform';
import { isDesktop } from '../../../utils/detect';

const CenterList = ({ list = '', type = '', centers, msg = '', hasNext, isNextLoading, loadNext }) => {
  const [last, setLast] = useState(null);
  const listRef = useRef(null);

  const onObserve = (entry, observer) => {
    if (entry[0].isIntersecting && !isNextLoading) {
      loadNext();
      observer.unobserve(entry[0].target);
    }
  };

  useEffect(() => {
    let observer;
    if (last) {
      observer = new IntersectionObserver(onObserve, { threshold: 0.5 });
      observer.observe(last);
    }
    return () => observer && observer.disconnect();
  }, [last]);

  useEffect(() => {
    if (centers && centers.length < 21) listRef.current.scrollTop = 0;
  }, [centers]);

  return (
    <CardList type={type} ref={listRef} className="bn-guide" data-guide="2">
      {centers.length <= 0 && isNextLoading && Loading()}
      {msg.length > 0 && Error(msg)}
      <ListWrap
        style={{
          paddingBottom: !isDesktop && centers && centers.length > 0 ? '40px' : '0',
        }}
      >
        {centers &&
          centers.length > 0 &&
          centers.map((center, idx) => (
            <div key={idx}>
              <CenterCard list={list} type={type} center={center} />
              <CardDivider />
              {idx >= centers.length - 1 && hasNext && <div ref={setLast}>{MoreLoading()}</div>}
            </div>
          ))}
      </ListWrap>
    </CardList>
  );
};

export default CenterList;
