import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateLogin } from '../../store/actions/service';
import { isDesktop } from "../../utils/detect";
import Modal from "../../components/Modal/Modal";
import Login from '../../components/Account/Login/Login';
import { USER_ID } from '../../utils/user';
import Section from "../../components/ui/Section/Section";
import { login } from '../../store/actions/user';
import NewPassword from "../../components/Account/NewPassword/NewPassword";
import {updateUserPassword} from "../../api/user";
import {activateAlert} from "../../store/actions/alert";

const LoginContainer = () => {

    const dispatch = useDispatch();

    const USER_LOGGEDIN = useSelector(state => state.User.loggedIn);

    const ID = USER_ID.get();
    const STORE_ID = ID.length > 0;
    const [ failMsg, setFailMsg ] = useState('');
    const [ updatePwd, setUpdatePwd ] = useState(false);

    const handleStoredId = (checked = false, userId = "") => {
        if(checked) USER_ID.store(userId);
        else USER_ID.remove();
    };

    const onFormSubmit = async data => {
        handleStoredId(data.userStoreId, data.userId);
        setFailMsg("");

        const RESULT = await dispatch(login({
            id: data.userId,
            password: data.userPwd
        }));

        if (RESULT.message === "비밀번호 초기화") {
            return setUpdatePwd(data);
        }

        if(!RESULT.success) setFailMsg(RESULT.message.toString());
    };

    const setNewPwd = async (data) => {

        const password = data.newPwd01;

        if (password === updatePwd.userPwd) {
            return dispatch(
              activateAlert({
                  title: "비밀번호 재설정",
                  contents: "동일한 비밀번호로 변경할 수 없습니다.",
              })
            );
        }

        const RESPONSE = await updateUserPassword({
            userEmail: updatePwd.userId,
            userPwd: password
        })

        if (RESPONSE.status !== 200) {
            return dispatch(activateAlert({
                title: "비밀번호 재설정 실패",
                contents: "비밀번호 재설정 실패하였습니다.",
            }))
        }

        dispatch(activateAlert({
            title: "비밀번호 재설정 성공",
            contents: `재설정한 비밀번호로 다시 로그인해주세요.`,
        }))
        setUpdatePwd(false);
    };

    const modalProps = {
        open: true,
        close: true,
        width: "320",
        center: true,
        onCloseClick: () => {dispatch(deactivateLogin())},
        title: !updatePwd ? "로그인" : "비밀번호 재설정"
    };

    useEffect(() => {
        if(USER_LOGGEDIN) dispatch(deactivateLogin());
    }, [USER_LOGGEDIN])

    return (
        <>
        {
            isDesktop &&
                <Modal {...modalProps} >
                    {
                        !updatePwd ?(
                            <Login
                                id={ ID }
                                storeId={ STORE_ID }
                                onStoreIdChange={ handleStoredId }
                                onFormSubmit={ onFormSubmit }
                                message = { failMsg }
                            />
                          ) : (
                            <NewPassword onFormSubmit={setNewPwd}/>
                          )
                    }
                </Modal>
        }
        {
            !isDesktop &&
            <Section
                title={!updatePwd ? "로그인" : "비밀번호 재설정"}
                themeColor="primary"
                close={ false }
                back={ true }
                action={ false }
                zIndex={99}
                onBackClick={ () => {dispatch(deactivateLogin())} }
            >
                {
                    !updatePwd ?(
                        <Login
                            id={ ID }
                            storeId={ STORE_ID }
                            onStoreIdChange={ handleStoredId }
                            onFormSubmit={ onFormSubmit }
                            message = { failMsg }
                        />
                      ) : (
                        <NewPassword onFormSubmit={setNewPwd}/>
                      )
                }
            </Section>
        }
        </>
    )
};

export default LoginContainer;
