import React from "react";
import { Section as SectionBlock, Title, Body, Close, Back, Option, Action } from "./SectionStyle";
import { useNavigate } from "react-router";
import {isDesktop} from "../../../utils/detect";

const Section = ({
  zIndex,
  title,
  themeColor,
  close,
  onCloseClick,
  back,
  onBackClick,
  option,
  optionText,
  onOptionClick,
  action = null,
  actionText,
  onActionClick,
  children,
}) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const sectionStyle = {};
  if (zIndex) sectionStyle.zIndex = zIndex;

  return (
    <SectionBlock style={sectionStyle} isDesktop={isDesktop}>
      <Title themeColor={themeColor}>{title}</Title>
      {back && (
        <Back
          className={`ico-${themeColor}`}
          onClick={() => {
            onBackClick ? onBackClick() : goBack();
          }}
        />
      )}

      {close && (
        <Close
          themeColor={themeColor}
          onClick={() => {
            onCloseClick ? onCloseClick() : goBack();
          }}
        />
      )}

      {option && <Option onClick={() => onOptionClick()}>{optionText}</Option>}
      <Body className={action !== null ? "" : "full"}>{children}</Body>

      {action && (
        <Action onClick={() => onActionClick()} themeColor={themeColor}>
          {actionText}
        </Action>
      )}
    </SectionBlock>
  );
};

export default Section;
