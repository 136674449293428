import { FILTER } from '../actions/filter';
import { isDesktop } from '../../utils/detect';

const initialState = {
  reset: false,
  filteredByList: false,
  data: {
    address: { area1: '', area2: '', area3: '' },
    region: '',
    categories: null,
    category2: null,
    latlng: isDesktop ? [36.480556, 127.288889] : [36.676111, 127.044167],
    sisulCustomNo: null,
    sort: 'sisul',
  },
  map: {
    latlng: isDesktop ? [36.480556, 127.288889] : [36.676111, 127.044167],
    zoom: 8,
    address: { area1: '', area2: '', area3: '' },
  },
};

const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER.RESET:
      return { ...initialState, reset: true };
    case FILTER.CLEAR_TEMP_FILTER:
      return { ...state, data: { ...state.data, sisulCustomNo: null } };
    case FILTER.UPDATE_FOR_DATA:
      let dataFilter = action.payload;

      if (dataFilter.hasOwnProperty('region')) {
        const filter = dataFilter;
        if (state.reset) {
          return {
            ...state,
            data: { ...state.data, ...filter },
            filteredByList: false,
            reset: false,
          };
        } else {
          if (state.data['categories'] === null && state.data['category2'] === null) {
            filter['categories'] = [];
          }
          if (state.data['category2']) {
            filter.sisulCustomNo =
              !action.payload.address.area1 && !action.payload.sisulCustomNo
                ? null
                : state.data.sisulCustomNo;
            filter.sort = 'maemul';
          }
          return { ...state, data: { ...state.data, ...filter }, filteredByList: false };
        }
      }

      if (dataFilter.hasOwnProperty('category2')) {
        dataFilter['categories'] = [];
        dataFilter.sisulCustomNo = null;
        return { ...state, data: { ...state.data, ...dataFilter }, filteredByList: true };
      }

      if (dataFilter.hasOwnProperty('categories')) {
        dataFilter['category2'] = '';
        return { ...state, data: { ...state.data, ...dataFilter }, filteredByList: true };
      }
    case FILTER.UPDATE_FOR_MAP:
      if (state.filteredByList === true) {
        return {
          ...state,
          filteredByList: false,
          map: { ...state.map, ...action.payload },
          data: {
            ...state.data,
            sisulCustomNo: action.payload.sisulCustomNo || state.data.sisulCustomNo,
            sort: action.payload.sort || state.data.sort,
          },
        };
      } else {
        const mapFilter = action.payload;
        if (mapFilter.address) {
          if (JSON.stringify(mapFilter.address) === JSON.stringify(state.data.address)) {
            if (state.data.sisulCustomNo !== mapFilter.sisulCustomNo) {
              return {
                ...state,
                map: mapFilter,
                data: { ...state.data, sisulCustomNo: action.payload.sisulCustomNo },
              };
            } else {
              return { ...state, map: mapFilter };
            }
          } else {
            const dataFilter = Object.assign({}, mapFilter);
            if (state.data.categories === null && state.data.category2 === null)
              dataFilter.categories = [];
            return { ...state, data: { ...state.data, ...dataFilter }, map: mapFilter };
          }
        } else {
          return { ...state, map: mapFilter };
        }
      }
    default:
      return state;
  }
};

export default FilterReducer;
