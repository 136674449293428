import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  activateBonoNews,
  activateQna,
  activateRegister,
  activateResume,
  deactivateBonoNews,
  activateLoginRequired
} from "../../store/actions/service";
import { deactivateLanding } from "../../store/actions/landing";
import Card from "../../components/Landing/Card";
import RegisterBox from "../../components/Landing/RegisterBox";
import CommonBlock from "../../components/Landing/CommonBlock";
import RecentPost from "../../components/Landing/RecentPost";
import BonoWellLife from "../../components/Landing/BonoWellLife";
import Lecture from "../../components/Landing/Lecture";
import QuickSale from "../../components/Landing/QuickSale";
import Remodeling from "../../components/Landing/Remodeling";
import map1 from "../../assets/images/landing/map1.png";
import Menu from "../../components/Landing/Menu";
import Review from "../../components/Landing/Review";
import star21 from "../../assets/images/landing/star21.png";
import { getRecently, getRecommend, getReviews } from "../../api/landing";
import { activateNews } from "../../store/actions/page";
import { useNavigate } from "react-router";

export const WIDTH = 600;
const INSET = 80;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: ${INSET + 46}px;
  justify-content: center;
  padding-bottom: 12rem;
  gap: 30px;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 6rem;
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LandingContainer = () => {

  const isLogin = useSelector(state => state.User.loggedIn);

  const userNo = useSelector(state => state.User.userInfo.no);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [recently, setRecently] = useState();
  const [reviews, setReviews] = useState();
  const [recommend, setRecommend] = useState();

  useEffect(() => {
    (async () => {
      const { data: recentlyData } = await getRecently(userNo);
      const { data: reviewsData } = await getReviews();
      const { data: recommendData } = await getRecommend();

      setRecently(recentlyData);
      setReviews(reviewsData);
      setRecommend(recommendData);
    })();
  }, []);

  const onRegister = () => dispatch(activateRegister());

  const onStartMapView = () => {
    dispatch(deactivateLanding());
    navigate("/");
  };

  const onStartMamul = () => {
    dispatch(deactivateLanding());
    navigate("/bono/nursing");
  };

  const onClickMenu = key => () => {
    if (key === 1) {
      window.open("https://www.youtube.com/@Bonoland01");
    }
    if (key === 2) {
      dispatch(activateResume());
    }
    if (key === 3) {
      dispatch(activateNews());
      dispatch(activateBonoNews());
    }
    if (key === 4) {
      dispatch(activateNews());
      dispatch(deactivateBonoNews());
    }
    if (key === 5) {
      dispatch(activateQna());
    }
  };

  const onClickQuickSale = pk => () => {
    if (!isLogin) {
      return dispatch(activateLoginRequired());
    }
    navigate(`/center/${pk}?filter=all`);
    dispatch(deactivateLanding());
  };

  const isInset = recommend?.result === null;

  return (
    <>
      <TopContainer>
        <Column>
          <RegisterBox onClick={onRegister} />
          <QuickSale recommend={recommend} onClick={onClickQuickSale} />
          <Lecture isInset={isInset} />
          <BonoWellLife isInset={isInset} />
        </Column>

        <Column>
          <CommonBlock width={WIDTH} isPadding={false} isShadow={false} isRadius={false}>
            <Card
              background="linear-gradient(#FFA18B, #E87256)"
              onStartMapView={onStartMapView}
              title="지도에서 쉽게보는"
              sub="전국 시설리스트"
              img={map1}
              btnText="보러가기"
            />

            <Card
              background="linear-gradient(#FFEBB8, #F6DC9A)"
              onStartMapView={onStartMamul}
              title="보노랜드"
              sub="매물보기"
              img={star21}
              btnText="보러가기"
              titleTextCol="#BC936A"
              subTextCol="#212121"
              borderThemeCol="#000"
              marginLeft={20}
            />
          </CommonBlock>

          <RecentPost
            data={recently?.recentlyList}
            top={40}
            title="최근 등록 매물"
            titleCol="#2962FF"
            onClick={data => {
              dispatch(deactivateLanding());
              navigate(`/center/${data?.sisulCustomNo}?filter=all`);
            }}
          />

          <RecentPost
            data={recently?.recentlySales}
            top={70}
            title="최근 본 매물"
            titleCol="#212121"
            onClick={data => {
              if (!isLogin) {
                return dispatch(activateLoginRequired());
              }
              dispatch(deactivateLanding());
              navigate(`/center/${data?.sisulCustomNo}?filter=all`);
            }}
            loginRequired={!isLogin}
          />

          <Remodeling />
        </Column>
      </TopContainer>

      <BottomContainer>
        <Menu onClick={onClickMenu} />
        {reviews && reviews?.length > 0 && <Review data={reviews} />}
      </BottomContainer>
    </>
  );
};

export default LandingContainer;
