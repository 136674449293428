import { createStore, applyMiddleware } from 'redux';
import combinedReducer from './reducers';
import reduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

export const store = createStore(
    combinedReducer, 
    {},
    applyMiddleware(reduxThunk)
);

export const persistor = persistStore(store); // persist store 내보내기



