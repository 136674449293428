import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../components/ui/Section/Section";
import CenterList from "../../components/Center/CenterList/CenterList";
import { isDesktop } from "../../utils/detect";
import { useGet } from "../../hooks";
import { getUserLocalAlarm } from "../../api/user";
import { deactivateMyAlarm, activateMyAlarmForm } from "../../store/actions/page";

const UserAlarmContainer = () => {
  const dispatch = useDispatch();

  const USER_NO = useSelector(state => state.User.userInfo.no);
  const USER_ALARMS = useSelector(state => state.User.userInfo.alarms);

  const [nextIndex, setNextIndex] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);

  const [centers, setCenters] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [total, setTotal] = useState(0);

  const loadNext = async () => {
    setIsNextLoading(true);
    const RESPONSE = await getUserLocalAlarm({ userNo: USER_NO, page: nextIndex });
    if (RESPONSE && RESPONSE.data.code === 1) {
      setCenters([...centers, ...RESPONSE.data.arrayResult]);
      setHasNext(RESPONSE.data.pageCode == 1);
      setTotal(RESPONSE.data.totalCount);
      setErrMsg("");
    } else {
      setErrMsg("알림 지역 매물이 없습니다.");
    }
    setIsNextLoading(false);
    setNextIndex(nextIndex => nextIndex + 1);
  };

  useEffect(() => {
    if (USER_ALARMS.length < 1) {
      dispatch(deactivateMyAlarm());
      dispatch(activateMyAlarmForm());
    } else {
      setCenters([]);
      setNextIndex(1);
      loadNext();
      setTotal(0);
    }
  }, [USER_ALARMS]);

  const onCloseClick = () => {
    dispatch(deactivateMyAlarm());
  };

  return (
    <Section
      title={`알림 매물(${total})`}
      themeColor={isDesktop ? "primary" : "secondary"}
      close={isDesktop && true}
      back={!isDesktop && true}
      onCloseClick={onCloseClick}
      onBackClick={onCloseClick}
      option={true}
      optionText={"지역설정"}
      zIndex={99}
      onOptionClick={() => {
        dispatch(activateMyAlarmForm());
      }}>
      <CenterList
        type={"sub"}
        centers={centers}
        hasNext={hasNext}
        isNextLoading={isNextLoading}
        loadNext={loadNext}
        msg={errMsg}
      />
    </Section>
  );
};

export default UserAlarmContainer;
