import styled from 'styled-components';
import closeIcon from '../../assets/images/icon/ico-x.svg';
import { isDesktop } from '../../utils/detect';

export const ModalWrap = styled.div`
  position: relative;
  width: 100%;
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  background: url(${closeIcon}) center no-repeat;
`;

export const Title = styled.h2`
  font-size: ${isDesktop ? '28px' : '24px'};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  &.a-c {
    text-align: center;
  }
`;

export const Description = styled.p`
  margin: ${isDesktop ? '16px 0 40px' : '16px 0 24px'};
  line-height: 1.4;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.gray500};
  white-space: pre-line;
`;

export const Hr = styled.hr`
  display: block;
  width: 100%;
  height: 2px;
  margin: 24px 0;
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Contents = styled.div`
  width: 100%;
  margin-top: 16px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.gray500};
  line-height: 1.4;
  white-space: pre-line;
`;

export const CloseAction = styled.button`
  width: 100%;
  height: 44px;
  margin-top: 32px;
  border-radius: 2px;
  line-height: 44px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;
