import React, { useEffect, useState } from 'react';
import { MAEMULALERT } from './MaemulAlertStyled';

const MaemulAlert = () => {
  const [displayOff, setDisplayOff] = useState(false);
  const [displayTimeout, setDisplayTimeout] = useState(null);

  useEffect(() => {
    setDisplayTimeout(
      setTimeout(() => {
        setDisplayOff(true);
      }, 7500)
    );

    return () => {
      clearTimeout(displayTimeout);
    };
  }, []);

  return (
    <MAEMULALERT className={displayOff ? 'off' : ''}>
      매물 정보 보호를 위해
      <br />
      해당 시설이 위치한 구까지만 표시됩니다.
      <br />
      <br />
      해당 시설의 자세한 정보는
      <br />
      왼쪽의 상세정보를 확인하시기 바랍니다.
    </MAEMULALERT>
  );
};

export default MaemulAlert;
