import styled from 'styled-components';
import { module } from '../../../themes/module';

export const Form = styled(module.AccountForm)`
  height: 250px;

  .desc.warn {
    color: ${({ theme }) => theme.colors.red};
  }

  .mobile & {
    height: auto;
    text-align: center;
  }
`;

export const TypeBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  > div {
    position: relative;
    flex: 1;
    height: 94px;
    border-radius: 2px;

    .mobile & {
      height: 127px;
      border-radius: 10px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: -4px;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      border: 1px solid ${({ theme }) => theme.colors.gray400};
      font-size: ${({ theme }) => theme.fontSizes.xl};
      color: ${({ theme }) => theme.colors.gray600};
      word-break: keep-all;
      text-align: center;
      cursor: pointer;

      .mobile & {
        border-radius: 10px;
      }
    }

    input:checked + label {
      border-color: ${({ theme }) => theme.colors.primary};

      .mobile & {
        color: ${({ theme }) => theme.colors.primary};
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
      }
    }
  }

  .mobile & {
    flex-direction: column;
    gap: 20px;

    > div {
      width: 100%;
      height: 126px;
      flex: auto !important;

      label {
        font-size: 24px;
        flex-direction: row;
        gap: 5px;
      }
    }
  }
`;
