import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isDesktop } from '../../utils/detect';
import Modal from '../../components/Modal/Modal';
import { deactivateRemodelingContact } from '../../store/actions/service';
import RemodelingContact from '../../components/Remodeling/RemodelingContact';
import { USER_AUTH } from '../../utils/user';
import { useGet } from '../../hooks/index';
import { getAllTerms } from '../../api/terms';
import MobileSection from '../../components/global/MobileSection/MobileSection';
import { activateAlert } from '../../store/actions/alert';
import { requestRemodelingContact } from '../../api/service';

const RemodelingContactContainer = () => {
	const dispatch = useDispatch();
	const IS_LOGGEDIN = useSelector((state) => state.User.loggedIn);

	const [term, setTerm] = useState('');
	const [loading, error, data, setGet] = useGet('');
	const [user, setUser] = useState({ uName: '', uEmail: '', uTel: '' });

	const onFormSubmit = async (data) => {
		const options = {
			name: data.uName,
			visitPath: data.uVisit === '직접입력' ? data.uVisitSelf : data.uVisit,
			phone: data.uTel,
			email: data.uEmail,
			siDo: data.rSido,
			guGun: data.rGugun,
			remodeling: data.rBdType === '직접입력' ? data.rBdTypeSelf : data.rBdType,
			inquiry: data.rRqType === '직접입력' ? data.rRqTypeSelf : data.rRqType,
			price: data.rAsset,
			contents: data.rDesc,
		};
		const RESPONSE = await requestRemodelingContact(options);

		if (RESPONSE) {
			dispatch(deactivateRemodelingContact());
			dispatch(
				activateAlert({
					title: '리모델링 문의',
					contents: RESPONSE.data.message || '리모델링 문의가 완료되었습니다.',
				})
			);
		} else {
			dispatch(
				activateAlert({
					title: '리모델링 문의',
					contents: '리모델링 문의 중 오류가 발생했습니다.',
				})
			);
		}
	};

	const RENDER_RMD_CONTACT = () => <RemodelingContact user={user} term={term} onFormSubmit={onFormSubmit} />;

	useEffect(() => {
		if (IS_LOGGEDIN) {
			const USER = USER_AUTH.get();
			setUser({
				uEmail: USER.id,
				uName: USER.name,
				uTel: USER.tel,
			});
		}
		setGet(getAllTerms());
	}, [IS_LOGGEDIN]);

	useEffect(() => {
		if (data && data.code === 0) setTerm(data.arrayResult[1].siteContents);
		else setTerm('이용약관을 불러올 수 없습니다. 새로고침 해주세요.');
	}, [data]);

	return (
		<>
			{isDesktop && (
				<Modal
					open={true}
					close={true}
					title="리모델링 문의"
					width="890"
					height="580"
					onCloseClick={() => dispatch(deactivateRemodelingContact())}
				>
					{RENDER_RMD_CONTACT()}
				</Modal>
			)}
			{!isDesktop && (
				<MobileSection
					title="리모델링 문의"
					themeColor="primary"
					onBackClick={() => dispatch(deactivateRemodelingContact())}
				>
					{RENDER_RMD_CONTACT()}
				</MobileSection>
			)}
		</>
	);
};

export default RemodelingContactContainer;
