import React, { useState, useEffect } from 'react';
import { module } from '../../themes/module';
import { useForm } from 'react-hook-form';
import { REGEXP } from '../../scheme/form';
import { LOCATION } from '../../scheme/location';
import { convertToNum } from '../../utils/number';
import { VISIT_ROUTES } from '../../scheme/contact';
import { isDesktop } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { activateAlert } from '../../store/actions/alert';

const RemodelingContact = ({ user, term, onFormSubmit }) => {
	const dispatch = useDispatch();
	const [submitAble, setSubmitAble] = useState(false);
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {
			uName: user ? user.uName : '',
			uEmail: user ? user.uEmail : '',
			uTel: user ? user.uTel : '',
			uVisit: '',
			rSido: Object.keys(LOCATION)[0],
			rGugun: LOCATION[Object.keys(LOCATION)[0]][0],
			rBdType: '어린이집',
			rRqType: '리모델링 문의',
			rAsset: '',
			rDesc: '',
			rAgree: false,
		},
	});

	const formWatching = watch();

	const requiredFields = () => {
		const fields = [
			'uName',
			'uEmail',
			'uTel',
			'uVisit',
			'rSido',
			'rGugun',
			'rBdType',
			'rRqType',
			'rAsset',
			'rDesc',
			'rAgree',
		];
		if (getValues('uVisit') === '직접입력') fields.push('uVisitSelf');
		if (getValues('rBdType') === '직접입력') fields.push('rBdTypeSelf');
		if (getValues('rRqType') === '직접입력') fields.push('rRqTypeSelf');

		return fields;
	};

	useEffect(() => {
		if (user) {
			setValue('uName', user.uName);
			setValue('uEmail', user.uEmail);
			setValue('uTel', user.uTel);
		}
	}, [user]);

	const handleMobileAlert = (errors) => {
		if (errors.length > 0) {
			dispatch(activateAlert({ title: '리모델링 문의', contents: errors[Object.keys(errors)[0]].message }));
		} else {
			dispatch(
				activateAlert({ title: '리모델링 문의', contents: '의뢰인, 시설 정보 입력 시 매물 접수가 가능합니다.' })
			);
		}
	};

	useEffect(() => {
		const requireds = requiredFields();
		const requiredLen = requireds.reduce((acc, required) => {
			let count = acc;
			if (formWatching.hasOwnProperty(required)) {
				if (typeof formWatching[required] === 'boolean' && formWatching[required] === true) count--;
				if (typeof formWatching[required] === 'string' && formWatching[required].length > 0) count--;
			}
			return count;
		}, requireds.length);
		setSubmitAble(requiredLen <= 0);
	}, [formWatching]);

	return (
		<>
			{isDesktop && (
				<module.scrollWrapper style={{ height: '500px' }}>
					<module.TableForm onSubmit={handleSubmit(onFormSubmit)}>
						<fieldset>
							<legend>의뢰인 정보</legend>
							<table>
								<colgroup>
									<col width="25%"></col>
									<col width="25%"></col>
									<col width="25%"></col>
									<col width="25%"></col>
								</colgroup>
								<tbody>
									<tr>
										<th colSpan="1">
											<label htmlFor="uName">이름</label>
										</th>
										<td colSpan="1">
											<input
												type="text"
												name="uName"
												id="uName"
												{...register('uName', {
													required: { value: true, message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.' },
												})}
											/>
										</td>
										<th colSpan="1">
											<label htmlFor="uVisit">방문 경로</label>
										</th>
										<td colSpan="1">
											<select
												name="uVisit"
												id="uVisit"
												{...register('uVisit', {
													required: true,
													message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.',
												})}
											>
												{VISIT_ROUTES.map((route, idx) => (
													<option key={idx} value={route.value}>
														{route.label}
													</option>
												))}
											</select>
											{getValues('uVisit') === '직접입력' && (
												<input
													className="selfType"
													placeholder="방문경로를 입력해 주세요"
													type="text"
													name="uVisit"
													{...register('uVisitSelf')}
												/>
											)}
										</td>
									</tr>
									<tr>
										<th colSpan="1">
											<label htmlFor="uTel">연락처</label>
										</th>
										<td colSpan="1">
											<input
												type="text"
												name="uTel"
												id="uTel"
												{...register('uTel', {
													required: { value: true, message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.' },
													pattern: { value: REGEXP.phone, message: '휴대폰 번호를 정확히 입력해 주세요.' },
												})}
											/>
										</td>
										<th colSpan="1">
											<label htmlFor="uEmail">이메일</label>
										</th>
										<td colSpan="1">
											<input
												type="text"
												name="uEmail"
												id="uEmail"
												{...register('uEmail', {
													required: { value: true, message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.' },
													pattern: { value: REGEXP.email, message: '이메일 주소를 정확히 입력해 주세요.' },
												})}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</fieldset>
						<fieldset>
							<legend>사업지 정보</legend>
							<table>
								<colgroup>
									<col width="25%" />
									<col width="25%" />
									<col width="25%" />
									<col width="25%" />
								</colgroup>
								<tbody>
									<tr>
										<th>
											<label htmlFor="rSido">시/도</label>
										</th>
										<td>
											<select
												name="rSido"
												id="rSido"
												{...register('rSido', {
													required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
												})}
											>
												{Object.keys(LOCATION).map((sido) => (
													<option key={sido} value={sido}>
														{sido}
													</option>
												))}
											</select>
										</td>
										<th>
											<label htmlFor="rGugun">구/군</label>
										</th>
										<td>
											<select
												name="rGugun"
												id="rGugun"
												{...register('rGugun', {
													required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
												})}
											>
												{LOCATION[formWatching['rSido']] &&
													LOCATION[formWatching['rSido']].map((gugun) => (
														<option key={gugun} value={gugun}>
															{gugun}
														</option>
													))}
											</select>
										</td>
									</tr>
									<tr>
										<th>
											<label htmlFor="rBdType">업체 유형</label>
										</th>
										<td colSpan="1">
											<select
												name="rBdType"
												id="rBdType"
												{...register('rBdType', {
													required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
												})}
											>
												<option value="어린이집">단독요양원</option>
												<option value="상가">상가</option>
												<option value="숙박시설">숙박시설</option>
												<option value="산후조리원">산후조리원</option>
												<option value="직접입력">기타(직접입력)</option>
											</select>
											{getValues('rBdType') === '직접입력' && (
												<input
													className="selfType"
													placeholder="업체 유형을 입력해 주세요"
													type="text"
													name="rBdType"
													{...register('rBdTypeSelf')}
												/>
											)}
										</td>
										<th>
											<label htmlFor="rRqType">문의 구분</label>
										</th>
										<td colSpan="1">
											<select
												name="rRqType"
												id="rRqType"
												{...register('rRqType', {
													required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
												})}
											>
												<option value="리모델링 문의">리모델링 문의</option>
												<option value="사업지 문의">사업지 문의</option>
												<option value="직접입력">기타(직접입력)</option>
											</select>
											{getValues('rRqType') === '직접입력' && (
												<input
													className="selfType"
													placeholder="문의 유형을 입력해 주세요"
													type="text"
													name="rRqType"
													{...register('rRqTypeSelf')}
												/>
											)}
										</td>
									</tr>
									<tr>
										<th colSpan={1}>
											<label htmlFor="rAsset">예상 자본금</label>
										</th>
										<td colSpan={3}>
											<input
												type="text"
												name="rAsset"
												id="rAsset"
												{...register('rAsset', {
													onChange: (e) => {
														setValue('rAsset', convertToNum(e.currentTarget.value));
													},
													required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
												})}
											/>
										</td>
									</tr>
									<tr>
										<th colSpan={1}>
											<label htmlFor="rDesc">소개내용</label>
										</th>
										<td colSpan={3}>
											<textarea
												name="rDesc"
												id="rDesc"
												{...register('rDesc', {
													required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
												})}
											></textarea>
										</td>
									</tr>
								</tbody>
							</table>
						</fieldset>
						<module.Agreement>
							<div className="inform">
								<strong>#보노랜드에서 알려드립니다.</strong>
								<p>
									보노랜드는 문의주신 고객님의 조건에 맞는 리모델링 견적 및 업체 선정을 담당자가 신속하고 정확히
									도와드립니다.
									<br />
									편하신 연락 시간을 적어 주시면 연락 드리도록 하겠습니다.
								</p>
							</div>
							<div className="agree">
								<input
									type="checkbox"
									id="rAgree"
									{...register('rAgree', {
										required: { value: true, message: '개인정보 수집 및 이용에 동의 시 매물 접수가 가능합니다.' },
									})}
								/>
								<label htmlFor="rAgree">개인정보수집에 대한 내용에 동의합니다</label>
							</div>
							<pre className="term">{term}</pre>
						</module.Agreement>
						{Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
						<button type="submit" className={submitAble ? '' : 'disabled'}>
							접수하기
						</button>
					</module.TableForm>
				</module.scrollWrapper>
			)}
			{!isDesktop && (
				<module.MobileForm onSubmit={handleSubmit(onFormSubmit)}>
					<fieldset>
						<legend>의뢰인 정보</legend>
						<div className="wrap">
							<label htmlFor="uName">이름</label>
							<input
								type="text"
								name="uName"
								id="uName"
								{...register('uName', {
									required: { value: true, message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.' },
								})}
							/>
						</div>
						<div className="wrap">
							<label htmlFor="uTel">연락처</label>
							<input
								type="text"
								name="uTel"
								id="uTel"
								{...register('uTel', {
									required: { value: true, message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.' },
									pattern: { value: REGEXP.phone, message: '휴대폰 번호를 정확히 입력해 주세요.' },
								})}
							/>
						</div>
						<div className="wrap">
							<label htmlFor="uEmail">이메일</label>
							<input
								type="text"
								name="uEmail"
								id="uEmail"
								{...register('uEmail', {
									required: { value: true, message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.' },
									pattern: { value: REGEXP.email, message: '이메일 주소를 정확히 입력해 주세요.' },
								})}
							/>
						</div>
						<div className="wrap">
							<label htmlFor="uVisit">방문 경로</label>
							<select
								name="uVisit"
								id="uVisit"
								{...register('uVisit', {
									required: true,
									message: '의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다.',
								})}
							>
								{VISIT_ROUTES.map((route, idx) => (
									<option key={idx} value={route.value}>
										{route.label}
									</option>
								))}
							</select>
							{getValues('uVisit') === '직접입력' && (
								<input
									className="selfType"
									placeholder="방문경로를 입력해 주세요"
									type="text"
									name="uVisit"
									{...register('uVisitSelf')}
								/>
							)}
						</div>
					</fieldset>
					<fieldset>
						<legend>사업지 정보</legend>
						<div className="wrap">
							<label htmlFor="rSido">시/도</label>
							<select
								name="rSido"
								id="rSido"
								{...register('rSido', {
									required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
								})}
							>
								{Object.keys(LOCATION).map((sido) => (
									<option key={sido} value={sido}>
										{sido}
									</option>
								))}
							</select>
						</div>
						<div className="wrap">
							<label htmlFor="rGugun">구/군</label>
							<select
								name="rGugun"
								id="rGugun"
								{...register('rGugun', {
									required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
								})}
							>
								{LOCATION[formWatching['rSido']] &&
									LOCATION[formWatching['rSido']].map((gugun) => (
										<option key={gugun} value={gugun}>
											{gugun}
										</option>
									))}
							</select>
						</div>
						<div className="wrap">
							<label htmlFor="rBdType">업체 유형</label>
							<select
								name="rBdType"
								id="rBdType"
								{...register('rBdType', {
									required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
								})}
							>
								<option value="어린이집">단독요양원</option>
								<option value="상가">상가</option>
								<option value="숙박시설">숙박시설</option>
								<option value="산후조리원">산후조리원</option>
								<option value="직접입력">기타(직접입력)</option>
							</select>
							{getValues('rBdType') === '직접입력' && (
								<input
									className="selfType"
									placeholder="업체 유형을 입력해 주세요"
									type="text"
									name="rBdType"
									{...register('rBdTypeSelf')}
								/>
							)}
						</div>
						<div className="wrap">
							<label htmlFor="rRqType">문의 구분</label>
							<select
								name="rRqType"
								id="rRqType"
								{...register('rRqType', {
									required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
								})}
							>
								<option value="리모델링 문의">리모델링 문의</option>
								<option value="사업지 문의">사업지 문의</option>
								<option value="직접입력">기타(직접입력)</option>
							</select>
							{getValues('rRqType') === '직접입력' && (
								<input
									className="selfType"
									placeholder="문의 유형을 입력해 주세요"
									type="text"
									name="rRqType"
									{...register('rRqTypeSelf')}
								/>
							)}
						</div>
						<div className="wrap">
							<label htmlFor="rAsset">예상 자본금</label>
							<input
								type="text"
								name="rAsset"
								id="rAsset"
								{...register('rAsset', {
									onChange: (e) => {
										setValue('rAsset', convertToNum(e.currentTarget.value));
									},
									required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
								})}
							/>
						</div>
						<div className="wrap">
							<label htmlFor="rDesc">소개내용</label>
							<textarea
								name="rDesc"
								id="rDesc"
								{...register('rDesc', {
									required: { value: true, message: '사업지 정보를 모두 입력 시 문의가 가능합니다.' },
								})}
							></textarea>
						</div>
					</fieldset>
					<module.Agreement>
						<div className="inform">
							<strong>#보노랜드에서 알려드립니다.</strong>
							<p>
								보노랜드는 문의주신 고객님의 조건에 맞는 리모델링 견적 및 업체 선정을 담당자가 신속하고 정확히
								도와드립니다.
								<br />
								편하신 연락 시간을 적어 주시면 연락 드리도록 하겠습니다.
							</p>
						</div>
						<div className="agree">
							<input
								type="checkbox"
								id="rAgree"
								{...register('rAgree', {
									required: { value: true, message: '개인정보 수집 및 이용에 동의 시 매물 접수가 가능합니다.' },
								})}
							/>
							<label htmlFor="rAgree">개인정보수집에 대한 내용에 동의합니다</label>
						</div>
						<pre className="term">{term}</pre>
					</module.Agreement>
					{Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
					<button
						type="submit"
						onClick={() => {
							if (!submitAble) handleMobileAlert(errors);
						}}
						className={submitAble ? '' : 'disabled'}
					>
						접수하기
					</button>
				</module.MobileForm>
			)}
		</>
	);
};

export default RemodelingContact;
