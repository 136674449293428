import React, { useState, useEffect } from "react";
import { module } from "../../themes/module";
import { isDesktop } from "../../utils/detect";
import { useForm } from "react-hook-form";
import { REGEXP } from "../../scheme/form";
import { LOCATION } from "../../scheme/location";
import { CONTACT_TYPES, CENTER_TYPES } from "../../scheme/contact";
import { convertToNum } from "../../utils/number";
import { VISIT_ROUTES } from "../../scheme/contact";
import { useDispatch } from "react-redux";
import { activateAlert } from "../../store/actions/alert";

const Contact = ({ user, center, privacyTerm, onFormSubmit }) => {
  const dispatch = useDispatch();

  const [submitAble, setSubmitAble] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      uName: user.uName || "",
      uEmail: user.uEmail || "",
      uTel: user.uTel || "",
      cSido: center ? center.sido : Object.keys(LOCATION)[0],
      cGugun: center ? center.gugun : LOCATION[Object.keys(LOCATION)[0]][0],
      cType: center ? CENTER_TYPES.filter(item => item.label === center.type)[0].value : CENTER_TYPES[0].value,
    },
  });

  const formWatching = watch();

  const handleMobileAlert = errors => {
    if (errors.length > 0) {
      dispatch(activateAlert({ title: "매수 문의", contents: errors[Object.keys(errors)[0]].message }));
    } else {
      dispatch(activateAlert({ title: "매수 문의", contents: "의뢰인, 시설 정보를 모두 입력시 매수문의가 가능합니다." }));
    }
  };

  useEffect(() => {
    setSubmitAble(
      Object.keys(formWatching).filter(key =>
        typeof formWatching[key] === "boolean" ? formWatching[key] === false : formWatching[key].length <= 0
      ).length <= 0
    );
  }, [formWatching]);

  useEffect(() => {
    if (user) {
      setValue("uName", user.uName);
      setValue("uEmail", user.uEmail);
      setValue("uTel", user.uTel);
    }
  }, [user]);

  return (
    <>
      {isDesktop && (
        <module.scrollWrapper style={{ height: "500px" }}>
          <module.TableForm onSubmit={handleSubmit(onFormSubmit)}>
            <fieldset>
              <legend>의뢰인 정보</legend>
              <table>
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="uName">이름</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="uName"
                        id="uName"
                        {...register("uName", {
                          required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="uVisit">방문 경로</label>
                    </th>
                    <td colSpan="1">
                      <select name="uVisit" id="uVisit" {...register("uVisit")}>
                        {VISIT_ROUTES.map((route, idx) => (
                          <option key={idx} value={route.value}>
                            {route.label}
                          </option>
                        ))}
                      </select>
                      {getValues("uVisit") === "직접입력" && (
                        <input
                          className="selfType"
                          placeholder="방문경로를 입력해 주세요"
                          type="text"
                          name="uVisit"
                          {...register("uVisitSelf")}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="uTel">연락처</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="uTel"
                        id="uTel"
                        {...register("uTel", {
                          required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                          pattern: { value: REGEXP.phone, message: "휴대폰 번호를 정확히 입력해 주세요." },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="uEmail">이메일</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="uEmail"
                        id="uEmail"
                        {...register("uEmail", {
                          required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                          pattern: { value: REGEXP.email, message: "이메일 주소를 정확히 입력해 주세요." },
                        })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <fieldset>
              <legend>요양시설 정보</legend>
              <table>
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label htmlFor="cSido">시/군</label>
                    </th>
                    <td>
                      <select
                        name="cSido"
                        id="cSido"
                        {...register("cSido", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        {Object.keys(LOCATION).map(sido => (
                          <option key={sido} value={sido}>
                            {sido}
                          </option>
                        ))}
                      </select>
                    </td>
                    <th>
                      <label htmlFor="cGugun">구/군</label>
                    </th>
                    <td>
                      <select
                        name="cGugun"
                        id="cGugun"
                        {...register("cGugun", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        {LOCATION[formWatching["cSido"]] &&
                          LOCATION[formWatching["cSido"]].map(gugun => (
                            <option key={gugun} value={gugun}>
                              {gugun}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cType">시설 분류</label>
                    </th>
                    <td>
                      <select
                        name="cType"
                        id="cType"
                        {...register("cType", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        {CENTER_TYPES.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <th>
                      <label htmlFor="cContact">문의 구분</label>
                    </th>
                    <td>
                      <select
                        name="cContact"
                        id="cContact"
                        {...register("cContact", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        {CONTACT_TYPES.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="cAssets">예상 자본금</label>
                    </th>
                    <td colSpan="3">
                      <input
                        type="text"
                        name="cAssets"
                        id="cAssets"
                        {...register("cAssets", {
                          onChange: e => {
                            setValue("cAssets", convertToNum(e.currentTarget.value));
                          },
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cContents">요청 내용</label>
                    </th>
                    <td colSpan="3">
                      <textarea
                        name="cContents"
                        id="cContents"
                        {...register("cContents", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <module.Agreement>
              <div className="inform">
                <strong>#보노랜드에서 알려드립니다.</strong>
                <p>
                  보노랜드는 문의주신 고객님의 조건에 맞는 매물을 보노랜드 영업 담당자가 신속하고 정확하게 매칭하여 연락 드립니다.
                  <br />
                  편하신 연락 시간을 적어 주시면 연락 드리도록 하겠습니다.
                </p>
              </div>
              <div className="agree">
                <input
                  type="checkbox"
                  id="cAgree"
                  {...register("cAgree", {
                    required: { value: true, message: "개인정보 수집 및 이용에 동의 시 매수 문의가 가능합니다" },
                  })}
                />
                <label htmlFor="cAgree">개인정보수집에 대한 내용에 동의합니다</label>
              </div>
              <pre className="term">{privacyTerm}</pre>
            </module.Agreement>

            {Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}

            <button type="submit" className={submitAble ? "" : "disabled"}>
              접수하기
            </button>
          </module.TableForm>
        </module.scrollWrapper>
      )}

      {!isDesktop && (
        <module.MobileForm onSubmit={handleSubmit(onFormSubmit)}>
          <fieldset>
            <legend>의뢰인 정보</legend>
            <div className="wrap">
              <label htmlFor="uName">이름</label>
              <input
                type="text"
                name="uName"
                id="uName"
                {...register("uName", {
                  required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="uVisit">방문 경로</label>
              <select name="uVisit" id="uVisit" {...register("uVisit")}>
                {VISIT_ROUTES.map((route, idx) => (
                  <option key={idx} value={route.value}>
                    {route.label}
                  </option>
                ))}
              </select>
              {getValues("uVisit") === "직접입력" && (
                <input
                  className="selfType"
                  placeholder="방문경로를 입력해 주세요"
                  type="text"
                  name="uVisit"
                  {...register("uVisitSelf")}
                />
              )}
            </div>
            <div className="wrap">
              <label htmlFor="uTel">연락처</label>
              <input
                type="text"
                name="uTel"
                id="uTel"
                {...register("uTel", {
                  required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                  pattern: { value: REGEXP.phone, message: "휴대폰 번호를 정확히 입력해 주세요." },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="uEmail">이메일</label>
              <input
                type="text"
                name="uEmail"
                id="uEmail"
                {...register("uEmail", {
                  required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                  pattern: { value: REGEXP.email, message: "이메일 주소를 정확히 입력해 주세요." },
                })}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>요양시설 정보</legend>
            <div className="wrap">
              <label htmlFor="cSigun">시/도</label>
              <select
                name="cSido"
                id="cSido"
                {...register("cSido", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                {Object.keys(LOCATION).map(sido => (
                  <option key={sido} value={sido}>
                    {sido}
                  </option>
                ))}
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cGugun">구/군</label>
              <select
                name="cGugun"
                id="cGugun"
                {...register("cGugun", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                {LOCATION[formWatching["cSido"]] &&
                  LOCATION[formWatching["cSido"]].map(gugun => (
                    <option key={gugun} value={gugun}>
                      {gugun}
                    </option>
                  ))}
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cType">시설 분류</label>
              <select
                name="cType"
                id="cType"
                {...register("cType", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                {CENTER_TYPES.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cContact">문의 구분</label>
              <select
                name="cContact"
                id="cContact"
                {...register("cContact", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                {CONTACT_TYPES.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cAssets">예상 자본금</label>
              <input
                type="text"
                name="cAssets"
                id="cAssets"
                {...register("cAssets", {
                  onChange: e => {
                    setValue("cAssets", convertToNum(e.currentTarget.value));
                  },
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cContents">요청 내용</label>
              <textarea
                name="cContents"
                id="cContents"
                {...register("cContents", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}></textarea>
            </div>
          </fieldset>
          <module.Agreement>
            <div className="inform">
              <strong>#보노랜드에서 알려드립니다.</strong>
              <p>
                보노랜드는 문의주신 고객님의 조건에 맞는 매물을 보노랜드 영업 담당자가 신속하고 정확하게 매칭하여 연락 드립니다.
                <br />
                편하신 연락 시간을 적어 주시면 연락 드리도록 하겠습니다.
              </p>
            </div>
            <div className="agree">
              <input
                type="checkbox"
                id="cAgree"
                {...register("cAgree", {
                  required: { value: true, message: "개인정보 수집 및 이용에 동의 시 매수 문의가 가능합니다" },
                })}
              />
              <label htmlFor="cAgree">개인정보수집에 대한 내용에 동의합니다</label>
            </div>
            <pre className="term">{privacyTerm}</pre>
          </module.Agreement>
          {Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
          <button
            type="submit"
            onClick={e => {
              if (!submitAble) handleMobileAlert(errors);
            }}
            className={submitAble ? "" : "disabled"}>
            접수하기
          </button>
        </module.MobileForm>
      )}
    </>
  );
};

export default Contact;
