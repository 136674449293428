import React, { useEffect, useRef } from 'react';
import { SRemodelingMenu } from './RemodelingMenuStyle';
import { useDispatch } from 'react-redux';
import { activateRemodelingContact, activateRemodelingJoin } from '../../../store/actions/service';

const RemodelingMenu = ({ on, setMenuOff, mobile = false }) => {
  const menuRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let clickEvent = null;
    if (on && menuRef && !clickEvent) {
      clickEvent = (e) => {
        const exceptions =
          e.target.className === 'rmdMenu' ||
          e.target.closest('.rmdMenu') ||
          Array.from(menuRef.current.parentElement.children).filter((child) => child === e.target).length > 0;
        if (exceptions) {
          return;
        }
        setMenuOff();
      };
      window.addEventListener('click', clickEvent);
    }

    if (!on && clickEvent) {
      window.removeEventListener('click', clickEvent);
      clickEvent = null;
    }
    return () => {
      if (clickEvent) {
        window.removeEventListener('click', clickEvent);
        clickEvent = null;
      }
    };
  }, [on, menuRef]);

  if (!on) {
    return <></>;
  }

  return (
    <SRemodelingMenu mobile={mobile} ref={menuRef} className="rmdMenu">
      <li>
        <button
          onClick={() => {
            dispatch(activateRemodelingContact());
            setMenuOff();
          }}
        >
          리모델링 문의
        </button>
      </li>
      <li>
        <button
          onClick={() => {
            dispatch(activateRemodelingJoin());
            setMenuOff();
          }}
        >
          리모델링 협력사 신청
        </button>
      </li>
    </SRemodelingMenu>
  );
};

export default RemodelingMenu;
