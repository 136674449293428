import styled from "styled-components";
import closeIcon from "../../../assets/images/icon/ico-x.svg";
import backIcon from "../../../assets/images/icon/ico-back.svg";
import accrIcon from "../../../assets/images/icon/ico-accordion.svg";
import accrBlackIcon from "../../../assets/images/form/ico-accordion_black.svg";
import calcIcon from "../../../assets/images/icon/img-detailsCalc_secondary.png";
import calcPrimaryIcon from "../../../assets/images/icon/img-detailsCalc_primary.png";
/* 환경정보 */
import nursingIcon from "../../../assets/images/envs/ico-nursing.svg";
import houseIcon from "../../../assets/images/envs/ico-house.svg";
import townIcon from "../../../assets/images/envs/ico-town.svg";
import risingIcon from "../../../assets/images/envs/ico-rising.svg";
import trafficIcon from "../../../assets/images/envs/ico-traffic.svg";
import marketIcon from "../../../assets/images/envs/ico-market.svg";
import roadIcon from "../../../assets/images/envs/ico-road.svg";
import workIcon from "../../../assets/images/envs/ico-work.svg";
import aptIcon from "../../../assets/images/envs/ico-apt.svg";
import viewIcon from "../../../assets/images/envs/ico-view.svg";
import interIcon from "../../../assets/images/envs/ico-interior.svg";
import dayCareIcon from "../../../assets/images/envs/ico-daycare.svg";
import devIcon from "../../../assets/images/envs/ico-develop.svg";
import parkIcon from "../../../assets/images/envs/ico-park.svg";
import equipIcon from "../../../assets/images/envs/ico-equip.svg";
import homeCareIcon from "../../../assets/images/envs/ico-homecare.svg";
import busIcon from "../../../assets/images/envs/ico-bus.svg";
import adminIcon from "../../../assets/images/envs/ico-admin.svg";
import newIcon from "../../../assets/images/envs/ico-new.svg";
/* 슬라이더 */
import sliderIcon from "../../../assets/images/icon/ico-back_white.svg";
/* 거래완료 */
import soldOutImg from "../../../assets/images/center/soldout.png";
/* 담당자 */
// import managerLgIcon from '../../../assets/images/center/ico-adviser_lg.svg';

export const Center = styled.article`
  position: relative;
  width: 390px;
  height: 100%;
  background-color: #fff;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.06);

  .mobile & {
    z-index: 40;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Calculator = styled.button`
  z-index: 30;
  position: absolute;
  top: 26px;
  right: -50px;
  width: 38px;
  height: 38px;
  padding: 0;
  border-radius: 2px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.06);
  background: url(${calcIcon}) center/ 26px 26px no-repeat #fff;
  color: ${props => props.theme.colors.gray700};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 38px;
  text-align: center;
  white-space: nowrap;

  &.c-pr {
    background-image: url(${calcPrimaryIcon});
  }

  .mobile & {
    top: 12px;
    right: 8px;
    width: 32px;
    height: 32px;
  }
`;

export const Top = styled.div`
  height: auto;
`;

export const Accordion = styled.div`
  width: 100%;
  height: 120px;
  background-color: #fff;

  .mobile & {
    height: auto;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;
  }

  .clr-pr & {
    .mobile & {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const AccordionSummary = styled.div`
  position: absolute;
  top: 34px;
  left: 0;
  display: flex;
  justify-content: Center;
  align-items: center;
  width: 100%;

  .mobile & {
    position: relative;
    top: 0;
    height: 56px;
  }

  .name {
    position: relative;
    padding: 0 8px;
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 18px;
      white-space: pre-wrap;
      line-height: 1.1;
      text-align: center;
    }

    .mobile & {
      padding: 0 40px;

      &::before {
        position: absolute;
        top: 12px;
        right: -18px;
        width: 24px;
        height: 24px;
        content: "";
        background: url(${accrIcon}) center no-repeat;
      }
    }
  }
`;

export const AccordionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 52px;
  padding-top: 20px;
  overflow: hidden;
  transition: height 0.3s;

  .mobile & {
    padding-top: 0;
    gap: 8px;
    height: 0px;
  }

  .accOn & {
    .mobile & {
      height: 56px;
    }
  }

  .num {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: rgba(117, 117, 117, 1);
    .mobile & {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .addr {
    color: rgba(97, 97, 97, 1);
    .mobile & {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const Assets = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;

    em {
      padding: 8px;
      font-size: ${({ theme }) => theme.fontSizes.s};
      color: #fff;
      border-radius: 2px;
      &.price {
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      &.invest {
        background-color: #d15f2e;
      }
      &.loan {
        background-color: ${({ theme }) => theme.colors.secondary};
      }

      .mobile & {
        font-size: ${({ theme }) => theme.fontSizes.xs};
      }
    }

    span {
      .mobile & {
        font-size: ${({ theme }) => theme.fontSizes.s};
      }
    }
  }
`;

export const Corp = styled.a`
  z-index: 12;
  position: absolute;
  top: 0px;
  right: 0;
  width: auto;
  height: 24px;
  overflow: hidden;
  cursor: pointer;

  &.p-td {
    height: 28px;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
  }

  img {
    height: 100%;
    width: auto;
    font-size: 12px;
  }
`;

export const Contents = styled.div`
  height: calc(100% - 214px);
  overflow-x: hidden;
  overflow-y: scroll;

  .mobile & {
    height: calc(100% - 160px);
  }

  .accOn & {
    .mobile & {
      height: calc(100% - 216px);
    }
  }

  > div {
    height: auto;
  }
`;

export const Thumbnail = styled.div`
  z-index: 30;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  overflow: hidden;

  .slide-img {
    width: 100%;
    height: 280px;
    &:focus {
      outline: none;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .mobile & {
    height: 260px;
  }

  .slick-arrow {
    z-index: 30;
    width: 34px;
    height: 34px;
    border-radius: 17px;

    &.slick-next {
      right: 20px;
      background: url(${sliderIcon}) center no-repeat rgba(0, 0, 0, 0.4);
      transform: translate(0, -50%) rotate(180deg);
    }
    &.slick-prev {
      left: 20px;
      background: url(${sliderIcon}) center no-repeat rgba(0, 0, 0, 0.4);
    }

    &::before {
      display: none !important;
      content: "";
    }
  }
`;

export const ThumbnailWrap = styled.div`
  z-index: ${({ active }) => (active ? 32 : 31)};
  position: ${({ active }) => (active ? "relative" : "absolute")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Section = styled.section`
  z-index: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;

  > h3 {
    z-index: 11;
    position: relative;
    margin-bottom: 16px;
    font-size: ${({ theme }) => theme.fontSizes.l};

    &.btn {
      padding: 8px 4px;
      position: relative;
      width: 100%;
      height: 100%;
      border: 1px solid ${({ theme }) => theme.colors.gray300};
      cursor: pointer;
      font-size: ${({ theme }) => theme.fontSizes.base};

      &::after {
        z-index: 20;
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 36px;
        content: "";
        background: url(${accrBlackIcon}) center no-repeat;
      }

      &.on::after {
        transform: translateY(-2px) scaleY(-1);
      }
    }
  }

  > hr {
    display: block;
    width: 100%;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray400};
    margin-bottom: 24px;
  }

  h3 + hr {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  .mobile & {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  }

  th {
    width: 114px;
    padding: 12px 16px;
    vertical-align: middle;
    border-right: 1px solid ${({ theme }) => theme.colors.gray200};
    font-weight: 500;
    white-space: nowrap;

    .mobile & {
      width: 100px;
    }

    &.t-wrap {
      white-space: pre-wrap;
    }

    &.t-rows {
      line-height: 1.5;
    }

    &.t-top {
      vertical-align: top;
      > * {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  td {
    position: relative;
    width: calc(100% - 114px);
    padding: 12px 16px;
    vertical-align: middle;
    line-height: 1.3;
    white-space: pre-wrap;

    .sync {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      font-size: ${({ theme }) => theme.fontSizes.s};
      background-color: ${({ theme }) => theme.colors.gray100};

      &.on + .pyeong {
        display: block;
      }

      & + .pyeong {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 40px);
        height: 100%;
        padding: 12px 16px;
        background-color: #fff;
      }
    }

    .fs-s {
      font-size: ${({ theme }) => theme.fontSizes.s};
    }
    .fs-xs {
      font-size: ${({ theme }) => theme.fontSizes.xs};
      letter-spacing: 0.5px;
    }
    .mobile & {
      width: calc(100% - 100px);
    }
  }
`;

export const Description = styled.p`
  padding: 16px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.gray700};
  background-color: ${({ theme }) => theme.colors.gray200};
  line-height: 1.625;
  .mobile & {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const Envs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px 10px;
  width: 100%;
`;

export const Env = styled.div`
  position: relative;
  width: calc(25% - 10px);
  padding: 94px 2px 0;
  font-size: 14px;
  text-align: center;
  line-height: 1.2;
  word-break: keep-all;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 82px;
    content: "";
    border-radius: 10px;
    background-image: url(${props => {
      switch (props.env) {
        case "출퇴근 용이":
          return workIcon;
        case "역세권":
          return trafficIcon;
        case "대로변":
          return roadIcon;
        case "중심상권":
          return marketIcon;
        case "병원의원 근접":
          return nursingIcon;
        case "주택밀집지역":
          return houseIcon;
        case "아파트밀집지역":
          return aptIcon;
        case "가시성 높음":
          return viewIcon;
        case "지가상승 기대지역":
          return risingIcon;
        case "신도시":
          return townIcon;
        case "최신 인테리어":
          return interIcon;
        case "주변개발계획 호재":
          return devIcon;
        case "경관우수":
          return parkIcon;
        case "신축건물":
          return newIcon;
        case "최신설비":
          return equipIcon;
        case "재가시설포함":
          return homeCareIcon;
        case "차량포함":
          return busIcon;
        case "주간보호":
          return dayCareIcon;
        case "관공서 근접":
          return adminIcon;
        default:
          return "";
      }
    }});
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #eee !important;
  }

  .mobile & {
    padding: 64px 2px 0;
    &::before {
      height: 56px;
      background-color: #fff !important;
    }
  }
`;

export const Contact = styled.button`
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #fff;

  .clr-pr & {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .mobile & {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const MContact = styled.button`
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #fff;

  .clr-pr & {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .mobile & {
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

export const Back = styled.div`
  position: absolute;
  top: 16px;
  left: calc(100% - 40px);
  width: 24px;
  height: 24px;
  background: url(${closeIcon}) center no-repeat;
  cursor: pointer;
  .mobile & {
    left: 16px;
    background: url(${backIcon}) center no-repeat;
  }
`;

/* ===== TAB =====*/
export const TabNavs = styled.div`
  display: flex;
  gap: 0;
`;

export const TabNav = styled.div`
  flex: 1;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background-color: ${({ theme }) => theme.colors.gray100};
  color: ${({ theme }) => theme.colors.gray500};
  border: none;
  cursor: pointer;

  .mobile & {
    background-color: #fff;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray300};
  }

  &.active {
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-width: 1px 1px 0;
    color: ${({ theme }) => theme.colors.secondary};
    background-color: #fff;

    .clr-pr & {
      border: 1px solid ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};
    }

    .mobile & {
      border-width: 0 0 2px;
    }
  }
`;

export const TabCont = styled.div`
  padding: 16px;
  display: ${props => (props.active ? "block" : "none")};
`;

export const ChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 32px);
  transform: translateX(-16px);
  background-color: #01153c;
  height: 100px;

  .addr {
    padding: 20px 0 10px;
    color: #fff;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.base};
    font-weight: ${({ theme }) => theme.fontWeights.light};
  }
`;

export const LogoInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  a {
    height: 30px;
    img {
      height: 100%;
    }
  }
`;

export const SoldoutThumbnail = styled.div`
  width: 100%;
  height: 100%;
  background: center / cover url(${soldOutImg});
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  letter-spacing: 2px;
`;

export const ManagerInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;

  .infos {
    display: flex;
    flex-direction: column;
    gap: 8px;
    word-break: keep-all;
    width: calc(100% - 70px);
  }

  .chats {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0;

    .img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .icon {
      width: 14px;
      height: auto;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .mobile & {
    .profile .img {
      width: 50px;
      height: 50px;
    }

    .icon {
      width: 12px;
    }
  }
`;

export const OfficeInfo = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  li {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-left: 7px;
    font-size: ${({ theme }) => theme.fontSizes.s};
    color: ${({ theme }) => theme.colors.gray700};

    strong {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.gray900};
    }

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 2px;
      background-color: ${({ theme }) => theme.colors.gray900};
    }
  }
`;
