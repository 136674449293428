import React, { useState, useEffect } from 'react';
import { MapLoading } from '../../components/ui/Inform/Inform';
const { naver } = window;

const locationBtnHtml = `<button style="z-index:41;position:absolute;top:10px;left:10px;padding:10px 12px;border:2px solid #fff; border-radius:4px;color:white;white-space:nowrap;background-color:#001F60">로드뷰</button>`;

const panoramaBtnHtml = `
    <button style="z-index:43;position:absolute;top:10px;left:10px;padding:10px 12px;border:2px solid #fff; border-radius:4px;color:white;white-space:nowrap;background-color:#001F60">지도</button>`;

const landMarkerHtml = `<svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="6" y="3" width="12" height="11" fill="white"/>
<path d="M12 0C7.03737 0 3 3.89916 3 8.69184C3 14.6397 11.0541 23.3715 11.397 23.7404C11.7191 24.0868 12.2815 24.0862 12.603 23.7404C12.9459 23.3715 21 14.6397 21 8.69184C20.9999 3.89916 16.9626 0 12 0ZM12 13.065C9.50318 13.065 7.47192 11.1032 7.47192 8.69184C7.47192 6.2805 9.50323 4.31878 12 4.31878C14.4968 4.31878 16.528 6.28055 16.528 8.69189C16.528 11.1032 14.4968 13.065 12 13.065Z" fill="#001F60"/>
</svg>
`;

const mapOptions = {
    zoom: 18,
    zoomControl: false,
    scaleControl: false,
    mapDataControl: false,
    logoControl: true,
    logoControlOptions: {
        position: naver.maps.Position.BOTTOM_LEFT
    }
};

const panoramaOptions = {
    aroundControl: true,
    aroundControlOptions: {
        position: naver.maps.Position.TOP_RIGHT
    },
    logoControl: true,
    logoControlOptions: {
        position: naver.maps.Position.BOTTOM_LEFT
    },
};

const roadLayerOptions = {
    overlayMap: false, 
    zIndex: 2
};

const RoadMapContainer = ({latlng}) => {
    
    const [ map, setMap ] = useState(null);
    const [ panoramaTimeout, setPanoramaTimeout ] = useState(null);
    const [ panoramaMode, setPanorama ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    
    const setRoadMap = (latlng) => {
        const mapCenter = new naver.maps.LatLng(latlng[0], latlng[1]);
        const markerCenter = new naver.maps.LatLng([latlng[1], latlng[0]]);

        const rMap = new naver.maps.Map('roadMap', {...mapOptions, center: mapCenter});
        const roadLayer = new naver.maps.StreetLayer(roadLayerOptions);
        const panorama = new naver.maps.Panorama('panorama', {...panoramaOptions, position: mapCenter});
        const panoramaBtn = new naver.maps.CustomControl(panoramaBtnHtml, {position: naver.maps.Position.TOP_LEFT});
        const locationBtn = new naver.maps.CustomControl(locationBtnHtml, {position: naver.maps.Position.TOP_LEFT});
        const marker = new naver.maps.Marker({
            position: markerCenter,
            icon: {
                content: landMarkerHtml,
                anchor: new naver.maps.Point(20, 20)
            },
            draggable: false
        });

        naver.maps.Event.once(rMap, 'init', function() {
            roadLayer.setMap(rMap);
            marker.setMap(rMap);
            locationBtn.setMap(rMap);
            // panorama
            panorama.setVisible(false);
            panoramaBtn.setMap(null);
        });

        naver.maps.Event.addDOMListener(panoramaBtn.getElement(), 'click', function() {
            togglePanorama(null);
        });

        naver.maps.Event.addDOMListener(locationBtn.getElement(), 'click', function() {
            togglePanorama(mapCenter);
        });

        naver.maps.Event.addListener(marker, 'click', function(e) {
            togglePanorama(mapCenter);
        });

        naver.maps.Event.addListener(rMap, 'click', function(e) {
            togglePanorama(e.coord);
        });

        const togglePanorama = (latlng = null) => {
            if(latlng === null) {
                rMap.morph(mapCenter, 18, "easeOutCubic");
                panoramaBtn.setMap(null);
                panorama.setVisible(false);
                locationBtn.setMap(rMap);
            } else {
                panorama.setPosition(latlng);
                panoramaBtn.setMap(rMap);
                locationBtn.setMap(null);
                setLoading(true);
                setPanoramaTimeout(setTimeout(function() {
                    panorama.setVisible(true);
                    setLoading(false);
                    setPanoramaTimeout(null);
                }, 400));
            }
        };

        setMap(rMap);
    };

    useEffect(() => {
        if(!map) setRoadMap(latlng);
        return () => {
            if(panoramaTimeout) clearTimeout(panoramaTimeout);
        }
    }, [])

    return (
        <>
            <div 
                id="roadMap"
                style={{
                    zIndex: '41',
                    position:'relative', 
                    width:'100%', 
                    height: '100%',
                    opacity: `${map? 1 : 0}`
                }} 
            >
                <div 
                    id="panorama"
                    style={{ 
                        zIndex: '43', 
                        position: 'absolute', 
                        top: '0', 
                        left: '0', 
                        width: '100%', 
                        height: '100%'
                    }} 
                ></div>
            </div>
            {
                loading && 
                <div
                    style={{
                        zIndex: '90',
                        position:'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: '0',
                        left: '0', 
                        width:'100%', 
                        height: '100%',
                        background: 'rgba(255,255,255, .3)'
                    }}
                >{MapLoading()}</div>
            }      
        </>
    )
    
};

export default RoadMapContainer;