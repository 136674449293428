import { getLocalNumber, getNumber } from "../../utils/number";

const QUARTERS = [
    {region: "서울특별시 종로구", quarter: "미적용"},
    {region: "서울특별시 중구", quarter: "미적용"},
    {region: "서울특별시 용산구", quarter: "미적용"},
    {region: "서울특별시 성동구", quarter: "미적용"},
    {region: "서울특별시 광진구", quarter: "미적용"},
    {region: "서울특별시 동대문구", quarter: "미적용"},
    {region: "서울특별시 중랑구", quarter: "미적용"},
    {region: "서울특별시 성북구", quarter: "미적용"},
    {region: "서울특별시 강북구", quarter: "미적용"},
    {region: "서울특별시 도봉구", quarter: "미적용"},
    {region: "서울특별시 노원구", quarter: "미적용"},
    {region: "서울특별시 은평구", quarter: "미적용"},
    {region: "서울특별시 서대문구", quarter: "미적용"},
    {region: "서울특별시 마포구", quarter: "미적용"},
    {region: "서울특별시 양천구", quarter: "미적용"},
    {region: "서울특별시 강서구", quarter: "미적용"},
    {region: "서울특별시 구로구", quarter: "미적용"},
    {region: "서울특별시 금천구", quarter: "미적용"},
    {region: "서울특별시 영등포구", quarter: "미적용"},
    {region: "서울특별시 동작구", quarter: "미적용"},
    {region: "서울특별시 관악구", quarter: "미적용"},
    {region: "서울특별시 서초구", quarter: "미적용"},
    {region: "서울특별시 강남구", quarter: "미적용"},
    {region: "서울특별시 송파구", quarter: "미적용"},
    {region: "서울특별시 강동구", quarter: "미적용"},
    {region: "부산광역시 중구", quarter: "미적용"},
    {region: "부산광역시 서구", quarter: "미적용"},
    {region: "부산광역시 동구", quarter: "미적용"},
    {region: "부산광역시 영도구", quarter: "미적용"},
    {region: "부산광역시 부산진구", quarter: "미적용"},
    {region: "부산광역시 동래구", quarter: "미적용"},
    {region: "부산광역시 남구", quarter: "미적용"},
    {region: "부산광역시 북구", quarter: "미적용"},
    {region: "부산광역시 해운대구", quarter: "미적용"},
    {region: "부산광역시 사하구", quarter: "미적용"},
    {region: "부산광역시 금정구", quarter: "미적용"},
    {region: "부산광역시 강서구", quarter: "미적용"},
    {region: "부산광역시 연제구", quarter: "미적용"},
    {region: "부산광역시 수영구", quarter: "미적용"},
    {region: "부산광역시 사상구", quarter: "미적용"},
    {region: "부산광역시 기장군", quarter: "미적용"},
    {region: "대구광역시 중구", quarter: "미적용"},
    {region: "대구광역시 동구", quarter: "미적용"},
    {region: "대구광역시 서구", quarter: "미적용"},
    {region: "대구광역시 남구", quarter: "미적용"},
    {region: "대구광역시 북구", quarter: "미적용"},
    {region: "대구광역시 수성구", quarter: "미적용"},
    {region: "대구광역시 달서구", quarter: "미적용"},
    {region: "대구광역시 달성군", quarter: "미적용"},
    {region: "인천광역시 중구", quarter: "미적용"},
    {region: "인천광역시 동구", quarter: "미적용"},
    {region: "인천광역시 남구", quarter: "미적용"},
    {region: "인천광역시 연수구", quarter: "미적용"},
    {region: "인천광역시 남동구", quarter: "미적용"},
    {region: "인천광역시 부평구", quarter: "미적용"},
    {region: "인천광역시 계양구", quarter: "미적용"},
    {region: "인천광역시 서구", quarter: "미적용"},
    {region: "인천광역시 강화군", quarter: "미적용"},
    {region: "인천광역시 옹진군", quarter: "미적용"},
    {region: "광주광역시 동구", quarter: "미적용"},
    {region: "광주광역시 서구", quarter: "미적용"},
    {region: "광주광역시 남구", quarter: "미적용"},
    {region: "광주광역시 북구", quarter: "미적용"},
    {region: "광주광역시 광산구", quarter: "미적용"},
    {region: "대전광역시 동구", quarter: "미적용"},
    {region: "대전광역시 중구", quarter: "미적용"},
    {region: "대전광역시 서구", quarter: "미적용"},
    {region: "대전광역시 유성구", quarter: "미적용"},
    {region: "대전광역시 대덕구", quarter: "미적용"},
    {region: "울산광역시 중구", quarter: "미적용"},
    {region: "울산광역시 남구", quarter: "미적용"},
    {region: "울산광역시 동구", quarter: "미적용"},
    {region: "울산광역시 북구", quarter: "미적용"},
    {region: "울산광역시 울주군", quarter: "미적용"},
    {region: "세종특별자치시", quarter: "미적용"},
    {region: "경기도 수원시 장안구", quarter: "미적용"},
    {region: "경기도 수원시 권선구", quarter: "미적용"},
    {region: "경기도 수원시 팔달구", quarter: "미적용"},
    {region: "경기도 수원시 영통구", quarter: "미적용"},
    {region: "경기도 성남시 수정구", quarter: "미적용"},
    {region: "경기도 성남시 중원구", quarter: "미적용"},
    {region: "경기도 성남시 분당구", quarter: "미적용"},
    {region: "경기도 의정부시", quarter: "적용"},
    {region: "경기도 안양시 만안구", quarter: "미적용"},
    {region: "경기도 안양시 동안구", quarter: "미적용"},
    {region: "경기도 부천시", quarter: "미적용"},
    {region: "경기도 광명시", quarter: "미적용"},
    {region: "경기도 평택시", quarter: "미적용"},
    {region: "경기도 동두천시", quarter: "미적용"},
    {region: "경기도 안산시 상록구", quarter: "미적용"},
    {region: "경기도 안산시 단원구", quarter: "미적용"},
    {region: "경기도 고양시 덕양구", quarter: "적용"},
    {region: "경기도 고양시 일산동구", quarter: "적용"},
    {region: "경기도 고양시 일산서구", quarter: "적용"},
    {region: "경기도 과천시", quarter: "미적용"},
    {region: "경기도 구리시", quarter: "미적용"},
    {region: "경기도 남양주시", quarter: "미적용"},
    {region: "경기도 오산시", quarter: "적용"},
    {region: "경기도 시흥시", quarter: "미적용"},
    {region: "경기도 군포시", quarter: "미적용"},
    {region: "경기도 의왕시", quarter: "미적용"},
    {region: "경기도 하남시", quarter: "미적용"},
    {region: "경기도 용인시 처인구", quarter: "미적용"},
    {region: "경기도 용인시 기흥구", quarter: "적용"},
    {region: "경기도 용인시 수지구", quarter: "적용"},
    {region: "경기도 파주시", quarter: "미적용"},
    {region: "경기도 이천시", quarter: "미적용"},
    {region: "경기도 안성시", quarter: "미적용"},
    {region: "경기도 김포시", quarter: "미적용"},
    {region: "경기도 화성시", quarter: "미적용"},
    {region: "경기도 광주시", quarter: "미적용"},
    {region: "경기도 양주시", quarter: "적용"},
    {region: "경기도 포천시", quarter: "적용"},
    {region: "경기도 여주시", quarter: "미적용"},
    {region: "경기도 연천군", quarter: "미적용"},
    {region: "경기도 가평군", quarter: "미적용"},
    {region: "경기도 양평군", quarter: "미적용"},
    {region: "강원도 춘천시", quarter: "미적용"},
    {region: "강원도 원주시", quarter: "미적용"},
    {region: "강원도 강릉시", quarter: "미적용"},
    {region: "강원도 동해시", quarter: "미적용"},
    {region: "강원도 태백시", quarter: "미적용"},
    {region: "강원도 속초시", quarter: "미적용"},
    {region: "강원도 삼척시", quarter: "미적용"},
    {region: "강원도 홍천군", quarter: "미적용"},
    {region: "강원도 횡성군", quarter: "미적용"},
    {region: "강원도 영월군", quarter: "미적용"},
    {region: "강원도 평창군", quarter: "미적용"},
    {region: "강원도 정선군", quarter: "미적용"},
    {region: "강원도 철원군", quarter: "미적용"},
    {region: "강원도 화천군", quarter: "미적용"},
    {region: "강원도 양구군", quarter: "미적용"},
    {region: "강원도 인제군", quarter: "미적용"},
    {region: "강원도 고성군", quarter: "미적용"},
    {region: "강원도 양양군", quarter: "미적용"},
    {region: "충청북도 청주시 상당구", quarter: "미적용"},
    {region: "충청북도 청주시 서원구", quarter: "미적용"},
    {region: "충청북도 청주시 흥덕구", quarter: "미적용"},
    {region: "충청북도 청주시 청원구", quarter: "미적용"},
    {region: "충청북도 충주시", quarter: "미적용"},
    {region: "충청북도 제천시", quarter: "미적용"},
    {region: "충청북도 보은군", quarter: "미적용"},
    {region: "충청북도 옥천군", quarter: "미적용"},
    {region: "충청북도 영동군", quarter: "미적용"},
    {region: "충청북도 증평군", quarter: "미적용"},
    {region: "충청북도 진천군", quarter: "미적용"},
    {region: "충청북도 괴산군", quarter: "미적용"},
    {region: "충청북도 음성군", quarter: "미적용"},
    {region: "충청북도 단양군", quarter: "미적용"},
    {region: "충청남도 천안시 동남구", quarter: "미적용"},
    {region: "충청남도 천안시 서북구", quarter: "미적용"},
    {region: "충청남도 공주시", quarter: "미적용"},
    {region: "충청남도 보령시", quarter: "미적용"},
    {region: "충청남도 아산시", quarter: "미적용"},
    {region: "충청남도 서산시", quarter: "미적용"},
    {region: "충청남도 논산시", quarter: "미적용"},
    {region: "충청남도 계룡시", quarter: "미적용"},
    {region: "충청남도 당진시", quarter: "미적용"},
    {region: "충청남도 금산군", quarter: "미적용"},
    {region: "충청남도 부여군", quarter: "미적용"},
    {region: "충청남도 서천군", quarter: "미적용"},
    {region: "충청남도 청양군", quarter: "미적용"},
    {region: "충청남도 홍성군", quarter: "미적용"},
    {region: "충청남도 예산군", quarter: "미적용"},
    {region: "충청남도 태안군", quarter: "미적용"},
    {region: "전라북도 전주시 완산구", quarter: "미적용"},
    {region: "전라북도 전주시 덕진구", quarter: "미적용"},
    {region: "전라북도 군산시", quarter: "미적용"},
    {region: "전라북도 익산시", quarter: "미적용"},
    {region: "전라북도 정읍시", quarter: "미적용"},
    {region: "전라북도 남원시", quarter: "미적용"},
    {region: "전라북도 김제시", quarter: "미적용"},
    {region: "전라북도 완주군", quarter: "미적용"},
    {region: "전라북도 진안군", quarter: "미적용"},
    {region: "전라북도 무주군", quarter: "미적용"},
    {region: "전라북도 장수군", quarter: "미적용"},
    {region: "전라북도 임실군", quarter: "미적용"},
    {region: "전라북도 순창군", quarter: "미적용"},
    {region: "전라북도 고창군", quarter: "미적용"},
    {region: "전라북도 부안군", quarter: "미적용"},
    {region: "전라남도 목포시", quarter: "미적용"},
    {region: "전라남도 여수시", quarter: "미적용"},
    {region: "전라남도 순천시", quarter: "미적용"},
    {region: "전라남도 나주시", quarter: "미적용"},
    {region: "전라남도 광양시", quarter: "미적용"},
    {region: "전라남도 담양군", quarter: "미적용"},
    {region: "전라남도 곡성군", quarter: "미적용"},
    {region: "전라남도 구례군", quarter: "미적용"},
    {region: "전라남도 고흥군", quarter: "미적용"},
    {region: "전라남도 보성군", quarter: "미적용"},
    {region: "전라남도 화순군", quarter: "미적용"},
    {region: "전라남도 장흥군", quarter: "미적용"},
    {region: "전라남도 강진군", quarter: "미적용"},
    {region: "전라남도 해남군", quarter: "미적용"},
    {region: "전라남도 영암군", quarter: "미적용"},
    {region: "전라남도 무안군", quarter: "미적용"},
    {region: "전라남도 함평군", quarter: "미적용"},
    {region: "전라남도 영광군", quarter: "미적용"},
    {region: "전라남도 장성군", quarter: "미적용"},
    {region: "전라남도 완도군", quarter: "미적용"},
    {region: "전라남도 진도군", quarter: "미적용"},
    {region: "전라남도 신안군", quarter: "미적용"},
    {region: "경상북도 포항시 남구", quarter: "미적용"},
    {region: "경상북도 포항시 북구", quarter: "미적용"},
    {region: "경상북도 경주시", quarter: "미적용"},
    {region: "경상북도 김천시", quarter: "미적용"},
    {region: "경상북도 안동시", quarter: "미적용"},
    {region: "경상북도 구미시", quarter: "미적용"},
    {region: "경상북도 영주시", quarter: "미적용"},
    {region: "경상북도 영천시", quarter: "미적용"},
    {region: "경상북도 상주시", quarter: "미적용"},
    {region: "경상북도 문경시", quarter: "미적용"},
    {region: "경상북도 경산시", quarter: "미적용"},
    {region: "경상북도 군위군", quarter: "미적용"},
    {region: "경상북도 의성군", quarter: "미적용"},
    {region: "경상북도 청송군", quarter: "미적용"},
    {region: "경상북도 영양군", quarter: "미적용"},
    {region: "경상북도 영덕군", quarter: "미적용"},
    {region: "경상북도 청도군", quarter: "미적용"},
    {region: "경상북도 고령군", quarter: "미적용"},
    {region: "경상북도 성주군", quarter: "미적용"},
    {region: "경상북도 칠곡군", quarter: "미적용"},
    {region: "경상북도 예천군", quarter: "미적용"},
    {region: "경상북도 봉화군", quarter: "미적용"},
    {region: "경상북도 울진군", quarter: "미적용"},
    {region: "경상북도 울릉군", quarter: "미적용"},
    {region: "경상남도 창원시 의창구", quarter: "미적용"},
    {region: "경상남도 창원시 성산구", quarter: "미적용"},
    {region: "경상남도 창원시 마산합포구", quarter: "미적용"},
    {region: "경상남도 창원시 마산회원구", quarter: "미적용"},
    {region: "경상남도 창원시 진해구", quarter: "미적용"},
    {region: "경상남도 진주시", quarter: "미적용"},
    {region: "경상남도 통영시", quarter: "미적용"},
    {region: "경상남도 사천시", quarter: "미적용"},
    {region: "경상남도 김해시", quarter: "미적용"},
    {region: "경상남도 밀양시", quarter: "미적용"},
    {region: "경상남도 거제시", quarter: "미적용"},
    {region: "경상남도 양산시", quarter: "미적용"},
    {region: "경상남도 의령군", quarter: "미적용"},
    {region: "경상남도 함안군", quarter: "미적용"},
    {region: "경상남도 창녕군", quarter: "미적용"},
    {region: "경상남도 고성군", quarter: "미적용"},
    {region: "경상남도 남해군", quarter: "미적용"},
    {region: "경상남도 하동군", quarter: "미적용"},
    {region: "경상남도 산청군", quarter: "미적용"},
    {region: "경상남도 함양군", quarter: "미적용"},
    {region: "경상남도 거창군", quarter: "미적용"},
    {region: "경상남도 합천군", quarter: "미적용"},
    {region: "제주특별자치도 제주시", quarter: "미적용"},
    {region: "제주특별자치도 서귀포시", quarter: "미적용" }
];


export const BUILD_FORM = [
    {
        label: '요양시설 타입',
        type: 'select',
        options: [
            {text: '단독요양원', value: '단독요양원'}, 
            {text: '상가요양원', value: '상가요양원'}
        ],
        defaultValue: '단독요양원'
    },
    {
        label: '지역구', 
        type: 'select',
        options: QUARTERS.map((item, idx) => ({text: item.region, value: idx})),
        defaultValue: '경기도 부천시'
    },
    {
        label: '지역 형태',
        type: 'select',
        options: [
            {text: '주거지역', value: '주거지역'}, 
            {text: '상업지역(유통X)', value: '상업지역'}, 
            {text: '공업지역', value: '공업지역'},
            {text: '녹지지역', value: '녹지지역'}, 
            {text: '관리지역', value: '관리지역'}, 
            {text: '농림지역', value: '농림지역'}, 
            {text: '자연환경보전지역', value: '자연환경보전지역'}
        ],
        defaultValue: '주거지역'
    },
    {
        label: '소유 여부',
        type: 'select',
        options: [
            {text: 'O', value: 'O'},
            {text: 'X', value: 'X'} 
        ],
        defaultValue: 'O'
    },
    {
        label: '인접도로폭 [m]',
        type: 'text',
        defaultValue: '6',
        placeholder: '숫자 입력'
    },
    {
        label: '전유면적 [㎡]',
        type: 'text',
        defaultValue: '',
        placeholder: '숫자 입력'
    },
    {
        label: '공용면적 [㎡]',
        type: 'text',
        defaultValue: '',
        placeholder: '숫자 입력'
    },
    {
        label: `공용면적에\n주차장 면적 포함 여부`,
        type: 'select',
        options: [
            {text: 'O', value: 'O'},
            {text: 'X', value: 'X'} 
        ],
        defaultValue: 'O'
    },
    {
        label: `공용면적에 미포함 시\n주차장 면적 [㎡]`,
        type: 'text',
        defaultValue: '',
        placeholder: '숫자 입력'
    },
    {
        label: `지자체 주차장\n설치 기준면적 [㎡]`,
        type: 'select',
        options: [
            {text: '100㎡', value: '100'}, 
            {text: '150㎡', value: '150'}, 
            {text: '200㎡', value: '200'}, 
            {text: '250㎡', value: '250'}, 
            {text: '300㎡', value: '300'}, 
        ],
        defaultValue: '300'
    }
];


export const getBuildResult = (dataset) => {
    const publicParking = dataset.publicParking === 'O';
    const parkingSize = publicParking? getNumber(dataset.parkingSize) : 0;

    const a = getNumber(dataset.privateSize) + getNumber(dataset.publicSize) + parkingSize;
    const b = a - parkingSize;
    const c = publicParking? 0 : Math.round(b/dataset.limit);
    const d = Math.round(c * 12.5);
    const e = b + (c * 12.5);
    const quarter = QUARTERS[dataset.regionAddr].quarter;
    const permission = dataset.assets === 'O' 
                    && getNumber(dataset.roadSize) >= 6 
                    && quarter === '미적용' 
                    && dataset.regionType !== '농림지역' 
                    && dataset.regionType !== '자연환경보전지역';
    const remarks = dataset.assets === 'X'?
                    '부동산 소유 문제'
                    : getNumber(dataset.roadSize) < 6?
                    '인접도로폭 문제'
                    : '없음';

    const resultSet =  {
        permission: permission? 'O' : 'X',
        quarter: quarter,
        remarks: remarks,
        calc: {
            a: getLocalNumber(a),
            b: getLocalNumber(b),
            c: getLocalNumber(c),
            d: getLocalNumber(d),
            e: getLocalNumber(e),
            f: permission? `${parseInt(e / 23.6)}인` : '불가',
        }
    };

    return resultSet;
};



