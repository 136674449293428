import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { isDesktop } from '../../utils/detect';
import Panel from '../../components/ui/Panel/Panel';
import CenterList from '../../components/Center/CenterList/CenterList';
import ListMore from '../../components/List/ListMore/ListMore';
import AddressFilterContainer from '../filters/AddressFilterContainer';
import CategoryFilterContainer from '../filters/CategoryFilterContainer';
import SwipePanel from '../../components/ui/SwipePanel/SwipePanel';
import { getBonoCenters, getFilteredCenters } from '../../api/centers';
import { updateFilterForData } from '../../store/actions/filter';
import useDeepCompareEffect from 'use-deep-compare-effect';

const CenterListContainer = () => {
  const dispatch = useDispatch();
  const FILTER = useSelector(state => state.Filter.data);
  const USER_NO = useSelector(state => state.User.userInfo.no);

  const PATH = useLocation().pathname;

  const [options, setOptions] = useState(null);
  const [mobileOn, setMobileOn] = useState(false);

  const [nextIndex, setNextIndex] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);

  const [centers, setCenters] = useState([]); // 목록 데이터
  const [errMsg, setErrMsg] = useState('');

  const isBonoRcmd = filter => {
    if (filter.categories === null && filter.category2 === null) return true;

    const hasArea = filter.address.area1.length > 0;
    const hasFilter = filter.categories.length > 0;

    if (!hasArea && !hasFilter) return true;
    else return false;
  };

  const loadNext = async () => {
    setIsNextLoading(true);

    const RESPONSE = isBonoRcmd(options)
      ? await getBonoCenters({ userNo: USER_NO, page: nextIndex })
      : await getFilteredCenters({
          userNo: USER_NO,
          area1: options.address.area1,
          area2: options.address.area2,
          area3: options.address.area3,
          categories: options.categories,
          page: nextIndex,
          sort: options.sort,
          sisulCustomNo: options.sisulCustomNo,
        });

    if (RESPONSE && RESPONSE.data.code === 1) {
      setCenters([...centers, ...RESPONSE.data.arrayResult]);
      setHasNext(RESPONSE.data.pageCode === 1);
      setErrMsg('');

      if (RESPONSE.data.arrayResult.length < 0)
        setErrMsg(`${isBonoRcmd(options) ? '추천' : '검색'} 매물이 없습니다.`);
    } else {
      setErrMsg(`${isBonoRcmd(options) ? '추천' : '검색'} 매물이 없습니다.`);
    }
    setNextIndex(nextIndex => nextIndex + 1);
    setIsNextLoading(false);
  };

  useDeepCompareEffect(() => {
    setNextIndex(1);
    setCenters([]);

    setOptions(FILTER);
    setErrMsg('');

    if (FILTER.address.area1.length > 0) {
      setOptions(FILTER);
      setErrMsg('');
    }

    if (!isDesktop) {
      if (FILTER.categories === null || Object.keys(FILTER.categories).length <= 0)
        setMobileOn(false);
      else setMobileOn(true);
    }
  }, [FILTER.address, FILTER.categories, FILTER.category2]);

  useEffect(() => {
    if (options) {
      if (
        options.address.area1.length <= 0 &&
        options.categories &&
        options.categories.length > 0
      ) {
        setErrMsg(`검색 지역에서 벗어나 있습니다. \n지도를 가까이 당기거나 지역을 옮겨 주세요.`);
      } else {
        loadNext(options);
      }
    }
  }, [options]);

  useEffect(() => {
    if (FILTER.category2) dispatch(updateFilterForData({ category2: null }));
  }, [PATH]);

  return (
    <>
      {isDesktop && (
        <Panel type={'floating'} position={'left'} fold={true}>
          <AddressFilterContainer type="main" />
          <CategoryFilterContainer />
          <ListMore
            links={[
              { path: '/bono/nursing', title: '요양원' },
              { path: '/bono/care', title: '주야간보호' },
              { path: '/bono/biz', title: '신규 사업지' },
            ]}
          />
          <CenterList
            list="main"
            type={isDesktop ? 'main' : 'abstract'}
            hasNext={hasNext}
            isNextLoading={isNextLoading}
            msg={errMsg}
            centers={centers}
            loadNext={loadNext}
          />
        </Panel>
      )}
      {!isDesktop && (
        <>
          <AddressFilterContainer type="main" />
          <CategoryFilterContainer />
          {mobileOn && (
            <SwipePanel>
              <CenterList
                list="main"
                type={isDesktop ? 'main' : 'abstract'}
                hasNext={hasNext}
                isNextLoading={isNextLoading}
                msg={errMsg}
                centers={centers}
                loadNext={loadNext}
              />
            </SwipePanel>
          )}
        </>
      )}
    </>
  );
};

export default CenterListContainer;
