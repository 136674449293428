import styled, { css } from 'styled-components';

export const SRemodelingMenu = styled.ul`
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 184px;
  height: auto;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  background-color: #fff;

  li {
    width: 100%;
    &:not(:first-child) {
      border-top: 1px solid ${({ theme }) => theme.colors.gray300};
    }

    button {
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      font-size: ${({ theme }) => theme.fontSizes.s};
      color: ${({ theme }) => theme.colors.gray800};
      white-space: nowrap;

      &:hover {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${(props) =>
    props.mobile &&
    css`
      width: 150px !important;
      top: auto;
      bottom: calc(100% + 2px);
      li {
        button {
          padding: 8px 12px;
          font-size: ${({ theme }) => theme.fontSizes.xs};
        }
      }
    `}
`;
