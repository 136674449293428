import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { activateChart, deactivateChart } from '../../store/actions/chart';
import MapChart from '../../components/MapChart/MapChart';

const MapChartContainer = () => {
  const dispatch = useDispatch();
  const IS_ACTIVE = useSelector((state) => state.Chart.active);
  const CHART_DATA = useSelector((state) => state.Chart.data);
  const IS_LOGGEDIN = useSelector((state) => state.User.loggedIn);

  useEffect(() => {
    if (IS_ACTIVE) dispatch(activateChart());
    else dispatch(deactivateChart());
  }, [CHART_DATA]);

  return <>{CHART_DATA && IS_ACTIVE && <MapChart data={CHART_DATA} isLoggedIn={IS_LOGGEDIN} />}</>;
};

export default MapChartContainer;
