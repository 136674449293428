export const ALERT = {
  ACTIVATE: '/alert',
  ACTIVATE_ACTION: '/alert/action',
  DEACTIVATE: '/',
};

export const activateAlert = message => ({
  type: ALERT.ACTIVATE,
  payload: message,
});

export const deactivateAlert = () => ({
  type: ALERT.DEACTIVATE,
  payload: '',
});

export const activateActionAlert = props => ({
  type: ALERT.ACTIVATE_ACTION,
  payload: props,
});
