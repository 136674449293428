import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategoryFilter from '../../components/filters/CategoryFilter/CategoryFilter';
import { CATEGORIES, CATEGORY } from '../../scheme/filter';
import { updateFilterForData } from '../../store/actions/filter';

// const categorySet = Object.keys(CATEGORY).reduce((acc, key) => {
//   return Object.assign({}, acc, {
//     [key]: {
//       capacity: TYPE_AND_CAPACITY[key][0].value,
//       selected: false,
//     },
//   });
// }, {});

const CategoryFilterContainer = () => {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState(JSON.parse(JSON.stringify(CATEGORY))); // 카테고리 필터 데이터

  const FILTER_CATEGORIES = useSelector((state) => state.Filter.data.categories);

  const submitCategory = (category, capacity, selected) => {
    const value = {
      [category]: {
        capacity: capacity,
        selected: selected,
      },
    };
    const newCtg = Object.assign({}, categories, value);
    dispatch(updateFilterForData({ categories: CATEGORIES(newCtg) }));
  };

  useEffect(() => {
    if (FILTER_CATEGORIES) {
      const newCtg = JSON.parse(JSON.stringify(CATEGORY));
      if (FILTER_CATEGORIES.length > 0) {
        for (let filter of FILTER_CATEGORIES) {
          const name = filter.category === '주야간보호센터' ? '주간보호' : filter.category;
          newCtg[name].selected = true;
          newCtg[name].capacity = [filter.min, filter.max];
        }
      }
      setCategories(newCtg);
    }
  }, [FILTER_CATEGORIES]);

  return <CategoryFilter categories={categories} onFormSubmit={submitCategory} />;
};

export default CategoryFilterContainer;
