import React, { useEffect, useState, useRef } from "react";
import RoadMapContainer from "../../../containers/RoadMap/RoadMapContainer";
import {
  Center,
  Calculator,
  Top,
  Contents,
  Thumbnail,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Corp,
  Section,
  Table,
  Envs,
  Env,
  Contact,
  Back,
  TabNavs,
  TabNav,
  TabCont,
  ChartWrap,
  LogoInfo,
  SoldoutThumbnail,
  OfficeInfo,
  MContact,
} from "./CenterItemStyle";
import CenterAction from "../CenterAction/CenterAction";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import RadarChart from "../../Chart/RadarChart/RadarChart";
import { isDesktop } from "../../../utils/detect";
import { Error, MoreLoading } from "../../ui/Inform/Inform";
import { getLocalNumber, getNumber, getPyeong, getLocalCurrent } from "../../../utils/number";
import { GET_MARKETS } from "../../../scheme/chart";
import CustomDoughnutChart from "../../Chart/CustomDoughnutChart/CustomDoughnutChart";
import defaultThumbnail from "../../../assets/images/form/img-default.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getValidObjValue } from "../../../utils/center";

const getFileIdx = fileType => {
  switch (fileType) {
    case "썸네일":
      return 0;
    case "외관":
      return 1;
    case "내관":
      return 2;
    default:
      return 2;
  }
};

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  width: "100%",
  arrows: true,
};

const imageSlider = (imgs, isSoldOut) => {
  let sImgs = [];

  if (isSoldOut) {
    sImgs.push(
      <div className="slide-img">
        <SoldoutThumbnail>거래완료</SoldoutThumbnail>
      </div>
    );
  }

  if (imgs === null || imgs.length <= 0) {
    if (isSoldOut) {
      return sImgs[0];
    }

    return (
      <div className="slide-img">
        <div className="img" style={{ background: `url(${defaultThumbnail})` }} />
      </div>
    );
  }

  if (imgs.length === 1) {
    sImgs.push(
      <div className="slide-img">
        <div className="img" style={{ background: `url(${imgs[0]["sisulFilePath"]})` }} />
      </div>
    );

    if (!isSoldOut) {
      return sImgs[0];
    }
  }

  if (imgs.length > 1) {
    const arranged = imgs.map(img => {
      img["index"] = getFileIdx(img["sisulFileType"]);
      return img;
    });

    for (let i = 1; i < arranged.length; i++) {
      for (let j = i; j > 0; j--) {
        if (arranged[j].index < arranged[j - 1].index) {
          let target = arranged[j];
          arranged[j] = arranged[j - 1];
          arranged[j - 1] = target;
        }
      }
    }

    sImgs = sImgs.concat(
      arranged.map(item => (
        <div className="slide-img">
          <div className="img" style={{ background: `url(${item["sisulFilePath"].replaceAll(" ", "")})` }} />
        </div>
      ))
    );

    return (
      <Slider {...sliderSettings} style={{ width: "100%", height: "280px", minWidth: "100%" }}>
        {sImgs.map((sImg, idx) => (
          <React.Fragment key={idx}>{sImg}</React.Fragment>
        ))}
      </Slider>
    );
  }
};

const CenterItem = ({ center, onContactClick, onCalcClick, loading, error, isLoggedIn }) => {
  const navigate = useNavigate();
  const boxRef = useRef();
  const localRef = useRef();
  const HISTORY = useSelector(state => state.History.url);

  // company
  let isMaemul = false;
  let maemulForm = "";
  let isDayCare = null;
  let companys = [];

  if (center) {
    isMaemul = center["sisulState"] === "매물";
    maemulForm =
      isMaemul && center["bonoForm"]
        ? center["bonoForm"].includes("리모델링")
          ? "신규 리모델링"
          : center["bonoForm"].includes("신규")
          ? "신규 사업지"
          : ""
        : "";
    isDayCare = getValidObjValue(center, [["sisul", "adminPttnCd"]]) === "주야간보호센터";
    if (center["company"]) companys.push({ ...center["company"], name: `관리 시스템\n(ERP)` });
    if (center["foodCompany"]) companys.push({ ...center["foodCompany"], name: "식자재" });
    if (center["hygieneCompany"]) companys.push({ ...center["hygieneCompany"], name: "위생" });
    if (center["quarantineCompany"]) companys.push({ ...center["quarantineCompany"], name: "방역" });
    if (center["accountingCompany"]) companys.push({ ...center["accountingCompany"], name: "세무·회계" });
  }

  const [tabIdx, setTabIdx] = useState(0);
  const [accordion, setAccordion] = useState(true);
  const [pyeongs, setPyeongs] = useState([false, false, false]);
  const [localVisible, setLocalVisible] = useState(isMaemul ? true : false);
  const [onLoading, setOnLoading] = useState(isDesktop ? false : true);
  const [roadMap, setRoadMap] = useState(false);

  const toggleTab = idx => {
    setTabIdx(idx);
  };
  const toggleAccordion = accordian => {
    setAccordion(!accordian);
  };
  const toggleLocalVisible = () => {
    setLocalVisible(localVisible => !localVisible);
  };

  const getStringValue = (value, unit = "") =>
    value === null || value === "" || Number(value) === 0 ? "정보 없음" : value + unit;

  const togglePyeong = index => {
    setPyeongs(pyeongs => pyeongs.map((item, idx) => (idx === index ? !item : item)));
  };

  const onCloseClick = () => {
    navigate(HISTORY, { replace: true });
  };

  useEffect(() => {
    if (boxRef.current) boxRef.current.scrollTo(0, 0);
    if (tabIdx === 1 && !roadMap && !isMaemul) setRoadMap(true);
  }, [tabIdx]);

  useEffect(() => {
    if (!isMaemul && localVisible) {
      const localRect = localRef.current.getBoundingClientRect();
      boxRef.current.scrollTo(0, localRect.top);
    }
  }, [localVisible]);

  useEffect(() => {
    // reset ui state
    if (boxRef.current) boxRef.current.scrollTo(0, 0);
    if (tabIdx === 1) setTabIdx(0);
    if (roadMap) setRoadMap(false);

    // mobile ui motion
    let timeout;
    if (center && !isDesktop)
      timeout = setTimeout(function () {
        setOnLoading(false);
      }, 150);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [center]);

  return (
    <>
      {onLoading && MoreLoading()}
      {error && Error()}
      {!onLoading && center && (
        <Center className={`${accordion ? "accOn" : ""} ${isMaemul ? "clr-pr" : ""}`}>
          <Calculator className={`bono-guide ${isMaemul ? "c-pr" : ""}`} data-guide="11" onClick={() => onCalcClick()} />
          <Top>
            <Accordion>
              <AccordionSummary
                onClick={() => {
                  !isDesktop && toggleAccordion(accordion);
                }}>
                <div className="name">
                  <h2>
                    {isMaemul
                      ? `${maemulForm.length > 0 ? maemulForm + " " : ""}${center["sisul"]["adminPttnCd"]} ${
                          center["sisul"]["toPer"]
                        } 인`
                      : getValidObjValue(center, [["adminName"], ["sisul", "adminNm"]])}
                  </h2>
                </div>
              </AccordionSummary>
              <AccordionDetails className={accordion && "active"}>
                <div className="num">{`${isMaemul ? "매물번호" : "시설번호"} : ${center["sisulCustomNo"]}`}</div>
                <div className="addr">
                  {isMaemul && !center["sisulAddSubExp"]
                    ? `${center["sisul"]["siDoCd"]} ${center["sisul"]["siGunGuCd"]}`
                    : center["sisul"]["local"]}
                </div>
              </AccordionDetails>
            </Accordion>
            <Back onClick={() => onCloseClick()}></Back>
            <TabNavs>
              <TabNav className={tabIdx === 0 && "active"} onClick={() => toggleTab(0)}>
                상세정보
              </TabNav>
              <TabNav className={tabIdx === 1 && "active"} onClick={() => toggleTab(1)}>
                부동산
              </TabNav>
            </TabNavs>
          </Top>
          <Contents ref={boxRef}>
            <div>
              <Thumbnail>
                {(!roadMap || tabIdx === 0) && imageSlider(center["sisulImage"], center["bonoState"] === "거래완료")}
                {roadMap && tabIdx === 1 && <RoadMapContainer latlng={[center["x"], center["y"]]} />}
              </Thumbnail>
              <TabCont active={tabIdx === 0}>
                <div style={{ marginBottom: "30px" }}>
                  <CenterAction scrapped={center["zzimResult"] === 1} centerId={center["sisulCustomNo"]} />
                </div>

                {isMaemul && (
                  <Section>
                    <h3>매매 정보</h3>

                    <hr />

                    <Table>
                      <tbody>
                        <tr>
                          <th>매물분류</th>
                          <td>
                            {center["sisul"]["adminPttnCd"] || "-"} {maemulForm}
                          </td>
                        </tr>

                        {!isDayCare ? (
                          <tr>
                            <th>매매가</th>
                            <td>{center["tradingPrice"] ? getLocalCurrent(center["tradingPrice"]) : "-"} 원</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {isDayCare && (
                          <>
                            <tr>
                              <th>보증금</th>
                              <td>{center["deposit"] ? getLocalCurrent(center["deposit"]) : "-"} 원</td>
                            </tr>

                            <tr>
                              <th>월차임</th>
                              <td>
                                {center["monthlyRent"] ? getLocalCurrent(center["monthlyRent"]) : "-"} 원{" "}
                                <span className="fs-s">
                                  ( 부가세<i className="fs-xs">(VAT)</i> {center["facilityFl"]} )
                                </span>
                              </td>
                            </tr>
                          </>
                        )}

                        {center["investmentFee"] && center["investmentFee"] > 0 ? (
                          <tr>
                            <th>투자</th>
                            <td>{getLocalCurrent(center["investmentFee"])} 원</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {center["loans"] && center["loans"] > 0 ? (
                          <tr>
                            <th>대출</th>
                            <td>{getLocalCurrent(center["loans"]) + " 원"}</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        {center["premium"] !== null && center["premium"] > 0 && (
                          <tr>
                            <th>{isDayCare ? "권리금" : "시설비(권리금)"}</th>
                            <td>
                              <span>{getLocalCurrent(center["premium"])} 원 </span>
                              {center["facilityFee"] !== null && center["facilityFee"] > 0 && (
                                <>
                                  <br />
                                  <span className="fs-s">(시설금 {getLocalCurrent(center["facilityFee"])} 원)</span>
                                </>
                              )}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <th>상세설명</th>
                          <td>{center["detailedDescription"] ? center["detailedDescription"] : "-"}</td>
                        </tr>

                        {center["officeName"] && (
                          <tr>
                            <th className="t-rows">
                              중개사무소
                              <br />
                              정보
                            </th>
                            <td>
                              <OfficeInfo>
                                <li>
                                  <strong>중개사무소</strong>
                                  <p>{center["officeName"]}</p>
                                </li>
                                {center["officeRep"] && (
                                  <li>
                                    <strong>대표</strong>
                                    <p>{center["officeRep"]}</p>
                                  </li>
                                )}
                                {center["officePhone"] && (
                                  <li>
                                    <strong>대표 번호</strong>
                                    <p>{center["officePhone"]}</p>
                                  </li>
                                )}
                                {center["regNumber"] && (
                                  <li>
                                    <strong>등록 번호</strong>
                                    <p>{center["regNumber"]}</p>
                                  </li>
                                )}
                                {center["officeAddr"] && (
                                  <li>
                                    <strong>소재지</strong>
                                    <p>{center["officeAddr"]}</p>
                                  </li>
                                )}
                              </OfficeInfo>
                            </td>
                          </tr>
                        )}

                        {center["manager"] && (
                          <tr>
                            <th>보노 담당자</th>
                            <td>
                              <span>{center["manager"]}</span>
                            </td>
                          </tr>
                        )}

                        {center["loansURL"] ? (
                          <tr>
                            <th>대출 제휴</th>

                            <td>
                              <LogoInfo>
                                <Link to={center["loansURL"]}>
                                  <img src={center["bonoFileName"]} alt="연계 금융기관" />
                                </Link>
                                <span>{center["loansName"] ? center["loansName"] : ""}</span>
                              </LogoInfo>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </Table>
                  </Section>
                )}
                <Section>
                  <h3>기본 정보</h3>
                  <hr />
                  {center["company"] && (
                    <Corp href={center["company"].companyURL.length > 0 ? center["company"].companyURL : "#none"} target="_blank">
                      <img src={center["company"].companyLogo} alt="시설 연계업체" />
                    </Corp>
                  )}
                  <Table>
                    <tbody>
                      <tr>
                        <th>기관</th>
                        <td>{getStringValue(center["sisul"]["adminPttnCd"])}</td>
                      </tr>
                      {!isMaemul && (
                        <tr>
                          <th>지정일</th>
                          <td>
                            {center["sisul"]["longTermPeribRgtDt"]
                              ? `${center["sisul"]["longTermPeribRgtDt"].substring(0, 4)}년 ${center["sisul"][
                                  "longTermPeribRgtDt"
                                ].substring(4, 6)}월 ${center["sisul"]["longTermPeribRgtDt"].substring(6, 8)}일`
                              : "정보 없음"}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th>정원</th>
                        <td>{center["sisul"]["toPer"]} 인</td>
                      </tr>
                      <tr>
                        <th>현원</th>
                        <td>
                          {center["sisul"]["maPer"] + center["sisul"]["fmPer"]} 인 (대기 : {center["sisul"]["rsvPer"]} 인)
                        </td>
                      </tr>
                      <tr>
                        <th>인력현황</th>
                        <td>
                          {getLocalNumber(
                            center["sisul"]["equipLong"] +
                              center["sisul"]["hdOfce"] +
                              center["sisul"]["socWel"] +
                              center["sisul"]["nur"] +
                              center["sisul"]["nurArticle"] +
                              center["sisul"]["recuProt1"] +
                              center["sisul"]["recuProt2"] +
                              center["sisul"]["recuProtDelay"] +
                              center["sisul"]["physicalMTret"] +
                              center["sisul"]["chargeDoc"] +
                              center["sisul"]["nut"] +
                              center["sisul"]["cook"] +
                              center["sisul"]["hygiPrsn"] +
                              center["sisul"]["ofceEmp"] +
                              center["sisul"]["mgmtPrsn"] +
                              center["sisul"]["suppPrsn"]
                          )}{" "}
                          명
                        </td>
                      </tr>
                      {!isMaemul && (
                        <tr>
                          <th>교통편</th>
                          <td>{getStringValue(center["sisul"]["tfMth"])}</td>
                        </tr>
                      )}
                      <tr>
                        <th>주차시설</th>
                        <td>{getStringValue(center["sisul"]["pkngEquip"])}</td>
                      </tr>
                      <tr>
                        <th>평가</th>
                        <td>{getStringValue(center["sisul"]["eval"])}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Section>
                {companys.length > 0 && (
                  <Section>
                    <h3>협력업체</h3>
                    <Table>
                      {companys.map((company, idx) => (
                        <tr key={idx}>
                          <th className="t-wrap">{company.name}</th>
                          <td>
                            <LogoInfo>
                              <a href={company.companyURL}>
                                <img src={company.companyLogo} alt="협력 업체" />
                              </a>
                              <span>{company.companyName}</span>
                            </LogoInfo>
                          </td>
                        </tr>
                      ))}
                    </Table>
                  </Section>
                )}
                {center["sisulCategoryList"] && (
                  <Section>
                    <h3>환경정보</h3>
                    <hr />
                    <Envs>
                      {center["sisulCategoryList"].map((item, idx) => (
                        <Env env={item} key={idx}>
                          {item}
                        </Env>
                      ))}
                    </Envs>
                  </Section>
                )}
                {center["localStatistics"] && (
                  <Section ref={localRef}>
                    {isMaemul ? (
                      <h3>시장현황</h3>
                    ) : (
                      <h3 className={`btn ${localVisible ? "on" : ""}`} onClick={() => toggleLocalVisible()}>
                        시장현황 보기
                      </h3>
                    )}

                    {((!isMaemul && localVisible) || isMaemul) && (
                      <ChartWrap>
                        <span className="addr">
                          {center["sisul"]["siDoCd"]} {center["sisul"]["siGunGuCd"]}
                        </span>

                        {center["localStatistics"] &&
                          GET_MARKETS(center["localStatistics"]).map((item, idx) => (
                            <CustomDoughnutChart key={idx} data={item} bgColor="#01153C" blur={!isLoggedIn && idx > 1} />
                          ))}
                      </ChartWrap>
                    )}
                  </Section>
                )}
                {isMaemul && center["totalScore"] !== null && center["totalScore"] !== 0 && (
                  <Section>
                    <h3>보노지수</h3>
                    <hr />
                    <RadarChart
                      data={{
                        total: getNumber(center["totalScore"]),
                        scores: [
                          {
                            label: "시설평가 등급",
                            value: getNumber(center["bonoFacEval"]),
                          },
                          {
                            label: "가산현황",
                            value: getNumber(center["bonoCntrlCndtn"]),
                          },
                          {
                            label: "관리상태",
                            value: getNumber(center["bonoAddnStus"]),
                          },
                        ],
                      }}
                    />
                  </Section>
                )}
              </TabCont>
              <TabCont active={tabIdx === 1}>
                <Section>
                  <h3>토지 정보</h3>
                  <div>
                    <Table>
                      <tbody>
                        <tr>
                          <th>면적</th>
                          <td>
                            {getStringValue(center["sisul"]["lndpclAr"], " m²")}
                            <button className={`sync ${pyeongs[0] ? "on" : ""}`} onClick={() => togglePyeong(0)}>
                              {pyeongs[0] ? "m²" : "평"}
                            </button>
                            <span className="pyeong">
                              {center["sisul"]["lndpclAr"] ? getPyeong(center["sisul"]["lndpclAr"]) : "정보 없음"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>지목</th>
                          <td>{getStringValue(center["sisul"]["lndcgrCodeNm"])}</td>
                        </tr>
                        <tr>
                          <th>용도지역</th>
                          <td>{getStringValue(center["sisul"]["prposArea"])}</td>
                        </tr>
                        <tr>
                          <th>이용상황</th>
                          <td>{getStringValue(center["sisul"]["ladUseSittn"])}</td>
                        </tr>
                        <tr>
                          <th>소유구분</th>
                          <td>{getStringValue(center["sisul"]["posesnSeCodeNm"])}</td>
                        </tr>
                        <tr>
                          <th>공시지가(원/m²)</th>
                          <td>
                            {center["sisul"]["pblntfPclnd"]
                              ? getLocalNumber(parseInt(center["sisul"]["pblntfPclnd"])) + " 원/m²"
                              : "정보 없음"}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Section>
                <Section>
                  <h3>건물 정보</h3>
                  <div>
                    <Table>
                      <tbody>
                        <tr>
                          <th>주용도</th>
                          <td>{getStringValue(center["sisul"]["mainPurpsCdNm"])}</td>
                        </tr>
                        <tr>
                          <th>신축년도</th>
                          <td>
                            {center["sisul"]["useAprDay"]
                              ? `${center["sisul"]["useAprDay"].substring(0, 4)}년 ${center["sisul"]["useAprDay"].substring(
                                  4,
                                  6
                                )}월 ${center["sisul"]["useAprDay"].substring(6, 8)}일`
                              : "정보 없음"}
                          </td>
                        </tr>
                        <tr>
                          <th>건폐율</th>
                          <td>{getStringValue(center["sisul"]["bcRat"], " %")}</td>
                        </tr>
                        <tr>
                          <th>용적률</th>
                          <td>{getStringValue(center["sisul"]["vlRat"], " %")}</td>
                        </tr>
                        <tr>
                          <th>건축물 연면적</th>
                          <td>
                            {getStringValue(center["sisul"]["totArea"], " m²")}
                            <button className={`sync ${pyeongs[1] ? "on" : ""}`} onClick={() => togglePyeong(1)}>
                              {pyeongs[1] ? "m²" : "평"}
                            </button>
                            <span className="pyeong">
                              {center["sisul"]["totArea"] ? getPyeong(center["sisul"]["totArea"]) : "정보 없음"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>층 면적</th>
                          <td>
                            {getStringValue(center["sisul"]["area"], " m²")}
                            <button className={`sync ${pyeongs[2] ? "on" : ""}`} onClick={() => togglePyeong(2)}>
                              {pyeongs[2] ? "m²" : "평"}
                            </button>
                            <span className="pyeong">
                              {center["sisul"]["area"] ? getPyeong(center["sisul"]["area"]) : "정보 없음"}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>규모</th>
                          <td>{getStringValue(center["sisul"]["flr"])}</td>
                        </tr>
                        <tr>
                          <th>주차대수</th>
                          <td>{center["sisul"]["totPkngCnt"] ? center["sisul"]["totPkngCnt"] + " 대" : "정보 없음"}</td>
                        </tr>
                        <tr>
                          <th>승강기</th>
                          <td>{getStringValue(center["sisul"]["elvt"])}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Section>
              </TabCont>
            </div>
          </Contents>

          <Contact className={isMaemul ? "primary" : ""} onClick={() => onContactClick()}>
            문의하기
          </Contact>

          {/* {isDesktop ? (
            <Contact className={isMaemul ? "primary" : ""} onClick={() => onContactClick()}>
              문의하기
            </Contact>
          ) : (
            <div style={{ display: "flex", background: "#bd9369", flex: 1 }}>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  padding: 20,
                }}
                onClick={() => {}}>
                <p style={{ color: "#fff" }}>전화 상담</p>
              </div>

              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  padding: 20,
                }}
                onClick={() => {}}>
                <p style={{ color: "#fff" }}>문자 상담</p>
              </div>
            </div>
          )} */}
        </Center>
      )}
    </>
  );
};

export default CenterItem;
