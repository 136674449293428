import styled from 'styled-components';
import localIcon from '../../../assets/images/icon/localIcon.svg';
import { isDesktop } from '../../../utils/detect';

export const AutoSearchForm = styled.form`
	background-color: #fff;
	z-index: 19;
	position: absolute;
	border: 0;
	box-shadow: 1px -1px 10px 1px rgb(0 0 0 / 4%);
	overflow-y: ${(props) => (props.type === 'sub' ? 'scroll' : 'auto')};
	z-index: 100;
	top: ${(props) => (props.type === 'sub' ? '88px' : isDesktop ? '100px ' : '62px')};
	left: ${(props) => (props.type === 'sub' ? '-24px' : '0')};
	width: ${(props) => (props.type === 'sub' ? 'calc(100% + 48px)' : '100%')};
	height: ${(props) =>
		props.active
			? props.type === 'sub'
				? isDesktop
					? 'calc(100vh - 224px)'
					: 'calc(100vh - 146px)'
				: isDesktop
				? 'calc(100% - 100px)'
				: 'calc(100vh - 62px)'
			: '0'};

	transition: height 0.1s ease-in;

	.container {
		margin-bottom: 15px;
	}

	div p {
		margin: 15px 10px;
		font-size: 14px;
	}

	.itemBox {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 0;
		border-bottom: 1px solid #dbdbdb;
		padding: 15px 10px;
	}

	.localFilterBtn {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.localFilterBtn span {
		font-size: ${(props) => props.theme.fontSizes.s};
		font-weight: 400;
	}

	.localFilterBtn strong {
		font-weight: bold;
		color: ${(props) => props.theme.colors.blue};
	}

	.searchFilterBtn {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.searchFilterBtn .itemContent {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}

	.searchFilterBtn .itemContent span {
		font-size: ${(props) => props.theme.fontSizes.s};
		font-weight: 400;
		color: #8e8e93;
		line-height: 22px;
	}

	.searchFilterBtn .itemContent span:first-child {
		font-weight: bold;
		color: #333;
	}

	.searchFilterBtn .itemContent strong {
		font-weight: bold;
		color: ${(props) => props.theme.colors.blue};
	}

	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background-color: #dbdbdb;
		border-radius: 10px;
		background-clip: padding-box;
		border: 2px solid transparent;
	}
	::-webkit-scrollbar-track {
		background-color: #fbfbfb;
		border-radius: 10px;
		box-shadow: inset 0px 0px 5px white;
	}

	.status-box {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 100%;
		height: 100%;
		font-size: ${(props) => props.theme.fontSizes.s};
		color: ${(props) => props.theme.colors.gray600};
	}
`;

export const LocalSvg = styled.div`
	background: url(${localIcon}) center no-repeat;
	width: 22px;
	height: 22px;
	display: inline-block;
	margin-right: 10px;
`;
