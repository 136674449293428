export function isEmptyObj(obj)  {
    const arr = Object.keys(obj);
    const length = arr.length;
    let emptyLength = 0;

    if (obj.constructor !== Object)  {
        return false;
    }
    if (length === 0) {
        return false;
    }
    arr.forEach(key => {
        if (!obj[key]) emptyLength++;
    })
    if (emptyLength === length) {
        return false;
    }
    return true;
}