import api from '.';

const MAP_URL = {
  base: '/searchAreaMap',
  infoWindow: '/briefContent',
};

/*  마커 데이터  */
export const getMapMarkers = async option => await api.post(MAP_URL.base, option);

/*  인포 윈도우  */
export const getMapInfoWindow = async option =>
  await api.get(MAP_URL.infoWindow, { params: option });
