import React, { useEffect } from "react";

import { USER_AUTH } from "./utils/user";
import {useDispatch, useSelector} from "react-redux";
import { setLoggedIn } from "./store/actions/user";
import { Routes, Route } from "react-router-dom";
import CenterListContainer from "./containers/Center/CenterListContainer";
import CenterItemContainer from "./containers/Center/CenterItemContainer";
import BonoListContainer from "./containers/BonoList/BonoListConatiner";
import TermsContainer from "./containers/Terrms/TermsContainer";
import ErrorPage from "./components/ui/ErrorPage/ErrorPage";
import MainView from "./view/MainView";
import {activateLanding, deactivateLanding} from "./store/actions/landing";

const App = () => {
    const dispatch = useDispatch();
    const USER = USER_AUTH.get();
    if (USER) dispatch(setLoggedIn(USER));

    const IS_LANDING = useSelector(state => state.Landing.landing);

    useEffect(() => {
        dispatch(IS_LANDING ? activateLanding() : deactivateLanding());
    }, [dispatch]);

    return (
        <Routes>
            <Route element={<MainView />}>
                <Route path="/" element={<CenterListContainer />} />
                <Route exact path="/bono/:category" element={<BonoListContainer />} />
                <Route exact path="/center/:id" element={<CenterItemContainer />} />
            </Route>

            <Route exact path="/terms" element={<TermsContainer />} />
            <Route exact path="/terms/:category" element={<TermsContainer />} />
            <Route path="/*" element={<ErrorPage></ErrorPage>}></Route>
        </Routes>
    );
};

export default App;
