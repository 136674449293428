import styled from 'styled-components';
import searchIcon from '../../../assets/images/icon/ico-search.svg';
import symbolIcon from '../../../assets/images/logo/ico-symbol.svg';

export const AddressForm = styled.form`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 56px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.06);

  input {
    display: block;
    width: 100%;
  }

  button[type='submit'] {
    width: 26px;
    height: 26px;
    font-size: 0;
    background: url(${searchIcon}) center no-repeat;
  }

  .mobile & {
    height: 40px;
    padding: 0 16px 0 42px;

    &.main {
      z-index: 20;
      position: fixed;
      top: 22px;
      left: 14px;
      width: calc(100% - 28px);
      border-radius: 2px;

      input::placeholder {
        font-size: 12px;
      }
    }
  }

  &.main {
    width: 100%;
    border-radius: 2px;
  }
`;

export const Home = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border: 0;
  background: url(${symbolIcon}) center no-repeat #fff;
`;

// 시설|매물 검색 박스 주석처리
// export const SisulMaemulForm = styled.ul`
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
//   height: 42px;
//   overflow: hidden;

//   .mobile & {
//     z-index: 21;
//     position: fixed;
//     top: 0;
//     left: 0;
//     top: 10px;
//     left: 14px;
//     width: calc(100% - 28px);
//     height: 32px;
//     border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
//     border-radius: 6px 6px 0 0;
//   }
// `;

// export const SisulMemulBox = styled.li`
//   position: relative;
//   flex: 1;
//   height: 100%;

//   input {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 0;
//     height: 0;
//     opacity: 0;
//   }

//   label {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%;
//     height: 100%;
//     background: white;
//     cursor: pointer;
//     font-size: ${({ theme }) => theme.fontSizes.s};
//     transition: all 0.2s ease-in-out;

//     .mobile & {
//       font-size: ${({ theme }) => theme.fontSizes.xs};
//     }
//   }

//   input:checked + label {
//     background-color: ${(props) => (props.maemul ? props.theme.colors.primary : props.theme.colors.secondary)};
//     color: white;
//     border-left: 0;
//   }
// `;
