import { getLocalNumber } from "../../utils/number";

export const BONOSCORE = {
    labels: ["시설평가 등급", "가산현황", "관리상태"]
}

export const GET_MARKETS = mkData => [
    {
        title: "고령인구",
        chartTitle: { 
            main: '총', 
            sub: `${getLocalNumber(mkData.highAgeManCnt + mkData.highAgeWomanCnt)}명` 
        },
        customDataset: [
            mkData.highAgeManCnt + mkData.highAgeWomanCnt, 
            mkData.highAgeManCnt,
            mkData.highAgeWomanCnt
        ],
        customLabels: [
            { color: '#7BF5BB', text: `여자 ${getLocalNumber(mkData.highAgeWomanCnt)}명`},
            { color: '#E4B251', text: `남자 ${getLocalNumber(mkData.highAgeManCnt)}명`},
        ], 
        inform: [' 65세 이상 인구수']
    },
    {
        title: "등급인원",
        chartTitle:{ 
            main: '총', 
            sub: `${getLocalNumber(mkData.ratingManCnt + mkData.ratingWomanCnt)}명` 
        },
        customDataset: [
            mkData.ratingManCnt + mkData.ratingWomanCnt, 
            mkData.ratingManCnt,
            mkData.ratingWomanCnt
        ],
        customLabels: [
            { color: '#7BF5BB', text: `여자 ${getLocalNumber(mkData.ratingWomanCnt)}명` },
            { color: '#E4B251', text: `남자 ${getLocalNumber(mkData.ratingManCnt)}명` },
        ],
        inform: ['장기요양등급 1~5등급,', ' 인지지원등급, 등급 외 판정 인원']
    },
    {
        title: "요양시설",
        chartTitle: { 
            main: `총 ${getLocalNumber(mkData.onlyTotal + mkData.mallTotal + mkData.centerTotal + mkData.visitTotal + mkData.anymoreTotal)}개`, 
            sub: `총 ${getLocalNumber(mkData.totalPer)}명` 
        },
        customDataset: [
            (mkData.onlyTotal + mkData.mallTotal + mkData.centerTotal + mkData.visitTotal + mkData.anymoreTotal),
            mkData.centerTotal, 
            (mkData.onlyTotal + mkData.mallTotal),  
            mkData.visitTotal,
            mkData.anymoreTotal
        ],
        customLabels: [
            { color: '#7BF5BB', text: `요양시설 ${getLocalNumber(mkData.onlyTotal + mkData.mallTotal)}개` },
            { color: '#E4B251', text: `주야간 ${getLocalNumber(mkData.centerTotal)}개` },
            { color: '#616161', text: `공생 ${getLocalNumber(mkData.anymoreTotal)}개` },
            { color: '#E98686', text: `방문 ${getLocalNumber(mkData.visitTotal)}개` },
        ],
        inform: ['단독&상가 요양원,', '주야간보호, 방문요양, 공생 시설수']
    },
    {
        title: "이용율",
        chartTitle:{ 
            main: '이용율', 
            sub: `${getLocalNumber(mkData.usePercent)}%` 
        },
        customDataset: [
            mkData.totalPer,
            mkData.currentPer,
        ],
        customLabels: [
            { color: '#E4B251', text: `인원 ${getLocalNumber(mkData.currentPer)}명` },
            { color: '#fff', text: `정원 ${getLocalNumber(mkData.totalPer)}명` }
        ],
        inform: ['시설 정원 대비 현원수'],
        source: '출처: 통계청/건강보험공단'
    }
]