import styled, { keyframes } from 'styled-components';
import { module } from '../../themes/module';
import dropdownIcon from '../../assets/images/icon/ico-accordion.svg';

const fadeIn = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;

export const Charts = styled.div`
  z-index: 12;
  position: fixed;
  bottom: -350px;
  right: 30px;
  transition: bottom 0.5s ease-in-out;
  animation: ${fadeIn} 0.4s;

  &.on {
    bottom: 30px;

    .mobile & {
      bottom: 56px;

      &.p-up {
        bottom: 56px;
      }
    }
  }

  .mobile & {
    z-index: 30;
    right: 0;
    width: 100%;
    bottom: calc(140px - 100%);
    height: calc(100% - 84px);

    &.p-up {
      bottom: calc(180px - 100%);
    }
  }
`;

export const Wrapper = styled(module.scrollWrapper)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  gap: 0;
  background: transparent;
  border-radius: 0 20px 20px 20px;
  background-color: rgba(0, 0, 54, 0.9);

  .mobile & {
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }

  .inform {
    position: absolute;
    bottom: 8px;
    right: 20px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: rgba(228, 178, 81, 0.7);
  }

  .chart-region {
    padding: 20px 0 10px;
    text-align: center;
    color: white;
    font-size: ${({ theme }) => theme.fontSizes.l};
  }
`;

export const Region = styled.div`
  position: absolute;
  top: -34px;
  left: 0;
  width: auto;
  min-width: 200px;
  height: 34px;
  line-height: 34px;
  padding: 0 48px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.s};
  border-radius: 20px 20px 0 0;
  background-color: rgba(0, 0, 54, 0.9);
  color: #fff;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0;
    right: 14px;
    width: 34px;
    height: 34px;
    content: '';
    background: url(${dropdownIcon}) center no-repeat;
    transform: rotate(180deg);
    transition: transform 0.4s;
  }

  .on & {
    &::after {
      transform: rotate(0);
    }
  }

  .mobile & {
    top: -28px;
    left: calc(50% - 90px);
    width: 180px;
    min-width: 180px;
    height: 28px;
    line-height: 28px;
    font-size: 13px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  }
`;
