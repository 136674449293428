import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal/Modal";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { deactivateQna } from "../../store/actions/service";
import { isDesktop } from "../../utils/detect";

import Frame2093 from "../../assets/images/landing/Frame2093.png";
import Frame2092 from "../../assets/images/landing/Frame2092.png";
import { getQnas } from "../../api/landing";

const ContentContainer = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  height: 400px;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const Row = styled.div(({ marginTop }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop
}));

const Text = styled.p(({ marginLeft, marginTop }) => ({
  fontSize: 15,
  color: "#616161",
  fontWeight: 400,
  marginLeft,
  marginTop,
}));

const Block = styled.div({
  background: "#EEEEEE",
  padding: 10,
  alignItems: "center",
  display: "flex",
  marginTop: 10,
});

const QnaComponent = ({ marginTop, title, text, onClick, isActive }) => {
  return (
    <>
      <Row marginTop={marginTop}>
        <Text>{title}</Text>

        <img
          style={{ width: 24, height: 24, cursor: "pointer" }}
          src={isActive ? Frame2092 : Frame2093}
          alt="Frame2093"
          onClick={onClick}
        />
      </Row>

      {isActive ? (
        <Block>
          <Text marginLeft="4px">{text}</Text>
        </Block>
      ) : null}
    </>
  );
};

const QnaModal = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await getQnas();

      const res = data.result.map((entitiy, index) => ({
        ...entitiy,
        isActive: false,
      }));

      setData(res);
    })();
  }, []);

  const onClick = param => () =>
    setData(
      data?.map(qna => ({
        ...qna,
        isActive: qna.id === param.id ? !param.isActive : false,
      }))
    );

  return (
    <Modal
      width={isDesktop ? "800" : "300"}
      close={true}
      title="보노랜드 FAQ"
      onCloseClick={() => {
        dispatch(deactivateQna());
      }}>
      <ContentContainer>
        {data?.map((qna, index) => (
          <QnaComponent
            key={index}
            marginTop={isDesktop ? index && 20 : 20}
            title={qna.qnaTitle}
            text={qna.qnaContent}
            onClick={onClick(qna)}
            isActive={qna.isActive}
          />
        ))}
      </ContentContainer>
    </Modal>
  );
};

export default QnaModal;
