import React, { useState, useEffect, useRef } from 'react';
import {
  Wrapper,
  AddsForm,
  ResetIcon,
  AddsCalcForm,
  Caption,
  CalcHeader,
  ResultAbstract,
  Unit,
  LegendInform,
} from '../CalculatorStyle';
import { isDesktop } from '../../../utils/detect';
import {
  ADDITIONAL_LABOUR,
  BASIC_FORM,
  getAdditionalLabour,
  GRADES_AND_PRICE,
  PAY_AND_TIMES,
  PAYMENT,
  getCommonPayment,
  getDayCarePayment,
  ADD_STATE,
  SERVICE_COUNT,
  getAddState,
  getAddResult,
} from '../../../scheme/calculator/adds';
import Modal from '../../Modal/Modal';
import { getLocalNumber, getNumber, getCalcNumber } from '../../../utils/number';

// only number 처리
const handleStringNum = event => {
  const value = Number(getNumber(event.target.value));
  if (isNaN(value)) {
    event.preventDefault();
    return null;
  }
  return value;
};

// 입력창 number 처리
const getInputNum = value => (value === '' ? value : getLocalNumber(value));

// 정원/현원 입력 오류 알림 처리
const getBaseInfoWarning = baseInfo => {
  let warning;
  const capacity = baseInfo.capacity;
  const totalCapacity = baseInfo.totalCapacity;
  const payDates = baseInfo.payDates;

  if (capacity === '') {
    warning = '입소자 정원을 입력해 주세요.';
  } else if (capacity < 1) {
    warning = '입소자 정원은 1 명 이하일 수 없습니다.';
  } else if (capacity > 200) {
    warning = '입소자 정원은 200명을 초과할 수 없습니다.';
  } else if (capacity < totalCapacity) {
    warning = '입소자 평균 현원이 정원을 초과합니다.';
  } else if (totalCapacity > 200) {
    warning = '입소자 평균 현원은 200명을 초과할 수 없습니다.';
  } else if (payDates === '') {
    warning = '급여 일수를 입력해 주세요.';
  } else if (payDates <= 0) {
    warning = '급여 일수는 1일 이하일 수 없습니다.';
  } else {
    warning = '';
  }
  return warning;
};

const getAddStateWarning = addLabour => {
  const nightLabour = addLabour.nightLabourNight;
  let warning;
  if (nightLabour === '') {
    warning = '야간직원배치의 야간인력을 입력해 주세요.';
  } else if (getCalcNumber(nightLabour) <= 0) {
    warning = '야간직원배치의 야간인력은 1명 미만일 수 없습니다.';
  } else {
    warning = '';
  }
  return warning;
};

const AddsCalculator = () => {
  const [result, setResult] = useState(null);
  const [resultShow, setResultShow] = useState(false);
  const [inputOn, setInputOn] = useState(true);
  const [type, setType] = useState('단독요양원');

  const [baseInfo, setBaseInfo] = useState(JSON.parse(JSON.stringify(BASIC_FORM)));
  const [payInfo, setPayInfo] = useState(JSON.parse(JSON.stringify(GRADES_AND_PRICE)));
  const [payTimes, setPayTimes] = useState(JSON.parse(JSON.stringify(PAY_AND_TIMES)));
  const [addLabour, setAddLabour] = useState(JSON.parse(JSON.stringify(ADDITIONAL_LABOUR)));
  const [addState, setAddState] = useState(JSON.parse(JSON.stringify(ADD_STATE)));

  const [warning, setWarning] = useState({ addState: '', baseInfo: '' });
  const [warnAlert, setWarnAlert] = useState('');

  const boxRef = useRef(null);

  const onSubmitClick = e => {
    e.preventDefault();

    // const addStateWarn = getAddStateWarning(addState);
    const addStateWarn = type === '주야간보호센터' ? '' : getAddStateWarning(addState);
    const baseInfoWarn = getBaseInfoWarning(baseInfo);
    setWarning({ addState: addStateWarn, baseInfo: baseInfoWarn });

    if (baseInfoWarn.length) {
      activateAlertWarning(`기본 정보를 확인해 주세요. ${baseInfoWarn}`);
    } else if (addStateWarn.length > 0) {
      activateAlertWarning(`가산현황을 확인해 주세요. ${addStateWarn}`);
    } else {
      setResult(getAddResult(baseInfo, addLabour, addState));
      setInputOn(false);
    }
  };

  const onResetClick = e => {
    e.preventDefault();
    setType('단독요양원');
    setBaseInfo(JSON.parse(JSON.stringify(BASIC_FORM)));
    setPayInfo(JSON.parse(JSON.stringify(GRADES_AND_PRICE)));
    setPayTimes(JSON.parse(JSON.stringify(PAY_AND_TIMES)));
    setAddLabour(JSON.parse(JSON.stringify(ADDITIONAL_LABOUR)));
    setAddState(JSON.parse(JSON.stringify(ADD_STATE)));

    setResult(null);
    setResultShow(false);
    setInputOn(true);

    setWarning({ addState: '', baseInfo: '' });
    setWarnAlert('');
  };

  // alert 경고 처리
  const activateAlertWarning = warning => {
    if (isDesktop) {
      setWarnAlert(warning);
    } else {
      alert(warning);
    }
  };

  // 기본정보 개별입력
  const handleBaseInfo = (event, key) => {
    const value = handleStringNum(event);

    if (value !== null) {
      const nBaseInfo = { ...baseInfo, [key]: value };
      setBaseInfo(nBaseInfo);
      if (key === 'capacity' || key === 'payDates') {
        setWarning(warning => ({ ...warning, baseInfo: getBaseInfoWarning(nBaseInfo) }));
      }
    }
  };

  // 급여 정보 개별 입력
  const handlePayInfo = (event, index) => {
    // 급여정보 인원수 입소자 정원보다 적도록 설정
    let value = handleStringNum(event);
    if (value !== null) {
      const nPayInfo = [...payInfo];
      nPayInfo[index].count = value;
      nPayInfo[index].pay = value * PAYMENT['common'][index] * baseInfo.payDates;
      setPayInfo(nPayInfo);
    }
  };

  // 급여시간 개별 입력
  const handlePayTime = (event, groupIndex, itemIndex) => {
    const value = handleStringNum(event);
    if (value !== null) {
      const nPayTimes = [...payTimes];
      const nTotal =
        nPayTimes[groupIndex]['total'] + (value - nPayTimes[groupIndex]['counts'][itemIndex]);
      nPayTimes[groupIndex]['counts'][itemIndex] = value;
      nPayTimes[groupIndex]['total'] = nTotal;

      const nPayInfo = [...payInfo];
      nPayInfo[groupIndex].count = nTotal;
      nPayInfo[groupIndex].pay =
        nPayTimes[groupIndex]['counts'].reduce(
          (acc, item, idx) => acc + item * PAYMENT['dayCare'][groupIndex][idx],
          0,
        ) * baseInfo.payDates;

      setPayTimes(nPayTimes);
      setPayInfo(nPayInfo);
    }
  };

  // 추가 인력 배치 개별 입력
  const handleAddLabour = (event, addIndex) => {
    const value = handleStringNum(event);
    if (value !== null) {
      const nAddLabour = [...addLabour];
      addLabour[addIndex].working = value;
      const resAddLabour = getAdditionalLabour(baseInfo.type, baseInfo.totalCapacity, nAddLabour);
      setAddLabour(resAddLabour);
    }
  };

  // 가산 현황 개별 입력
  const handleAddState = (event, stateKey) => {
    const value = handleStringNum(event);

    if (value !== null) {
      const nAddState = getAddState(baseInfo.totalCapacity, addLabour, {
        ...addState,
        [stateKey]: value,
      });
      setAddState(nAddState);
      if (stateKey === 'nightLabourNight') {
        setWarning(warning => ({ ...warning, addState: getAddStateWarning(nAddState) }));
      }
    }
  };

  useEffect(() => {
    // 단독, 상가 급여정보 변경시 기본정보 재계산
    const totalCapacity = payInfo.reduce((acc, item) => acc + getCalcNumber(item.count), 0);
    const totalPay = payInfo.reduce((acc, item) => acc + item.pay, 0);
    const basePrice =
      totalPay > 0
        ? parseInt(
            totalPay * (baseInfo.type === '주야간보호센터' ? 0.85 : totalCapacity < 10 ? 0 : 0.8),
          )
        : 0;
    setBaseInfo(baseInfo => ({ ...baseInfo, totalCapacity: totalCapacity, basePrice: basePrice }));
  }, [payInfo]);

  useEffect(() => {
    // 추가인력배치 변동시 가산현황 재계산
    setAddState(getAddState(baseInfo.totalCapacity, addLabour, addState));
  }, [addLabour]);

  useEffect(() => {
    // 급여일수 변동시 급여정보 재계산
    if (type !== '주야간보호센터') setPayInfo(getCommonPayment(baseInfo.payDates, payInfo));
    else setPayInfo(getDayCarePayment(baseInfo.payDates, payInfo, payTimes));
  }, [baseInfo.payDates]);

  useEffect(() => {
    // 급여 유형 변동시 급여정보 초기화
    setType(baseInfo.type);
    setPayTimes(JSON.parse(JSON.stringify(PAY_AND_TIMES)));
    if (baseInfo.type === '주야간보호센터') {
      setPayInfo(JSON.parse(JSON.stringify(GRADES_AND_PRICE)).map(item => ({ ...item, count: 0 })));
      setAddState(
        getAddState(baseInfo.totalCapacity, addLabour, {
          ...addState,
          nightLabourDay: '',
          nightLabourNight: '',
        }),
      );
    } else {
      setPayInfo(JSON.parse(JSON.stringify(GRADES_AND_PRICE)));
    }
  }, [baseInfo.type]);

  useEffect(() => {
    // 입소자 현원 변동시 추가인력배치 재계산
    setAddLabour(getAdditionalLabour(baseInfo.type, baseInfo.totalCapacity, addLabour)); // 추가인력배치 재계산
    if (baseInfo.totalCapacity > 0)
      setWarning(warning => ({ ...warning, baseInfo: getBaseInfoWarning(baseInfo) }));
  }, [baseInfo.totalCapacity]);

  useEffect(() => {
    if (resultShow || result) {
      const rect = boxRef.current.children[0].getBoundingClientRect();
      boxRef.current.scrollTo({
        top: rect.height,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [resultShow, result]);

  return (
    <Wrapper ref={boxRef}>
      <div className="wrap">
        {isDesktop && (
          <AddsForm
            className="adds"
            onSubmit={e => {
              onSubmitClick(e);
            }}
            onReset={e => {
              onResetClick(e);
            }}
          >
            <fieldset>
              <legend>기본 정보</legend>
              <table>
                <colgroup>
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <label htmlFor="bc1">급여 유형</label>
                    </th>
                    <th>
                      <label htmlFor="bc2">입소자 정원</label>
                    </th>
                    <th>
                      <label htmlFor="bc3">입소자 평균 현원</label>
                    </th>
                    <th>
                      <label htmlFor="bc4">산정 기간</label>
                    </th>
                    <th>
                      <label htmlFor="bc5">급여 일수</label>
                    </th>
                    <th className="clr-accent">
                      <label htmlFor="bc6">가산 기준 금액</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select
                        id="bc1"
                        name="bc1"
                        value={type}
                        onChange={e => {
                          setBaseInfo(baseInfo => ({ ...baseInfo, type: e.target.value }));
                        }}
                        disabled={!inputOn}
                      >
                        <option value="단독요양원">단독요양원</option>
                        <option value="상가요양원">상가요양원</option>
                        <option value="주야간보호센터">주야간보호센터</option>
                      </select>
                    </td>
                    <td>
                      <input
                        id="bc2"
                        name="bc2"
                        type="text"
                        value={getInputNum(baseInfo.capacity)}
                        onChange={event => {
                          handleBaseInfo(event, 'capacity');
                        }}
                        placeholder="숫자입력"
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <input
                        id="bc3"
                        name="bc3"
                        type="text"
                        value={getLocalNumber(baseInfo.totalCapacity)}
                        readOnly={true}
                      />
                    </td>
                    <td>
                      <input
                        id="bc4"
                        name="bc4"
                        type="text"
                        value={getInputNum(baseInfo.duration)}
                        onChange={event => {
                          handleBaseInfo(event, 'duration');
                        }}
                        placeholder="숫자입력"
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <input
                        id="bc5"
                        name="bc5"
                        type="text"
                        value={getInputNum(baseInfo.payDates)}
                        onChange={event => {
                          handleBaseInfo(event, 'payDates');
                        }}
                        placeholder="숫자입력"
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <Unit>원</Unit>
                      <input
                        id="bc6"
                        name="bc6"
                        className="fw-b"
                        type="text"
                        value={getLocalNumber(baseInfo.basePrice)}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {warning.baseInfo.length > 0 && <div className="warn">{warning.baseInfo}</div>}
            </fieldset>
            {baseInfo.type === '주야간보호센터' && (
              <fieldset>
                <legend>
                  급여 시간 (등급 및 유형별)
                  <LegendInform>
                    <strong>보호센터 유형별</strong> 인원을 입력해 주세요
                  </LegendInform>
                </legend>
                <table>
                  <colgroup>
                    <col width="16.6%" />
                    <col width="16.6%" />
                    <col width="16.6%" />
                    <col width="16.6%" />
                    <col width="16.6%" />
                    <col width="16.6%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <label htmlFor="bc7">주간보호센터 유형</label>
                      </th>
                      <th className="fw-b">
                        <span>라-1</span>
                      </th>
                      <th className="fw-b">
                        <span>라-2</span>
                      </th>
                      <th className="fw-b">
                        <span>라-3</span>
                      </th>
                      <th className="fw-b">
                        <span>라-4</span>
                      </th>
                      <th className="fw-b">
                        <span>라-5</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="fs-s clr-black">시간</span>
                      </td>
                      <td>
                        <span className="fs-s clr-black">3시간 ~ 6시간 미만</span>
                      </td>
                      <td>
                        <span className="fs-s clr-black">6시간 ~ 8시간 미만</span>
                      </td>
                      <td>
                        <span className="fs-s clr-black">8시간 ~ 10시간 미만</span>
                      </td>
                      <td>
                        <span className="fs-s clr-black">10시간 ~ 13시간 이하</span>
                      </td>
                      <td>
                        <span className="fs-s clr-black">13시간 초과</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {payTimes.map((payTime, idx) => {
                  const index = 13 + payTimes.length * idx;
                  return (
                    <table key={idx}>
                      <colgroup>
                        <col width="16.6%" />
                        <col width="16.6%" />
                        <col width="16.6%" />
                        <col width="16.6%" />
                        <col width="16.6%" />
                        <col width="16.6%" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>
                            <label htmlFor={`bc${index}`}>{payTime.label} 소계</label>
                          </th>
                          {payTime.counts.map((item, pIdx) => (
                            <th key={pIdx}>
                              <label htmlFor={`bc${index + pIdx + 1}`}>라-{pIdx + 1}</label>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Unit>명</Unit>
                            <input
                              id={`bc${index}`}
                              name={`bc${index}`}
                              type="text"
                              value={getLocalNumber(payTime.total)}
                              readOnly={true}
                            />
                          </td>
                          {payTime.counts.map((item, pIdx) => (
                            <td key={pIdx}>
                              <input
                                id={`bc${index + pIdx + 1}`}
                                name={`bc${index + pIdx + 1}`}
                                type="text"
                                value={getInputNum(payTime.counts[pIdx])}
                                placeholder="숫자입력"
                                readOnly={!inputOn}
                                onChange={event => {
                                  handlePayTime(event, idx, pIdx);
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </fieldset>
            )}
            <fieldset>
              <legend>
                급여 정보
                {type === '주야간보호센터' && (
                  <LegendInform>
                    <strong>주야간 보호센터</strong>는 위 <strong>급여 시간(등급 및 유형별)</strong>{' '}
                    에서 인원을 입력해 주세요
                  </LegendInform>
                )}
              </legend>
              <table>
                <colgroup>
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <label htmlFor="bc7">1등급 인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc8">2등급 인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc9">3등급 인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc10">4등급 인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc11">5등급 인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc12">인지지원등급 인원</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {payInfo.map((item, idx) => {
                      const isDayCare = type === '주야간보호센터';
                      const index = 'bc' + 7 + idx;

                      return (
                        <td key={idx}>
                          {isDayCare && <Unit>명</Unit>}
                          <input
                            id={index}
                            name={index}
                            type="text"
                            value={isDayCare ? payInfo[idx].count : getInputNum(payInfo[idx].count)}
                            onChange={event => {
                              handlePayInfo(event, idx);
                            }}
                            readOnly={isDayCare || !inputOn}
                            placeholder="숫자입력"
                          />
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    {payInfo.map((item, idx) => {
                      const index = 'bc' + 7 + idx + '-cost';

                      return (
                        <td key={idx}>
                          <Unit>원</Unit>
                          <input
                            id={index}
                            name={index}
                            type="text"
                            value={getLocalNumber(payInfo[idx].pay)}
                            readOnly={true}
                          />
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <fieldset>
              <legend>추가 인력 배치</legend>
              <table>
                <colgroup>
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                  <col width="16.6%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>
                      <label htmlFor="bc50">직종</label>
                    </th>
                    <th>
                      <label htmlFor="bc51">의무인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc52">근무인원</label>
                    </th>
                    <th>
                      <label htmlFor="bc53">추가인원 </label>
                    </th>
                    <th>
                      <label htmlFor="bc54">1인당 입소자</label>
                    </th>
                    <th className="clr-accent">
                      <label htmlFor="bc55">가산 점수</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {addLabour.map((item, idx) => {
                    const index = 50 + 5 * idx;
                    return (
                      <tr key={idx}>
                        <td>
                          <span>{item.label}</span>
                        </td>
                        <td>
                          <Unit>명</Unit>
                          <input
                            id={index + 0}
                            name={index + 0}
                            type="text"
                            value={getLocalNumber(item.required)}
                            readOnly={true}
                          />
                        </td>
                        <td>
                          {idx === 5 && <Unit>명</Unit>}
                          <input
                            id={index + 1}
                            name={index + 1}
                            type="text"
                            value={getInputNum(item.working)}
                            readOnly={idx >= addLabour.length - 1 || !inputOn}
                            onChange={event => {
                              handleAddLabour(event, idx);
                            }}
                            placeholder={idx >= addLabour.length - 1 ? '' : '숫자입력'}
                          />
                        </td>
                        <td>
                          <Unit>명</Unit>
                          <input
                            id={index + 2}
                            name={index + 2}
                            type="text"
                            value={getLocalNumber(item.additional)}
                            readOnly={true}
                          />
                        </td>
                        <td>
                          {idx < 2 ? <Unit>명</Unit> : ''}
                          <input
                            id={index + 3}
                            name={index + 3}
                            type="text"
                            value={idx < 2 ? getLocalNumber(item.perOne) : ''}
                            readOnly={true}
                          />
                        </td>
                        <td>
                          <Unit>점</Unit>
                          <input
                            id={index + 4}
                            name={index + 4}
                            type="text"
                            value={item.score}
                            readOnly={true}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </fieldset>
            <fieldset>
              <legend>가산 현황</legend>
              <table>
                <colgroup>
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="7.32%" />
                  <col width="7.32%" />
                  <col width="7.32%" />
                  <col width="7.32%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="3%" />
                  <col width="6.64%" />
                  <col width="6.64%" />
                  <col width="4%" />
                  <col width="4%" />
                  <col width="4.32%" />
                  <col width="4.32%" />
                  <col width="4.32%" />
                  <col width="4.32%" />
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan="4" rowSpan="2">
                      <label htmlFor="bc80">필수 인력 배치</label>
                    </th>
                    <th colSpan="4">
                      <label htmlFor="bc81">추가 인력 배치</label>
                    </th>
                    <th colSpan="4">
                      <label htmlFor="bc82">야간 직원 배치</label>
                    </th>
                    <th colSpan="4">
                      <label htmlFor="bc83">간호사 배치</label>
                    </th>
                    <th colSpan="4">
                      <label htmlFor="bc84">맞춤형 서비스 제공</label>
                    </th>
                    <th colSpan="4" rowSpan="2" className="clr-accent">
                      <label htmlFor="bc85">가산 총점</label>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan="4">
                      <label>가산 점수</label>
                    </th>
                    <th colSpan="1">
                      <label className="fs-s" htmlFor="bc87">
                        주간인력
                      </label>
                    </th>
                    <th colSpan="1">
                      <label className="fs-s" htmlFor="bc88">
                        야간인력
                      </label>
                    </th>
                    <th colSpan="1">
                      <label className="fs-s" htmlFor="bc89">
                        인력비율
                      </label>
                    </th>
                    <th colSpan="1">
                      <label className="fs-s" htmlFor="bc90">
                        가산점수
                      </label>
                    </th>
                    <th colSpan="4">
                      <label htmlFor="bc91">가산점수</label>
                    </th>
                    <th colSpan="2">
                      <label htmlFor="bc92">횟수</label>
                    </th>
                    <th colSpan="2">
                      <label htmlFor="bc93">가산점수</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="4">
                      <input
                        id="bc80"
                        name="bc80"
                        type="text"
                        value={addState['requiredLabour']}
                        readOnly={true}
                      />
                    </td>
                    <td colSpan="4">
                      <Unit>점</Unit>
                      <input
                        id="bc81"
                        name="bc81"
                        type="text"
                        value={addState['addLabourScore']}
                        readOnly={true}
                      />
                    </td>
                    <td colSpan="1">
                      <input
                        id="bc87"
                        name="bc87"
                        type="text"
                        value={getInputNum(addState['nightLabourDay'])}
                        onChange={event => handleAddState(event, 'nightLabourDay')}
                        placeholder={type === '주야간보호센터' ? '-' : '입력'}
                        readOnly={!inputOn || type === '주야간보호센터'}
                        autoComplete="off"
                      />
                    </td>
                    <td colSpan="1">
                      <input
                        id="bc88"
                        name="bc88"
                        type="text"
                        value={getInputNum(addState['nightLabourNight'])}
                        onChange={event => handleAddState(event, 'nightLabourNight')}
                        placeholder={type === '주야간보호센터' ? '-' : '입력'}
                        readOnly={!inputOn || type === '주야간보호센터'}
                        autoComplete="off"
                      />
                    </td>
                    <td colSpan="1">
                      <input
                        id="bc89"
                        name="bc89"
                        type="text"
                        value={type === '주야간보호센터' ? '-' : addState['nightLabourRate']}
                        readOnly={true}
                      />
                    </td>
                    <td colSpan="1">
                      <input
                        id="bc90"
                        name="bc90"
                        type="text"
                        value={type === '주야간보호센터' ? '-' : addState['nightLabourScore']}
                        readOnly={true}
                      />
                    </td>
                    <td colSpan="4">
                      <Unit>점</Unit>
                      <input
                        id="bc91"
                        name="bc91"
                        type="text"
                        value={addState['nursingLabourScore']}
                        readOnly={true}
                      />
                    </td>
                    <td colSpan="2">
                      <select
                        id="bc92"
                        name="bc92"
                        value={addState['serviceCount']}
                        onChange={event => handleAddState(event, 'serviceCount')}
                        disabled={!inputOn}
                      >
                        {SERVICE_COUNT.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td colSpan="2">
                      <Unit>점</Unit>
                      <input
                        id="bc93"
                        name="bc93"
                        type="text"
                        value={addState['serviceScore']}
                        readOnly={true}
                      />
                    </td>
                    <td colSpan="4">
                      <Unit>점</Unit>
                      <input
                        id="bc85"
                        name="bc85"
                        type="text"
                        value={addState['totalScore']}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {warning.addState.length > 0 && <div className="warn">{warning.addState}</div>}
            </fieldset>
            <div className="actions">
              <button type="submit">계산하기</button>
              <button type="reset">초기화</button>
            </div>
          </AddsForm>
        )}
        {!isDesktop && (
          <AddsForm
            className="adds"
            onSubmit={e => {
              onSubmitClick(e);
            }}
            onReset={e => {
              onResetClick(e);
            }}
          >
            <fieldset>
              <Caption className="adds">기본 정보</Caption>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <label htmlFor="bc1">급여 유형</label>
                    </th>
                    <td>
                      <select
                        id="bc1"
                        name="bc1"
                        value={type}
                        onChange={e => {
                          setBaseInfo(baseInfo => ({ ...baseInfo, type: e.target.value }));
                        }}
                        disabled={!inputOn}
                      >
                        <option value="단독요양원">단독요양원</option>
                        <option value="상가요양원">상가요양원</option>
                        <option value="주야간보호센터">주야간보호센터</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc2">입소자 정원</label>
                    </th>
                    <td>
                      <input
                        id="bc2"
                        name="bc2"
                        type="text"
                        value={getInputNum(baseInfo.capacity)}
                        onChange={event => {
                          handleBaseInfo(event, 'capacity');
                        }}
                        placeholder="숫자입력"
                        readOnly={!inputOn}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc3">입소자 평균 현원</label>
                    </th>
                    <td>
                      <input
                        id="bc3"
                        name="bc3"
                        type="text"
                        value={getLocalNumber(baseInfo.totalCapacity)}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc4">산정 기간</label>
                    </th>
                    <td>
                      <input
                        id="bc4"
                        name="bc4"
                        type="text"
                        value={getInputNum(baseInfo.duration)}
                        onChange={event => {
                          handleBaseInfo(event, 'duration');
                        }}
                        placeholder="숫자입력"
                        readOnly={!inputOn}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc5">급여 일수</label>
                    </th>
                    <td>
                      <input
                        id="bc5"
                        name="bc5"
                        type="text"
                        value={getInputNum(baseInfo.payDates)}
                        onChange={event => {
                          handleBaseInfo(event, 'payDates');
                        }}
                        placeholder="숫자입력"
                        readOnly={!inputOn}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="clr-accent">
                      <label htmlFor="bc6">가산 기준 금액</label>
                    </th>
                    <td>
                      <Unit>원</Unit>
                      <input
                        id="bc6"
                        name="bc6"
                        className="fw-b"
                        type="text"
                        value={getLocalNumber(baseInfo.basePrice)}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {warning.baseInfo.length > 0 && <div className="warn">{warning.baseInfo}</div>}
            </fieldset>
            {baseInfo.type === '주야간보호센터' && (
              <fieldset className="rows">
                <Caption>급여시간 (등급 및 유형별)</Caption>
                <table>
                  <caption>주간보호센터 유형</caption>
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                    <col width="20%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="fw-b">
                        <span>라-1</span>
                      </th>
                      <th className="fw-b">
                        <span>라-2</span>
                      </th>
                      <th className="fw-b">
                        <span>라-3</span>
                      </th>
                      <th className="fw-b">
                        <span>라-4</span>
                      </th>
                      <th className="fw-b">
                        <span>라-5</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="fs-xs">
                          3시간 이상
                          <br />
                          6시간 미만
                        </span>
                      </td>
                      <td>
                        <span className="fs-xs">
                          6시간 이상
                          <br />
                          8시간 미만
                        </span>
                      </td>
                      <td>
                        <span className="fs-xs">
                          8시간 이상
                          <br />
                          10시간 미만
                        </span>
                      </td>
                      <td>
                        <span className="fs-xs">
                          10시간 이상
                          <br />
                          13시간 이하
                        </span>
                      </td>
                      <td>
                        <span className="fs-xs">13시간 초과</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {payTimes.map((payTime, idx) => {
                  const index = 13 + payTimes.length * idx;
                  return (
                    <table key={idx}>
                      <caption>
                        {idx + 1}등급 인원 (소계 : {getLocalNumber(payTime.total)}명)
                      </caption>
                      <colgroup>
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                        <col width="20%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          {payTime.counts.map((item, lIdx) => (
                            <th key={lIdx}>
                              <label htmlFor={`bc${index + lIdx + 1}`}>라-{lIdx + 1}</label>
                            </th>
                          ))}
                        </tr>
                        <tr>
                          {payTime.counts.map((item, iIdx) => (
                            <td key={iIdx}>
                              <input
                                id={`bc${index + iIdx + 1}`}
                                name={`bc${index + iIdx + 1}`}
                                type="text"
                                value={getInputNum(payTime.counts[iIdx])}
                                placeholder="입력"
                                readOnly={!inputOn}
                                onChange={event => {
                                  handlePayTime(event, idx, iIdx);
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </fieldset>
            )}
            <fieldset>
              <Caption className="adds">급여 정보</Caption>
              <table>
                <tbody>
                  {payInfo.map((item, idx) => {
                    const isDayCare = type === '주야간보호센터';
                    const index = 'bc' + 7 + idx;

                    return (
                      <tr key={idx}>
                        <th>
                          <label htmlFor="bc7">{item.label}</label>
                        </th>
                        <td>
                          {isDayCare && <Unit>명</Unit>}
                          <input
                            id={index}
                            name={index}
                            type="text"
                            value={isDayCare ? payInfo[idx].count : getInputNum(payInfo[idx].count)}
                            onChange={event => {
                              handlePayInfo(event, idx);
                            }}
                            readOnly={isDayCare || !inputOn}
                            placeholder="숫자입력"
                          />
                        </td>
                        <td>
                          <Unit>원</Unit>
                          <input
                            id={index + '-cost'}
                            name={index + '-cost'}
                            type="text"
                            value={getLocalNumber(payInfo[idx].pay)}
                            readOnly={true}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </fieldset>
            <fieldset className="rows">
              <Caption>추가 인력 배치</Caption>
              {addLabour.map((item, idx) => {
                const index = 50 + 5 * idx;
                return (
                  <table key={idx}>
                    <caption>{item.label}</caption>
                    <colgroup>
                      <col width="25%" />
                      <col width="25%" />
                      <col width="25%" />
                      {/* <col width="24%" /> */}
                      <col width="25%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          <label htmlFor={index + 1} className="fs-s dpBtn">
                            의무인원
                          </label>
                        </th>
                        <th>
                          <label htmlFor={index + 2} className="fs-s dpBtn">
                            근무인원
                          </label>
                        </th>
                        <th>
                          <label htmlFor={index + 3} className="fs-s dpBtn">
                            추가인원{' '}
                          </label>
                        </th>
                        {/* <th><label htmlFor={index + 4} className="fs-s dpBtn">1인당 입소자</label></th> */}
                        <th className="clr-accent">
                          <label htmlFor={index + 5}>가산점</label>
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <Unit>명</Unit>
                          <input
                            id={index + 0}
                            name={index + 0}
                            type="text"
                            value={getLocalNumber(item.required)}
                            readOnly={true}
                          />
                        </td>
                        <td>
                          {idx === 5 && <Unit>명</Unit>}
                          <input
                            id={index + 1}
                            name={index + 1}
                            type="text"
                            value={getInputNum(item.working)}
                            readOnly={idx >= addLabour.length - 1 || !inputOn}
                            onChange={event => {
                              handleAddLabour(event, idx);
                            }}
                            placeholder={idx >= addLabour.length - 1 ? '' : '입력'}
                          />
                        </td>
                        <td>
                          <Unit>명</Unit>
                          <input
                            id={index + 2}
                            name={index + 2}
                            type="text"
                            value={getLocalNumber(item.additional)}
                            readOnly={true}
                          />
                        </td>
                        {/* <td>{idx<2? <Unit>명</Unit> : ''}<input id={index + 3} name={index + 3} type="text" value={ idx<2? getLocalNumber(item.perOne): ''} readOnly={true} /></td>  */}
                        <td>
                          <Unit>점</Unit>
                          <input
                            id={index + 4}
                            name={index + 4}
                            type="text"
                            value={item.score}
                            readOnly={true}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </fieldset>
            <fieldset>
              <Caption className="adds">가산 현황</Caption>
              <table>
                <colgroup>
                  <col width="40%" />
                  <col width="25%" />
                  <col width="35%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan="2">
                      <label htmlFor="bc80">필수 인력 배치</label>
                    </th>
                    <td>
                      <input
                        id="bc80"
                        name="bc80"
                        type="text"
                        value={addState['requiredLabour']}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc81">추가 인력 배치</label>
                    </th>
                    <th>
                      <label htmlFor="bc82" className="fs-s">
                        가산 점수
                      </label>
                    </th>
                    <td>
                      <Unit>점</Unit>
                      <input
                        id="bc81"
                        name="bc81"
                        type="text"
                        value={addState['addLabourScore']}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan="4">
                      <label htmlFor="bc82">야간 직원 배치</label>
                    </th>
                    <th>
                      <label htmlFor="bc87" className="fs-s">
                        주간인력
                      </label>
                    </th>
                    <td>
                      <input
                        id="bc87"
                        name="bc87"
                        type="text"
                        value={getInputNum(addState['nightLabourDay'])}
                        onChange={event => handleAddState(event, 'nightLabourDay')}
                        placeholder={type === '주야간보호센터' ? '-' : '입력'}
                        readOnly={!inputOn || type === '주야간보호센터'}
                        autoComplete="off"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc88" className="fs-s">
                        야간인력
                      </label>
                    </th>
                    <td>
                      <input
                        id="bc88"
                        name="bc88"
                        type="text"
                        value={getInputNum(addState['nightLabourNight'])}
                        onChange={event => handleAddState(event, 'nightLabourNight')}
                        placeholder={type === '주야간보호센터' ? '-' : '입력'}
                        readOnly={!inputOn || type === '주야간보호센터'}
                        autoComplete="off"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc89" className="fs-s">
                        인력비율
                      </label>
                    </th>
                    <td>
                      <input
                        id="bc89"
                        name="bc89"
                        type="text"
                        value={type === '주야간보호센터' ? '-' : addState['nightLabourRate']}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc90" className="fs-s">
                        가산점수
                      </label>
                    </th>
                    <td>
                      <input
                        id="bc90"
                        name="bc90"
                        type="text"
                        value={type === '주야간보호센터' ? '-' : addState['nightLabourScore']}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc83">간호사 배치</label>
                    </th>
                    <th>
                      <label htmlFor="bc91" className="fs-s">
                        가산점수
                      </label>
                    </th>
                    <td>
                      <Unit>점</Unit>
                      <input
                        id="bc91"
                        name="bc91"
                        type="text"
                        value={addState['nursingLabourScore']}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th rowSpan="2">
                      <label htmlFor="bc84">맞춤형 서비스 제공</label>
                    </th>
                    <th>
                      <label htmlFor="bc92" className="fs-s">
                        횟수
                      </label>
                    </th>
                    <td colSpan="2">
                      <select
                        id="bc92"
                        name="bc92"
                        className="fs-s"
                        value={addState['serviceCount']}
                        onChange={event => handleAddState(event, 'serviceCount')}
                        disabled={!inputOn}
                      >
                        {SERVICE_COUNT.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="bc93" className="fs-s">
                        가산점수
                      </label>
                    </th>
                    <td colSpan="2">
                      <Unit>점</Unit>
                      <input
                        id="bc93"
                        name="bc93"
                        type="text"
                        value={addState['serviceScore'] || ''}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="2" className="clr-accent">
                      <label htmlFor="bc85">가산 총점</label>
                    </th>
                    <td colSpan="4">
                      <Unit>점</Unit>
                      <input
                        id="bc85"
                        name="bc85"
                        type="text"
                        value={addState['totalScore'] || ''}
                        readOnly={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {warning.addState.length > 0 && <div className="warn">{warning.addState}</div>}
            </fieldset>
            <div className="actions">
              {!result && <button type="submit">계산하기</button>}
              {result && (
                <button type="reset">
                  초기화
                  <ResetIcon />
                </button>
              )}
            </div>
          </AddsForm>
        )}
        {result && (
          <>
            <ResultAbstract>
              <div className="result">
                <span>
                  월 가산 예상 금액 : <strong>{getLocalNumber(result.monthlyPrice)} 원</strong>
                </span>
              </div>
              <button
                className={resultShow ? 'on' : ''}
                onClick={() => {
                  setResultShow(resultShow => !resultShow);
                }}
              >
                상세 계산 내역 보기
              </button>
            </ResultAbstract>
            {resultShow && (
              <AddsCalcForm>
                <CalcHeader>
                  <span className="title">예상 가산금 상세</span>
                </CalcHeader>
                {isDesktop && (
                  <table>
                    <colgroup>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                      <col width="8.3%"></col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th colSpan="2" rowSpan="2">
                          가산 기준 금액
                        </th>
                        <th colSpan="2" rowSpan="2">
                          가산 점수
                        </th>
                        <th colSpan="2">가산 점수 인정 범위</th>
                        <th colSpan="2" rowSpan="2">
                          월 평균 입소자 수
                        </th>
                        <th colSpan="2" rowSpan="2">
                          서비스 유형점수
                        </th>
                        <th colSpan="2" rowSpan="2">
                          월 가산 예상 금액
                        </th>
                      </tr>
                      <tr>
                        <th>기본(이하)</th>
                        <th>추가</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="2">
                          <Unit>원</Unit>
                          {getLocalNumber(result.basePrice)}
                        </td>
                        <td colSpan="2" className="accent">
                          <Unit>점</Unit>
                          {result.addScore}
                        </td>
                        <td colSpan="1">{result.scoreRangeBase}</td>
                        <td colSpan="1">{result.scoreRangeAdds}</td>
                        <td colSpan="2">
                          <Unit>명</Unit>
                          {result.monthlyCapacity}
                        </td>
                        <td colSpan="2">
                          <Unit>점</Unit>
                          {result.serviceScore}
                        </td>
                        <td colSpan="2" className="accent">
                          <Unit>원</Unit>
                          {getLocalNumber(result.monthlyPrice)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                {!isDesktop && (
                  <table>
                    <colgroup>
                      <col width="40%"></col>
                      <col width="20%"></col>
                      <col width="40%"></col>
                    </colgroup>
                    <tbody>
                      <tr>
                        <th colSpan="2">가산 기준 금액</th>
                        <td>
                          <Unit>원</Unit>
                          {getLocalNumber(result.basePrice)}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2">가산 점수</th>
                        <td className="accent">
                          <Unit>점</Unit>
                          {result.addScore}
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan="2">가산 점수 인정 범위</th>
                        <th>기본(이하)</th>
                        <td colSpan="1">{result.scoreRangeBase}</td>
                      </tr>
                      <tr>
                        <th>추가</th>
                        <td colSpan="1">{result.scoreRangeAdds}</td>
                      </tr>
                      <tr>
                        <th colSpan="2">월 평균 입소자 수</th>
                        <td>
                          <Unit>명</Unit>
                          {result.monthlyCapacity}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2">서비스 유형점수</th>
                        <td>
                          <Unit>점</Unit>
                          {result.serviceScore}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="2">월 가산 예상 금액</th>
                        <td className="accent">
                          <Unit>원</Unit>
                          {getLocalNumber(result.monthlyPrice)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <span className="warn">
                  ※ 장기요양기관 예상 가산금 결과값은 실제 결과값과 상이할 수 있으니 주의 바랍니다.{' '}
                </span>
              </AddsCalcForm>
            )}
          </>
        )}
      </div>
      {warnAlert.length > 0 && (
        <Modal title="가산금 계산" width="300" close={true} onCloseClick={() => setWarnAlert('')}>
          <div>{warnAlert}</div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default AddsCalculator;
