import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  initMap,
  updateMapMarkers,
  showMapOverlay,
  updateMapInfoWindow,
  clearUserGeolocation,
  toggleStreetMode,
  deactivateCadastral,
  activateCadastral,
} from "../../store/actions/map";
import { updateFilterForData } from "../../store/actions/filter";
import {
  getRegionByLatlng,
  getRegionByZoom,
  renderedGroupMarker,
  getZoomLevel,
  renderItemMarkers,
  renderInfoWindow,
  removeMarkers,
  getPolygonAddr,
  getStringAddr,
  getRegionLevel,
  isSameAddr,
} from "../../utils/map";
import { isDesktop } from "../../utils/detect";
import Map from "../../components/Map/Map";
import MapRegion from "../../components/MapRegion/MapRegion";
import InfoWindow from "../../components/ui/InfoWindow/InfoWindow";
import MaemulAlert from "../../components/Map/MaemulAlert/MaemulAlert";
import { activateAlert } from "../../store/actions/alert";
import { activateContact } from "../../store/actions/service";
import { getMapMarkers, getMapInfoWindow } from "../../api/map";
import { updateChart } from "../../store/actions/chart";
import { GET_MARKETS } from "../../scheme/chart";
import gugunGeo from "../../geojson/gugun.json";
import { saveHistory } from "../../store/actions/history";
import useDeepCompareEffect from "use-deep-compare-effect";

const { naver } = window;
const GUGUN_FEATURES = JSON.parse(JSON.stringify(gugunGeo)).features;

const MapContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileInfoWindow, setMobileInfoWindow] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /* === 매물보기 알림 === */
  const MAEMUL_ALERT = useSelector(state => state.Map.maemulAlert);
  const [maemulAlertVisible, setMaemulAlertVisible] = useState(false);

  /* === 지도 속성 === */
  const MAP = useSelector(state => state.Map.map);
  const INFO_WINDOW = useSelector(state => state.Map.infoWindow); // 인포윈도우 객체
  const MY_GEO = useSelector(state => state.Map.geolocation); // 사용자 위치 표시
  const FILTER_MAP = useSelector(state => state.Filter.map);
  const FILTER_DATA = useSelector(state => state.Filter.data);

  /* === 로그인 여부 ===*/
  const IS_LOGGEDIN = useSelector(state => state.User.loggedIn);

  /* === 로드뷰 로딩 타이머 === */
  let panoramaTimeout = null;

  /* === 지도 생성 === */
  const NAVER_MAP = () => {
    // STATES
    let zoomLevel = getZoomLevel(11);
    let addrObj = { area1: "서울특별시", area2: "", area3: "" };
    let polygonAddr = "";
    let polygons = [];

    // MAP
    const nvMap = new naver.maps.Map("map", {
      center: new naver.maps.LatLng(FILTER_MAP.latlng[0], FILTER_MAP.latlng[1]),
      zoom: FILTER_MAP.zoom,
      minZoom: 8,
      // maxBounds: new naver.maps.LatLngBounds(
      //     new naver.maps.LatLng(32.122953, 125.182447),
      //     new naver.maps.LatLng(38.2070148, 128.5918488)
      // )
    });
    // LAYER
    const nvCadastralLayer = new naver.maps.CadastralLayer();
    const nvStreetLayer = new naver.maps.StreetLayer({ overlayMap: false, zIndex: 2 });
    const nvPanorama = new naver.maps.Panorama("pano", {
      position: new naver.maps.LatLng(FILTER_MAP.latlng[0], FILTER_MAP.latlng[1]),
      aroundControl: true,
      aroundControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    });
    // BUTTON
    // const panoramaBtn = new naver.maps.CustomControl("<button class='mapPanoBtn'>지도 보기</button>");
    const streetBtn = document.getElementById("mapStreetBtn");
    const cadBtn = document.getElementById("mapCadBtn");
    const zoomIn = new naver.maps.CustomControl("<button class='mapZoom mapZoom--in'></button>");
    const zoomOut = new naver.maps.CustomControl("<button class='mapZoom mapZoom--out'></button>");

    naver.maps.Event.once(nvMap, "init", function () {
      // ZOOM BUTTON
      naver.maps.Event.addDOMListener(zoomIn.getElement(), "click", function () {
        nvMap.setZoom(nvMap.getZoom() + 1, true);
      });
      naver.maps.Event.addDOMListener(zoomOut.getElement(), "click", function () {
        nvMap.setZoom(nvMap.getZoom() - 1, true);
      });

      // CAD BUTTON
      naver.maps.Event.addDOMListener(cadBtn, "click", function () {
        if (nvCadastralLayer.getMap()) {
          nvCadastralLayer.setMap(null);
          dispatch(deactivateCadastral());
        } else {
          nvCadastralLayer.setMap(nvMap);
          dispatch(activateCadastral());
        }
      });

      // STREET BUTTON
      naver.maps.Event.addDOMListener(streetBtn, "click", function (e) {
        if (nvPanorama.getVisible()) {
          nvPanorama.setVisible(false);
          return;
        }
        if (nvStreetLayer.getMap()) {
          nvStreetLayer.setMap(null);
          // if(nvPanorama.getVisible()) {
          //     nvPanorama.setVisible(false);
          //     panoramaBtn.setMap(null);
          // }
        } else {
          nvStreetLayer.setMap(nvMap);
        }
        dispatch(toggleStreetMode());
      });

      // PANORAMA BUTTON
      // naver.maps.Event.addDOMListener(panoramaBtn.getElement(), 'click', function() {
      //     nvPanorama.setVisible(false);
      //     panoramaBtn.setMap(null);
      // });

      zoomIn.setMap(nvMap);
      zoomOut.setMap(nvMap);
      // panoramaBtn.setMap(null);
      nvPanorama.setVisible(false);
    });

    naver.maps.Event.addListener(nvMap, "click", function (e) {
      if (nvStreetLayer.getMap()) {
        nvPanorama.setPosition(e.coord);
        if (!nvPanorama.getVisible()) {
          setIsLoading(true);
          panoramaTimeout = setTimeout(function () {
            nvPanorama.setVisible(true);
            // panoramaBtn.setMap(nvMap);
            setIsLoading(false);
          }, 400);
        }
      }
    });

    // map event : zoom_changed, bounds_changed, deragend, idle
    naver.maps.Event.addListener(nvMap, "idle", async () => {
      const nZoom = nvMap.getZoom();
      const nZoomLevel = getZoomLevel(nZoom);
      const nLatlng = [nvMap.getCenter()._lat, nvMap.getCenter()._lng];
      const fullAddr = await getRegionByLatlng(nLatlng);
      const nAddrObj = getRegionByZoom(fullAddr, nZoom);

      if (!isSameAddr(addrObj, nAddrObj)) {
        if (nZoomLevel !== "korea" && nAddrObj.area1.length <= 0) return;

        dispatch(
          updateFilterForData({
            address: nAddrObj,
            region: getStringAddr(nAddrObj),
            latlng: nLatlng,
            sisulCustomNo: null,
            sort: "sisul",
          })
        );
        addrObj = nAddrObj;
      }

      // 구군 줌레벨이면서 주소값 있을 때 폴리곤 생성
      if (nZoomLevel === "gugun" && fullAddr) {
        const diffPolygonAddr = getPolygonAddr(fullAddr);
        if (polygonAddr === diffPolygonAddr) {
          togglePolygonVisible(true);
        } else {
          clearPolygon();
          if (polygonAddr === null) return;
          polygonAddr = diffPolygonAddr;
          addPolygon(polygonAddr);
        }
      }

      // zoom_changed 일 때, 마커 visible: false 처리
      dispatch(showMapOverlay(true));
      zoomLevel = nZoomLevel;
    });

    // zoom_changed => animation - visible hide 처리
    naver.maps.Event.addListener(nvMap, "zoom_changed", () => {
      //   if (zoomLevel !== getZoomLevel(nvMap.getZoom())) {
      //       dispatch(clearMapOverlay());
      //   } else {
      //     dispatch(showMapOverlay(false));
      //   }
      // idle 일 때, 마커 visible: true 처리
      dispatch(showMapOverlay(false));
      togglePolygonVisible(false);
    });

    const addPolygon = addr => {
      if (nvStreetLayer.getMap()) return;

      for (let gFeature of GUGUN_FEATURES) {
        if (gFeature.properties.SIG_KOR_NM.replace(" ", "") === addr) {
          const feature = new naver.maps.Feature(gFeature);
          feature.setStyle({
            strokeColor: "rgba(255, 0, 0, 0.6)",
            fillColor: "rgba(255, 0, 0, 0.1)",
          });
          const polygon = feature.getOverlays()[0];
          polygon.setMap(nvMap);
          polygon.addListener("click", function () {
            if (nvStreetLayer.getMap()) clearPolygon();
          });
          polygons.push(polygon);
        }
      }
    };

    const clearPolygon = () => {
      if (polygons.length <= 0) return;
      for (let polygon of polygons) polygon.setMap(null);
      polygons = [];
    };

    const togglePolygonVisible = (visible = true) => {
      if (polygons.length <= 0) return;
      for (let polygon of polygons) polygon.setVisible(visible);
    };

    return nvMap;
  };

  // 지도 위치 변경
  const moveMap = mapProps => {
    if (mapProps.latlng && mapProps.latlng.length === 2 && mapProps.zoom !== null) {
      const point = new naver.maps.Point(mapProps.latlng[1], mapProps.latlng[0]);
      MAP.morph(point, mapProps.zoom, "easeOutCubic");
    }
  };

  // 인포윈도우 ON
  const updateInfoWindow = async props => {
    const RESPONSE = await getMapInfoWindow({ sisulCustomNo: props.id });
    if (RESPONSE && RESPONSE.data.code === 1) {
      const iwData = { ...RESPONSE.data.result, ...props };
      if (!isDesktop) {
        showMobileInfoWindow(iwData);
      } else {
        const option = {
          data: iwData,
          onCloseClick: closeInfoWindow,
          onContactClick: onContactClick,
          onDetailsClick: onDetailsClick,
        };
        const infoWindow = renderInfoWindow(option);
        dispatch(updateMapInfoWindow(infoWindow));
      }
    }
  };

  // 모바일 인포윈도우 ON
  const showMobileInfoWindow = data => {
    setInfoWindowData(data);
    setMobileInfoWindow(true);
  };

  // 인포윈도우 OFF
  const closeInfoWindow = () => {
    if (!isDesktop) {
      setInfoWindowData(null);
      setMobileInfoWindow(false);
    } else {
      removeMarkers(INFO_WINDOW);
    }
  };

  // 인포윈도우 문의하기
  const onContactClick = data => {
    if (isDesktop) {
      if (IS_LOGGEDIN) {
        dispatch(
          activateContact({
            id: data.id,
            sido: data["siDoCd"],
            gugun: data["siGunGuCd"],
            type: data["adminPttnCd"],
          })
        );
      } else {
        dispatch(
          activateAlert({
            title: "보노랜드 회원 서비스",
            contents: `비회원은 ${isDesktop ? "상단" : "하단"} 매수문의를 통해서 문의해 주세요.`,
          })
        );
      }
    } else {
      document.location.href = "tel:16610169";
    }
  };

  const HISTORY = useSelector(state => state.History.url);

  // 인포윈도우 상세보기
  const onDetailsClick = centerId => {
    closeInfoWindow();

    const currentPath = location.pathname;

    if (!currentPath.includes("/center/")) dispatch(saveHistory({ url: currentPath }));

    navigate(HISTORY, { replace: true });

    setTimeout(() => {
      navigate(`center/${centerId}`);
    }, 200);
  };

  // 그룹핑 마커 클릭
  const onGroupMarkerClick = props => {
    moveMap({ latlng: props.latlng, zoom: props.zoom });
  };

  // 단일 마커 클릭
  const onItemMarkerClick = props => {
    updateInfoWindow(props);
  };

  /*  마커 (시설, 매물별 마커(차트) 다르게 필요)
      시설검색: 시도, 구군, 동
      매물검색: 시도, 구군(차트정보만 선택)
    */
  const updateMarkers = async (map, option) => {
    setAlertMsg(null);
    const REGION_LEVEL = getRegionLevel(option.address);
    const SEARCH_MAEMUL = option.category2 && option.category2.length > 0;

    // 매물 검색일 때에도 return;
    if (REGION_LEVEL === "dong" && SEARCH_MAEMUL) return;

    setIsLoading(true);
    dispatch(showMapOverlay(false));

    const RESPONSE = await getMapMarkers({
      area1: option.address.area1,
      area2: option.address.area2,
      area3: option.address.area3,
      categories: option.categories,
      category2: option.category2,
      sisulCustomMarker: option.sisulCustomNo,
      sort: option.sort,
    });

    setIsLoading(false);
    if (RESPONSE && RESPONSE.data.code === 1) {
      const mks =
        REGION_LEVEL === "dong"
          ? renderItemMarkers(RESPONSE.data.arrayResult, map, onItemMarkerClick)
          : SEARCH_MAEMUL && REGION_LEVEL === "gugun"
          ? []
          : renderedGroupMarker(RESPONSE.data.arrayResult, map, REGION_LEVEL, onGroupMarkerClick);

      dispatch(updateMapMarkers(mks));

      const maemulResult = option.sisulCustomNo && RESPONSE.data.arrayResult.length < 1;

      const statistics =
        maemulResult || RESPONSE.data.arrayResult.length < 1 ? null : RESPONSE.data.arrayResult[0].localStatistics;
      if (statistics) dispatch(updateChart({ region: option.region, data: GET_MARKETS(statistics) }));
      else dispatch(updateChart(null));
    } else {
      dispatch(showMapOverlay(false));
      dispatch(updateChart(null));
      const currentAddr = Object.keys(option.address).filter(key => option.address[key].length > 0);
      setAlertMsg(
        `${currentAddr.length > 0 ? option.address[currentAddr[currentAddr.length - 1]] : ""} ${
          option.category2 && option.category2.length > 0 ? "매물" : "시설"
        }이 없습니다.`
      );
    }
  };

  const handleMaemulAlert = data => {
    const isDongZoom = data.address["area2"].length > 0;
    if (isDongZoom) setMaemulAlertVisible(true);
    else setMaemulAlertVisible(false);
  };

  useEffect(() => {
    const map = NAVER_MAP();
    dispatch(initMap(map));
    return () => {
      if (panoramaTimeout) clearTimeout(panoramaTimeout);
    };
  }, []);

  useEffect(() => {
    if (MAP) updateMarkers(MAP, FILTER_DATA);
  }, [MAP]);

  useEffect(() => {
    if (MAP) moveMap(FILTER_MAP);
  }, [FILTER_MAP]);

  // categories, address, category2, zoom 변동시에만 요청
  useDeepCompareEffect(() => {
    if (MAP) updateMarkers(MAP, FILTER_DATA);
    if (MY_GEO.pin && FILTER_DATA.region !== MY_GEO.region) dispatch(clearUserGeolocation());
    if (MAEMUL_ALERT) handleMaemulAlert(FILTER_DATA);
  }, [
    {
      c: FILTER_DATA.categories,
      r: FILTER_DATA.address,
      c2: FILTER_DATA.category2,
      z: FILTER_DATA.zoom,
    },
  ]);

  return (
    <>
      <Map />
      {isDesktop && alertMsg && <div className="map-alert">{alertMsg}</div>}
      {MAEMUL_ALERT && maemulAlertVisible && <MaemulAlert />}
      {isLoading && (
        <div className="map-loading">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
      {FILTER_DATA.region.length > 0 && <MapRegion region={FILTER_DATA.region} />}
      {!isDesktop && mobileInfoWindow && (
        <InfoWindow
          data={infoWindowData}
          onCloseClick={closeInfoWindow}
          onDetailsClick={onDetailsClick}
          onContactClick={onContactClick}
        />
      )}
    </>
  );
};

export default MapContainer;
