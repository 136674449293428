import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Form, Agreement, SAlarmCheck, SAlarmForm } from './SignupFormStyle';
import { isDesktop } from '../../../utils/detect';
import { module } from '../../../themes/module';
import { REGEXP } from '../../../scheme/form';
import { REGIONS } from '../../../scheme/alarm';

const requiredFields = ['userId', 'userPwd01', 'userPwd02', 'userName', 'userAgree'];

const SignupForm = ({ onFormSubmit, invalidId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    clearErrors,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      aReg_all: true,
      aliemAgree: true,
    },
  });
  const watching = watch();

  const [submitAble, setSubmitAble] = useState(false);
  const SUBAGREE_LEN = 3;

  const toggleAgreeAll = (event) => {
    const idx = event.currentTarget.dataset.idx;
    const checked = event.currentTarget.checked;
    setValue(`agree${idx}`, checked);

    const totalChecked =
      getValues(['agree0', 'agree1', 'agree2']).filter((item) => item === true).length === SUBAGREE_LEN;
    setValue('userAgree', totalChecked);
    handleAgreeError(totalChecked);
  };

  const handleAgreeAll = (event) => {
    const checked = event.currentTarget.checked;

    setValue('userAgree', checked);
    for (let i = 0; i < SUBAGREE_LEN; i++) setValue(`agree${i}`, checked);
    handleAgreeError(checked);
  };

  const handleAgreeError = (checked) => {
    if (checked) clearErrors('userAgree');
    else setError('userAgree');
  };

  const handleAllcheckAliem = (e) => {
    if (e.currentTarget.checked && getValues('aReg').length > 0) {
      setValue('aReg', []);
    }
  };

  const handleSubCheckAliem = (e) => {
    if (e.currentTarget.checked) {
      if (getValues('aReg').length === Object.keys(REGIONS.dataset).length) {
        setValue('aReg_all', true);
        setValue('aReg', []);
        return;
      }

      if (getValues('aReg_all')) {
        setValue('aReg_all', false);
      }
    }
  };

  useEffect(() => {
    if (invalidId.length > 0) {
      setError('userId', { type: 'invalidId', messgae: '이미 가입된 아이디 입니다.' });
    }
  }, [invalidId]);

  useEffect(() => {
    const hasError = Object.keys(errors).length > 0;
    const requiredFilled =
      requiredFields.reduce((acc, key) => {
        if (!watching[key]) {
          return acc;
        }
        if (typeof watching[key] === 'boolean' && watching[key] === true) {
          return acc + 1;
        }
        if (typeof watching[key] === 'string' && watching[key].length > 0) {
          return acc + 1;
        }
        return acc;
      }, 0) === requiredFields.length;

    const validAliemAgree =
      ((watching['aReg_all'] || watching['aReg'].length > 0) && watching['aliemAgree']) ||
      (!watching['aReg_all'] && watching['aReg'].length === 0 && !watching['aliemAgree']);

    setSubmitAble(!hasError && requiredFilled && validAliemAgree);
  }, [watching]);

  const RENDER_FORM = () => (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <div>
        <div className="desc">회원가입을 위해 본인정보를 기입해주세요.</div>
        <fieldset>
          <div className="wrap">
            <label htmlFor="userId">아이디</label>
            <input
              type="text"
              name="userId"
              id="userId"
              placeholder="bonoland@naver.com"
              className={`bd ${errors.userId ? 'invalid' : ''}`}
              {...register('userId', {
                required: { value: true, message: '아이디를 입력해 주세요.' },
                pattern: { value: REGEXP.email, message: '사용하시는 이메일로 아이디를 입력해 주세요.' },
              })}
            />
            {errors.userId && (
              <span className="warn">
                {(errors.userId.type === 'required' && '아이디를 입력해 주세요.') ||
                  (errors.userId.type === 'pattern' && '사용하시는 이메일로 아이디를 입력해 주세요.') ||
                  (errors.userId.type === 'invalidId' && '이미 가입된 아이디 입니다.')}
              </span>
            )}
          </div>
          <div className="wrap">
            <label htmlFor="uPwd01">비밀번호</label>
            <input
              type="password"
              name="uPwd01"
              id="uPwd01"
              placeholder="영문, 숫자 포함 최소 10자리 이상"
              className={`bd ${errors.userPwd01 ? 'invalid' : ''}`}
              {...register('userPwd01', {
                required: { value: true, message: '비밀번호를 입력해 주세요.' },
                pattern: { value: REGEXP.newPassword2, message: '영문, 숫자 포함 최소 10자리 이상을 입력해주세요.' },
                validate: value => {
                  if (REGEXP.easyPasswords.some(regex => regex.test(value))) {
                    return '일련번호, 주민등록번호, 전화번호 등의 쉬운 패스워드는 사용하실 수 없습니다.';
                  }
                  return true;
                }
              })}
            />
            {errors.userPwd01 && <span className="warn">{errors.userPwd01.message || "일련번호, 주민등록번호, 전화번호 등의 쉬운 패스워드는 사용하실 수 없습니다."}</span>}
          </div>
          <div className="wrap">
            <label htmlFor="uPwd02">비밀번호 확인</label>
            <input
              type="password"
              name="uPwd02"
              id="uPwd02"
              className={`bd ${errors.userPwd02 ? 'invalid' : ''}`}
              {...register('userPwd02', {
                required: true,
                validate: { confirm: (value) => value === getValues().userPwd01 },
              })}
            />
            {errors.userPwd02 && (
              <span className="warn">
                <>
                  {errors.userPwd02.type === 'required' && '확인 비밀번호를 입력해 주세요.'}
                  {errors.userPwd02.type === 'confirm' && '비밀번호가 서로 일치하지 않습니다.'}
                </>
              </span>
            )}
          </div>
          <div className="wrap">
            <label htmlFor="uName">이름</label>
            <input
              type="text"
              name="uName"
              id="uName"
              className={`bd ${errors.userName ? 'invalid' : ''}`}
              {...register('userName', {
                required: { value: true, message: '이름을 입력해 주세요.' },
              })}
            />
            {errors.userName && <span className="warn">{errors.userName.message}</span>}
          </div>
        </fieldset>
        <hr />
        <div className="wrap">
          <label htmlFor="uAliem">매물 알림 설정</label>
          <SAlarmForm>
            <fieldset>
              <SAlarmCheck full>
                <input
                  name="aReg"
                  id="aReg_all"
                  type="checkbox"
                  {...register('aReg_all', { onChange: handleAllcheckAliem })}
                />
                <label htmlFor="aReg_all">전체</label>
              </SAlarmCheck>
              {Object.keys(REGIONS.dataset).map((item, idx) => (
                <SAlarmCheck key={idx}>
                  <input
                    name="aReg"
                    id={`aReg_${idx}`}
                    value={item}
                    type="checkbox"
                    {...register('aReg', { onChange: handleSubCheckAliem })}
                  />
                  <label htmlFor={`aReg_${idx}`}>{item}</label>
                </SAlarmCheck>
              ))}
            </fieldset>
          </SAlarmForm>
          <Agreement>
            <div>
              <div>
                <input type="checkbox" id="aliemAgree" name="aliemAgree" {...register('aliemAgree')} />
                <label htmlFor="aliemAgree"></label>
              </div>
              <span>카톡 알림 서비스에 동의하시겠습니까?</span>
            </div>
            {(watching['aReg_all'] || watching['aReg'].length > 0) && !watching['aliemAgree'] && (
              <span className="warn">매물 알림 서비스에 동의해 주세요.</span>
            )}
          </Agreement>
        </div>
        <hr />
        <Agreement>
          <div className="main-agree">
            <div>
              <input
                type="checkbox"
                id="userAgree"
                name="userAgree"
                {...register('userAgree', {
                  required: true,
                  validate: { confirm: (value) => value === true },
                })}
                onChange={(event) => handleAgreeAll(event)}
              />
              <label htmlFor="userAgree"></label>
            </div>
            <strong>전체동의</strong>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                id="agree0"
                data-idx={0}
                {...register('agree0')}
                onChange={(event) => toggleAgreeAll(event)}
              />
              <label htmlFor="agree0"></label>
            </div>
            <Link target="_blank" to="/terms/이용약관">
              이용약관
            </Link>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                id="agree1"
                data-idx={1}
                {...register('agree1')}
                onChange={(event) => toggleAgreeAll(event)}
              />
              <label htmlFor="agree1"></label>
            </div>
            <Link target="_blank" to="/terms/개인정보처리방침">
              개인정보처리방침
            </Link>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                id="agree2"
                data-idx={2}
                {...register('agree2')}
                onChange={(event) => toggleAgreeAll(event)}
              />
              <label htmlFor="agree2"></label>
            </div>
            <Link target="_blank" to="/terms/위치기반서비스이용약관">
              위치기반서비스 이용동의
            </Link>
          </div>
          {errors.userAgree && <span className="warn">보노랜드 필수 이용약관에 모두 동의해 주세요.</span>}
        </Agreement>
        <div className="action">
          <button type="submit" className={submitAble ? '' : 'disabled'}>
            회원가입
          </button>
        </div>
      </div>
    </Form>
  );

  return (
    <>
      {isDesktop && RENDER_FORM()}
      {!isDesktop && <module.scrollWrapper>{RENDER_FORM()}</module.scrollWrapper>}
    </>
  );
};

export default SignupForm;
