import React from "react";
import { useDispatch } from 'react-redux';
import AddsCalculator from "../../components/Calculators/AddsCalculator/AddsCalculator";
import { isDesktop } from "../../utils/detect";
import Modal from '../../components/Modal/Modal';
import Section from '../../components/ui/Section/Section';
import { deactivateAddsCalculator } from '../../store/actions/service';

const AddsCalculatorContainer = () => {
    const dispatch = useDispatch();

    return (
        <> 
        {
            isDesktop &&
            <Modal
                open={ true }
                close={ true }
                title="장기요양기관 예상 가산금 계산"
                width="970"
                onCloseClick={ () => dispatch(deactivateAddsCalculator()) }
            >
                <AddsCalculator />
            </Modal>
        }
        {
            !isDesktop &&
            <Section 
                zIndex="90"
                title="장기요양기관 예상 가산금 계산"
                themeColor="primary"
                back={ true }
                onBackClick={ () => dispatch(deactivateAddsCalculator()) }
            >
                <AddsCalculator />
            </Section>
        }
        </>
    )
};

export default AddsCalculatorContainer;