import React from "react";
import styled from "styled-components";

import StyledText from "./StyledText";
import Ellipse from "./Ellipse";
import { isDesktop } from "../../utils/detect";
import { useDispatch } from "react-redux";
import { deactivateLanding } from "../../store/actions/landing";

const SubBox = styled.div(({ background, marginLeft }) => ({
  width: '290px',
  height: '340px',
  cursor: "pointer",
  background,
  boxShadow: "0px 2px 6px 0px gray",
  borderRadius: 4,
  marginLeft,
}));

const MSubBox = styled.div(({ marginLeft, marginRight, background }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: 160,
  cursor: "pointer",
  background,
  borderRadius: 4,
  boxShadow: "0px 2px 6px 0px gray",
  marginLeft,
  marginRight,
}));

const HeadBlock = styled.div(() => ({
  display: "flex",
  flex: 1,
  paddingTop: isDesktop ? 30 : 20,
  paddingLeft: isDesktop ? 20 : 20,
}));

const ImageBlock = styled.div(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const Card = ({
  background,
  title,
  sub,
  img,
  btnText,
  titleTextCol = "#fff",
  subTextCol = "#fff",
  borderThemeCol = "#fff",
  marginLeft,
  marginRight,
  onStartMapView,
}) => {
  return isDesktop ? (
    <SubBox background={background} marginLeft={marginLeft} onClick={onStartMapView}>
      <HeadBlock>
        <div style={{ marginLeft: 20 }}>
          <StyledText fontSize={18} fontWeight={400} color={titleTextCol}>
            {title}
          </StyledText>

          <StyledText fontSize={24} fontWeight={700} marginTop={10} color={subTextCol}>
            {sub}
          </StyledText>
        </div>
      </HeadBlock>

      <ImageBlock>
        <img style={{ width: 160, height: 160 }} src={img} alt="map1" />

        <Ellipse width={145} marginTop="1.8rem" borderColor={borderThemeCol}>
          <StyledText fontSize={15} cursor="pointer" color={borderThemeCol}>
            {btnText}
          </StyledText>
        </Ellipse>
      </ImageBlock>
    </SubBox>
  ) : (
    <MSubBox background={background} marginLeft={marginLeft} marginRight={marginRight} onClick={onStartMapView}>
      <HeadBlock>
        <div>
          <StyledText fontSize={15} color={titleTextCol} fontWeight={700}>
            {title}
          </StyledText>

          <StyledText fontSize={18} fontWeight={700} marginTop={5} color={subTextCol}>
            {sub}
          </StyledText>
        </div>
      </HeadBlock>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          alignItems: "end",
          padding: "0 10px 10px 20px",
        }}>
        <p style={{ fontSize: 15, fontWeight: 500, color: borderThemeCol, marginBottom: 10 }}>보러가기 〉</p>
        <img style={{ width: 50, height: 50 }} src={img} alt="map1" />
      </div>
    </MSubBox>
  );
};

export default Card;
