export const HISTORY = {
  SAVE: '/history/save',
  CLEAR: '/history/clear',
};

export const saveHistory = data => ({
  type: HISTORY.SAVE,
  payload: data,
});

export const resoterHistory = () => ({
  type: HISTORY.CLEAR,
});
