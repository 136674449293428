import styled from 'styled-components';

export const ErrorWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding: 0 20px;

    .inform {
      display: flex;
      flex-direction: column;
      gap: 24px;
      line-height: 1.4;
      word-break: keep-all;

      strong {
        font-size: ${({ theme }) => theme.fontSizes.l};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
      }

      p {
        font-size: ${({ theme }) => theme.fontSizes.base};
        color: ${({ theme }) => theme.colors.gray800};
      }

      button {
        width: 150px;
        height: 48px;
        line-height: 46px;
        margin-top: 20px;
        border-radius: 26px;
        color: #fff;
        background-color: ${({ theme }) => theme.colors.secondary};
        text-align: center;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
    }

    .mobile & {
      flex-direction: column;

      .inform {
        text-align: center;
        strong {
          font-size: ${({ theme }) => theme.fontSizes.base};
        }
        p {
          font-size: ${({ theme }) => theme.fontSizes.s};
        }
        button {
          margin: 20px auto 0;
        }
      }
    }
  }
`;
