import { SERVICE } from "../actions/service";

const initialState = {
  signup: false,
  login: false,
  findId: false,
  findPwd: false,
  calculator: false,
  addsCalculator: false,
  buildCalculator: false,
  contact: false,
  alarm: false,
  loginRequired: false,
  registser: false,
  calculatorData: null,
  contactData: null,
  remodelingContact: false,
  remodelingJoin: false,
  landing: false,
  bonoNews: false,
  qna: false,
  resume: false,
};

const ServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE.SIGNUP_ON:
      return { ...initialState, signup: true };
    case SERVICE.SIGNUP_OFF:
      return { ...initialState, signup: false };
    case SERVICE.LOGIN_ON:
      return { ...initialState, login: true };
    case SERVICE.LOGIN_OFF:
      return { ...initialState, login: false };
    case SERVICE.FIND_ID_ON:
      return { ...initialState, findId: true };
    case SERVICE.FIND_ID_OFF:
      return { ...initialState, findId: false };
    case SERVICE.FIND_PWD_ON:
      return { ...initialState, findPwd: true };
    case SERVICE.FIND_PWD_OFF:
      return { ...initialState, findPwd: false };
    case SERVICE.CENTER_CALCULATOR_ON:
      return { ...initialState, calculator: true, calculatorData: action.payload };
    case SERVICE.CENTER_CALCULATOR_OFF:
      return { ...initialState, calculator: false, calculatorData: null };
    case SERVICE.ADDS_CALCULATOR_ON:
      return { ...initialState, addsCalculator: true };
    case SERVICE.ADDS_CALCULATOR_OFF:
      return { ...initialState, addsCalculator: false };
    case SERVICE.BUILD_CALCULATOR_ON:
      return { ...initialState, buildCalculator: true };
    case SERVICE.BUILD_CALCULATOR_OFF:
      return { ...initialState, buildCalculator: false };
    case SERVICE.CENTER_CALCULATOR_RESET:
      return { ...state, calculatorData: action.payload };
    case SERVICE.CONTACT_ON:
      return { ...initialState, contact: true, contactData: action.payload };
    case SERVICE.CONTACT_OFF:
      return { ...initialState, contact: false, contactData: null };
    case SERVICE.ALARM_ON:
      return { ...initialState, alarm: true };
    case SERVICE.ALARM_OFF:
      return { ...initialState, alarm: false };
    case SERVICE.REGISTER_ON:
      return { ...initialState, register: true };
    case SERVICE.REGISTER_OFF:
      return { ...initialState, register: false };
    case SERVICE.LOGIN_REQUIRED_ON:
      return { ...initialState, loginRequired: true };
    case SERVICE.LOGIN_REQUIRED_OFF:
      return { ...initialState, loginRequired: false };
    case SERVICE.REMODELING_CONTACT_ON:
      return { ...initialState, remodelingContact: true };
    case SERVICE.REMODELING_CONTACT_OFF:
      return { ...initialState, remodelingContact: false };
    case SERVICE.REMODELING_JOIN_ON:
      return { ...initialState, remodelingJoin: true };
    case SERVICE.REMODELING_JOIN_OFF:
      return { ...initialState, remodelingJoin: false };
    case SERVICE.LANDING_ON:
      return { ...initialState, landing: true };
    case SERVICE.LANDING_OFF:
      return { ...initialState, landing: false };
    case SERVICE.BONO_NEWS_ON:
      return { ...initialState, bonoNews: true };
    case SERVICE.BONO_NEWS_OFF:
      return { ...initialState, bonoNews: false };
    case SERVICE.QNA_ON:
      return { ...initialState, qna: true };
    case SERVICE.QNA_OFF:
      return { ...initialState, qna: false };
    case SERVICE.RESUME_ON:
      return { ...initialState, resume: true };
    case SERVICE.RESUME_OFF:
      return { ...initialState, resume: false };

    default:
      return state;
  }
};

export default ServiceReducer;
