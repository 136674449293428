import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

export const MAEMULALERT = styled.div`
  z-index: 99;
  position: fixed;
  top: calc(50% - 100px);
  left: 50%;
  width: auto;
  max-width: calc(100% - 40px);
  height: auto;
  padding: 30px 40px;
  border-radius: 20px;
  background-color: rgba(138, 101, 63, 0.8);
  color: #fff;
  font-size: 24px;
  text-align: center;
  line-height: 1.6;
  transform: translate(-50%, -50%);
  animation: ${fadeOut} 1s 6.5s forwards;

  &.off {
    display: none;
  }
`;
