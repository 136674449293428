import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import map from './themes/map.css';
import GmarketSansOtf from './assets/fonts/GmarketSansMedium.otf';
import GmarketSansTtf from './assets/fonts/GmarketSansTTFMedium.ttf';

const AppStyle = createGlobalStyle`
    ${reset}
    ${map}

    * {
        box-sizing: border-box;
    }

    html, 
    body,
    #root {
        width: 100%;
        height: 100%;
        font: 400 1rem/1 'Noto Sans KR', sans-serif;
        color: #212121;
        letter-spacing: -1px;
        overscroll-behavior-y: none;
    }

    main {
        z-index: 30;
        position: relative;
    }

    a {
        text-decoration: none;
        color: #212121;
        cursor: pointer;
    }
    
    button {
        outline: none;
        border: 0;
        font: 400 1rem/1 'Noto Sans KR', sans-serif;
        color: #212121;
        background-color: #ffffff;
        cursor: pointer;
    }

    input,
    input:focus,
    input:active,
    select,
    select:focus,
    select:active,
    textarea,
    textarea:focus,
    textarea:active {
        border: 0;
        outline: 0;
        font-family: 'Noto Sans KR', sans-serif;
        background-color: #fff;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
      background: transparent;
    }

    @font-face {
        font-family: 'GmarketSans';
        src: url(${GmarketSansOtf}),
            url(${GmarketSansTtf});
    }
`;

export default AppStyle;
