import { MAP, PANORAMA } from './MapStyle';
import React from 'react';
import { useSelector } from 'react-redux';
import { QuickBtn } from '../global/QuickMenu/QuickMenuStyle';

const Map = () => {
  const STREET_MODE = useSelector((state) => state.Map.street);
  const CAD_MODE = useSelector((state) => state.Map.cadastral);

  return (
    <MAP id="map">
      <PANORAMA id="pano" />
      <QuickBtn id="mapStreetBtn" className={`street ${STREET_MODE ? 'active' : ''}`}>
        거리
      </QuickBtn>
      <QuickBtn id="mapCadBtn" className={`cad ${CAD_MODE ? 'on' : ''}`}>
        지적도
      </QuickBtn>
    </MAP>
  );
};

export default Map;
