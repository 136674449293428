export const REGEXP = {
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    password: /^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{6,12}$/,
    newPassword: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/,
    newPassword2: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10,}$/, // 영문, 숫자 포함 최소 10자리 이상
    easyPasswords: [
        /^(\d)\1{9,}$/,                         // 동일 숫자 반복 패턴 (ex: 0000000000)
        /^(0123456789|1234567890|0987654321)$/, // 연속된 숫자 패턴
        /^\d{6}[ -]?\d{7}$/,                    // 주민등록번호 패턴
        /^\d{3}[ -]?\d{3}[ -]?\d{4}$/,          // 전화번호 패턴
    ],
    phone: /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
    number: /[0-9]/g
}
