import React from "react";
import styled from "styled-components";

import Frame2144 from "../../assets/images/landing/Frame2144.png";
import Frame2145 from "../../assets/images/landing/Frame2145.png";
import Frame2146 from "../../assets/images/landing/Frame2146.png";
import Frame12423 from "../../assets/images/landing/Frame12423.png";
import XMLID_184 from "../../assets/images/landing/youtube48.png";
import { isDesktop } from "../../utils/detect";
import { useDispatch } from "react-redux";
import { activateBonoNews } from "../../store/actions/page";

const Container = styled.div({
  display: "flex",
  width: 1200,
  justifyContent: "space-between",
  alignSelf: "center",
});

const MContainer = styled.div(({ justifyContent, marginLeft, marginRight, marginTop }) => ({
  display: "flex",
  alignSelf: "center",
  justifyContent,
  alignItems: "center",
  top: 180,
  position: "relative",
  width: "100%",
  marginLeft,
  marginRight,
  marginTop,
}));

const MenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #8a653f;
    filter: opacity(0.5) drop-shadow(0 0 0 brown);
  }
`;

const Text = styled.p`
  font-size: 18px;
  margin-top: 10px;
`;

const MText = styled.p`
  font-size: 15px;
  margin-top: 10px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

const MImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const menus = [
  {
    id: 1,
    title: isDesktop ? "보노랜드 대표채널" : "대표채널",
    src: XMLID_184,
  },
  {
    id: 2,
    title: isDesktop ? "보노랜드 인재채용" : "인재채용",
    src: Frame2146,
  },
  {
    id: 3,
    title: "언론 속 보노랜드",
    src: Frame2144,
  },
  // {
  //   id: 4,
  //   title: "요양산업 뉴스",
  //   src: Frame12423,
  // },
  {
    id: 5,
    title: "FAQ",
    src: Frame2145,
  },
];

const Menu = ({ onClick }) => {
  return isDesktop ? (
    <Container>
      {menus.map(menu => (
        <MenuBlock key={menu.id} onClick={onClick(menu.id)}>
          <Image src={menu.src} alt="map1" />
          <Text>{menu.title}</Text>
        </MenuBlock>
      ))}
    </Container>
  ) : (
    <>
      <MContainer justifyContent="space-between" marginTop="1rem">
        {
          menus.map(menu => (
              <MenuBlock key={menu.id} onClick={onClick(menu.id)}>
                <MImage src={menu.src} alt="map1" />
                <MText>{menu.title}</MText>
              </MenuBlock>
          ))
        }
        {/*<MenuBlock onClick={onClick(menus[0].id)}>*/}
        {/*  <MImage src={menus[0].src} alt="map1" />*/}
        {/*  <MText>{menus[0].title}</MText>*/}
        {/*</MenuBlock>*/}

        {/*<MenuBlock onClick={onClick(menus[1].id)}>*/}
        {/*  <MImage src={menus[1].src} alt="map1" />*/}
        {/*  <MText>{menus[1].title}</MText>*/}
        {/*</MenuBlock>*/}

        {/*<MenuBlock onClick={onClick(menus[2].id)}>*/}
        {/*  <MImage src={menus[2].src} alt="map1" />*/}
        {/*  <MText>{menus[2].title}</MText>*/}
        {/*</MenuBlock>*/}
      </MContainer>

      {/*<MContainer justifyContent="space-evenly" marginRight="3rem" marginTop="2rem">*/}
      {/*  <MenuBlock onClick={onClick(menus[3].id)}>*/}
      {/*    <MImage src={menus[3].src} alt="map1" />*/}
      {/*    <MText>{menus[3].title}</MText>*/}
      {/*  </MenuBlock>*/}

      {/*  <MenuBlock onClick={onClick(menus[4].id)}>*/}
      {/*    <MImage src={menus[4].src} alt="map1" />*/}
      {/*    <MText>{menus[4].title}</MText>*/}
      {/*  </MenuBlock>*/}
      {/*</MContainer>*/}
    </>
  );
};

export default Menu;
