import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import UserReducer from './user';
import AuthReducer from './auth';
import MapReducer from './map';
import ServiceReducer from './service';
import ChartReducer from './chart';
import AlertReducer from './alert';
import FilterReducer from './filter';
import PageReducer from './page';
import HistoryReducer from './history';
import GuideReducer from './guide';
import LandingReducer from './landing';

const combinedReducer = combineReducers({
  User: UserReducer,
  Auth: AuthReducer,
  Map: MapReducer,
  Service: ServiceReducer,
  Chart: ChartReducer,
  Alert: AlertReducer,
  Filter: FilterReducer,
  Page: PageReducer,
  History: HistoryReducer,
  Guide: GuideReducer,
  Landing: LandingReducer,
});

// config 작성
const persistConfig = {
  key: "root", // localStorage key
  storage, // localStorage
  whitelist: ["Landing"],
}

export default persistReducer(persistConfig, combinedReducer);
