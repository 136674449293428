import styled, { keyframes } from 'styled-components';
import closeIcon from '../../../assets/images/icon/ico-x.svg';

const windowMotion = keyframes`
    0% { transform: translateY(100%); }
    100% { transform: translateY(0); }
`;

export const Window = styled.div`
    z-index: 32;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 280px;
    padding: 24px 14px 70px;
    background-color: #fff;
    box-shadow: 0 15px 25px rgba(0,0,0,.5);
    animation: ${windowMotion} .3s forwards;

    .img {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
`;

export const Close = styled.button`
    z-index: 31;
    position: absolute;
    top: 24px;
    right: 14px;
    width: 24px;
    height: 24px;
    background: url(${closeIcon}) center no-repeat;

    & + * {
        z-index: 30;
    }
`;