import styled from 'styled-components';
import soldOutImg from '../../../assets/images/center/soldout.png';

export const Card = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;

  &.abstract {
    padding: 8px 0;
  }
`;

export const Head = styled.h3`
  padding-bottom: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray800};
  color: ${({ theme }) => theme.colors.gray900};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Wrap = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
  height: auto;
  min-height: 138px;
  overflow: hidden;
  padding-left: 160px;
  cursor: pointer;

  &.main {
    .mobile & {
      padding-left: 0;
      min-height: auto;
    }
  }
`;

export const Thumbnail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;

  .img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.soldout {
      background-image: url(${soldOutImg});
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: ${({ theme }) => theme.fontSizes.xl};
      letter-spacing: 1px;
    }
  }
`;

export const Sales = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const Cate = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 16px;
  color: #fff;
  background-color: rgba(0, 31, 96, 0.6);
`;

export const Corp = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  max-width: 64px;
  height: 26px;
  gap: 4px;
  overflow: hidden;
  img {
    height: 100%;
  }

  &.loan {
    height: 20px;
  }
`;

export const Num = styled.div`
  margin-bottom: 8px;

  .abstract & {
    position: absolute;
    top: 0;
    right: 0;
  }

  span {
    display: inline-block;
    width: auto;
    padding: 6px 8px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-radius: 2px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.gray600};

    .abstract & {
      padding: 4px 8px;
      font-size: ${({ theme }) => theme.fontSizes.xxs};
    }
  }
`;

export const Badges = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
`;

export const Badge = styled.div`
  padding: 4px 8px 4px 6px;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: #fff;
  border-radius: 2px;
  background-color: ${(props) => {
    switch (props.division) {
      case '추천':
        return '#E91E63';
      case '프리미엄':
        return '#4CAF50';
      case '급매':
        return '#D15F2E';
      default:
        return '#D19A2E';
    }
  }};
`;

export const Name = styled.div`
  margin-bottom: 6px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  &.fw-b {
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    .mobile & {
      max-width: calc(100% - 100px);
    }
  }
`;

export const Region = styled.div`
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.fontSizes.s};
  color: ${({ theme }) => theme.colors.gray800};

  .abstract & {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.gray700};
  }
`;

export const Price = styled.div`
  position: relative;
  margin-bottom: 12px;
  font-size: ${(props) => (props.depositStyle ? '17px' : props.theme.fontSizes.l)};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  .abstract & {
    margin-bottom: 8px;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.gray700};
  line-height: 1.2;
  margin-bottom: 16px;

  &.sisul {
    position: absolute;
    bottom: 0;
    left: 164px;
    margin-bottom: 0;
  }

  .abstract & {
    gap: 4px;
    margin-bottom: 0;

    &.sisul {
      position: relative;
      left: 0;
    }
  }
`;

export const Assets = styled.div`
  display: flex;

  div {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;

    em {
      padding: 4px 8px 5px;
      font-size: ${({ theme }) => theme.fontSizes.xs};
      border-radius: 2px;
      color: #fff;
      line-height: 1;
      &.invest {
        background-color: #d15f2e;
      }
      &.loan {
        background-color: #d19a2e;
      }
    }

    span {
      font-size: ${({ theme }) => theme.fontSizes.s};
    }
  }
`;
