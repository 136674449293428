import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { MHeaderContainer } from "./GlobalHeaderStyle";
import FrameHome from "../../../assets/images/landing/FrameHome.png";
import Frame2082 from "../../../assets/images/landing/Frame2082.png";
import styled from "styled-components";
import { activateLanding, deactivateLanding } from "../../../store/actions/landing";
import {activateMyAlarm, activateMyAlarmForm} from "../../../store/actions/page";
import {activateLoginRequired} from "../../../store/actions/service";
import {isDesktop} from "react-device-detect";

const BellContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 5,
  cursor: "pointer",
});

const MobileHeader = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector(state => state.User.loggedIn);

  return (
    <MHeaderContainer>
      <img
        style={{ width: 144, height: 28, alignSelf: "center" }}
        src={FrameHome}
        alt="landing_logo"
        onClick={() => dispatch(activateLanding())}
      />
      <BellContainer
        onClick={() => {
          if (!isLogin) return dispatch(activateLoginRequired());
          dispatch(activateMyAlarm());
        }}>
        <p style={{ fontSize: 13, color: "#616161", marginBottom: 2.1 }}>매물알림</p>

        <img style={{ width: 24, height: 24, marginLeft: 2 }} src={Frame2082} alt="Frame2082" />
      </BellContainer>
    </MHeaderContainer>
  );
};

export default MobileHeader;
