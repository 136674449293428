import React from "react";
import Modal from "../../components/Modal/Modal";
import { useDispatch } from "react-redux";
import { activateAlert } from "../../store/actions/alert";
import { isDesktop } from "../../utils/detect";
import {deactivateResume} from "../../store/actions/service";
import ResumeRegister from "../../components/Resume/ResumeModal";
import {requestCreateResume} from "../../api/landing";
import {isEmptyObj} from "../../utils/utils";

const RegisterContainer = () => {

    const dispatch = useDispatch();

    // 인재채용 닫기
    const onCloseClick = () => {
        dispatch(deactivateResume());
    };

    // 인재채용 등록
    const onFormSubmit = async data => {
        try {
            const res = await requestCreateResume(data);
            if (res.status !== 200) {
                return onFail();
            }
            onSuccess();
        } catch (e) {
            onFail();
        }
    };

    const onSuccess = () => {
        dispatch(
            activateAlert({
                title: "등록 완료",
                contents: "등록되었습니다.",
            })
        );
        onCloseClick();
    }

    const onFail = () => {
        dispatch(
            activateAlert({
                title: "지원 중 오류 발생",
                contents: "지원 중 오류가 발생하였습니다.",
            })
        );
    }

    return (
        <Modal open={true} close={true} onCloseClick={onCloseClick} width="890" height="580" title="보노랜드 중개 영업사원 채용 지원">
            <ResumeRegister device={isDesktop ? "browser" : "mobile"} onFormSubmit={onFormSubmit} />
        </Modal>
    );
};

export default RegisterContainer;
