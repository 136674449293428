import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { SwipeBox, SwipeArea, SwipeIcon } from './SwipePanelStyle';

const SwipePanel = ({ children }) => {
  const [hide, setHide] = useState(true);

  const togglePannel = (mode) => {
    if (mode === 'hide' && hide) return;
    if (mode === 'show' && !hide) return;
    setHide(!hide);
  };

  const swipeHandler = useSwipeable({
    onSwipedUp: () => togglePannel('show'),
    onSwipedDown: () => togglePannel('hide'),
  });

  return (
    <SwipeBox className={hide && 'hide'}>
      <SwipeIcon />
      <SwipeArea onClick={() => togglePannel((hide) => (hide ? 'show' : 'hide'))} {...swipeHandler} />
      {children}
    </SwipeBox>
  );
};

export default SwipePanel;
