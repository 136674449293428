const EXCEPTIONS = {
    '경기도' : [37.27520, 127.00950],
    '충청남도' : [36.6173379, 126.8453965],
    '세종특별자치시': [36.4803512, 127.2894325],
    '제주특별자치도 서귀포시' : [33.25730, 126.51530],
    '서울특별시 서초구' : [37.483569, 127.032598],
    '서울특별시 용산구' : [37.532527, 126.99049],
    '인천광역시 서구 마전동': [37.595396, 126.6736756],
    '인천광역시 서구 당하동': [37.590082, 126.679800],
    '경기도 안성시 옥산동': [37.0071, 127.2489125],
    '경기도 안성시 석정동': [37.0099, 127.2594],
    '경기도 안성시 서인동': [37.00622, 127.2698],
    '경기도 안성시 인지동': [37.004425, 127.2665],
    '경기도 안성시 숭인동' : [37.0105966, 127.2748293],
    '경기도 안성시 봉산동' : [37.00705, 127.2795625],
    '경기도 안성시 가사동' : [37.0155, 127.2883],
};

export const getFilteredLatlng =  (latlng, address) => EXCEPTIONS[address] || latlng;