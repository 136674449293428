import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AddressForm, Home } from './AddressFilterStyle';
import { isDesktop } from '../../../utils/detect';
import { useDispatch } from 'react-redux';
import {
  updateFilterForData,
  updateFilterForMap,
  resetFilter,
} from '../../../store/actions/filter';

const AddressFilter = ({ type, adress, onAddressChange, onAddressSubmit, setAutoSearchData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const onHomeClick = () => {
    dispatch(
      updateFilterForMap({
        latlng: [36.676111, 127.044167],
        zoom: 8,
        address: { area1: '', area2: '', area3: '' },
      }),
    );
    dispatch(
      updateFilterForData({
        address: { area1: '', area2: '', area3: '' },
        region: '',
        // latlng: [36.676111, 127.044167],
        // categories: null,
        // category2: null,
        sort: 'sisul',
        sisulCustomNo: null,
      }),
    );
    setAutoSearchData(null);
  };

  return (
    <>
      <AddressForm
        autoComplete="off"
        onSubmit={event => onAddressSubmit(event)}
        className={type === 'main' ? 'main bono-guide' : 'bono-guide'}
        data-guide="5"
      >
        {!isDesktop && <Home type="button" onClick={() => onHomeClick()} />}
        <input
          type="text"
          placeholder={`지역구, 지역명${type === 'main' ? ', 시설명' : ''}${
            !isDesktop && type === 'main' ? '' : ', 매물번호'
          }로 검색하세요.`}
          value={adress}
          onChange={event => onAddressChange(event)}
          id="autocomplete"
          autoComplete="off"
        />
        <button type="submit">검색</button>
      </AddressForm>
    </>
  );
};

export default AddressFilter;
