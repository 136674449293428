import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../components/ui/Panel/Panel";
import CenterItem from "../../components/Center/CenterItem/CenterItem";
import { activateAlert } from "../../store/actions/alert";
import { isDesktop } from "../../utils/detect";
import { useGet } from "../../hooks";
import { getCenter, getCenterCalc } from "../../api/center";
import { activateContact, activateCalculator } from "../../store/actions/service";
import Section from "../../components/ui/Section/Section";
import { GET_FIXED_RESULT } from "../../scheme/calculator";
import { updateFilterForData, updateFilterForMap } from "../../store/actions/filter";
import { ZOOMS } from "../../utils/map";
import { activateMapMaemulAlert, deactivateMapMaemulAlert } from "../../store/actions/map";

const CENTERS_CTG = {
   biz: {
      category2: "신규시설",
   },
   remodeling: {
      category2: "리모델링",
   },
   nursing: {
      category2: "요양원",
   },
   care: {
      category2: "주야간",
   },
};

const CenterItemContainer = () => {
   const dispatch = useDispatch();
   const location = useLocation();

   const IS_LOGGEDIN = useSelector(state => state.User.loggedIn);
   const USER_NO = useSelector(state => state.User.userInfo.no);
   // 상세페이지에서 매물지도보기 알림
   // const FILTER_DATA = useSelector(state => state.Filter.data);
   // const MAEMUL_ALERT = useSelector(state => state.Map.maemulAlert);

   const id = location.pathname.split("/center/")[1];
   const [center, setCenter] = useState(null);
   // const [isMaemul, setIsMaemul] = useState(false);
   // 상세페이지에서 매물지도보기 알림
   // const [alertActive, setAlertActive] = useState(false);
   // const [alertTimeout, setAlertTimeout] = useState(null);
   const [loading, error, data, setGet] = useGet({});

   const onContactClick = () => {
      if (isDesktop) {
         if (IS_LOGGEDIN) {
            dispatch(
               activateContact({
                  id: center["sisulCustomNo"],
                  sido: center["sisul"]["siDoCd"],
                  gugun: center["sisul"]["siGunGuCd"],
                  type: center["sisul"]["adminPttnCd"],
               })
            );
         } else {
            dispatch(
               activateAlert({
                  title: "비회원 매수문의",
                  contents: `비회원의 경우 ${
                     isDesktop ? "상단 우측의" : "메인 페이지 하단 메뉴의"
                  } 매수문의를 통해 문의할 수 있습니다.`,
               })
            );
         }
      } else {
         document.location.href = "tel:16610169";
      }
   };

   const onCalcClick = async () => {
      if (!IS_LOGGEDIN) {
         dispatch(
            activateAlert({
               title: "보노랜드 회원 서비스",
               contents: "해당 기능은 회원가입 후 사용하실 수 있습니다.",
            })
         );
         return;
      }
      const RESPONSE = await getCenterCalc({ sisulCustomNo: id });
      if (RESPONSE) {
         const data = RESPONSE.data.result;
         const RESULT = GET_FIXED_RESULT(data);
         dispatch(activateCalculator(RESULT));
      }
   };

   useEffect(() => {
      if (id && USER_NO) {
         setGet(getCenter({ userNo: USER_NO, sisulCustomNo: parseInt(id) }));
      }
   }, [id, USER_NO]);

   useEffect(() => {
      let maemulState = false;
      if (data.result) {
         maemulState = data.result["sisulState"] === "매물";
         const search = location.search.split("?filter=");

         dispatch(
            updateFilterForMap({
               latlng: [data.result.x, data.result.y],
               zoom: maemulState ? ZOOMS["gugun"][0] - 1 : ZOOMS["dong"][0], // 포커싱 시 주석 해제 요망
            })
         );

         if (maemulState && search.length > 1) {
            const category = CENTERS_CTG[search[1]];
            if (category) {
               dispatch(
                  updateFilterForData({
                     category2: category.category2,
                  })
               );
            }
         }
         setCenter(data.result);
         // setIsMaemul(maemulState);

         if (isDesktop && maemulState) dispatch(activateMapMaemulAlert());
      }

      return () => {
         if (isDesktop && maemulState) dispatch(deactivateMapMaemulAlert());
      };
   }, [data]);

   const RENDER_CENTER = () => (
      <CenterItem
         center={center}
         loaging={loading}
         error={error}
         onContactClick={onContactClick}
         onCalcClick={onCalcClick}
         isLoggedIn={IS_LOGGEDIN}
      />
   );

   return (
      <>
         {}
         {isDesktop && (
            <Panel type={"side"} position={"left"} fold={true}>
               <div className="bono-guide" data-guide="10" style={{ width: "100%", height: 0, opacity: 0 }} />
               {RENDER_CENTER()}
            </Panel>
         )}

         {!isDesktop && (
            <Section zIndex="999" themeColor="primary" close={false} back={false} action={false}>
               {RENDER_CENTER()}
            </Section>
         )}
      </>
   );
};

export default CenterItemContainer;
