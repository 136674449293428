import React from 'react';
import styled from 'styled-components';

import StyledText from './StyledText';
import Ellipse from './Ellipse';
import house2 from '../../assets/images/landing/house2.png';
import { isDesktop } from '../../utils/detect';

const SisulBox = styled.div(() =>
  isDesktop
    ? {
        display: 'flex',
        width: 600,
        height: 340,
        borderRadius: 4,
        background: 'linear-gradient(#3097F6, #1F83DE)',
        boxShadow: '0px 2px 12px 0px gray',
        cursor: "pointer",
      }
    : {
        display: 'flex',
        flex: 1,
        borderRadius: 4,
        padding: '20px',
        background: 'linear-gradient(#3097F6, #1F83DE)',
        boxShadow: '0px 2px 12px 0px gray',
        flexDirection: 'column',
        cursor: "pointer",
      },
);

const Block = styled.div(({ paddingTop, marginLeft }) => ({
  display: 'flex',
  flex: 1,
  paddingTop,
  marginLeft,
}));

const LeftContent = styled.div(() => ({
  paddingTop: 40,
  marginLeft: 40,
}));

const Component = () => {
  return (
    <div>
      <StyledText fontSize={isDesktop ? 20 : 14} fontWeight={400}>
        프롭테크 기술 + 다양한 광고 채널
      </StyledText>

      <StyledText fontSize={isDesktop ? 20 : 14} fontWeight={400} marginTop={8}>
        안전하고 신속한 거래
      </StyledText>

      <StyledText fontSize={isDesktop ? 34 : 22} fontWeight={700} marginTop={16}>
        시설 내놓기
      </StyledText>
    </div>
  );
};

const RegisterBox = ({ onClick }) => {
  return isDesktop ? (
    <SisulBox onClick={onClick}>
      <Block>
        <LeftContent>
          <Component />

          <Ellipse width={145} marginTop="8rem" borderColor="#fff">
            <StyledText fontSize={15} cursor="pointer">
              매물접수
            </StyledText>
          </Ellipse>
        </LeftContent>
      </Block>

      <Block>
        <img style={{ flex: 1 }} src={house2} alt="house2" />
      </Block>
    </SisulBox>
  ) : (
    <SisulBox onClick={onClick}>
      <Component />

      <div>
        <StyledText fontSize={15} fontWeight={500} marginTop="2rem">
          매물접수 〉
        </StyledText>
      </div>

      <img
        style={{ width: 140, height: 140, position: 'absolute', alignSelf: 'end' }}
        src={house2}
        alt="house2"
      />
    </SisulBox>
  );
};

export default RegisterBox;
