import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Charts, Wrapper, Region } from './MapChartStyle';
import { isDesktop } from '../../utils/detect';
import { useSwipeable } from 'react-swipeable';
import CustomDoughnutChart from '../Chart/CustomDoughnutChart/CustomDoughnutChart';
import { handleSejong } from '../MapRegion/MapRegion';

const MapChart = ({ data, isLoggedIn }) => {
  const [visible, setVisible] = useState(isDesktop ? true : false);
  const FILTER_CATEGORIES = useSelector(state => state.Filter.data.categories);

  const swipeHandler = useSwipeable({
    onSwipedUp: () => setVisible(true),
    onSwipedDown: () => setVisible(false),
  });

  return (
    <Charts
      className={`bono-guide${visible ? ' on' : ''} ${
        FILTER_CATEGORIES && FILTER_CATEGORIES.length > 0 ? ' p-up' : ''
      }`}
      data-guide="4-2"
    >
      {isDesktop && (
        <Region
          onClick={() => {
            setVisible(visible => !visible);
          }}
        >
          {handleSejong(data.region)}
        </Region>
      )}
      {!isDesktop && (
        <Region
          className="bono-guide"
          onClick={() => {
            setVisible(visible => !visible);
          }}
          data-guide="4-3"
          {...swipeHandler}
        >
          시장현황 보기
        </Region>
      )}
      <Wrapper>
        {!isDesktop && <div className="chart-region">{handleSejong(data.region)}</div>}
        {data.data.map((item, idx) => (
          <CustomDoughnutChart
            key={idx}
            data={item}
            bgColor="transparent"
            blur={!isLoggedIn && idx > 1}
          />
        ))}
        {isDesktop && (
          <span className="inform">
            * 오른쪽 인구 버튼 클릭 시 통계 그래프를 비활성화 할 수 있습니다.
          </span>
        )}
      </Wrapper>
    </Charts>
  );
};

export default MapChart;
