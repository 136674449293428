import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const Calculator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: 100%;
    height: 52px;
    line-height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-align: center;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.s};
    color: ${({ theme }) => theme.colors.gray600};
  }
`;
