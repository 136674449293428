import React from "react";
import styled from "styled-components";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StyledText from "./StyledText";
import Star from "./Star";
import Intersect from "../../assets/images/landing/Intersect.png";
import right_arrow from "../../assets/images/landing/right-arrow.png";
import left_arrow from "../../assets/images/landing/left-arrow.png";


const MReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  position: relative;
  min-width: 375px;
  width: 100%;
  top: 240px;
  gap: 2rem;
`;

const Box = styled.div(({ marginTop, marginLeft }) => ({
    minWidth: 320,
    height: 300,
    marginTop,
    marginLeft,
    padding: "0px 20px 0px 20px",
    border: "1px solid #EEEEEE",
    boxShadow: "0px 1px 6px 0px rgb(0, 0, 0, 0.2)",
    marginBottom: "2rem",
}));

const Header = styled.div(() => ({
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 20px 0px",
}));

const Divider = styled.div(() => ({
    width: "100%",
    height: 1,
    background: "#EEEEEE",
}));

const InfoBlock = styled.div(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: 20,
}));

const ContentBlock = styled.div(() => ({
    wordBreak: "break-all",
    marginTop: 20,
}));

const ProfileBlock = styled.div(() => ({
    width: 28,
    height: 28,
    backgroundColor: "#D9D9D9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

const MContainer = styled.div({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    position: "relative",
    minWidth: "375px",
});

const MScrollContainer = styled(Slider)`
  width: 320px;

  .slick-prev, .slick-next {
    background: #ffffff;
    box-shadow: 0px 1px 6px 0px rgb(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 6px;
    width: 28px;
    height: 28px;
    z-index: 9;
  }
`;

const ReviewText = styled.p({
    fontSize: "14px",
    color: "#616161",
    letterSpacing: "0.025px",
    lineHeight: "18px",
})

const MAX_LENGTH = 140;

const type = str => {
    if (str === "REMODELING") return "리모델링후기";
    if (str === "PURCHASE") return "시설매매후기";
    if (str === "CONSULTING") return "컨설팅후기";
};


const ReviewMob = ({ data }) => {

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <img src={right_arrow}  alt="left_carot"/>,
        prevArrow: <img src={left_arrow} alt="right_carot"/>
    };
    return (
        <MReviewContainer>
            <StyledText fontSize={20} fontWeight={700} color={"#212121"}>
                고객들이 직접 등록한 생생한 거래후기
            </StyledText>
            <MContainer>

                <MScrollContainer {...settings}>
                    {data?.map((review) => (
                        <Box key={review.reviewNo}>
                            <Header>
                                <Star rate={review.star} />

                                <StyledText fontSize={15} color="black" marginLeft={5}>
                                    {type(review.reviewType)}
                                </StyledText>
                            </Header>

                            <Divider />

                            <InfoBlock>
                                <ProfileBlock>
                                    <img style={{ width: 18, height: 22 }} src={Intersect} alt="Intersect" />
                                </ProfileBlock>

                                <StyledText fontSize={16} color="#212121" marginLeft={8}>
                                    {review.username}
                                </StyledText>

                                <StyledText fontSize={13} color="#616161" marginLeft={8}>
                                    {review.createDate.slice(0, 10)}
                                </StyledText>
                            </InfoBlock>

                            <ContentBlock>
                                <ReviewText>
                                    {review.review.length > MAX_LENGTH ? `${review.review.slice(0, MAX_LENGTH)}...` : review.review}
                                </ReviewText>
                            </ContentBlock>
                        </Box>
                    ))}
                </MScrollContainer>

            </MContainer>
        </MReviewContainer>
    )
}

export default ReviewMob;