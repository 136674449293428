import React, { useState } from 'react';
import { PanelBox, PanelWrap, PanelBtn } from './PanelStyle';
import { isDesktop } from '../../../utils/detect';
import SpreadFloatingButton from '../FloatingButton/SpreadFloatingButton';
import { useDispatch } from 'react-redux';
import { activateRegister, activateContact } from '../../../store/actions/service';

const Panel = ({ type, position, fold, children }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(true);
  const togglePanel = () => {
    setActive(!active);
  };

  return (
    <PanelBox type={type} position={position} active={active}>
      {isDesktop && (
        <SpreadFloatingButton
          customStyle={{
            position: 'absolute',
            top: type === 'floating' ? '7px' : '82px',
            right: '-50px',
          }}
          items={[
            {
              text: '매수문의',
              onClick: () => {
                dispatch(activateContact());
              },
            },
            {
              text: '매물접수',
              onClick: () => {
                dispatch(activateRegister());
              },
            },
          ]}
        />
      )}
      <PanelWrap>{children}</PanelWrap>
      {fold && <PanelBtn onClick={() => togglePanel()} active={active} position={position}></PanelBtn>}
    </PanelBox>
  );
};

export default Panel;
