import styled from 'styled-components';
import scrapIcon from '../../../assets/images/icon/ico-star.svg';
import scrapActiveIcon from '../../../assets/images/icon/ico-star_brown.svg';
import shareIcon from '../../../assets/images/icon/ico-share.svg';

export const Actions = styled.div`
  position: relative;
  display: flex;
  gap: 12px;

  textarea {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    font-size: 0;
    position: absolute;
    text-indent: -999;
  }
`;

export const Action = styled.button`
  flex: 1;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.gray300};
  border-radius: 2px;
  font-size: ${({ theme }) => theme.fontSizes.base};
  color: ${({ theme }) => theme.colors.gray500};
  outline: 0;
  transition: 0.1s ease-in-out;

  &.scrap {
    font-size: 0;
    background: url(${scrapIcon}) center no-repeat #fff;
    &.on {
      background: url(${scrapActiveIcon}) center no-repeat #fff;
    }
  }

  &.share {
    font-size: 0;
    background: url(${shareIcon}) center no-repeat #fff;
  }

  &.details {
    &:hover {
      border-color: ${props =>
        props.centerType === 'maemul' ? props.theme.colors.primary : props.theme.colors.secondary};
      background-color: ${props =>
        props.centerType === 'maemul' ? props.theme.colors.primary : props.theme.colors.secondary};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: #fff;
    }
  }
`;
