import React, { useEffect, useState } from "react";
import { module } from "../../themes/module";
import { useForm } from "react-hook-form";
import { isDesktop } from "../../utils/detect";
import { REGEXP } from "../../scheme/form";
import { LOCATION } from "../../scheme/location";
import { convertToNum } from "../../utils/number";
import { VISIT_ROUTES } from "../../scheme/contact";
import { useDispatch } from "react-redux";
import { activateAlert } from "../../store/actions/alert";

const REQUIREDS = [
  "cAddr",
  "cCapacity",
  "cGugun",
  "cName",
  "cPerson",
  "cPrice",
  "cSido",
  "cType",
  "pAgree",
  "uEmail",
  "uName",
  "uTel",
];

const Register = ({ user, center, privacyTerm, onFormSubmit }) => {
  const [submitAble, setSubmitAble] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {
      uName: user.uName || "",
      uEmail: user.uEmail || "",
      uTel: user.uTel || "",
      cSido: center ? center.sido : Object.keys(LOCATION)[0],
      cGugun: center ? center.gugun : LOCATION[Object.keys(LOCATION)[0]][0],
      cSize: "",
      cRent: "",
      cPremium: "",
      cDesc: "",
    },
  });

  const formWatching = watch();

  //   setSubmitAble(false);

  useEffect(() => {
    const uVisit = document.getElementById("uVisit").value;

    //     setSubmitAble(uVisit === "");
  }, []);

  const handleMobileAlert = errors => {
    if (errors.length > 0) {
      dispatch(activateAlert({ title: "매물 접수", contents: errors[Object.keys(errors)[0]].message }));
    } else {
      dispatch(activateAlert({ title: "매물 접수", contents: "의뢰인, 시설 정보 입력 시 매물 접수가 가능합니다." }));
    }
  };

  useEffect(() => {
    const requiredLen = REQUIREDS.reduce((acc, required) => {
      let count = acc;
      if (formWatching.hasOwnProperty(required)) {
        if (typeof formWatching[required] === "boolean" && formWatching[required] === true) count--;
        if (typeof formWatching[required] === "string" && formWatching[required].length > 0) count--;
      }
      return count;
    }, REQUIREDS.length);
    setSubmitAble(requiredLen <= 0);
  }, [formWatching]);

  useEffect(() => {
    if (user) {
      setValue("uName", user.uName);
      setValue("uEmail", user.uEmail);
      setValue("uTel", user.uTel);
    }
  }, [user]);

  return (
    <>
      {isDesktop && (
        <module.scrollWrapper style={{ height: "500px" }}>
          <module.TableForm onSubmit={handleSubmit(onFormSubmit)}>
            <fieldset>
              <legend>의뢰인 정보</legend>
              <table>
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="uName">이름</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="uName"
                        id="uName"
                        {...register("uName", {
                          required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="uVisit">방문 경로</label>
                    </th>
                    <td colSpan="1">
                      <select name="uVisit" id="uVisit" {...register("uVisit")}>
                        {VISIT_ROUTES.map((route, idx) => (
                          <option key={idx} value={route.value}>
                            {route.label}
                          </option>
                        ))}
                      </select>
                      {getValues("uVisit") === "직접입력" && (
                        <input
                          className="selfType"
                          placeholder="방문경로를 입력해 주세요"
                          type="text"
                          name="uVisit"
                          {...register("uVisitSelf")}
                        />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cName">요양시설 이름</label>
                    </th>
                    <td colSpan="3">
                      <input
                        type="text"
                        name="cName"
                        id="cName"
                        {...register("cName", {
                          required: { value: true, message: "시설 정보를 모두 입력 시 매물 접수가 가능합니다." },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cAddr">요양시설 주소</label>
                    </th>
                    <td colSpan="3">
                      <input
                        type="text"
                        name="cAddr"
                        id="cAddr"
                        {...register("cAddr", {
                          required: { value: true, message: "시설 정보를 모두 입력 시 매물 접수가 가능합니다." },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th colSpan="1">
                      <label htmlFor="uTel">연락처</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="uTel"
                        id="uTel"
                        {...register("uTel", {
                          required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                          pattern: { value: REGEXP.phone, message: "휴대폰 번호를 정확히 입력해 주세요." },
                        })}
                      />
                    </td>
                    <th colSpan="1">
                      <label htmlFor="uEmail">이메일</label>
                    </th>
                    <td colSpan="1">
                      <input
                        type="text"
                        name="uEmail"
                        id="uEmail"
                        {...register("uEmail", {
                          required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                          pattern: { value: REGEXP.email, message: "이메일 주소를 정확히 입력해 주세요." },
                        })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <fieldset>
              <legend>요양시설 정보</legend>
              <table>
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label htmlFor="cSido">시/도</label>
                    </th>
                    <td>
                      <select
                        name="cSido"
                        id="cSido"
                        {...register("cSido", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        {Object.keys(LOCATION).map(sido => (
                          <option key={sido} value={sido}>
                            {sido}
                          </option>
                        ))}
                      </select>
                    </td>
                    <th>
                      <label htmlFor="cGugun">구/군</label>
                    </th>
                    <td>
                      <select
                        name="cGugun"
                        id="cGugun"
                        {...register("cGugun", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        {LOCATION[formWatching["cSido"]] &&
                          LOCATION[formWatching["cSido"]].map(gugun => (
                            <option key={gugun} value={gugun}>
                              {gugun}
                            </option>
                          ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cType">요양시설 유형</label>
                    </th>
                    <td>
                      <select
                        name="cType"
                        id="cType"
                        {...register("cType", {
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}>
                        <option value="단독요양원">단독요양원</option>
                        <option value="상가요양원">상가요양원</option>
                        <option value="주야간보호센터">주야간보호센터</option>
                      </select>
                    </td>
                    <th>
                      <label htmlFor="cSize">면적(m2)</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="cSize"
                        id="cSize"
                        {...register("cSize", {
                          onChange: e => {
                            setValue("cSize", convertToNum(e.currentTarget.value));
                          },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cPrice">매매가(보증금)</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="cPrice"
                        id="cPrice"
                        {...register("cPrice", {
                          onChange: e => {
                            setValue("cPrice", convertToNum(e.currentTarget.value));
                          },
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}
                      />
                    </td>
                    <th>
                      <label htmlFor="cRent">월세</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="cRent"
                        id="cRent"
                        {...register("cRent", {
                          onChange: e => {
                            setValue("cRent", convertToNum(e.currentTarget.value));
                          },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cCapacity">인가정원</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="cCapacity"
                        id="cCapacity"
                        {...register("cCapacity", {
                          onChange: e => {
                            setValue("cCapacity", convertToNum(e.currentTarget.value));
                          },
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}
                      />
                    </td>
                    <th>
                      <label htmlFor="cPremium">권리금</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="cPremium"
                        id="cPremium"
                        {...register("cPremium", {
                          onChange: e => {
                            setValue("cPremium", convertToNum(e.currentTarget.value));
                          },
                        })}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="cPerson">현원</label>
                    </th>
                    <td>
                      <input
                        type="text"
                        name="cPerson"
                        id="cPerson"
                        {...register("cPerson", {
                          onChange: e => {
                            setValue("cPerson", convertToNum(e.currentTarget.value));
                          },
                          required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                        })}
                      />
                    </td>
                    <th>
                      <label htmlFor="cDesc">소개내용</label>
                    </th>
                    <td>
                      <input type="text" name="cDesc" id="cDesc" {...register("cDesc")} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <module.Agreement>
              <div className="inform">
                <strong>#보노랜드에서 알려드립니다.</strong>
                <p>
                  보노랜드는 고객님의 프라이버시 및 비밀 엄수를 위해 접수하신 매물의 위치와 상세 정보는 공개되지 않습니다.
                  <br />
                  보노랜드는 매물 보호를 위해 ‘매수 희망 고객님’에게 유선 및 회사 방문 상담으로만 정보를 공개합니다.
                </p>
              </div>
              <div className="agree">
                <input
                  type="checkbox"
                  id="pAgree"
                  {...register("pAgree", {
                    required: { value: true, message: "개인정보 수집 및 이용에 동의 시 매물 접수가 가능합니다" },
                  })}
                />
                <label htmlFor="pAgree">개인정보수집에 대한 내용에 동의합니다</label>
              </div>
              <pre className="term">{privacyTerm}</pre>
            </module.Agreement>
            {Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
            <button type="submit" className={submitAble ? "" : "disabled"}>
              접수하기
            </button>
          </module.TableForm>
        </module.scrollWrapper>
      )}
      {!isDesktop && (
        <module.MobileForm onSubmit={handleSubmit(onFormSubmit)}>
          <fieldset>
            <legend>의뢰인 정보</legend>
            <div className="wrap">
              <label htmlFor="uName">이름</label>
              <input
                type="text"
                name="uName"
                id="uName"
                {...register("uName", {
                  required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="uVisit">방문 경로</label>
              <select name="uVisit" id="uVisit" {...register("uVisit")}>
                {VISIT_ROUTES.map((route, idx) => (
                  <option key={idx} value={route.value}>
                    {route.label}
                  </option>
                ))}
              </select>
              {getValues("uVisit") === "직접입력" && (
                <input
                  className="selfType"
                  placeholder="방문경로를 입력해 주세요"
                  type="text"
                  name="uVisit"
                  {...register("uVisitSelf")}
                />
              )}
            </div>
            <div className="wrap">
              <label htmlFor="cCtName">요양시설 이름</label>
              <input
                type="text"
                name="cName"
                id="cName"
                {...register("cName", {
                  required: { value: true, message: "시설 정보를 모두 입력 시 매물 접수가 가능합니다." },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cCtAddr">요양시설 주소</label>
              <input
                type="text"
                name="cAddr"
                id="cAddr"
                {...register("cAddr", {
                  required: { value: true, message: "시설 정보를 모두 입력 시 매물 접수가 가능합니다." },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="uTel">연락처</label>
              <input
                type="text"
                name="uTel"
                id="uTel"
                {...register("uTel", {
                  required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                  pattern: { value: REGEXP.phone, message: "휴대폰 번호를 정확히 입력해 주세요." },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="uEmail">이메일</label>
              <input
                type="text"
                name="uEmail"
                id="uEmail"
                {...register("uEmail", {
                  required: { value: true, message: "의뢰인 정보를 모두 입력 시 매물 접수가 가능합니다." },
                  pattern: { value: REGEXP.email, message: "이메일 주소를 정확히 입력해 주세요." },
                })}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>요양시설 정보</legend>
            <div className="wrap">
              <label htmlFor="cSigun">시/군</label>
              <select
                name="cSido"
                id="cSido"
                {...register("cSido", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                {Object.keys(LOCATION).map(sido => (
                  <option key={sido} value={sido}>
                    {sido}
                  </option>
                ))}
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cGugun">구/군</label>
              <select
                name="cGugun"
                id="cGugun"
                {...register("cGugun", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                {LOCATION[formWatching["cSido"]] &&
                  LOCATION[formWatching["cSido"]].map(gugun => (
                    <option key={gugun} value={gugun}>
                      {gugun}
                    </option>
                  ))}
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cType">요양시설 유형</label>
              <select
                name="cType"
                id="cType"
                {...register("cType", {
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}>
                <option value="단독요양원">단독요양원</option>
                <option value="상가요양원">상가요양원</option>
                <option value="주야간보호센터">주야간보호센터</option>
              </select>
            </div>
            <div className="wrap">
              <label htmlFor="cSize">면적(m2)</label>
              <input
                type="text"
                name="cSize"
                id="cSize"
                {...register("cSize", {
                  onChange: e => {
                    setValue("cSize", convertToNum(e.currentTarget.value));
                  },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cPrice">매매가(보증금)</label>
              <input
                type="text"
                name="cPrice"
                id="cPrice"
                {...register("cPrice", {
                  onChange: e => {
                    setValue("cPrice", convertToNum(e.currentTarget.value));
                  },
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cRent">월세</label>
              <input
                type="text"
                name="cRent"
                id="cRent"
                {...register("cRent", {
                  onChange: e => {
                    setValue("cRent", convertToNum(e.currentTarget.value));
                  },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cPremium">권리금</label>
              <input
                type="text"
                name="cPremium"
                id="cPremium"
                {...register("cPremium", {
                  onChange: e => {
                    setValue("cPremium", convertToNum(e.currentTarget.value));
                  },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cCapacity">인가정원</label>
              <input
                type="text"
                name="cCapacity"
                id="cCapacity"
                {...register("cCapacity", {
                  onChange: e => {
                    setValue("cCapacity", convertToNum(e.currentTarget.value));
                  },
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cPerson">현원</label>
              <input
                type="text"
                name="cPerson"
                id="cPerson"
                {...register("cPerson", {
                  onChange: e => {
                    setValue("cPerson", convertToNum(e.currentTarget.value));
                  },
                  required: { value: true, message: "요양시설 정보를 모두 입력 시 매물 접수가 가능합니다" },
                })}
              />
            </div>
            <div className="wrap">
              <label htmlFor="cDesc">소개내용</label>
              <input type="text" name="cDesc" id="cDesc" {...register("cDesc")} />
            </div>
          </fieldset>
          <module.Agreement>
            <div className="inform">
              <strong>#보노랜드에서 알려드립니다.</strong>
              <p>
                보노랜드는 고객님의 프라이버시 및 비밀 엄수를 위해 접수하신 매물의 위치와 상세 정보는 공개되지 않습니다.
                <br />
                보노랜드는 매물 보호를 위해 ‘매수 희망 고객님’에게 유선 및 회사 방문 상담으로만 정보를 공개합니다.
              </p>
            </div>
            <div className="agree">
              <input
                type="checkbox"
                id="pAgree"
                {...register("pAgree", {
                  required: { value: true, message: "개인정보 수집 및 이용에 동의 시 매물 접수가 가능합니다" },
                })}
              />
              <label htmlFor="pAgree">개인정보수집에 대한 내용에 동의합니다</label>
            </div>
            <pre className="term">{privacyTerm}</pre>
          </module.Agreement>
          {Object.keys(errors).length > 0 && <span className="warn">{errors[Object.keys(errors)[0]].message}</span>}
          <button
            type="submit"
            onClick={() => {
              if (!submitAble) handleMobileAlert(errors);
            }}
            className={submitAble ? "" : "disabled"}>
            접수하기
          </button>
        </module.MobileForm>
      )}
    </>
  );
};

export default Register;
