import React, { useEffect, useRef } from "react";
import { isDesktop } from "../../../utils/detect";
import "context-filter-polyfill";
import styled from "styled-components";

const PALETTE = ["#fff", "#E4B251", "#7BF5BB", "#E98686", "#616161"];

const FRAME_PROPS = {
    size: {
        x: 320,
        y: 350,
    },
    // ratio: 2, // polyfill 미적용시 2
    ratio: 1,
    color: "#fff",
};

const ARC_PROPS = {
    size: 300,
    radius: 75,
    lineWidth: 6.5,
    gap: 9.5,
};

const CanvasWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${({ bgColor }) => bgColor};
`;

const textOnCanvas = (ctx, textProps) => {
    ctx.fillStyle = textProps.color || "#FFF";
    ctx.textAlign = textProps.textAlign || "center";
    ctx.font = `${textProps.fontWeight || "normal"} ${textProps.fontSize || 14}px 'NotoSans KR', sans-serif`;
    ctx.fillText(textProps.text, textProps.x, textProps.y);
};

const CustomDoughnutChart = ({ data, bgColor = null, blur = false }) => {
    const boxRef = useRef(null);
    const frameRef = useRef(null);
    const arcRef = useRef(null);
    const titleRef = useRef(null);

    const drawChart = data => {
        drawFrame(data);
        drawArcs(data);
        drawTitle(data);
    };

    const drawFrame = data => {
        const canvas = frameRef.current;
        const ctx = canvas.getContext("2d");

        const fixedRatio = FRAME_PROPS.ratio;
        window.devicePixelRatio = fixedRatio;
        const dpr = window.devicePixelRatio;

        const cw = Math.floor(FRAME_PROPS.size.x * dpr);
        const ch = Math.floor(FRAME_PROPS.size.y * dpr);
        // const ax = cw / 4; // polifill 미적용시 4
        // const ay = ch / 4; // polyfill 미적용시 4
        const ax = cw / 2;
        const ay = ch / 2;

        canvas.style.position = "relative";
        canvas.style.width = FRAME_PROPS.size.x + "px";
        canvas.style.height = FRAME_PROPS.size.y + "px";
        canvas.style.margin = "0 auto";
        canvas.style.letterSpacing = !isDesktop ? "0.5px" : "0";
        canvas.width = cw;
        canvas.height = ch;

        ctx.scale(dpr, dpr);

        ctx.fillStyle = bgColor || "transparent";
        ctx.fillRect(0, 0, cw, ch);

        if (blur) {
            ctx.filter = "blur(6px)";
            ctx.transform = "translate3d(0,0,0)";
        }

        textOnCanvas(ctx, { text: data.chartTitle.main, x: ax, y: ay + 6 });
        textOnCanvas(ctx, { text: data.chartTitle.sub, x: ax, y: ay + 24 });

        if (data.source) {
            textOnCanvas(ctx, { fontSize: 12, text: data.source, x: ax, y: FRAME_PROPS.size.y - 24 });
        }

        data.customLabels.forEach((label, idx) => {
            const x = idx % 2 > 0 ? ax - (!isDesktop ? 110 : 85) : ax + (!isDesktop ? 35 : 40);
            const y = ay + 120 + (idx < 2 ? 0 : 1) * 25;

            ctx.beginPath();
            ctx.arc(x - 10, y - 5, 4, 0, Math.PI * 2);
            ctx.fillStyle = label.color;
            ctx.fill();

            textOnCanvas(ctx, { textAlign: "left", text: label.text, x: x, y: y });
        });
    };

    const drawTitle = data => {
        const canvas = titleRef.current;
        const ctx = canvas.getContext("2d");

        const cw = Math.floor(FRAME_PROPS.size.x);
        const ch = Math.floor(FRAME_PROPS.size.y);
        const ax = cw / 2;
        const ay = ch / 2;

        canvas.style.position = "absolute";
        canvas.style.top = "0";
        canvas.style.left = "50%";
        canvas.style.transform = "translateX(-50%)";
        canvas.style.width = cw + "px";
        canvas.style.height = ch + "px";
        canvas.width = cw;
        canvas.height = ch;

        ctx.fillStyle = "transparent";
        ctx.fillRect(0, 0, cw, ch);

        // main title
        textOnCanvas(ctx, { fontSize: 16.5, text: data.title, x: ax, y: 40 });

        // label
        data.inform.forEach((info, idx) => {
            textOnCanvas(ctx, { fontSize: 13.5, color: "rgba(255,255,255,.8)", text: info, x: ax, y: 68 + 17 * (idx % 2) });
        });

        if (blur) {
            textOnCanvas(ctx, {
                fontWeight: "bold",
                fontSize: 15,
                color: "rgba(228,178,81)",
                text: "로그인 후 확인 가능합니다",
                x: ax,
                y: ay + 15,
            });
        }
    };

    const drawArcs = data => {
        const arcCanvas = arcRef.current;
        const ctx = arcCanvas.getContext("2d");

        const cw = ARC_PROPS.size;
        const ch = ARC_PROPS.size;
        const ax = cw / 2;
        const ay = ch / 2;
        const radius = 75;
        arcCanvas.style.position = "absolute";
        arcCanvas.style.top = "calc(50% + 10px)";
        arcCanvas.style.left = "50%";
        arcCanvas.style.transform = "translate(-50%, -50%)";
        arcCanvas.style.width = cw;
        arcCanvas.style.height = ch;

        arcCanvas.width = cw;
        arcCanvas.height = ch;

        if (blur) {
            ctx.filter = "blur(6px)";
            ctx.transform = "translate3d(0,0,0)";
        }

        const TOTAL = data.customDataset[0];

        const customOffset = 1.5 * Math.PI;
        const customAngle = angle => angle + customOffset;

        const drawArc = (angle, idx) => {
            const ar = radius - idx * ARC_PROPS.gap;
            ctx.strokeStyle = idx < 0 ? "rgba(255,255,255,.3)" : angle <= 0 ? "transparent" : PALETTE[idx];

            ctx.beginPath();
            ctx.arc(ax, ay, ar, customOffset, customAngle(angle));
            ctx.lineWidth = ARC_PROPS.lineWidth;
            ctx.lineCap = "round";
            ctx.stroke();
            ctx.closePath();
        };

        if (TOTAL > 0) {
            const rateArray = data.customDataset.map(item => parseFloat(item / TOTAL).toFixed(2));
            let aniArray = new Array(rateArray.length).fill(-0.22);
            const step = 0.015;

            const arcAnimtaion = () => {
                if (aniArray[0] >= 1) window.cancelAnimationFrame(arcAnimtaion);

                aniArray = aniArray.map((item, idx) => (item >= rateArray[idx] ? rateArray[idx] : item + step));
                ctx.clearRect(0, 0, cw, ch);
                aniArray.forEach((item, idx) => (idx === 0 ? drawArc(Math.PI * 2, idx) : drawArc(item * (Math.PI * 2), idx)));
                window.requestAnimationFrame(arcAnimtaion);
            };
            window.requestAnimationFrame(arcAnimtaion);
        } else {
            drawArc(Math.PI * 2, -1);
        }
    };

    useEffect(() => {
        let io = undefined;
        if (boxRef.current && data) {
            io = new IntersectionObserver(
                function (entries, observer) {
                    if (entries[0].isIntersecting) {
                        drawChart(data);
                        observer.disconnect();
                    }
                },
                { threshold: 0.5 }
            );
            io.observe(boxRef.current);
        }
    }, [data, boxRef.current]);

    return (
        <CanvasWrapper ref={boxRef} bgColor={bgColor || "transparent"} blur={blur}>
            <canvas ref={frameRef} />
            <canvas ref={titleRef} />
            <canvas ref={arcRef} />
        </CanvasWrapper>
    );
};

export default CustomDoughnutChart;
