import { Head, Wrap, Title, Back, Alarm } from './ListHeadStyle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activateLoginRequired } from '../../../store/actions/service';
import { activateMyAlarmForm } from '../../../store/actions/page';
import { useNavigate } from 'react-router-dom';
import { updateFilterForData } from '../../../store/actions/filter';

const ListHeader = ({ title, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const USER_LOGGEDIN = useSelector((state) => state.User.loggedIn);

  const handleBackClick = () => {
    dispatch(updateFilterForData({ category2: null, sort: 'sisul' }));
    navigate('/');
  };

  const handleAlarmClick = () => {
    if (USER_LOGGEDIN) dispatch(activateMyAlarmForm());
    else dispatch(activateLoginRequired());
  };

  return (
    <Head>
      <Back onClick={() => handleBackClick()}></Back>
      <Alarm
        onClick={() => {
          handleAlarmClick();
        }}
      >
        지역알림
      </Alarm>
      <Title>{title}</Title>
      <Wrap>{children}</Wrap>
    </Head>
  );
};

export default ListHeader;
