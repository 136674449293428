import styled from 'styled-components';
import telIcon from '../../../assets/images/floating/ico-tel.svg';
import telIconBlue from '../../../assets/images/floating/ico-tel_blue.svg';
import docsIcon01 from '../../../assets/images/floating/ico-docs01.svg';
import docsIcon02 from '../../../assets/images/floating/ico-docs02.svg';

export const ContactButton = styled.a`
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: url(${(props) => (props.color === 'primary' ? telIcon : telIconBlue)}) center / 100% 100% no-repeat;
  box-shadow: ${(props) => (props.shadow ? '10px 10px 15px rgba(0,0,0,.15)' : 'none')};
`;

export const SpreadWrap = styled.div`
  width: 40px;
  height: 40px;
  overflow: visible;
  border-radius: 20px;
`;

export const SpreadButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
  padding: 0;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 25px;
    height: 3px;
    border-radius: 2px;
    background-color: #fff;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
    transition: transform 0.3s ease-in-out;
  }

  .f-on & {
    &::after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
`;

const icons = [docsIcon01, docsIcon02];
export const SpreadItem = styled.div`
  position: absolute;
  top: ${(props) => props.index * 56 + 'px'};
  left: 50px;
  height: 48px;
  overflow: hidden;
  transition: width 0.4s ease-in-out;
  background: transprent;

  button {
    width: 124px;
    height: 48px;
    border-radius: 24px;
    line-height: 46px;
    padding-left: 46px;
    background-position: 16px 10px;
    background-repeat: no-repeat;
    background-image: url(${(props) => icons[props.index % 2]});
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    white-space: nowrap;
    font-size: ${({ theme }) => theme.fontSizes.s};
  }

  .f-on & {
    width: 124px;
  }

  .f-off & {
    width: 0;
  }
`;
