import React, { useState, useRef, useEffect } from 'react';
import { Wrapper, Form, ResetIcon, CalcForm, CalcHeader, ResultAbstract } from '../CalculatorStyle';
import { isDesktop } from '../../../utils/detect';
import { module } from '../../../themes/module';
import { BUILD_FORM, getBuildResult } from '../../../scheme/calculator/build';
import { useOnlyNum } from '../../../hooks/form';

const BuildCalculator = () => {
  const [result, setResult] = useState(null);
  const [resultShow, setResultShow] = useState(false);
  const [inputOn, setInputOn] = useState(true);

  const [publicParking, setPublickParking] = useState('O');
  const [privateSize, setPrivateSize, resetPrivateSize] = useOnlyNum('');
  const [publicSize, setPublicSize, resetPublicSize] = useOnlyNum('');
  const [parkingSize, setParkingSize, resetParkingSize] = useOnlyNum('');
  const [roadSize, setRoadSize, resetRoadSize] = useOnlyNum(6);

  const resultRef = useRef(null);
  const boxRef = useRef(null);

  const onSubmitClick = e => {
    e.preventDefault();
    setInputOn(false);

    const form = new FormData(e.target);
    const result = getBuildResult({
      type: form.get('ac0'),
      regionAddr: form.get('ac1'),
      regionType: form.get('ac2'),
      assets: form.get('ac3'),
      roadSize: form.get('ac4'),
      privateSize: form.get('ac5'),
      publicSize: form.get('ac6'),
      publicParking: form.get('ac7'),
      parkingSize: form.get('ac8'),
      limit: form.get('ac9'),
    });
    setResult(result);
  };

  const onResetClick = e => {
    setInputOn(true);
    setResult(null);
    setResultShow(false);
    resetPrivateSize('');
    resetPublicSize('');
    resetParkingSize('');
    resetRoadSize('6');
    e.currentTarget.reset();
  };

  useEffect(() => {
    if (publicParking === 'O') resetParkingSize('');
  }, [publicParking]);

  useEffect(() => {
    if (result) {
      boxRef.current.scrollTo({
        top: resultRef.current.getBoundingClientRect().top,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [result]);

  useEffect(() => {
    if (resultShow) {
      const rect = resultRef.current.getBoundingClientRect();
      boxRef.current.scrollTo({
        top: rect.top + rect.height,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [resultShow]);

  return (
    <Wrapper ref={boxRef} className={result ? 'calced' : ''}>
      <div className="wrap">
        {isDesktop && (
          <Form
            onSubmit={e => {
              onSubmitClick(e);
            }}
            onReset={e => {
              onResetClick(e);
            }}
          >
            <fieldset>
              <legend>신규 시설 인원 산출 계산기</legend>
              <table>
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <thead>
                  <tr>
                    {BUILD_FORM.slice(0, 5).map((item, idx) => (
                      <th key={idx}>
                        <label htmlFor={`ac${idx}`}>{item.label}</label>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {BUILD_FORM.slice(0, 4).map((item, idx) => (
                      <td key={idx}>
                        {item.type === 'select' && (
                          <select
                            id={'ac' + idx}
                            name={'ac' + idx}
                            className={idx === 1 ? 'fs-s' : ''}
                            disabled={!inputOn}
                            defaultValue={item.defaultValue}
                          >
                            {item.options.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.text}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    ))}
                    <td>
                      <input
                        id="ac4"
                        name="ac4"
                        value={roadSize}
                        placeholder={BUILD_FORM[5].placeholder}
                        onChange={event => setRoadSize(event)}
                        readOnly={!inputOn}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <colgroup>
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                  <col width="20%" />
                </colgroup>
                <thead>
                  <tr>
                    {BUILD_FORM.slice(5, 10).map((item, idx) => (
                      <th key={idx}>
                        <label htmlFor={`ac${idx}`}>{item.label}</label>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        id="ac5"
                        name="ac5"
                        value={privateSize}
                        placeholder="숫자 입력"
                        onChange={event => setPrivateSize(event)}
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <input
                        id="ac6"
                        name="ac6"
                        value={publicSize}
                        placeholder="숫자 입력"
                        onChange={event => setPublicSize(event)}
                        readOnly={!inputOn}
                      />
                    </td>
                    <td>
                      <select
                        id="ac7"
                        name="ac7"
                        defaultValue={publicParking}
                        onChange={event => setPublickParking(event.target.value)}
                        disabled={!inputOn}
                      >
                        <option value="O">O</option>
                        <option value="X">X</option>
                      </select>
                    </td>
                    <td>
                      <input
                        id="ac8"
                        name="ac8"
                        value={parkingSize}
                        placeholder="숫자 입력"
                        onChange={event => setParkingSize(event)}
                        readOnly={!inputOn || publicParking === 'O'}
                      />
                    </td>
                    <td>
                      <select
                        id="ac9"
                        name="ac9"
                        disabled={!inputOn}
                        defaultValue={BUILD_FORM[9].defaultValue}
                      >
                        {BUILD_FORM[9].options.map((option, idx) => (
                          <option key={idx} value={option.value}>
                            {option.text}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
            <div className="actions">
              <button type="submit">계산하기</button>
              <button type="reset">초기화</button>
            </div>
          </Form>
        )}
        {!isDesktop && (
          <module.MobileForm
            onSubmit={e => {
              onSubmitClick(e);
            }}
            onReset={e => {
              onResetClick(e);
            }}
          >
            <fieldset className="cols">
              {BUILD_FORM.slice(0, 4).map((item, idx) => (
                <div className="wrap" key={idx}>
                  <label htmlFor={'ac' + idx}>{item.label}</label>
                  <select
                    id={'ac' + idx}
                    name={'ac' + idx}
                    className={idx === 1 ? 'fs-s' : ''}
                    disabled={!inputOn}
                  >
                    {item.options.map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              <div className="wrap">
                <label htmlFor="ac4">{BUILD_FORM[4].label}</label>
                <input
                  id="ac4"
                  name="ac4"
                  value={roadSize}
                  placeholder={BUILD_FORM[5].placeholder}
                  onChange={event => setRoadSize(event)}
                  readOnly={!inputOn}
                />
              </div>
              <div className="wrap">
                <label htmlFor="ac5">{BUILD_FORM[5].label}</label>
                <input
                  id="ac5"
                  name="ac5"
                  value={privateSize}
                  placeholder="숫자 입력"
                  onChange={event => setPrivateSize(event)}
                  readOnly={!inputOn}
                />
              </div>
              <div className="wrap">
                <label htmlFor="ac6">{BUILD_FORM[6].label}</label>
                <input
                  id="ac6"
                  name="ac6"
                  value={publicSize}
                  placeholder="숫자 입력"
                  onChange={event => setPublicSize(event)}
                  readOnly={!inputOn}
                />
              </div>
              <div className="wrap">
                <label htmlFor="ac7">{BUILD_FORM[7].label}</label>
                <select
                  id="ac7"
                  name="ac7"
                  defaultValue={publicParking}
                  onChange={event => setPublickParking(event.target.value)}
                  disabled={!inputOn}
                >
                  <option value="O">O</option>
                  <option value="X">X</option>
                </select>
              </div>
              <div className="wrap">
                <label htmlFor="ac8">{BUILD_FORM[8].label}</label>
                <input
                  id="ac8"
                  name="ac8"
                  value={parkingSize}
                  placeholder="숫자 입력"
                  onChange={event => setParkingSize(event)}
                  readOnly={!inputOn || publicParking === 'O'}
                />
              </div>
              <div className="wrap">
                <label htmlFor="ac9">{BUILD_FORM[9].label}</label>
                <select id="ac9" name="ac9" disabled={!inputOn}>
                  {BUILD_FORM[9].options.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </fieldset>
            <div className="actions">
              {!result && <button type="submit">계산하기</button>}
              {result && (
                <button type="reset">
                  초기화
                  <ResetIcon />
                </button>
              )}
            </div>
          </module.MobileForm>
        )}
        {result && (
          <>
            <ResultAbstract ref={resultRef}>
              <div className="result">
                <span>
                  <strong>정원 {result.calc.f}</strong>{' '}
                  {result.permission === 'O'
                    ? '신규 요양시설 설립 가능'
                    : '신규 요양시설 설립 불가'}
                </span>
              </div>
              <button
                className={resultShow ? 'on' : ''}
                onClick={() => {
                  setResultShow(resultShow => !resultShow);
                }}
              >
                상세 검토 결과 보기
              </button>
            </ResultAbstract>
            {resultShow && (
              <CalcForm>
                <CalcHeader>
                  <span className="title">신규 인원 산출 검토 상세</span>
                </CalcHeader>
                <table>
                  {isDesktop && (
                    <colgroup>
                      <col width="160px"></col>
                      <col width="auto"></col>
                      <col width="160px"></col>
                    </colgroup>
                  )}
                  {!isDesktop && (
                    <colgroup>
                      <col width="70%"></col>
                      <col width="30%"></col>
                    </colgroup>
                  )}
                  <thead>
                    <tr>
                      <th colSpan={isDesktop ? '2' : '1'}>구분</th>
                      <th>상세내용</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={isDesktop ? '2' : '1'}>요양시설 가능 여부</td>
                      <td className="accent">{result.permission}</td>
                    </tr>
                    <tr>
                      <td colSpan={isDesktop ? '2' : '1'}>총량제 (쿼터제)</td>
                      <td>{result.quarter}</td>
                    </tr>
                    <tr>
                      <td colSpan={isDesktop ? '2' : '1'}>특이 사항</td>
                      <td>{result.remarks}</td>
                    </tr>
                    {!isDesktop && (
                      <tr className="cost">
                        <td colSpan="2">시설 가능 정원 계산</td>
                      </tr>
                    )}
                    <tr>
                      {isDesktop && <td rowSpan="6">시설 가능 정원 계산</td>}
                      <td>A. 전유면적 + 공용면적</td>
                      <td>{result.calc.a}</td>
                    </tr>
                    <tr>
                      <td>B. 전유면적 + 공용면적 - 주차장 면적</td>
                      <td>{result.calc.b}</td>
                    </tr>
                    <tr>
                      <td>C. 의무 주차장 차량 대수 = A ÷ 지자체 설치 기준 면적 (반올림)</td>
                      <td>{result.calc.c}</td>
                    </tr>
                    <tr>
                      <td>D. 의무 주차장 면적 = C X 12.5㎡</td>
                      <td>{result.calc.d}</td>
                    </tr>
                    <tr>
                      <td>E. 총면적 = B + D</td>
                      <td>{result.calc.e}</td>
                    </tr>
                    <tr>
                      <td>F. 가능 정원 = E ÷ 23.6㎡ (소수점 절사)</td>
                      <td className="accent">{result.calc.f}</td>
                    </tr>
                  </tbody>
                </table>
                <span className="warn">
                  ※ 건축물 대장 상 공용부분에 주차장 면적이 일괄적으로 포함되어 있으나 지자체 구분
                  상 주차장 면적을 제외해야 하는 경우가 있으므로 지자체에 반드시 문의하시기
                  바랍니다.{' '}
                </span>
              </CalcForm>
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default BuildCalculator;
