import React from 'react';
import { useDispatch } from 'react-redux';
import { Wrap, Calculator } from './CalculatorsMenuStyled';
import { activateCalculator, activateAddsCalculator, activateBuildCalculator } from '../../../store/actions/service';
import Modal from '../../Modal/Modal';

// const modalStyle = {
//   overlay: {
//     zIndex: '90',
//     backgroundColor: 'rgba(0, 0, 0, 0.4)',
//   },
//   content: {
//     zIndex: '91',
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     width: '100%',
//     maxWidth: `${window.innerWidth > 320 ? '320px' : 'calc(100% - 28px)'}`,
//     height: 'auto',
//     maxHeight: 'calc(100% - 160px)',
//     padding: '0',
//     transform: 'translate(-50%, -50%)',
//     overflow: 'hidden',
//     borderRadius: '10px',
//   },
// };

const CalculatorsMenu = ({ onCloseClick }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      title="보노랜드 계산기"
      description="보노랜드 계산기를 통해 요양시설의 예상수익, 신규 설립 요양시설 인원 산출, 예상 가산금을 확인해보세요."
      width="300"
      onCloseClick={onCloseClick}
      close={true}
    >
      <Wrap>
        <Calculator>
          <button
            onClick={() => {
              onCloseClick();
              dispatch(activateCalculator());
            }}
          >
            수익 계산기
          </button>
          <p>요양시설의 월 예상 수익을 직접 계산할 수 있습니다. </p>
        </Calculator>
        <Calculator>
          <button
            onClick={() => {
              onCloseClick();
              dispatch(activateBuildCalculator());
            }}
          >
            신규 시설 인원 산출 계산기
          </button>
          <p>요양시설을 신규 설립 시 인가 가능 여부를 확인하고 가능 인원을 계산할 수 있습니다.</p>
        </Calculator>
        <Calculator>
          <button
            onClick={() => {
              onCloseClick();
              dispatch(activateAddsCalculator());
            }}
          >
            장기요양기관 예상 가산금 계산기
          </button>
          <p>요양시설 운영 예정 시 받을 수 있는 예상 가산금을 계산할 수 있습니다.</p>
        </Calculator>
      </Wrap>
    </Modal>
  );
};

export default CalculatorsMenu;
