import styled, { css, keyframes } from 'styled-components';
import checkOffIcon from '../../../assets/images/guide/ico-check_default.svg';
import checkOnIcon from '../../../assets/images/guide/ico-check_checked.svg';
import checkOnPcIcon from '../../../assets/images/form/ico-checkbox_checked.svg';
import pointingIcon from '../../../assets/images/guide/ico-pointing_all.svg';
// arrow
import arrowIcon_vertical from '../../../assets/images/guide/ico-arrow.svg';
import arrowIcon_horizontal from '../../../assets/images/guide/ico-arrow_horizontal.svg';
import curvedArrowIcon_vertical from '../../../assets/images/guide/ico-arrow_curved.svg';
import curvedArrowIcon_horizontal from '../../../assets/images/guide/ico-arrow_curved_horizontal.svg';
import shortArrowIcon_vertical from '../../../assets/images/guide/ico-shortArrow_vertical.svg';
import shortArrowIcon_horizontal from '../../../assets/images/guide/ico-shortArrow_horizontal.svg';
// pc arrow
import registerArrow from '../../../assets/images/guide/ico-registerArrow.svg';
import cate01Arrow from '../../../assets/images/guide/ico-cate01Arrow.svg';
import cate02Arrow from '../../../assets/images/guide/ico-cate02Arrow.svg';
import cate03Arrow from '../../../assets/images/guide/ico-cate03Arrow.svg';
// mobile arrow
import mobileMarkerArrow from '../../../assets/images/guide/ico-mobileMarkerArrow.svg';
import mobileAlarmArrow from '../../../assets/images/guide/ico-mobileAlarmArrow.svg';
import mobileRegisterArrow from '../../../assets/images/guide/ico-mobileRegisterArrow.svg';
import mobileSalesArrow from '../../../assets/images/guide/ico-mobileSalesArrow.svg';
import mobileRcmdArrow from '../../../assets/images/guide/ico-mobileRcmdArrow.svg';
import mobileCate01Arrow from '../../../assets/images/guide/ico-mobileCate01Arrow.svg';
import mobileCate02Arrow from '../../../assets/images/guide/ico-mobileCate02Arrow.svg';
import mobileCate03Arrow from '../../../assets/images/guide/ico-mobileCate03Arrow.svg';
// import mobileTelArrow from '../../../assets/images/guide/ico-mobileTelArrow.svg';
import mobileLoginArrow from '../../../assets/images/guide/ico-mobileLoginArrow.svg';
import mobileDetailsCalcArrow from '../../../assets/images/guide/ico-mobileDetailsCalcArrow.svg';
// welcome
import welcomeLogo from '../../../assets/images/guide/ico-welcomeLogo.svg';
import welcomeSearch from '../../../assets/images/guide/img-welcomeSearch.png';
import welcomeCalc from '../../../assets/images/guide/img-welcomeCalc.png';
import welcomePrivacy from '../../../assets/images/guide/img-welcomePrivacy.png';
import welcomeMarkets from '../../../assets/images/guide/img-welcomeMarkets.png';

export const GuidePage = styled.div`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .wrap {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;

    svg {
      z-index: 101;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .loadingWrap {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const nextButtonMotion = keyframes`
    0% { opacity: 0; transform: translateY(10px); }
    40% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
`;

export const NextButton = styled.button`
  z-index: 103;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 95px);
  width: 190px;
  height: 120px;
  border-radius: 20px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  background: url(${pointingIcon}) center no-repeat;
  background-color: rgba(0, 0, 0, 0.2);
  transform-origin: bottom center;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  animation: ${nextButtonMotion} 2.5s;

  &:hover {
    transform: scale(0.98);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const NextInform = styled.div`
  z-index: 102;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.8);
  font-family: 'GmarketSans';
  font-size: ${({ theme }) => theme.fontSizes.base};
  letter-spacing: 1px;
  white-space: nowrap;

  strong {
    color: ${({ theme }) => theme.colors.primary};
  }

  .mobile & {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

const arrowIconCss = (direction, arrow = '') =>
  css`
    position: relative;
    width: ${direction === 'horizontal' ? '116px' : '20px'};
    height: ${direction === 'horizontal' ? '20px' : '116px'};
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: url(${direction === 'horizontal' ? arrowIcon_horizontal : arrowIcon_vertical}) center no-repeat;
      transform: rotate(${arrow === 'toRight' ? '180deg' : '0'});
    }
  `;
const curvedArrowIconCss = (direction, arrow = '') =>
  css`
    position: relative;
    width: ${direction === 'horizontal' ? '116px' : '80px'};
    height: ${direction === 'horizontal' ? '80px' : '116px'};
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: url(${direction === 'horizontal' ? curvedArrowIcon_horizontal : curvedArrowIcon_vertical}) center
        no-repeat;
      transform: rotate(${arrow === 'toRight' ? '180deg' : '0'});
    }
  `;

const shortArrowIconCss = (direction, arrow = '') =>
  css`
    position: relative;
    width: ${direction === 'horizontal' ? '56px' : '20px'};
    height: ${direction === 'horizontal' ? '20px' : '56px'};
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: url(${direction === 'horizontal' ? shortArrowIcon_horizontal : shortArrowIcon_vertical}) center
        no-repeat;
      transform: rotate(${arrow === 'toRight' ? '180deg' : '0'});
    }
  `;

const fadeIn = keyframes`
    0% { opacity: 0; transform: translateY(2%); }
    100% { opacity: 1; transform: translateY(0); }
`;

export const GuideBox = styled.article`
  z-index: 102;
  position: absolute;
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    display: flex;
    flex-direction: column;
    gap: 0;
    width: 160px;
    text-align: center;

    h3 {
      width: 100%;
      padding: 6px 0;
      background-color: #fff;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      border-radius: 6px 6px 0 0;
    }

    p {
      width: 100%;
      padding: 16px;
      border-radius: 0 0 6px 6px;
      border: 1px solid #fff;
      background-color: rgba(62, 70, 142, 0.8);
      word-break: keep-all;
      color: #fff;
      overflow: hidden;
      white-space: pre-wrap;
      line-height: 1.4;
      font-weight: ${({ theme }) => theme.fontWeights.regular};

      strong,
      a {
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.primary};
      }

      a {
        display: inline-block;
        margin-bottom: 2px;
        text-decoration: underline;
      }
    }

    .mobile & {
      font-size: ${({ theme }) => theme.fontSizes.s};
    }
  }

  &.box-statistics {
    flex-direction: row-reverse;
    align-items: start;

    div {
      width: 240px;
      transform: translateY(-70px);
      p {
        background-color: rgba(62, 70, 142, 1);
      }
    }
    i {
      ${arrowIconCss('horizontal', 'toRight')}
    }
  }
  &.box-statistics02 {
    div {
      display: none;
    }
    i {
      ${arrowIconCss('vertical')} transform: scaleY(-1);
    }
  }
  &.box-marker {
    flex-direction: column-reverse;
    align-items: center;

    div {
      width: 180px;
    }
    i {
      ${arrowIconCss('vertical')} transform: scaleY(-1)
    }
  }
  &.box-calc {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-cate01 {
    align-items: end;

    div {
      width: 180px;
      transform: translateY(22px);
    }
    i {
      width: 442px;
      height: 405px;
      background: url(${cate01Arrow}) left center no-repeat;
    }
  }
  &.box-cate02 {
    align-items: end;

    div {
      width: 180px;
      transform: translateY(30px);
    }
    i {
      width: 328px;
      height: 250px;
      background: url(${cate02Arrow}) left center no-repeat;
    }
  }
  &.box-cate03 {
    align-items: start;

    div {
      width: 180px;
    }
    i {
      width: 214px;
      height: 94px;
      background: url(${cate03Arrow}) left center no-repeat;
    }
  }
  &.box-register {
    flex-direction: column;
    align-items: end;

    div {
      width: 180px;
    }
    i {
      width: 54px;
      height: 140px;
      background: url(${registerArrow}) center no-repeat;
    }
  }
  &.box-contact {
    flex-direction: column;
    align-items: start;

    div {
      width: 180px;
    }
    i {
      width: 54px;
      height: 140px;
      background: url(${registerArrow}) center no-repeat;
      transform: scaleX(-1);
    }
  }
  &.box-signIn {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-myPage {
    flex-direction: row-reverse;
    align-items: start;

    div {
      transform: translateY(-50px);
    }
    i {
      ${arrowIconCss('horizontal', 'toRight')}
    }
  }
  &.box-search {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-sales {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-rcmd {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-dCalc {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-details {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-lands {
    flex-direction: column;
    align-items: center;

    i {
      ${arrowIconCss('vertical')}
    }
  }
  &.box-mCalc {
    flex-direction: row-reverse;
    align-items: center;

    div {
      width: 150px;
    }
    i {
      ${arrowIconCss('horizontal', 'toRight')}
    }
  }
  &.box-mMarker {
    flex-direction: row;
    align-items: start;

    div {
      width: 160px;
      transform: translateY(-60px);
    }
    i {
      width: 52px;
      height: 134px;
      background: url(${mobileMarkerArrow}) center no-repeat;
    }
  }
  &.box-mStatistics {
    div {
      display: none;
    }
    i {
      ${curvedArrowIconCss('horizontal', 'toRight')} transform: scaleY(-1);
    }
  }
  &.box-mStatistics02 {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    height: calc(50% - 60px);

    div {
      width: 200px;
    }
    i {
      ${arrowIconCss('vertical')} transform: scaleY(-1);
    }
  }
  &.box-mAlarm {
    flex-direction: row-reverse;
    align-items: start;

    div {
      width: 200px;
      transform: translateY(-24px);
    }
    i {
      width: 90px;
      height: 270px;
      background: url(${mobileAlarmArrow}) center no-repeat;
    }
  }
  &.box-mSales {
    flex-direction: column-reverse;
    align-items: start;

    div {
      width: 130px;
    }
    i {
      width: 20px;
      height: 245px;
      background: url(${mobileSalesArrow}) center bottom no-repeat;
    }
  }
  &.box-mSales-long {
      flex-direction: column-reverse;
      align-items: start;

      div {
            width: 130px;
      }
      i {
            width: 20px;
            height: 386px;
            background: url(${mobileSalesArrow}) center bottom no-repeat;
      }
  }
  &.box-mSales-shart {
      flex-direction: column-reverse;
      align-items: flex-start;

      div {
            width: 160px;
      }
      i {
            width: 50px;
            height: 140px;
            background: url(${mobileSalesArrow}) center bottom no-repeat;
      }
  }
  &.box-mRcmd {
    flex-direction: column-reverse;
    align-items: start;

    div {
      width: 130px;
    }
    i {
      width: 40px;
      height: 250px;
      background: url(${mobileRcmdArrow}) center bottom no-repeat;
    }
  }
  &.box-mRegister {
    flex-direction: column-reverse;
    align-items: start;

    div {
      width: 160px;
    }
    i {
      width: 50px;
      height: 144px;
      background: url(${mobileRegisterArrow}) center bottom no-repeat;
    }
  }
  &.box-mContact {
    flex-direction: column-reverse;
    align-items: center;
    gap: 10px;

    div {
      width: 160px;
    }
    i {
      ${shortArrowIconCss('vertical')} height: 22px;
      transform: scaleY(-1);
    }
    i::after {
      background-position: center top;
    }
  }
  &.box-mLogin {
    flex-direction: column-reverse;
    align-items: center;

    i {
      width: 72px;
      height: 80px;
      background: url(${mobileLoginArrow}) center no-repeat;
      transform: translateX(36px);
    }
  }
  &.box-mTel {
    flex-direction: column-reverse;
    align-items: end;

    i {
      width: 46px;
      height: 265px;
      background: url(${mobileSalesArrow}) center bottom no-repeat;
      transform: translateX(0);
    }
  }
  &.box-mSearch {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
      ${shortArrowIconCss('vertical')}
    }
  }

  &.box-mCate01 {
    align-items: start;

    div {
      transform: translateY(0px);
    }
    i {
      width: 92px;
      height: 70px;
      background: url(${mobileCate01Arrow}) center no-repeat;
    }
  }
  &.box-mCate02 {
    align-items: start;

    div {
      transform: translateY(90px);
    }
    i {
      width: 92px;
      height: 158px;
      background: url(${mobileCate02Arrow}) center no-repeat;
    }
  }
  &.box-mCate03 {
    align-items: start;

    div {
      transform: translateY(180px);
    }
    i {
      width: 92px;
      height: 252px;
      background: url(${mobileCate03Arrow}) center no-repeat;
    }
  }
  &.box-mDetails {
    flex-direction: column;
    align-items: center;

    i {
      ${shortArrowIconCss('vertical')}
    }
  }
  &.box-mLands {
    flex-direction: column;
    align-items: center;

    i {
      ${shortArrowIconCss('vertical')}
    }
  }
  &.box-mDCalc {
    flex-direction: row-reverse;
    align-items: end;

    div {
      transform: translateY(90px);
    }
    i {
      width: 78px;
      height: 80px;
      background: url(${mobileDetailsCalcArrow}) center no-repeat;
    }
  }

  .welcome {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    animation: ${fadeIn} 1.2s forwards;

    .logo {
      position: absolute;
      top: 0;
      width: 195px;
      height: 38px;
      background: url(${welcomeLogo}) center / 100% auto no-repeat;
    }
    h2 {
      margin-top: 50px;
      text-align: center;
      font-size: 32px;
      color: #fff;
      font-weight: ${({ theme }) => theme.fontWeights.bold};

      strong {
        color: rgba(255, 107, 0, 1);
      }
    }
    p {
      margin: 32px 0 80px;
      color: #fff;
      line-height: 2;
      font-size: ${({ theme }) => theme.fontSizes.l};

      strong {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
    ul {
      display: flex;
      gap: 32px;

      li {
        width: 245px;
        height: 190px;
        padding: 130px 0 0;
        border-radius: 10px;
        color: #fff;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        background-position: center 30px;
        background-repeat: no-repeat;
        background-size: 70px 70px;
        font-size: ${({ theme }) => theme.fontSizes.base};

        &.search {
          background-image: url(${welcomeSearch});
          background-color: rgba(255, 107, 0, 0.8);
        }
        &.calc {
          background-image: url(${welcomeCalc});
          background-color: rgba(107, 203, 63, 0.8);
        }
        &.privacy {
          background-image: url(${welcomePrivacy});
          background-color: rgba(189, 147, 105, 0.8);
        }
        &.markets {
          background-image: url(${welcomeMarkets});
          background-color: rgba(0, 31, 96, 0.8);
        }
      }
    }

    .mobile & {
      .logo {
        position: relative;
        width: 163px;
        height: 36px;
      }
      h2 {
        margin-top: 30px;
        font-size: 20px;
      }
      p {
        margin: 12px 0 40px;
        line-height: 1.4;
      }
      ul {
        flex-direction: column;
        align-items: center;
        gap: 16px;

        li {
          width: 240px;
          height: 56px;
          line-height: 56px;
          padding: 0 0 0 80px;
          text-align: left;
          background-position: 30px center;
          background-size: 24px 24px;
        }
      }
    }
  }

  &.box-welcome {
    position: fixed;
    width: 1076px;
    height: 500px;

    .mobile & {
      width: 280px;
    }

    > div {
      width: 100%;
      height: 500px;

      > h3 {
        display: none;
      }
      p {
        padding: 0;
        background-color: transparent;
        border: 0;
        white-space: normal;
      }
    }
  }
`;

export const CloseForm = styled.form`
  z-index: 102;
  position: absolute;
  bottom: 80px;
  left: calc(50% - 120px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  height: 44px;

  fieldset {
    position: relative;
    width: auto;
    height: 20px;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    label {
      padding-left: 26px;
      height: 18px;
      line-height: 18px;
      color: #fff;
      background: url(${checkOffIcon}) left center / 18px 18px no-repeat;
      cursor: pointer;
    }

    input:checked + label {
      background: url(${checkOnPcIcon}) left center / 18px 18px no-repeat;
      .mobile & {
        background: url(${checkOnIcon}) left center / 18px 18px no-repeat;
      }
    }
  }

  button {
    width: 90px;
    height: 44px;
    border-radius: 22px;
    background-color: ${({ theme }) => theme.colors.primary};
    line-height: 42px;
    color: #fff;
  }

  .mobile & {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;

    fieldset {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      label {
        color: ${({ theme }) => theme.colors.gray900};
      }
    }

    button {
      width: 50%;
      height: 100%;
      line-height: 56px;
      background-color: ${({ theme }) => theme.colors.secondary};
      border-radius: 0;
    }
  }
`;
