export const CATEGORY = {
  단독요양원: {
    label: '단독요양원',
    mobileLabel: '단독',
    value: '단독요양원',
    key: 'bonoDandok',
  },
  상가요양원: {
    label: '상가요양원',
    mobileLabel: '상가',
    value: '상가요양원',
    key: 'bonoSanga',
  },
  주간보호: {
    label: '주야간보호',
    mobileLabel: '주간',
    value: '주간보호',
    key: 'bonoJugan',
  },
};

export const TYPE_AND_MARKERS = {
  단독요양원: { 0: 0, 30: 30, 60: 60, 90: 90, 120: 120 },
  상가요양원: { 0: 0, 30: 30, 60: 60, 90: 90, 120: 120 },
  주간보호: { 0: 0, 30: 30, 60: 60, 90: 90, 120: 120 },
};

export const TYPE_AND_CAPACITY = {
  단독요양원: [
    {
      label: '전체',
      value: [0, 120],
    },
    {
      label: '0 ~ 29',
      value: [0, 29],
    },
    {
      label: '29 ~ 39',
      value: [29, 39],
    },
    {
      label: '39 ~ 59',
      value: [39, 59],
    },
    {
      label: '59 ~ 79',
      value: [59, 79],
    },
    {
      label: '79 ~ 99',
      value: [79, 99],
    },
    {
      label: '99 ~ 120',
      value: [99, 120],
    },
  ],
  상가요양원: [
    {
      label: '전체',
      value: [0, 120],
    },
    {
      label: '0 ~ 29',
      value: [0, 29],
    },
    {
      label: '29 ~ 39',
      value: [29, 39],
    },
    {
      label: '39 ~ 59',
      value: [39, 59],
    },
    {
      label: '59 ~ 79',
      value: [59, 79],
    },
    {
      label: '79 ~ 99',
      value: [79, 99],
    },
    {
      label: '99 ~ 120',
      value: [99, 120],
    },
  ],
  주간보호: [
    {
      label: '전체',
      value: [0, 120],
    },
    {
      label: '0 ~ 29',
      value: [0, 29],
    },
    {
      label: '29 ~ 39',
      value: [29, 39],
    },
    {
      label: '39 ~ 59',
      value: [39, 59],
    },
    {
      label: '59 ~ 79',
      value: [59, 79],
    },
    {
      label: '79 ~ 99',
      value: [79, 99],
    },
    {
      label: '99 ~ 120',
      value: [99, 120],
    },
  ],
};

export const CATEGORIES = (data) => {
  let result = [];
  if (data) {
    const selected = Object.keys(data).filter((key) => data[key].selected);
    result = selected.map((key) => ({
      category: key === '주간보호' ? '주야간보호센터' : key,
      min: data && data[key].selected ? data[key].capacity[0] : TYPE_AND_CAPACITY[key][0].value[0],
      max: data && data[key].selected ? data[key].capacity[1] : TYPE_AND_CAPACITY[key][0].value[1],
    }));
  }
  return result;
};
