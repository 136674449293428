import styled from "styled-components";

export const Card = styled.div`
  padding: 24px 16px;
  height: 100%;
  cursor: pointer;
  margin-top: 10px;

  &.wrapper {
    height: calc(100% - 56px);
    overflow-y: auto;
  }

  &.bd {
    height: auto;
    border-bottom: 10px solid ${({ theme }) => theme.colors.gray100};
  }
`;

export const Post = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: left;

  h3 {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.l};
    line-height: 1.5;
  }

  p {
    width: 100%;
    color: ${({ theme }) => theme.colors.gray600};
    line-height: 1.625;
    max-height: ${props => (props.type === "post" ? "auto" : "104px")};
    text-overflow: ellipsis;
    &.contents {
      white-space: pre-wrap;
    }
  }

  img {
    max-width: 100%;
  }

  .date {
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.s};
    text-align: right;
    color: ${({ theme }) => theme.colors.gray500};
  }
`;
