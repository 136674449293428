import { LANDING } from '../actions/landing';

const initialState = {
  landing: true,
};

const LandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANDING.LANDING_ON:
      return { landing: true };
    case LANDING.LANDING_OFF:
      return { landing: false };
    default:
      return state;
  }
};

export default LandingReducer;
