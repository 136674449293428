import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { isDesktop } from '../../../utils/detect';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateGuide } from '../../../store/actions/guide';
import { GuidePage, NextButton, NextInform, GuideBox, CloseForm } from './SiteGuideStyle';
import pcChart from '../../../assets/images/guide/img-pcChart.png';
import pcDetails from '../../../assets/images/guide/img-pcDetails.png';
import pcMarker from '../../../assets/images/guide/img-pcMarker.png';
import mbNav from '../../../assets/images/guide/img-mbNav.png';
import mbMarker from '../../../assets/images/guide/img-mbMarker.png';
import mbChart from '../../../assets/images/guide/img-mbChart.png';
import mbCalc from '../../../assets/images/guide/img-mbCalculator.png';
import { updateFilterForMap } from '../../../store/actions/filter';

const SiteGuide = ({ page }) => {
  const USER_LOGGEDIN = useSelector(state => state.User.loggedIn);

  const dispatch = useDispatch();

  const offRef = useRef();

  const GUIDE = useMemo(
    () => ({
      main: {
        pc: [
          {
            next: { element: 'page', customStyle: { top: 'auto', bottom: '140px' } },
            guides: [
              {
                boxText: {
                  title: '',
                  contents: {
                    __html: `
                                    <div class='welcome'>
                                        <div class='logo'></div>
                                        <h2>요양시설 중개의 새로운 <strong>패러다임!</strong></h2>
                                        <p>
                                            <strong>보노랜드</strong>는 공공 빅데이터를 활용한 <strong>프롭테크 서비스</strong>를 제공합니다.
                                            <br/>
                                            <strong>노인요양시설</strong>에 대한 정확한 정보를 한눈에 파악하고 <strong>다양한 서비스</strong>를 이용하세요!
                                        </p>
                                        <ul>
                                            <li class='search'><span>시장조사 기능</span></li>
                                            <li class='calc'><span>수익계산 기능</span></li>
                                            <li class='privacy'><span>프라이버시 보호</span></li>
                                            <li class='markets'><span>허위매물 차단</span></li>
                                        </ul>
                                    </div>
                                `,
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 - 532),
                    y: parseInt(window.innerHeight / 2 - 250),
                  }),
                },
                boxCssClass: 'welcome',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                guideImage: {
                  src: pcChart,
                  customStyle: () => ({
                    x: parseInt(window.innerWidth - 1310),
                    y: parseInt(window.innerHeight - 426),
                    width: 1280,
                    height: 396,
                  }),
                },
                boxText: { title: '', contents: { __html: '' } },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth - 340),
                    y: parseInt(window.innerHeight - 520),
                  }),
                },
                boxCssClass: 'statistics02',
              },
              {
                masking: { data: '4', customStyle: { rx: 4, ry: 4 } },
                boxText: {
                  title: '시장조사',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>고령 인구수, 등급판정 인원수,</strong><br><strong>요양시설 수, 이용률</strong><br/>을 확인하실 수 있습니다.',
                  },
                },
                boxPosition: { data: '4', customStyle: { x: -380, y: 10 } },
                boxCssClass: 'statistics',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                guideImage: {
                  src: pcMarker,
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 + 50),
                    y: parseInt(window.innerHeight / 2),
                    width: 400,
                  }),
                },
                boxText: {
                  title: '시장조사',
                  contents: {
                    __html:
                      '해당 지역의<br/> <strong>시설개수</strong> 확인 및<br/>해당 시의<br/><strong>등급 판정 인원 대비</strong><br/><strong>시설 포화도</strong><br/>를 알려드립니다.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 + 160),
                    y: parseInt(window.innerHeight / 2 - 340),
                  }),
                },
                boxCssClass: 'marker',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                masking: { data: '3', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '예상 수익 계산',
                  contents: {
                    __html: '원하는 시설의<br/><strong>예상 월수익</strong>을<br/> 계산해 보세요.',
                  },
                },
                boxPosition: { data: '3', customStyle: { x: -10, y: 60 } },
                boxCssClass: 'calc',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                masking: { data: '7-group' },
                boxText: {
                  title: '단독요양원',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>단독요양원</strong>을 조건<br/>별로 확인해 보세요.',
                  },
                },
                boxPosition: { data: '7', customStyle: { x: 40, y: 48 } },
                boxCssClass: 'cate01',
              },
              {
                boxText: {
                  title: '상가요양원',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>상가요양원</strong>을 조건<br/>별로 확인해 보세요.',
                  },
                },
                boxPosition: { data: '8', customStyle: { x: 44, y: 48 } },
                boxCssClass: 'cate02',
              },
              {
                boxText: {
                  title: '주야간보호센터',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>주야간보호센터</strong>을 조건<br/>별로 확인해 보세요.',
                  },
                },
                boxPosition: { data: '9', customStyle: { x: 48, y: 48 } },
                boxCssClass: 'cate03',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                masking: { data: '1', customStyle: { rx: 10, ry: 10 } },
                boxText: {
                  title: '홈',
                  contents: { __html: '<strong>홈</strong> 클릭 시 <br/>메인으로 돌아갑니다.' },
                },
                boxPosition: { data: '1', customStyle: { x: -200, y: 10 } },
                boxCssClass: 'register',
              },
              {
                masking: { data: '1-2', customStyle: { rx: 10, ry: 10 } },
                boxText: {
                  title: '매물접수',
                  contents: { __html: '<strong>시설의 매도</strong>를 원하면<br/>등록해 보세요.' },
                },
                boxPosition: { data: '1-2', customStyle: { x: -30, y: 55 } },
                boxCssClass: 'sales',
              },
              {
                masking: { data: '1-3', customStyle: { rx: 10, ry: 10 } },
                boxText: {
                  title: '매수문의',
                  contents: {
                    __html:
                      '<strong>원하는 유형과 규모의</strong><br/><strong>시설</strong>을 찾고 싶으면<br/>등록해 보세요.',
                  },
                },
                boxPosition: { data: '1-3', customStyle: { x: 120, y: 10 } },
                boxCssClass: 'contact',
              },
              {
                masking: { data: '7-group', customStyle: { x: 0, height: -6, y: 70 } },
                boxText: {
                  title: '보노랜드 매물',
                  contents: {
                    __html:
                      '<strong>보노랜드</strong>에서<br/>적극 추천하는<br/><strong>매물</strong>을 확인해<br/>보세요.',
                  },
                },
                boxPosition: { data: '7-group', customStyle: { x: 114, y: 120 } },
                boxCssClass: 'sales',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                masking: USER_LOGGEDIN
                  ? { data: '2-2', customStyle: { rx: 4, ry: 4 } }
                  : { data: '2', customStyle: { rx: 10, ry: 10 } },
                boxText: {
                  title: USER_LOGGEDIN ? '마이 페이지' : '로그인',
                  contents: {
                    __html: `${
                      USER_LOGGEDIN ? '' : '회원가입/로그인 후<br/>'
                    }<strong>마이페이지 > 알림</strong><br/>을 설정하시면<br/>원하는 타입의<br/><strong>시설매물정보</strong>와<br/><strong>새로운정보</strong>를<br/>알려드립니다.`,
                  },
                },
                boxPosition: USER_LOGGEDIN
                  ? { data: '2-2', customStyle: { x: -305, y: 10 } }
                  : { data: '2', customStyle: { x: -38, y: 60 } },
                boxCssClass: USER_LOGGEDIN ? 'myPage' : 'signIn',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                masking: { data: '5', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '시설 검색',
                  contents: {
                    __html: '전국의 시설에서<br/>원하는 조건을<br/>선택하여 검색해<br/>보세요.',
                  },
                },
                boxPosition: { data: '5', customStyle: { x: 110, y: 70 } },
                boxCssClass: 'search',
              },
            ],
          },
        ],
        mobile: [
          {
            next: { element: 'page', customStyle: { top: 'auto', bottom: '60px' } },
            guides: [
              {
                boxText: {
                  title: '',
                  contents: {
                    __html: `
                                <div class='welcome'>
                                    <div class='logo'></div>
                                    <h2>요양시설 중개의 새로운 <strong>패러다임!</strong></h2>
                                    <p>
                                        <strong>보노랜드</strong>는 공공 빅데이터를 활용한 <strong>프롭테크 서비스</strong>를 제공합니다.
                                    </p>
                                    <ul>
                                        <li class='search'><span>시장조사 기능</span></li>
                                        <li class='calc'><span>수익계산 기능</span></li>
                                        <li class='privacy'><span>프라이버시 보호</span></li>
                                        <li class='markets'><span>허위매물 차단</span></li>
                                    </ul>
                                </div>
                            `,
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 - 140),
                    y: parseInt(window.innerHeight / 2 - 250),
                  }),
                },
                boxCssClass: 'welcome m-fade',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: { data: '4', customStyle: { rx: 2, ry: 2 } },
                boxText: { title: '', contents: { __html: '' } },
                boxPosition: {
                  data: '4',
                  customStyle: () => ({ x: -parseInt(window.innerWidth / 2 - 50), y: 8 }),
                },
                boxCssClass: 'mStatistics',
                script: () => {
                  dispatch(updateFilterForMap({ zoom: 13, latlng: [37.5666103, 126.9783882] }));
                },
              },
              {
                guideImage: {
                  src: mbChart,
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 - 90),
                    y: parseInt(window.innerHeight - 84),
                    width: 180,
                  }),
                },
                boxText: {
                  title: '시장조사',
                  contents: {
                    __html:
                      '위로 스크롤하여 해당 지역의<br/><strong>고령 인구수, 등급판정 인원수,</strong><br><strong>요양시설 수, 이용률</strong><br/>을 확인하실 수 있습니다.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 - 100),
                    y: parseInt(window.innerHeight / 2 - 60),
                  }),
                },
                boxCssClass: 'mStatistics02',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                guideImage: {
                  src: mbMarker,
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 - 160),
                    y: parseInt(window.innerHeight / 2 + 40),
                    width: 150,
                    height: 150,
                  }),
                },
                boxText: {
                  title: '시장조사',
                  contents: {
                    __html:
                      '해당 지역의<br/> <strong>시설개수</strong> 확인 및<br/>해당 시의<br/><strong>등급 판정 인원 대비</strong><br/><strong>시설 포화도</strong><br/>를 알려드립니다.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth / 2 - 100),
                    y: parseInt(window.innerHeight / 2 - 110),
                  }),
                },
                boxCssClass: 'mMarker',
                script: () => {
                  dispatch(updateFilterForMap({ zoom: 11, latlng: [37.5035917, 126.766] }));
                },
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: { data: 'm2', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '예상 수익 계산',
                  contents: {
                    __html: '원하는 시설의<br/><strong>예상 월수익</strong>을<br/> 계산해 보세요.',
                  },
                },
                boxPosition: { data: 'm2', customStyle: { x: -300, y: -44 } },
                boxCssClass: 'mCalc',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: { data: '7', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '단독요양원',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>단독요양원</strong>을 조건<br/>별로 확인해 보세요.',
                  },
                },
                boxPosition: { data: '7', customStyle: { x: 40, y: 6 } },
                boxCssClass: 'mCate01',
              },
              {
                masking: { data: '8', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '상가요양원',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>상가요양원</strong>을 조건<br/>별로 확인해 보세요.',
                  },
                },
                boxPosition: { data: '8', customStyle: { x: 40, y: 8 } },
                boxCssClass: 'mCate02',
              },
              {
                masking: { data: '9', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '주야간보호센터',
                  contents: {
                    __html:
                      '해당 지역의<br/><strong>주야간보호센터</strong>을 조건<br/>별로 확인해 보세요.',
                  },
                },
                boxPosition: { data: '9', customStyle: { x: 40, y: 8 } },
                boxCssClass: 'mCate03',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: {
                  data: 'm5',
                  customStyle: { x: 6, width: -12, y: 4, height: -8, rx: 6, ry: 6 },
                },
                guideImage: {
                  data: 'm5',
                  src: mbNav,
                  customStyle: { x: 7, width: -14, y: 5, height: 46 },
                },
                boxText: {
                  title: '홈',
                  contents: {
                    __html:
                        '<strong>홈</strong> 클릭 시 <br/>메인으로 돌아갑니다.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth * 0.075 - 8),
                    y: parseInt(window.innerHeight - 585),
                  }),
                },
                boxCssClass: 'mSales-long',
              },
              {
                masking: {
                  data: 'm6',
                  customStyle: { x: 6, width: -12, y: 4, height: -8, rx: 6, ry: 6 },
                },
                guideImage: {
                  data: 'm6',
                  src: mbNav,
                  customStyle: { x: 7, width: -14, y: 5, height: 46 },
                },
                boxText: {
                  title: '보노랜드 매물',
                  contents: {
                    __html:
                      '<strong>보노랜드</strong>에서<br/>적극 추천하는<br/><strong>매물</strong>을 확인해<br/>보세요.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    // x: parseInt(window.innerWidth * 0.125 - 8),
                    x: parseInt(window.innerWidth * 0.205 - 8),
                    y: parseInt(window.innerHeight - 460),
                  }),
                },
                boxCssClass: 'mSales',
              },
              {
                masking: {
                  data: 'm8',
                  customStyle: { x: 6, width: -12, y: 4, height: -8, rx: 6, ry: 6 },
                },
                guideImage: {
                  data: 'm8',
                  src: mbNav,
                  customStyle: { x: 7, width: -14, y: 5, height: 46 },
                },
                boxText: {
                  title: '매물접수',
                  contents: { __html: '<strong>시설의 매도</strong>를 원하면<br/>등록해 보세요.' },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth * 0.450 - 50),
                    y: parseInt(window.innerHeight - 316),
                  }),
                },
                boxCssClass: 'mSales-shart',
              },
              {
                masking: {
                  data: 'm9',
                  customStyle: { x: 6, width: -12, y: 4, height: -8, rx: 6, ry: 6 },
                },
                guideImage: {
                  data: 'm9',
                  src: mbNav,
                  customStyle: { x: 7, width: -14, y: 5, height: 46 },
                },
                boxText: {
                  title: '매수문의',
                  contents: {
                    __html:
                      '<strong>원하는 유형과 규모의</strong><br/><strong>시설</strong>을 찾고 싶으면<br/>등록해 보세요.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth * 0.625 - 80),
                    y: parseInt(window.innerHeight - 212),
                  }),
                },
                boxCssClass: 'mContact',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: {
                  data: 'm10',
                  customStyle: { x: 6, width: -12, y: 4, height: -8, rx: 6, ry: 6 },
                },
                guideImage: {
                  data: 'm10',
                  src: mbNav,
                  customStyle: { x: 7, width: -14, y: 5, height: 46 },
                },
                boxText: {
                  title: USER_LOGGEDIN ? '마이페이지' : '로그인',
                  contents: {
                    __html: `${
                      USER_LOGGEDIN ? '' : '회원가입/로그인 후<br/>'
                    }<strong>마이페이지 > 알림</strong><br/>을 설정하시면<br/>원하는 타입의<br/><strong>시설매물정보</strong>와<br/><strong>새로운정보</strong>를<br/>알려드립니다.`,
                  },
                },
                boxPosition: {
                  customStyle: () => ({
                    x: parseInt(window.innerWidth * 0.9 - 170),
                    y: parseInt(window.innerHeight - 360),
                  }),
                },
                boxCssClass: 'mLogin',
              },
              {
                masking: {
                  data: 'm11',
                  customStyle: { x: 1, y: 1, width: -2, height: -2, rx: 30, ry: 30 },
                },
                boxText: {
                  title: '전화 연결',
                  contents: { __html: '<strong>보노랜드 대표번호</strong><br/>로 연결합니다.' },
                },
                boxPosition: { data: 'm11', customStyle: { x: -115, y: -393 } },
                boxCssClass: 'mTel',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: { data: '5', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '시설 검색',
                  contents: {
                    __html: '전국의 시설에서<br/>원하는 조건을<br/>선택하여 검색해<br/>보세요.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({ x: parseInt(window.innerWidth * 0.5 - 80), y: 80 }),
                },
                boxCssClass: 'mSearch',
              },
            ],
          },
        ],
      },
      details: {
        pc: [
          {
            next: { element: 'button' },
            guides: [
              {
                masking: { data: '11', customStyle: { rx: 2, ry: 2 } },
                boxText: {
                  title: '예상 수익 계산',
                  contents: {
                    __html: '선택한 시설의<br/><strong>예상 월수익</strong>을<br/> 계산해 보세요.',
                  },
                },
                boxPosition: { data: '11', customStyle: { x: -62, y: 50 } },
                boxCssClass: 'dCalc',
              },
            ],
          },
          {
            next: { element: 'button' },
            guides: [
              {
                guideImage: {
                  src: pcDetails,
                  customStyle: { x: 0, y: 195, width: 390, height: 48 },
                },
                boxText: {
                  title: '시설 상세정보',
                  contents: {
                    __html:
                      '선택한 시설의<br/><strong>상세정보</strong><br/><strong>(건강보험공단 제공)</strong><br/>를 확인해 보세요.',
                  },
                },
                boxPosition: { customStyle: { x: 15, y: 255 } },
                boxCssClass: 'details',
              },
              {
                boxText: {
                  title: '부동산 정보',
                  contents: {
                    __html:
                      '선택한 시설의<br/><strong>부동산 정보</strong><br/><strong>(국토교통부 제공)</strong><br/>를 확인해 보세요.',
                  },
                },
                boxPosition: { customStyle: { x: 210, y: 255 } },
                boxCssClass: 'lands',
              },
            ],
          },
        ],
        mobile: [
          {
            next: { element: 'page' },
            guides: [
              {
                guideImage: {
                  src: mbCalc,
                  customStyle: () => ({
                    y: 12,
                    x: parseInt(window.innerWidth - 40),
                    width: 32,
                    height: 32,
                  }),
                },
                boxText: {
                  title: '예상 수익 계산',
                  contents: {
                    __html: '선택한 시설의<br/><strong>예상 월수익</strong>을<br/> 계산해 보세요.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({ y: 12, x: parseInt(window.innerWidth - 270) }),
                },
                boxCssClass: 'mDCalc',
              },
            ],
          },
          {
            next: { element: 'page' },
            guides: [
              {
                masking: {
                  customStyle: () => ({ x: 0, y: 112, height: 48, width: window.innerWidth }),
                },
                boxText: {
                  title: '시설 상세정보',
                  contents: {
                    __html:
                      '선택한 시설의<br/><strong>상세정보</strong><br/><strong>(건강보험공단 제공)</strong><br/>를 확인해 보세요.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({ x: parseInt(window.innerWidth * 0.25 - 80), y: 175 }),
                },
                boxCssClass: 'mDetails',
              },
              {
                boxText: {
                  title: '부동산 정보',
                  contents: {
                    __html:
                      '선택한 시설의<br/><strong>부동산 정보</strong><br/><strong>(국토교통부 제공)</strong><br/>를 확인해 보세요.',
                  },
                },
                boxPosition: {
                  customStyle: () => ({ x: parseInt(window.innerWidth * 0.75 - 80), y: 175 }),
                },
                boxCssClass: 'mLands',
              },
            ],
          },
        ],
      },
    }),
    [USER_LOGGEDIN, dispatch],
  );

  const [guide, setGuide] = useState(null);
  const [guideNum, setGuideNum] = useState(0);
  const [isResizing, setIsResizing] = useState(false);

  let resizeTimeout = null;

  const onWindowResize = useCallback(() => {
    setIsResizing(true);
    clearTimeout(resizeTimeout);
    resizeTimeout = null;
    resizeTimeout = setTimeout(function () {
      setIsResizing(false);
    }, 500);
  }, []);

  const onCloseSubmit = e => {
    e.preventDefault();
    dispatch(deactivateGuide({ page: page, off: offRef.current.checked }));
  };

  const onNextClick = () => {
    if (guideNum >= guide.length - 1 || resizeTimeout !== null) return;
    else setGuideNum(guideNum => guideNum + 1);
  };

  useEffect(() => {
    if (page.length > 0) {
      window.addEventListener('resize', onWindowResize);

      setTimeout(function () {
        setGuide(GUIDE[page][isDesktop ? 'pc' : 'mobile']);
        setGuideNum(0);
      }, 200);
    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [GUIDE, onWindowResize, page]);

  return (
    <GuidePage
      onClick={() => {
        if (guide[guideNum].next.element === 'page') onNextClick();
      }}
    >
      {(isResizing || !guide) && (
        <div className="loadingWrap">
          <div className="glass" />
        </div>
      )}
      {!isResizing && guide && (
        <div className={`wrap ${!isDesktop ? 'mobile' : ''}`}>
          <svg height="100%" width="100%">
            <defs>
              <mask id="gMask" width={window.innerWidth} height={window.innerHeight}>
                <rect width="100%" height="100%" fill="rgba(255,255,255,.8)"></rect>
                {guide[guideNum] &&
                  guide[guideNum].guides.map((item, idx) => {
                    if (!item.masking) return '';

                    const mask = item.masking;
                    let maskingRect = { x: 0, y: 0, width: 0, height: 0 };
                    const maskingStyle = Object.assign(
                      { x: 0, y: 0, width: 0, height: 0, rx: 0, ry: 0 },
                      mask.customStyle
                        ? typeof mask.customStyle === 'function'
                          ? mask.customStyle()
                          : mask.customStyle
                        : {},
                    );

                    if (mask.data) {
                      const maskingEl = document.querySelector(
                        `.bono-guide[data-guide="${mask.data}"]`,
                      );
                      if (maskingEl) maskingRect = maskingEl.getBoundingClientRect();
                    }

                    return (
                      <rect
                        x={maskingRect.x + maskingStyle.x + 'px'}
                        y={maskingRect.y + maskingStyle.y + 'px'}
                        width={maskingRect.width + maskingStyle.width + 'px'}
                        height={maskingRect.height + maskingStyle.height + 'px'}
                        rx={maskingStyle.rx + 'px'}
                        ry={maskingStyle.ry + 'px'}
                        key={`${idx + 1}`}
                      ></rect>
                    );
                  })}
              </mask>
            </defs>
            <rect width="100%" height="100%" mask="url(#gMask)"></rect>
          </svg>
          {guide[guideNum].next.element === 'page' && guideNum < guide.length - 1 && (
            <NextInform
              style={guide[guideNum].next.customStyle ? guide[guideNum].next.customStyle : {}}
            >
              {isDesktop ? (
                <span>
                  화면 <strong>클릭</strong>시 다음으로 이동
                </span>
              ) : (
                <span>
                  <strong>터치</strong>하시면 다음으로 넘어갑니다.
                </span>
              )}
            </NextInform>
          )}
          {guide[guideNum].next.element === 'button' && guideNum < guide.length - 1 && (
            <NextButton
              style={guide[guideNum].next.customStyle ? guide[guideNum].next.customStyle : {}}
              onClick={() => {
                onNextClick();
              }}
            />
          )}
          {guide[guideNum].guides.map((item, index) => {
            let guideBoxRect = { x: 0, y: 0, width: 0, height: 0 };
            let renderGuideImg = null;

            if (item.boxPosition.data) {
              const positoinEl = document.querySelector(
                `.bono-guide[data-guide="${item.boxPosition.data}"]`,
              );
              if (positoinEl) guideBoxRect = positoinEl.getBoundingClientRect();
            }

            if (item.boxPosition.customStyle) {
              const style =
                typeof item.boxPosition.customStyle === 'function'
                  ? item.boxPosition.customStyle()
                  : item.boxPosition.customStyle;
              guideBoxRect.x += style.x ? style.x : 0;
              guideBoxRect.y += style.y ? style.y : 0;
            }

            if (item.script) item.script();

            if (item.guideImage) {
              let guideImageRect = { x: 0, y: 0, width: 0, height: 0 };
              const customStyle = item.guideImage.customStyle
                ? typeof item.guideImage.customStyle === 'function'
                  ? item.guideImage.customStyle()
                  : item.guideImage.customStyle
                : {};
              const guideStyle = Object.assign({ x: 0, y: 0, width: 0, height: 0 }, customStyle);

              if (item.guideImage.data) {
                const guideImage = document.querySelector(
                  `.bono-guide[data-guide="${item.guideImage.data}"]`,
                );
                if (guideImage) guideImageRect = guideImage.getBoundingClientRect();
              }

              renderGuideImg = () => (
                <img
                  style={{
                    zIndex: '102',
                    position: 'fixed',
                    top: guideImageRect.y + guideStyle.y + 'px',
                    left: guideImageRect.x + guideStyle.x + 'px',
                    width: guideImageRect.width + guideStyle.width + 'px',
                    height: guideStyle.height !== 0 ? guideStyle.height + 'px' : 'auto',
                  }}
                  src={item.guideImage.src}
                  alt="가이드"
                />
              );
            }

            return (
              <>
                {renderGuideImg && renderGuideImg()}
                {item.boxText && (
                  <GuideBox
                    key={index}
                    style={{
                      top: guideBoxRect.y + 'px',
                      left: guideBoxRect.x + 'px',
                    }}
                    className={`box-${item.boxCssClass}`}
                  >
                    <i />
                    <div>
                      <h3>{item.boxText.title}</h3>
                      <p dangerouslySetInnerHTML={item.boxText.contents} />
                    </div>
                  </GuideBox>
                )}
              </>
            );
          })}
          {guideNum === guide.length - 1 && (
            <CloseForm
              onSubmit={e => {
                onCloseSubmit(e);
              }}
            >
              <fieldset>
                <input type="checkbox" id="guideOff" ref={offRef} />
                <label htmlFor="guideOff">다시 보지 않음</label>
              </fieldset>
              <button type="submit">닫기</button>
            </CloseForm>
          )}
        </div>
      )}
    </GuidePage>
  );
};

export default SiteGuide;
