import styled from 'styled-components';
import pinIcon from '../../assets/images/map/ico-pin.svg';

export const Region = styled.div`
  display: ${(props) => props.display};
  z-index: 11;
  position: fixed;
  top: 104px;
  left: 50%;
  transform: translateX(-50%);
  height: 46px;
  line-height: 46px;
  padding: 0 24px 0 44px;
  border-radius: 23px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  background: url(${pinIcon}) 20px center/ 14px 14px no-repeat #fff;
  font-size: ${({ theme }) => theme.fontSizes.s};

  .mobile & {
    top: 68px;
    height: 32px;
    line-height: 32px;
    padding: 0 14px 0 28px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
    color: ${({ theme }) => theme.colors.gray700};
    background-position: 10px center;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;
