import React, { Children } from 'react';
import { ContactButton } from './FloatingButtonStyled';

const ContactFloatingButton = ({ customStyle, color = null, shadow = true, tel = '16610169', children }) => (
  <ContactButton style={customStyle} href={`tel:${tel}`} color={color} shadow={shadow}>
    {children}
  </ContactButton>
);

export default ContactFloatingButton;
