import React, { useState } from 'react';
import { GUIDEBOX } from './MaemulGuideStyled';
import { useDispatch } from 'react-redux';
import { deactivateGuide } from '../../../store/actions/guide';
import balloonIcon from '../../../assets/images/guide/ico-maemulBalloon.svg';

const GUIDE = [
  {
    boxText: {
      title: '시설매물 확인',
      contents: {
        __html: `
            <p>
                해당지역의 시설매물은
                <br />
                <strong>시설매물 버튼</strong>을
                <br/>
                누르시면 확인하실 수 있습니다.
            <p>
        `,
      },
    },
    boxPosition: { left: 'calc(10% - 22px)' },
  },
  // {
  //     boxText: { title: '보노랜드 추천매물 확인', contents: {__html: `
  //         <p>
  //             해당지역의 보노랜드 추천매물은
  //             <br />
  //             <strong>추천매물 버튼</strong>을
  //             <br/>
  //             누르시면 확인하실 수 있습니다.
  //         <p>
  //     `} },
  //     boxPosition: { left: 'calc(10% - 22px)' }
  // }
];

const MaemulGuide = () => {
  const dispatch = useDispatch();

  const [guideIdx, setGuideIdx] = useState(0);

  const onCloseClick = e => {
    e.preventDefault();
    if (guideIdx >= GUIDE.length - 1) dispatch(deactivateGuide({ page: 'maemul', off: false }));
    else setGuideIdx(guideIdx => guideIdx + 1);
  };

  const onGuideOffCLick = e => {
    e.preventDefault();
    dispatch(deactivateGuide({ page: 'maemul', off: true }));
  };

  return (
    <>
      {guideIdx >= 0 && (
        <GUIDEBOX index={guideIdx}>
          <div className="wrap">
            <button
              className="close"
              onClick={e => {
                onCloseClick(e);
              }}
            ></button>
            <div className="title">{GUIDE[guideIdx].boxText.title}</div>
            <div className="contents" dangerouslySetInnerHTML={GUIDE[guideIdx].boxText.contents} />
            {guideIdx === GUIDE.length - 1 && (
              <button
                className="off"
                onClick={e => {
                  onGuideOffCLick(e);
                }}
              >
                다시 보지 않음
              </button>
            )}
          </div>
          <svg width="30" height="20" viewBox="0 0 30 20">
            <polygon
              points="0,0 20,0 10,20"
              stroke="#BD9369"
              strokeOpacity="0.8"
              strokeLinejoin="round"
              fill="#BD9369"
              fillOpacity="0.8"
            />
          </svg>
        </GUIDEBOX>
      )}
    </>
  );
};

export default MaemulGuide;
