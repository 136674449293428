import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './store';
import App from './App';
import AppStyle from './AppStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from './themes/theme';

const Root = () => (
  <HashRouter>
    <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <AppStyle />
            <App />
          </ThemeProvider>
        </PersistGate>
    </Provider>
  </HashRouter>
);

export default Root;
