import React from "react";

import Ellipse from "./Ellipse";
import StyledText from "./StyledText";
import { WIDTH } from "../../containers/Landing/LandingContainer";
import CommonBlock from "./CommonBlock";
import landing_logo from "../../assets/images/landing/landing_logo.png";
import { isDesktop } from "../../utils/detect";

const Lecture = ({ isInset }) => {
  const onClick = () =>
    window.open("https://docs.google.com/forms/d/e/1FAIpQLScb_XqWZTy3T8EhjMezOeERpFzVS-Y_WLW4qD4frzLtYFpktQ/viewform?pli=1");

  return isDesktop ? (
    <CommonBlock height={220} width={WIDTH} top={isInset ? 40 : 70} justifyContent="center" background={"linear-gradient(#FFEBB8,#F6DC9A)"}>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "12px", cursor: "pointer" }} onClick={onClick}>
        <StyledText fontSize={20} fontWeight={700} color="#212121">
          이달의 특강
        </StyledText>

        <StyledText fontSize={16} color="#757575">
          오직 보노랜드에서만 들을 수 있는 액기스 강의
        </StyledText>

        <StyledText fontSize={28} fontWeight={500} color="#e68119">
          120분 요양시설 창업 특강
        </StyledText>

        <Ellipse width={200} height={46} borderColor="#e68119" marginTop={12}>
          <StyledText fontSize={18} fontWeight={500} color="#e68119">
            특강 신청하기
          </StyledText>
        </Ellipse>
      </div>
    </CommonBlock>
  ) : (
    <CommonBlock height={200} display="flex" top={isInset ? 40 : 60} justifyContent="center" isShadow={false}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1rem" }}>
          <img style={{ width: 22, height: 29 }} src={landing_logo} alt="landing_logo" />

          <StyledText fontSize={20} fontWeight={700} color="#212121" marginLeft={10}>
            이달의 특강
          </StyledText>
        </div>

        <StyledText fontSize={14} color="#757575">
          오직 보노랜드에서만 들을 수 있는 액기스 강의
        </StyledText>

        <StyledText fontSize={20} fontWeight={500} color="#8A653F" marginTop={5}>
          120분 요양시설 창업 특강
        </StyledText>

        <div
          onClick={onClick}
          style={{
            display: "flex",
            padding: "10px",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #BC936A",
            marginTop: "2rem",
            cursor: "pointer",
            borderRadius: "99px",
          }}>
          <StyledText fontSize={13} color="#8A653F">
            특강 신청하기
          </StyledText>
        </div>
      </div>
    </CommonBlock>
  );
};

export default Lecture;
