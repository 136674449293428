import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activateChart, deactivateChart } from "../../../store/actions/chart";
import { QuickBtn, QuickBox } from "./QuickMenuStyle";
import { activateLogin, activateLoginRequired } from "../../../store/actions/service";
import {
  // activateCadastral,
  // deactivateCadastral,
  setUserGeolocation,
  // toggleStreetMode,
} from "../../../store/actions/map";
import { isDesktop } from "../../../utils/detect";
import { GEOLOCATION } from "../../../utils/user";
import { activateAlert } from "../../../store/actions/alert";
import { updateFilterForMap } from "../../../store/actions/filter";
import { activateMyAlarm, activateMyMenu, activateNews, activateMyAlarmForm } from "../../../store/actions/page";
import { getRegionByLatlng, getRegionByZoom, getStringAddr } from "../../../utils/map";
import CalculatorsMenu from "../CalculatorsMenu/CalculatorsMenu";
import {insertUserLocationLog} from "../../../api/user";

const QuickMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const FILTER_MAP = useSelector(state => state.Filter.map);
  const USER_GEO = useSelector(state => state.Map.geolocation.latlng);

  const [chartReady, setChartReady] = useState(false);
  const CHART_DATA = useSelector(state => state.Chart.data);
  const CHART_ACTIVE = useSelector(state => state.Chart.active);

  const USER = useSelector(state => state.User);
  const USER_LOGGEDIN = USER.loggedIn;
  const LOCAL_ALARMS = USER.userInfo.alarms && USER.userInfo.alarms.length > 0;

  const [calcMenuOn, setCalcMenuOn] = useState(false);

  const onCalcClick = e => {
    e.preventDefault();
    if (USER_LOGGEDIN) {
      setCalcMenuOn(true);
    } else {
      dispatch(
        activateAlert({
          title: "보노랜드 회원 서비스",
          contents: "해당 기능은 회원가입 후 사용하실 수 있습니다.",
        })
      );
    }
  };

  const onChartClick = () => {
    if (CHART_DATA) {
      if (CHART_ACTIVE) dispatch(deactivateChart());
      else dispatch(activateChart());
    } else {
      dispatch(
        activateAlert({
          title: "인구 통계",
          contents: "시 단위에서 인구 통계를 확인하실 수 있습니다.",
        })
      );
    }
  };

  const createUserLocationLog = async () => {
    if (!USER.loggedIn) {
      return;
    }
    try {
      await insertUserLocationLog({userEmail: USER.userInfo.id});
    } catch (e) {
      console.log(e);
    }

  }

  // 현재 내 위치 정보 조회 후 장기요양시설 전문중계서비스 내역 저장
  const onLocationClick = async () => {
    GEOLOCATION.get()
      .then(async position => {
        const LATLNG = [position.coords.latitude.toString(), position.coords.longitude.toString()];
        const region = getStringAddr(getRegionByZoom(await getRegionByLatlng(LATLNG), 15));
        dispatch(updateFilterForMap({ latlng: LATLNG, zoom: 15 }));
        dispatch(
          setUserGeolocation({
            latlng: LATLNG,
            region: region,
          })
        );
        createUserLocationLog();
      })
      .catch(err => {
        dispatch(
          activateAlert({
            title: "사용자 위치 정보 조회",
            contents: "현재 사용자의 위치 정보를 가져올 수 없습니다.",
          })
        );
      });
  };

  useEffect(() => {
    if (!isDesktop) {
      if (CHART_DATA) setChartReady(true);
      else setChartReady(false);
    } else {
      if (CHART_DATA && CHART_ACTIVE) setChartReady(true);
      else setChartReady(false);
    }
  }, [FILTER_MAP, CHART_DATA, CHART_ACTIVE]);

  return (
    <>
      {isDesktop && (
        <QuickBox className={`user bono-guide ${USER_LOGGEDIN ? "on" : ""}`} data-guide="2-2">
          <button
            onClick={() => {
              if (USER_LOGGEDIN) dispatch(activateMyMenu());
              else dispatch(activateLogin());
            }}>
            마이페이지
          </button>
        </QuickBox>
      )}
      {isDesktop && (
        <QuickBtn
          className="alarm bono-guide"
          data-guide="10"
          onClick={() => {
            if (USER_LOGGEDIN) {
              if (LOCAL_ALARMS) dispatch(activateMyAlarm());
              else dispatch(activateMyAlarmForm());
            } else {
              dispatch(activateLoginRequired());
            }
          }}>
          알람 설정
        </QuickBtn>
      )}
      {isDesktop && (
        <QuickBtn className={`location ${USER_GEO && USER_GEO.length > 0 ? "active" : ""}`} onClick={() => onLocationClick()}>
          내위치
        </QuickBtn>
      )}
      {!isDesktop && (
        <QuickBox className={`location ${USER_GEO && USER_GEO.length > 0 ? "active" : ""}`}>
          <button onClick={() => onLocationClick()}>내위치</button>
        </QuickBox>
      )}
      <QuickBtn className={`chart ${chartReady ? "on bono-guide" : "bono-guide"}`} data-guide="4" onClick={() => onChartClick()}>
        인구
      </QuickBtn>
      <QuickBtn className="news bono-guide" data-guide="m1" onClick={() => dispatch(activateNews())}>
        뉴스
      </QuickBtn>
      {!isDesktop && (
        <QuickBtn className="calc mobile bono-guide" data-guide="m2" onClick={onCalcClick}>
          수익계산기
        </QuickBtn>
      )}
      {!isDesktop && (
        <QuickBtn
          className="maemul mobile on"
          onClick={() => {
            navigate("/bono/nursing");
          }}>
          매물
        </QuickBtn>
      )}
      {!isDesktop && calcMenuOn && (
        <CalculatorsMenu
          onCloseClick={() => {
            setCalcMenuOn(false);
          }}
        />
      )}
    </>
  );
};

export default QuickMenu;
