import Section from "../../components/ui/Section/Section";
import UserMenu from "../../components/User/UserMenu/UserMenu";
import { useDispatch } from "react-redux";
import { isDesktop } from "../../utils/detect";
import React from "react";
import { deactivateMyMenu } from "../../store/actions/page";

const UserMenuContainer = () => {
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(deactivateMyMenu());
  };

  return (
    <Section
      title={"마이페이지"}
      themeColor="primary"
      close={isDesktop && true}
      back={!isDesktop && true}
      onCloseClick={onCloseClick}
      onBackClick={onCloseClick}
      isDesktop={isDesktop}>
      <UserMenu />
    </Section>
  );
};

export default UserMenuContainer;
