import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal/Modal";
import MobileSection from "../../components/global/MobileSection/MobileSection";
import Contact from "../../components/Contact/Contact";
import { isDesktop } from "../../utils/detect";
import { useDispatch, useSelector } from "react-redux";
import { activateAlert } from "../../store/actions/alert";
import { contactCenter } from "../../api/service";
import { USER_AUTH } from "../../utils/user";
import { deactivateContact } from "../../store/actions/service";
import { useGet } from "../../hooks/index";
import { getAllTerms } from "../../api/terms";
import ContactFloatingButton from "../../components/ui/FloatingButton/ContactFloatingButton";

const ContactContainer = ({ centerData }) => {
  const dispatch = useDispatch();
  const IS_LOGGEDIN = useSelector(state => state.User.loggedIn);

  // 개인정보 활용동의 (1) 약관 받아오기
  const [term, setTerm] = useState("");
  const [loading, error, data, setGet] = useGet("");

  // 로그인 되었을 때
  const [user, setUser] = useState({ uName: "", uEmail: "", uTel: "" });

  // 문의 폼 제출
  const onFormSubmit = async data => {
    if (data.uVisit === "") {
      return dispatch(
        activateAlert({
          title: "문의 접수 오류",
          contents: "방문 경로를 입력해주세요.",
        })
      );
    }

    const option = {
      userName: data.uName,
      userTel: data.uTel,
      userEmail: data.uEmail,
      siDoCd: data.cSido,
      siGunGuCd: data.cGugun,
      adminPttnCd: data.cType,
      questionCtg: data.cContact,
      expctCapital: data.cAssets,
      requestContent: data.cContents,
      visitRoute: data.uVisit === "직접입력" ? data.uVisitSelf : data.uVisit,
    };

    if (centerData) option.sisulCustomNo = centerData.id;

    const RESPONSE = await contactCenter(option);

    if (RESPONSE) {
      dispatch(deactivateContact());
      dispatch(
        activateAlert({
          title: "매수 문의",
          contents: RESPONSE.data.message || "매수 문의가 완료되었습니다.",
        })
      );
    } else {
      dispatch(
        activateAlert({
          title: "문의 접수 오류",
          contents: "문의 접수 중 오류가 발생했습니다.",
        })
      );
    }
  };

  useEffect(() => {
    if (IS_LOGGEDIN) {
      const USER = USER_AUTH.get();
      setUser({
        uEmail: USER.id,
        uName: USER.name,
        uTel: USER.tel,
      });
    }
    setGet(getAllTerms());
  }, []);

  useEffect(() => {
    if (data && data.code === 0) setTerm(data.arrayResult[1].siteContents);
    else setTerm("이용약관을 불러올 수 없습니다. 새로고침 해주세요.");
  }, [data]);

  const RENDER_CONTACT = () => <Contact user={user} center={centerData} privacyTerm={term} onFormSubmit={onFormSubmit} />;

  return (
    <>
      {isDesktop && (
        <Modal
          open={true}
          close={true}
          onCloseClick={() => {
            dispatch(deactivateContact());
          }}
          width="890"
          height="580"
          title="매수 문의">
          {RENDER_CONTACT()}
        </Modal>
      )}
      {!isDesktop && (
        <MobileSection
          title="매수문의"
          onBackClick={() => {
            dispatch(deactivateContact());
          }}>
          <ContactFloatingButton
            customStyle={{ zIndex: "91", position: "fixed", top: "7px", right: "12px" }}
            color="primary"
            shadow={false}
            tel="16610169"
          />
          {RENDER_CONTACT()}
        </MobileSection>
      )}
    </>
  );
};

export default ContactContainer;
