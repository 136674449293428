import { List as NewsListWrap } from "./NewsListStyle";
import { Card, Post } from "../NewsCard/NewsCardStyle.js";
import React, { useEffect, useState } from "react";
import { Loading, NoData, Error } from "../../ui/Inform/Inform";

const NewsList = ({ items, onCardClick, hasNext, isNextLoading, loadNext }) => {
  const [last, setLast] = useState(null);

  const onObserve = (entry, observer) => {
    if (entry[0].isIntersecting && !isNextLoading) {
      loadNext();
      observer.unobserve(entry[0].target);
    }
  };

  useEffect(() => {
    let observer;
    if (last) {
      observer = new IntersectionObserver(onObserve, { threshold: 0.5 });
      observer.observe(last);
    }
    return () => observer && observer.disconnect();
  }, [last]);

  return (
    <NewsListWrap>
      {items?.length <= 0 && isNextLoading && Loading()}
      {items?.length > 0 &&
        items.map((item, idx) => (
          <React.Fragment key={idx}>
            <Card
              className="bd"
              onClick={() => {
                onCardClick(item);
              }}>
              <Post>
                <h3>{item.newsTitle}</h3>
                <p>{item.newsContent.slice(0, 125)}...</p>
              </Post>
            </Card>

            {idx >= items?.length - 1 && hasNext && <div ref={setLast}>...</div>}
          </React.Fragment>
        ))}
    </NewsListWrap>
  );
};

export default NewsList;
