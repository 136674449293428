import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";

import { WIDTH } from "../../containers/Landing/LandingContainer";
import CommonBlock from "./CommonBlock";
import image14 from "../../assets/images/landing/image14.png";
import StyledText from "./StyledText";
import Ellipse from "./Ellipse";
import { isDesktop } from "../../utils/detect";

const Container = styled.div({
  flex: 1,
  position: "relative",
  cursor: "pointer"
});

const SpanText = styled.span({
  fontSize: isDesktop ? 28 : 20,
  color: "#212121",
  fontWeight: 700,
});

const BottomBlock = styled.div({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 20,
});

const HighLighter = styled.span({
  fontSize: isDesktop ? 28 : 20,
  color: "#212121",
  fontWeight: 700,
  background: "linear-gradient(to top, #ffe400 50%, transparent 50%)",
});

const QuickSale = ({ recommend, onClick }) => {
  const isShow = recommend?.result?.expose;

  return isDesktop ? (
    <CommonBlock height={524} width={WIDTH} top={40} display={isShow ? "flex" : "none"} >
      <Container onClick={onClick(recommend?.result?.sisulCustomNo)}>
        <SpanText>이달의 </SpanText>

        <HighLighter>급매 매물 추천</HighLighter>

        <StyledText fontSize={16} fontWeight={500} color="#616161" marginTop={10}>
          {recommend?.result?.local} {recommend?.result?.adminPttnCd}
        </StyledText>

        <img
          style={{ width: "100%", height: 330, alignSelf: "center", marginTop: 20 }}
          src={recommend?.result?.sisulFilePath}
          alt="image14"
        />

        <BottomBlock>
          {recommend?.result?.tradingPrice !== null ? (
            <div
              style={{
                display: "flex",
                alignSelf: "flex-end",
                alignItems: "flex-end",
              }}>
              <StyledText fontSize={18} fontWeight={500} color="#212121">
                매매가
              </StyledText>

              <StyledText fontSize={32} fontWeight={700} color="#2962FF" marginLeft={5}>
                {recommend?.result?.convertTradingPrice}
              </StyledText>

              <StyledText fontSize={18} fontWeight={500} color="#2962FF" marginLeft={5}>
                억원
              </StyledText>

            </div>
          ) : null}

          <Ellipse width={200} height={46} borderColor="#212121" background="#212121" >
            <StyledText fontSize={18} fontWeight={500} color="#fff">
              보러가기
            </StyledText>
          </Ellipse>

        </BottomBlock>
      </Container>
    </CommonBlock>
  ) : (
    <CommonBlock height={360} display={isShow ? "flex" : "none"} top={30}>
      <div
          style={{ display: "flex", flexDirection: "column", flex: 1, cursor: "pointer" }}
          onClick={onClick(recommend?.result?.sisulCustomNo)}
      >
        <div>
          <SpanText>이달의 </SpanText>

          <HighLighter>급매 매물 추천</HighLighter>
        </div>

        <StyledText fontSize={13} fontWeight={500} color="#616161" marginTop={8}>
          {recommend?.result?.local} {recommend?.result?.adminPttnCd}
        </StyledText>

        <img
          style={{ width: "100%", height: 180, alignSelf: "center", marginTop: 20 }}
          src={recommend?.result?.sisulFilePath}
          alt="image14"
        />

        <BottomBlock>
          {recommend?.result?.tradingPrice !== null ? (
            <div
              style={{
                display: "flex",
                alignSelf: "flex-end",
                alignItems: "flex-end",
              }}>
              <StyledText fontSize={16} color="#212121">
                매매가
              </StyledText>

              <StyledText fontSize={24} fontWeight={700} color="#2962FF" marginLeft={5}>
                {recommend?.result?.convertTradingPrice}
              </StyledText>

              <StyledText fontSize={16} fontWeight={500} color="#2962FF" marginLeft={5}>
                억원
              </StyledText>
            </div>
          ) : null}

          <Ellipse
            width={136}
            height={42}
            borderColor="#212121"
            background="#212121">
            <StyledText fontSize={15} fontWeight={500}>
              보러가기
            </StyledText>
          </Ellipse>
        </BottomBlock>
      </div>
    </CommonBlock>
  );
};

export default QuickSale;
