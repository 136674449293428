import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { deactivateLanding } from "../../store/actions/landing";
import {
  activateBonoNews, activateLoginRequired,
  activateQna,
  activateRegister,
  activateResume,
  deactivateBonoNews
} from "../../store/actions/service";
import RegisterBox from "../../components/Landing/RegisterBox";
import Card from "../../components/Landing/Card";
import QuickSale from "../../components/Landing/QuickSale";
import map1 from "../../assets/images/landing/map1.png";
import star21 from "../../assets/images/landing/star21.png";
import Lecture from "../../components/Landing/Lecture";
import BonoWellLife from "../../components/Landing/BonoWellLife";
import { getRecently, getRecommend, getReviews } from "../../api/landing";
import RecentPost from "../../components/Landing/RecentPost";
import Remodeling from "../../components/Landing/Remodeling";
import Menu from "../../components/Landing/Menu";
import Review from "../../components/Landing/Review";
import { activateNews } from "../../store/actions/page";
import {activateAlert} from "../../store/actions/alert";

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 5rem;
  justify-content: center;
  margin-inline: 0.5rem;
  flex-direction: column;
  padding-bottom: 16rem;
`;

const CardContaier = styled.div({
  display: "flex",
  marginTop: "2rem",
});

const Responsive = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isLogin = useSelector(state => state.User.loggedIn);

  const userNo = useSelector(state => state.User.userInfo.no);

  const [recently, setRecently] = useState();
  const [reviews, setReviews] = useState();
  const [recommend, setRecommend] = useState();

  useEffect(() => {
    (async () => {
      const { data: recentlyData } = await getRecently(userNo);
      const { data: reviewsData } = await getReviews();
      const { data: recommendData } = await getRecommend();

      setRecently(recentlyData);
      setReviews(reviewsData);
      setRecommend(recommendData);
    })();
  }, []);

  const onRegister = () => {
    dispatch(activateRegister());
  };

  const onStartMapView = () => {
    dispatch(deactivateLanding());
  };

  const onStartMamul = () => {
    dispatch(deactivateLanding());
    navigate("/bono/nursing");
  };

  const onClickQuickSale = pk => () => {
    if (!isLogin) {
      return dispatch(activateLoginRequired());
    }
    navigate(`/center/${pk}?filter=all`);
  };

  const onClickMenu = key => () => {
    if (key === 1) {
      window.open("https://www.youtube.com/@Bonoland01");
    }
    if (key === 2) {
      dispatch(
          activateAlert({
            title: "PC 전용 서비스",
            contents: "채용 지원은 PC버전에 활성화 되어있습니다. PC로 접속해 지원해주세요.",
          })
      );
    }
    if (key === 3) {
      dispatch(activateNews());
      dispatch(activateBonoNews());
    }
    if (key === 4) {
      dispatch(activateNews());
      dispatch(deactivateBonoNews());
    }
    if (key === 5) {
      dispatch(activateQna());
    }
  };

  return (
    <Container>
      <RegisterBox onClick={onRegister} />

      <CardContaier>
        <Card
          background="linear-gradient(#FFA18B, #E87256)"
          onStartMapView={onStartMapView}
          title="지도에서 쉽게보는"
          sub="전국 시설리스트"
          img={map1}
          btnText="보러가기"
          marginRight={5}
          titleTextCol="#fff"
          subTextCol="#fff"
        />

        <Card
          background="linear-gradient(#FFEBB8, #F6DC9A)"
          onStartMapView={onStartMamul}
          title="보노랜드"
          sub="매물보기"
          img={star21}
          btnText="보러가기"
          titleTextCol="#BC936A"
          subTextCol="#212121"
          borderThemeCol="#000"
          marginLeft={5}
        />
      </CardContaier>

      <QuickSale recommend={recommend} onClick={onClickQuickSale} />

      <Lecture />

      <BonoWellLife />

      <RecentPost
        data={recently?.recentlyList}
        top={120}
        title="최근 등록 매물"
        titleCol="#2962FF"
        onClick={data => {
          navigate(`/center/${data?.sisulCustomNo}?filter=all`);
        }}
      />


      <RecentPost
        data={recently?.recentlySales}
        top={140}
        title="최근 본 매물"
        titleCol="#212121"
        onClick={data => {
          if (!isLogin) {
            return dispatch(activateLoginRequired());
          }
          navigate(`/center/${data?.sisulCustomNo}?filter=all`);
        }}
        loginRequired={!isLogin}
      />

      <Remodeling />

      <Menu onClick={onClickMenu} />

      {reviews && <Review data={reviews} />}

    </Container>
  );
};

export default Responsive;
