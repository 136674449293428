import React from 'react';
import styled from 'styled-components';

const Component = styled.div(({ width, height, marginTop, borderColor, background }) => ({
  display: 'flex',
  width,
  height,
  justifyContent: 'center',
  alignItems: 'center',
  marginTop,
  border: `1px solid ${borderColor}`,
  borderRadius: 60,
  background,
  cursor:"pointer"
}));

const Ellipse = ({ width, height = 46, marginTop, borderColor, children, background, onClick }) => {
  return (
    <Component
      width={width}
      height={height}
      marginTop={marginTop}
      borderColor={borderColor}
      background={background}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};

export default Ellipse;
