import styled, { keyframes } from 'styled-components';

// const fadeIn = keyframes`
//     0% { opaicty: 0; }
//     100% { opacity: 1; }
// `;

export const MAP = styled.div`
  z-index: 1;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff !important;
`;

export const PANORAMA = styled.div`
  z-index: 1;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
