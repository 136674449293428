export const getValidObjValue = (valObj, keyPairs, alter="") => {
    let result = alter;
    for(let keyPair of keyPairs) {
        let obj = valObj;
        for(let key of keyPair) {
            if(obj.hasOwnProperty(key)){
                obj = obj[key];
            } else {
                obj = null;
                break;
            }
        }
        if(obj !== null) {
            result = obj;
            break;
        }
    }
    return result;
};