import api from '.';

const CENTERS_URL = {
  bono: '/rcmndBono',
  filter: '/searchAreaProc',
  autoFilter: '/autoSearchFilter',
  category: '/sisul/totalNrsngHome',
};

/*  메인페이지 보노추천 목록  */
export const getBonoCenters = async option =>
  await api.get(CENTERS_URL.bono, {
    params: option,
  });

/*  메인페이지 자동 검색 데이터 목록 */
export const getAutoSearchData = async option =>
  await api.get(CENTERS_URL.autoFilter, {
    params: option,
  });

/*  메인페이지 필터링 목록  */
// export const getFilteredCenters = async (filters) => await api.post(CENTERS_URL.filter, filters);
export const getFilteredCenters = async filters => {
  const response = await api.post(CENTERS_URL.filter, filters);
  return response;
};

/* 카테고리 목록 */
// export const getCategoryCenters = async (options) => await api.post(CENTERS_URL.category, options);
export const getCategoryCenters = async options => {
  const response = await api.post(CENTERS_URL.category, options);
  return response;
};
