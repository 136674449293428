import { Card, Post } from './NewsCardStyle';
import React from 'react';
import { Loading, Error } from '../../ui/Inform/Inform';

const NewsCard = ({ type = '', news, loading, error }) => {
  return (
    <>
      {loading && Loading()}
      {error && Error()}
      {news && (
        <Card className="wrapper">
          <Post type={type}>
            <h3>{news.newsTitle}</h3>
            {news.newsFile && <img src={news.newsFile} alt={news.newsTitle} />}
            <p className="contents">{news.newsContent}</p>
            {type === 'post' && <div className="date">등록일자: {news.createDate.split('T')[0]}</div>}
          </Post>
        </Card>
      )}
    </>
  );
};

export default NewsCard;
