import { USER_GUIDE } from '../../utils/user';

export const GUIDE = {
  ACTIVATE: '/guide/on',
  DEACTIVATE: '/guide/off',
};

export const activateGuide = () => ({
  type: GUIDE.ACTIVATE,
});

export const deactivateGuide = offGuide => {
  USER_GUIDE.setSessionOff(offGuide.page);
  if (offGuide.off) USER_GUIDE.setLocalOff(offGuide.page);

  return {
    type: GUIDE.DEACTIVATE,
    payload: offGuide,
  };
};
