import React, { useState } from 'react';
import { SpreadWrap, SpreadButton, SpreadItem } from './FloatingButtonStyled';

const SpreadFloatingButton = ({ customStyle, items = [] }) => {
  const [spread, setSpread] = useState(false);

  return (
    <SpreadWrap style={customStyle} className={spread ? 'f-on' : 'f-off'}>
      <SpreadButton
        onClick={() => {
          setSpread((spread) => !spread);
        }}
      />
      {items.map((item, index) => (
        <SpreadItem key={index} index={index}>
          <button
            onClick={() => {
              item.onClick();
            }}
          >
            {item.text}
          </button>
        </SpreadItem>
      ))}
    </SpreadWrap>
  );
};

export default SpreadFloatingButton;
