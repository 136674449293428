import React, { useState } from "react";
import styled from "styled-components";

import CommonBlock from "./CommonBlock";
import StyledText from "./StyledText";
import More from "./More";
import { isDesktop } from "../../utils/detect";
import {activateLoginRequired} from "../../store/actions/service";
import {useDispatch, useSelector} from "react-redux";

const WIDTH = 600;

const Row = styled.div(({isLoginRequired}) => ({
  display: "flex",
  flexDirection: "column",
  filter: isLoginRequired ? "blur(5px)" : "blur(0)",
}));

const Container = styled.div(({ marginTop }) => ({
  display: "flex",
  cursor: "pointer",
  marginTop,
}));

const Title = styled.p({
  fontSize: 15,
  fontWeight: 400,
  color: "#616161",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: 400,
});

const Chip = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#BC936A",
  borderRadius: 2,
  padding: "3px 8px 3px 8px",
  marginLeft: 10,
});

const BlurModal = styled.div({
  width: "100%",
  height: "200px",
  zIndex: 1,
  background: "#fff",
  position: "absolute",
  bottom: 0,
  left: 0,
});

const RecentPost = ({ data, title, titleCol, top, onClick, loginRequired=false }) => {
  const [isMore, setIsMore] = useState(false);
  const dispatch = useDispatch();
  const onClickMore = () => !!data && setIsMore(prev => !prev);
  const isLogin = useSelector(state => state.User.loggedIn);

  return isDesktop ? (
    <CommonBlock
      height={isMore ? 470 : 270}
      width={WIDTH}
      flexDirection="column"
      top={top}
      justifyContent="space-between"
      onClick={onClick}>
      <StyledText fontSize={28} fontWeight={700} color={titleCol}>
        {title}
      </StyledText>

      {data?.length > 0 ? (
        data?.slice(0, isMore ? data?.length : 3).map((post, index) => (
          <Row key={`${index + 1}`} isLoginRequired={loginRequired}>
            <Container onClick={() => onClick(post)} marginTop={index || 20}>
              <Title>{post?.recentlySaleTitle}</Title>

              <Chip>
                <StyledText fontSize={12} fontWeight={500}>
                  {post?.adminPttnCd}
                </StyledText>
              </Chip>
            </Container>
          </Row>
        ))
      ) : (
          isLogin
              ? <p>데이터가 존재하지 않습니다.</p>
              : [0, 1, 2].map((post, index) => (
                  <Row key={`${index + 1}`} isLoginRequired={loginRequired}>
                    <Container onClick={() => onClick(post)} marginTop={index || 20}>
                      <Title>서울 00구 00000 00 00억</Title>

                      <Chip>
                        <StyledText fontSize={12} fontWeight={500}>
                          단독
                        </StyledText>
                      </Chip>
                    </Container>
                  </Row>
              ))
      )}

      <More onClick={!loginRequired ? onClickMore : () => dispatch(activateLoginRequired())}>{isMore ? "줄이기" : "+ 더보기"}</More>
    </CommonBlock>
  ) : (
    <CommonBlock
      height={isMore ? 400 : 250}
      display="flex"
      flexDirection="column"
      top={top}
      justifyContent="space-between"
      onClick={onClick}>
      <StyledText fontSize={28} fontWeight={700} color={titleCol}>
        {title}
      </StyledText>

      {data?.length > 0 ? (
        data?.slice(0, isMore ? data?.length : 3).map((post, index) => (
          <Row key={`${index + 1}`} isLoginRequired={loginRequired}>
            <Container onClick={() => onClick(post)} marginTop={index || 20}>
              <Title>{post?.recentlySaleTitle}</Title>

              <Chip>
                <StyledText fontSize={12} fontWeight={500}>
                  {post?.adminPttnCd}
                </StyledText>
              </Chip>
            </Container>
          </Row>
        ))
      ) : (
          isLogin
              ? <p>데이터가 존재하지 않습니다.</p>
              : [0, 1, 2].map((post, index) => (
                  <Row key={`${index + 1}`} isLoginRequired={loginRequired}>
                    <Container onClick={() => onClick(post)} marginTop={index || 20}>
                      <Title>서울 00구 00000</Title>

                      <Chip>
                        <StyledText fontSize={12} fontWeight={500}>
                          단독
                        </StyledText>
                      </Chip>
                    </Container>
                  </Row>
                ))
      )}

      <More onClick={!loginRequired ? onClickMore : () => dispatch(activateLoginRequired())}>{isMore ? "줄이기" : "+ 더보기"}</More>
    </CommonBlock>
  );
};

export default RecentPost;
