import React, { useState, useEffect } from "react";

import NewsList from "../../components/News/NewsList/NewsList";
import Section from "../../components/ui/Section/Section";
import { getNewsList, getNewsPost } from "../../api/news";
import { useGet } from "../../hooks";
import { isDesktop } from "../../utils/detect";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { deactivateNews } from "../../store/actions/page";
import NewsCard from "../../components/News/NewsCard/NewsCard";
import { getBonoNews } from "../../api/landing";
import { activateBonoNews, deactivateBonoNews } from "../../store/actions/service";

const NewsContainer = () => {
  const BONO_NEWS_MODE = useSelector(state => state.Service.bonoNews);

  const dispatch = useDispatch();

  const [newsList, setNewsList] = useState([]);
  const [post, setPost] = useState(null);

  const [postLoading, postError, postData, setPostGet] = useGet({});

  const [sectionProps, setSectionProps] = useState({});
  const [nextIndex, setNextIndex] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [bono, setBono] = useState();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setTab(BONO_NEWS_MODE ? 0 : 1);
  }, [BONO_NEWS_MODE]);

  useEffect(() => {
    (async () => {
      const { data } = await getBonoNews();

      setBono(data);
    })();
  }, []);

  const loadNext = async () => {
    setIsNextLoading(true);

    const RESPONSE = await getNewsList({ page: nextIndex });

    if (RESPONSE && RESPONSE.data.code === 1) {
      setNewsList([...newsList, ...RESPONSE.data.arrayResult]);
      setHasNext(RESPONSE.data.pageCode === 1);
    }

    setNextIndex(nextIndex => nextIndex + 1);
    setIsNextLoading(false);
  };

  const onCardClick = item => {
    if (BONO_NEWS_MODE) {
      window.open(item.newsURL);
    }

    if (!BONO_NEWS_MODE) {
      setPostGet(getNewsPost(item.newsNo));
    }
  };

  const onPostCloseClick = () => {
    setPost(null);
    setSectionProps(listSectionProps);
  };

  useEffect(() => {
    setNextIndex(1);
    setNewsList([]);
    setSectionProps(listSectionProps);
    loadNext();
  }, []);

  useEffect(() => {
    if (postData.arrayResult) {
      setPost(postData.arrayResult[0]);
      setSectionProps(postSectionProps(postData.arrayResult[0].newsURL));
    }
  }, [postData]);

  const listSectionProps = {
    title: "뉴스",
    themeColor: "secondary",
    close: isDesktop ? true : false,
    back: !isDesktop ? true : false,
    onCloseClick: () => dispatch(deactivateNews()),
    onBackClick: () => dispatch(deactivateNews()),
  };

  const postSectionProps = url => ({
    title: "뉴스",
    themeColor: "secondary",
    back: true,
    onBackClick: onPostCloseClick,
    action: true,
    actionText: "출처 링크",
    onActionClick: () => {
      window.open(url);
    },
  });

  const onClickTab = tab => () => {
    if (tab === 0) dispatch(activateBonoNews());
    if (tab === 1) dispatch(deactivateBonoNews());
    setTab(tab);
  };

  return (
    <Section {...sectionProps} isDesktop={isDesktop}>
      <div style={{ display: "flex", flex: 1, padding: 10, border: "1px solid #000" }}>
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            display: "flex",
            cursor: "pointer",
          }}
          onClick={onClickTab(0)}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>언론 속 보노랜드</p>
        </div>

        <div
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 10,
            display: "flex",
            borderLeft: "1px solid #000",
            cursor: "pointer",
          }}
          onClick={onClickTab(1)}>
          <p style={{ fontSize: 14, fontWeight: 600 }}>요양산업 뉴스</p>
        </div>
      </div>

      {!post && (
        <NewsList
          items={tab === 0 ? bono : newsList}
          onCardClick={onCardClick}
          hasNext={hasNext}
          isNextLoading={isNextLoading}
          loadNext={loadNext}
        />
      )}

      {post && <NewsCard type={"post"} news={post} loading={postLoading} error={postError} />}
    </Section>
  );
};

export default NewsContainer;
