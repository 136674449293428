import { Region } from "./MapRegionStyle";
import React from "react";

export const handleSejong = addr => {
  return addr === "세종특별자치시 세종특별자치시" ? "세종특별자치시 " : addr;
};

const MapRegion = ({ region }) => <Region display={region.length > 0 ? "block" : "none"}>{handleSejong(region)}</Region>;

export default MapRegion;
