import { Window, Close } from "./InfoWindowStyle";
import { getPyeong } from "../../../utils/number";
import React from "react";
import defaultImg from "../../../assets/images/form/img-default.png";
import { getLocalNumber } from "../../../utils/number";
import soldOutImg from "../../../assets/images/center/soldout.png";

const BADGES = {
  분양: "sales",
  리모델링: "sales",
  추천: "rcmd",
  프리미엄: "premium",
  매매: "sales",
};

const getImg = imgs => {
  if (imgs.length <= 0) return defaultImg;
  if (imgs.length === 1) return imgs[0]["sisulFilePath"].replaceAll(" ", "");

  const thumbs = imgs.filter(item => item["sisulFileType"] === "썸네일");
  if (thumbs.length > 0) return thumbs[0]["sisulFilePath"].replaceAll(" ", "");
  else return imgs[0]["sisulFilePath"].replaceAll(" ", "");
};

const InfoWindow = ({ data, onCloseClick, onContactClick, onDetailsClick }) => {
  const isMaemul = data["sisulState"] === "매물";

  return (
    <Window>
      <Close onClick={() => onCloseClick()} />
      <div className="info-window">
        <div className="info-window__body">
          <div className="pic">
            {data["bonoState"] === "거래완료" ? (
              <div
                className="img soldout"
                style={{
                  backgroundImage: `url(${soldOutImg})`,
                }}>
                거래완료
              </div>
            ) : (
              <div
                className="img"
                style={{
                  backgroundImage: `url(${getImg(data["sisulImage"])})`,
                }}
              />
            )}
          </div>
          <div className="conts">
            <div className="conts__badges">
              {data["bonoForm"] &&
                data["bonoForm"].split(",").map(item => <div className={`badge badge--${BADGES[item]}`}>{item}</div>)}
            </div>
            <span className="conts__name">{isMaemul ? `${data["adminPttnCd"]} ${data["toPer"]}인` : data["adminNm"]}</span>
            <span className="conts__addr">{data["local"] ? data["local"] : `${data["siDoCd"]} ${data["siGunGuCd"]}`}</span>
            <span className="conts__category">{isMaemul ? "" : `${data["adminPttnCd"]} ${data["toPer"]}인`}</span>
          </div>
          <div className="infos">
            <span className="infos__price">
              {!isMaemul
                ? ""
                : data["adminPttnCd"] == "주야간보호센터"
                ? `보증금 ${getLocalNumber(data["deposit"])} / 월세 ${getLocalNumber(data["monthlyRent"])}`
                : `매매 ${getLocalNumber(data["tradingPrice"])} 원`}
            </span>
            <span className="infos__capacity">{`면적 ${data["lndpclAr"] ? getPyeong(data["lndpclAr"]) : "-평"} / 인가 ${
              data["toPer"]
            }인 시설`}</span>
          </div>
        </div>

        <div className="info-window__actions">
          <button className={`btn btn--details ${isMaemul ? "c-pr" : ""}`} onClick={() => onDetailsClick(data["id"])}>
            상세
          </button>
          {isMaemul && (
            <button className="btn" onClick={() => onContactClick(data)}>
              문의
            </button>
          )}
        </div>
      </div>
    </Window>
  );
};

export default InfoWindow;
