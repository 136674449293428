export const MAP = {
  INIT_MAP: '/map',
  SHOW_OVERLAY: '/map/overlay/show',
  CLEAR_OVERLAY: '/map/overlay/clear',
  UPDATE_MARKERS: '/map/markers',
  UPDATE_INFOWINDOW: '/map/infowindow',
  ACTIVATE_GEOLOCATION: '/map/geoloactionOn',
  DEACTIVATE_GEOLOCATION: '/map/geolocationOff',
  STREET_MODE: '/map/street',
  ACTIVATE_CADASTRAL: '/map/cadastralOn', // 지적도 ON
  DEACTIVATE_CADASTRAL: '/map/cadastralOff', // 지적도 OFF
  ACTIVATE_MAEMULALERT: '/map/maemulAlertOn',
  DEACTIVATE_MAEMULALERT: '/map/maemulAlertOff',
};

export const initMap = map => ({
  type: MAP.INIT_MAP,
  payload: map,
});

export const showMapOverlay = showMode => ({
  type: MAP.SHOW_OVERLAY,
  payload: showMode,
});

export const clearMapOverlay = () => ({
  type: MAP.CLEAR_OVERLAY,
});

export const updateMapMarkers = data => ({
  type: MAP.UPDATE_MARKERS,
  payload: data,
});

export const updateMapInfoWindow = infoWindow => ({
  type: MAP.UPDATE_INFOWINDOW,
  payload: infoWindow,
});

export const setUserGeolocation = data => ({
  type: MAP.ACTIVATE_GEOLOCATION,
  payload: data,
});

export const clearUserGeolocation = () => ({ type: MAP.DEACTIVATE_GEOLOCATION });

export const activateCadastral = () => ({ type: MAP.ACTIVATE_CADASTRAL });
export const deactivateCadastral = () => ({ type: MAP.DEACTIVATE_CADASTRAL });

export const toggleStreetMode = () => ({ type: MAP.STREET_MODE });

export const activateMapMaemulAlert = () => ({ type: MAP.ACTIVATE_MAEMULALERT });
export const deactivateMapMaemulAlert = () => ({ type: MAP.DEACTIVATE_MAEMULALERT });

export const activateMapAlert = message => ({
  type: MAP.ACTIVATE_ALERT,
  payload: message,
});
export const deactivateMapAlert = () => ({ type: MAP.ACTIVATE_ALERT });
