export const FILTER = {
  RESET: '/filter/reset',
  UPDATE_FOR_DATA: '/filterByList',
  UPDATE_FOR_MAP: '/filterByMap',
  CLEAR_TEMP_FILTER: '/filter/cliear',
};

export const updateFilterForData = data => ({
  type: FILTER.UPDATE_FOR_DATA,
  payload: data,
});

export const updateFilterForMap = data => ({
  type: FILTER.UPDATE_FOR_MAP,
  payload: data,
});

export const clearTempFilterData = () => ({
  type: FILTER.CLEAR_TEMP_FILTER,
});

export const resetFilter = () => ({
  type: FILTER.RESET,
});
