import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal/Modal";
import MobileSection from "../../components/global/MobileSection/MobileSection";
import Register from "../../components/Register/Register";
import { useDispatch, useSelector } from "react-redux";
import { activateAlert } from "../../store/actions/alert";
import { registerCenter } from "../../api/service";
import { isDesktop } from "../../utils/detect";
import { USER_AUTH } from "../../utils/user";
import { deactivateRegister } from "../../store/actions/service";
import { useGet } from "../../hooks/index";
import { getAllTerms } from "../../api/terms";
import ContactFloatingButton from "../../components/ui/FloatingButton/ContactFloatingButton";
import { getNumber } from "../../utils/number";

const RegisterContainer = () => {
  const dispatch = useDispatch();
  const IS_LOGGEDIN = useSelector(state => state.User.loggedIn);

  // 개인정보 활용동의 (1) 약관 받아오기
  const [term, setTerm] = useState("");
  const [loading, error, data, setGet] = useGet("");

  // 로그인 되었을 때
  const [user, setUser] = useState({ uName: "", uEmail: "", uTel: "" });

  // 매물접수 닫기
  const onCloseClick = () => {
    dispatch(dispatch(deactivateRegister()));
  };

  const onFormSubmit = async data => {
    if (data.uVisit === "") {
      return dispatch(
        activateAlert({
          title: "매물 접수",
          contents: "방문 경로를 선택해주세요.",
        })
      );
    }

    const option = {
      userName: data.uName,
      adminNm: data.cName,
      sisulAddress: data.cAddr,
      userTel: data.uTel,
      userEmail: data.uEmail,
      siDoCd: data.cSido,
      siGunGuCd: data.cGugun,
      adminPttnCd: data.cType,
      lndpclAr: data.cSize,
      tradingPrice: getNumber(data.cPrice),
      monthlyRent: getNumber(data.cRent),
      toPer: getNumber(data.cCapacity),
      premium: getNumber(data.cPremium),
      nowLimit: getNumber(data.cPerson),
      sisulContents: data.cDesc,
      visitRoute: data.uVisit === "직접입력" ? data.uVisitSelf : data.uVisit,
    };

    const RESPONSE = await registerCenter(option);

    if (RESPONSE) {
      dispatch(deactivateRegister());
      dispatch(
        activateAlert({
          title: "매물 접수",
          contents: RESPONSE.data.message || "매물 접수가 완료되었습니다.",
        })
      );
    } else {
      dispatch(
        activateAlert({
          title: "매물 접수 오류",
          contents: "매물 접수 중 오류가 발생했습니다.",
        })
      );
    }
  };

  const RENDER_REGISTER = () => (
    <Register device={isDesktop ? "browser" : "mobile"} user={user} privacyTerm={term} onFormSubmit={onFormSubmit} />
  );

  useEffect(() => {
    if (IS_LOGGEDIN) {
      const USER = USER_AUTH.get();
      setUser({
        uEmail: USER.id,
        uName: USER.name,
        uTel: USER.tel,
      });
    }
    setGet(getAllTerms());
  }, [IS_LOGGEDIN]);

  useEffect(() => {
    if (data && data.code === 0) setTerm(data.arrayResult[1].siteContents);
    else setTerm("이용약관을 불러올 수 없습니다. 새로고침 해주세요.");
  }, [data]);

  return (
    <>
      {isDesktop && (
        <Modal open={true} close={true} onCloseClick={onCloseClick} width="890" height="580" title="매물 접수">
          {RENDER_REGISTER()}
        </Modal>
      )}
      {!isDesktop && (
        <MobileSection title="매물접수" onBackClick={onCloseClick}>
          <ContactFloatingButton
            customStyle={{ zIndex: "91", position: "fixed", top: "7px", right: "12px" }}
            color="primary"
            shadow={false}
            tel="16610169"
          />
          {RENDER_REGISTER()}
        </MobileSection>
      )}
    </>
  );
};

export default RegisterContainer;
