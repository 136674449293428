import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TabBar, TabLink, TabBtn } from "./MobileTabBarStyle";
import { activateLogin, activateContact, activateRegister } from "../../../store/actions/service";
import { activateMyMenu } from "../../../store/actions/page";
import { module } from "../../../themes/module";
import RemodelingMenu from "../RemodelingMenu";
import { activateLanding, deactivateLanding } from "../../../store/actions/landing";

const MobileTabBar = () => {
  const dispatch = useDispatch();
  const IS_LOGGEDIN = useSelector(state => state.User.loggedIn);
  const [remodelMenuOn, setRemodelMenuOn] = useState(false);

  return (
    <TabBar>
      <module.QuestionMark className="bono-guide" data-guide="0" />
      <ul>
        <li className="bono-guide" data-guide="m5">
          <TabBtn
            icon="home"
            onClick={() => {
              dispatch(activateLanding());
            }}>
            <p style={{ marginTop: 1.5 }}>홈</p>
          </TabBtn>
        </li>

        <li className="bono-guide" data-guide="m6">
          <TabLink
            icon="house"
            to="/bono/nursing"
            onClick={() => {
              dispatch(deactivateLanding());
            }}>
            <p style={{ marginTop: 1.5 }}>보노랜드 매물</p>
          </TabLink>
        </li>
        {/* <li className="bono-guide" data-guide="m7"><TabLink icon="rcmd" to="/bono/biz">추천매물</TabLink></li> */}
        <li className="bono-guide" data-guide="m8">
          <TabBtn
            icon="register"
            onClick={() => {
              dispatch(activateRegister());
            }}>
            매물접수
          </TabBtn>
        </li>
        <li className="bono-guide" data-guide="m9">
          <TabBtn
            icon="contact"
            onClick={() => {
              dispatch(activateContact());
            }}>
            매수문의
          </TabBtn>
        </li>
        <li>
          <TabBtn
            icon="remodeling"
            onClick={() => {
              setRemodelMenuOn(remodelMenuOn => !remodelMenuOn);
            }}>
            리모델링
          </TabBtn>

          <RemodelingMenu
            on={remodelMenuOn}
            setMenuOff={() => {
              setRemodelMenuOn(false);
            }}
            mobile
          />
        </li>
        {IS_LOGGEDIN && (
          <li className="bono-guide" data-guide="m10">
            <TabBtn
              icon="user"
              onClick={() => {
                dispatch(deactivateLanding());
                dispatch(activateMyMenu());
              }}>
              마이페이지
            </TabBtn>
          </li>
        )}
        {!IS_LOGGEDIN && (
          <li className="bono-guide" data-guide="m10">
            <TabBtn
              icon="user"
              onClick={() => {
                dispatch(activateLogin());
              }}>
              로그인
            </TabBtn>
          </li>
        )}
      </ul>
    </TabBar>
  );
};

export default MobileTabBar;
