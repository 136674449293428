import React from 'react';
import styled from 'styled-components';

const Text = styled.p(({ fontSize, fontWeight, color, marginTop, marginLeft, cursor }) => ({
  fontSize,
  fontWeight,
  color,
  marginTop,
  marginLeft,
  cursor,
}));

const StyledText = ({
  fontSize,
  fontWeight,
  color = '#fff',
  marginTop,
  marginLeft,
  cursor,
  children,
}) => {
  return (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      marginTop={marginTop}
      marginLeft={marginLeft}
      cursor={cursor}
    >
      {children}
    </Text>
  );
};

export default StyledText;
