import React from 'react';
import styled from 'styled-components';

const Container = styled.div(
  ({
    width,
    marginLeft,
    height,
    marginRight,
    background,
    flexDirection,
    isShadow = true,
    isPadding = true,
    isRadius = true,
    top,
    justifyContent,
    display,
    boxShadowColor = 'rgb(0, 0, 0, 0.2)'
  }) => ({
    display,
    width,
    height,
    borderRadius: isRadius ? 4 : undefined,
    marginLeft,
    marginRight,
    background,
    boxShadow: isShadow ? `0px 1px 6px 0px ${boxShadowColor}` : undefined,
    padding: isPadding ? 30 : undefined,
    flexDirection,
    position: 'relative',
    top,
    justifyContent,
  }),
);

const CommonBlock = ({
  display = 'flex',
  width,
  marginLeft,
  height,
  marginRight,
  background,
  flexDirection,
  isShadow,
  isPadding,
  isRadius,
  top,
  children,
  justifyContent,
  boxShadowColor
}) => {
  return (
    <Container
      display={display}
      width={width}
      marginLeft={marginLeft}
      height={height}
      marginRight={marginRight}
      background={background}
      flexDirection={flexDirection}
      isShadow={isShadow}
      isPadding={isPadding}
      isRadius={isRadius}
      top={top}
      justifyContent={justifyContent}
      boxShadowColor={boxShadowColor}
    >
      {children}
    </Container>
  );
};

export default CommonBlock;
