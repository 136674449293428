import { useState } from 'react';
import { getNumber, getLocalNumber } from '../../utils/number';

export const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return [{ value, onChange: (e) => setValue(e.currentTarget.value) }, () => setValue(initialValue)];
};

export const useOnlyNum = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const setOnlyNumber = (event) => {
    let value = getNumber(event.currentTarget.value);
    if (typeof value === 'string') {
      event.preventDefault();
    } else {
      setValue(getLocalNumber(value));
    }
  };

  const setClear = (defaultValue = '') => {
    setValue(defaultValue);
  };

  return [value, setOnlyNumber, setClear];
};
