import { isDesktop } from "../../../utils/detect";
import styled from "styled-components";
import React from "react";

const MainWrapper = styled.main`
  z-index: 20;
  position: ${isDesktop ? "relative" : "initial"};
  width: 100%;
  height: 100%;
  min-width: ${isDesktop ? "1240px" : "100%"};
`;

const Main = ({ children }) => {
  return <MainWrapper className={!isDesktop && "mobile"}>{children}</MainWrapper>;
};

export default Main;
