import React, { useState, useEffect } from 'react';
import AddressFilter from '../../components/filters/AddressFilter/AddressFilter';
import AutoSearchFilter from '../../components/filters/AutoSearchFilter/AutoSearchFilter';
import { useDispatch } from 'react-redux';
import { getSearchByAddress } from '../../utils/map';
import { activateAlert } from '../../store/actions/alert';
import { updateFilterForMap } from '../../store/actions/filter';
import { getAutoSearchData } from '../../api/centers';
import { handleSejong } from '../../components/MapRegion/MapRegion';

const TEXT_PATTERN = /([^0-9a-zA-Z가-힣\x20])/i;

const AddressFilterContainer = ({ type, category2 = '' }) => {
  const dispatch = useDispatch();

  const [address, setAddress] = useState('');
  const [autoSearchData, setAutoSearchData] = useState(null);
  const [maemulCategory, setMaemulCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [tm, setTm] = useState(null);

  const onAddressChange = event => {
    setAddress(event.currentTarget.value);
  };

  const onAddressSubmit = async event => {
    event.preventDefault();
    requestAutoSearchResult();
  };

  const onAutoSearchSubmit = async (area, sisulCustomNo = null, isMaemul) => {
    await getSearchByAddress(handleSejong(area))
      .then(res => {
        setAutoSearchData(null);
        dispatch(
          updateFilterForMap({
            zoom: res.zoom,
            latlng: res.latlng,
            address: res.address,
            sisulCustomNo: sisulCustomNo,
            sort: isMaemul ? 'maemul' : 'sisul',
          }),
        );
      })
      .catch(err => {
        dispatch(
          activateAlert({
            title: '검색 결과',
            contents: '검색 지역을 찾을 수 없습니다. 지역명을 정확히 입력해 주세요.',
          }),
        );
      });
  };

  const requestAutoSearchResult = async () => {
    setMessage('');

    if (address.length < 1 || TEXT_PATTERN.test(address)) {
      setIsLoading(false);
      return;
    }

    await getAutoSearchData({ keyword: address, category2: maemulCategory, type: type })
      .then(res => {
        setAutoSearchData(res.data);
        if (res.data.keywordFilters.length < 1 && res.data.localFilters.length < 1) {
          setMessage('검색 결과가 없습니다.');
        }
      })
      .catch(err => {
        setAutoSearchData(null);
        setMessage('검색 결과가 없습니다. \n 지역명, 시설명, 매물번호를 정확히 입력해 주세요.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!tm) {
      requestAutoSearchResult();
    }
  }, [tm]);

  useEffect(() => {
    setMaemulCategory(category2);
    setAddress('');
  }, [category2]);

  useEffect(() => {
    setIsLoading(address.length > 0);
    clearTimeout(tm);

    if (address.length > 0) {
      let nTm = setTimeout(() => {
        setTm(null);
      }, 1500);
      setTm(nTm);
    } else {
      setAutoSearchData(null);
      setMessage('');
    }
  }, [address]);

  return (
    <>
      <AddressFilter
        type={type}
        address={address}
        onAddressChange={onAddressChange}
        onAddressSubmit={onAddressSubmit}
        setAutoSearchData={setAutoSearchData}
      />
      {
        <AutoSearchFilter
          type={type}
          data={autoSearchData}
          address={address}
          onAutoSearchSubmit={onAutoSearchSubmit}
          loading={isLoading}
          message={message}
        />
      }
    </>
  );
};

export default AddressFilterContainer;
