import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isDesktop } from "../../utils/detect";
import Modal from "../../components/Modal/Modal";
import { deactivateRemodelingContact, deactivateRemodelingJoin } from "../../store/actions/service";
import { useGet } from "../../hooks/index";
import MobileSection from "../../components/global/MobileSection/MobileSection";
import RemodelingJoin from "../../components/Remodeling/RemodelingJoin";
import { getAllTerms } from "../../api/terms";
import { requestRemodelingJoin } from "../../api/service";
import { activateAlert } from "../../store/actions/alert";

const RemodelingJoinContainer = () => {
   const dispatch = useDispatch();

   const [term, setTerm] = useState("");
   const [loading, error, data, setGet] = useGet("");

   const onFormSubmit = async data => {
      const options = {
         company: data.cName,
         visitPath: data.cVisit === "직접입력" ? data.cVisitSelf : data.cVisit,
         address: data.cAddr,
         manager: data.cManager,
         phone: data.cTel,
         email: data.cEmail,
         url: data.cWeb,
         construction: data.cType,
         hold: data.cOwner,
         introduce: data.cDesc,
      };

      const RESPONSE = await requestRemodelingJoin(options);

      if (RESPONSE) {
         dispatch(deactivateRemodelingJoin());
         dispatch(
            activateAlert({
               title: "리모델링 협력사 신청",
               contents: RESPONSE.data.message || "리모델링 협력사 신청이 완료되었습니다.",
            })
         );
      } else {
         dispatch(
            activateAlert({
               title: "리모델링 협력사 신청",
               contents: "리모델링 협력사 신청 중 오류가 발생했습니다.",
            })
         );
      }
   };

   const RENDER_RMD_CONTACT = () => <RemodelingJoin term={term} onFormSubmit={onFormSubmit} />;

   useEffect(() => {
      setGet(getAllTerms());
   }, []);

   useEffect(() => {
      if (data && data.code === 0) setTerm(data.arrayResult[1].siteContents);
      else setTerm("이용약관을 불러올 수 없습니다. 새로고침 해주세요.");
   }, [data]);

   return (
      <>
         {isDesktop && (
            <Modal
               open={true}
               close={true}
               title="리모델링 협력사 신청"
               width="890"
               height="580"
               onCloseClick={() => dispatch(deactivateRemodelingContact())}>
               {RENDER_RMD_CONTACT()}
            </Modal>
         )}
         {!isDesktop && (
            <MobileSection
               title="리모델링 협력사 신청"
               themeColor="primary"
               onBackClick={() => dispatch(deactivateRemodelingContact())}>
               {RENDER_RMD_CONTACT()}
            </MobileSection>
         )}
      </>
   );
};

export default RemodelingJoinContainer;
