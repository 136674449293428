import React, { useState } from "react";
import CommonBlock from "./CommonBlock";
import { WIDTH } from "../../containers/Landing/LandingContainer";
import styled from "styled-components";
import StyledText from "./StyledText";
import Ellipse from "./Ellipse";
import int1 from "../../assets/images/landing/int1.png";
import { isDesktop } from "../../utils/detect";
import { useDispatch, useSelector } from "react-redux";
import { activateRemodelingContact } from "../../store/actions/service";
import RemodelingMenu from "../global/RemodelingMenu";
import { deactivateLanding } from "../../store/actions/landing";

const Empty = styled.div(() => ({
  minWidth: "300px",
}));

const RemodelContainer = styled.div(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  padding: isDesktop ? "20px 0px 10px 10px" : undefined,
}));

const Image = styled.img`
  width: 266px;
  height: 266px;
  position: absolute;
  right: 20px;
  bottom: 20px;

  filter: drop-shadow(0px 10px 20px gray);
`;

const Remodeling = () => {

  const dispatch = useDispatch();

  const [remodelMenuOn, setRemodelMenuOn] = useState(false);

  const onClick = () => {
    dispatch(activateRemodelingContact());
  };

  return isDesktop ? (
    <CommonBlock height={360} width={WIDTH} top={100}>
      <RemodelContainer>
        <Empty>
          <StyledText fontSize={18} color="#616161">
            매달 줄어드는 원생수에 스트레스 받고 계신가요?
          </StyledText>

          <StyledText fontSize={16} color="#616161" marginTop={6}>
            아이들에서 어르신으로 사업전환을 고려해 보세요
          </StyledText>

          <StyledText fontSize={28} fontWeight={700} color="#212121" marginTop={20}>
            어린이집 리모델링
          </StyledText>

          <StyledText fontSize={28} fontWeight={700} color="#212121" marginTop={5}>
            원스톱 서비스
          </StyledText>
        </Empty>

        <Ellipse width={178} height={46} borderColor="#616161" onClick={onClick}>
          <StyledText fontSize={15} fontWeight={500} color="#212121">
            쉬운견적 받아보기
          </StyledText>
        </Ellipse>
      </RemodelContainer>

      <Image src={int1} alt="int1" />
    </CommonBlock>
  ) : (
    <CommonBlock height={250} display="flex" top={160}>
      <RemodelContainer>
        <Empty>
          <StyledText fontSize={"0.875rem"} color="#616161">
            매달 줄어드는 원생수에 스트레스 받고 계신가요?
          </StyledText>

          <StyledText fontSize={"0.875rem"} color="#616161" marginTop={4}>
            아이들에서 어르신으로 사업전환을 고려해 보세요
          </StyledText>

          <StyledText fontSize={20} fontWeight={700} color="#212121" marginTop={10}>
            어린이집 리모델링
          </StyledText>

          <StyledText fontSize={20} fontWeight={700} color="#212121" marginTop={5}>
            원스톱 서비스
          </StyledText>
        </Empty>

        <div onClick={onClick}>
          <StyledText fontSize={15} fontWeight={500} color="#212121">
            쉬운견적 받아보기 〉
          </StyledText>
        </div>
      </RemodelContainer>

      <Image style={{ width: 140, height: 140 }} src={int1} alt="int1" />
    </CommonBlock>
  );
};

export default Remodeling;
